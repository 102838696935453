// @ts-nocheck
import { Button } from '@mui/material'
import React from 'react'
import styles from './ActiveTable.module.css'

function ActiveTable({ heading, data, onDetails }) {
  return (
    <div className={styles.card}>
      <div className={styles.headerRow}>
        <div className={styles.heading}>{heading}</div>
      </div>
      {data?.shifts.length !== 0 ? (
        data?.shifts?.map((item, index) => (
          <div key={index} className={styles.container}>
            <div className={styles.row}>
              <div>
                <div className={styles.name}>{`SHIFT #${item?.id}`}</div>
                <label className={styles.message}>{item?.start_time}</label>
              </div>
            </div>
            <Button
              variant="outlined"
              className={styles.details}
              onClick={() => onDetails(item)}
            >
              Details
            </Button>
          </div>
        ))
      ) : (
        <div
          style={{
            fontWeight: '600',
            color: '#8F95B2',
            paddingLeft: '20px',
          }}
        >
          No Shifts Available
        </div>
      )}
    </div>
  )
}

export default ActiveTable
