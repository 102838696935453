import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  Center,
  Container,
  Flex,
  Box,
  InputRightElement,
  InputGroup,
  VStack,
  Image,
  Text,
} from '@chakra-ui/react'
import { useForm, Controller } from 'react-hook-form'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { useState } from 'react'
import { useAuth } from 'main/hooks'
import { Link } from 'react-router-dom'
import { PhoneInput } from 'main/components'
import { useNavigate } from 'react-router-dom'

// import {NextPageWithLayout} from "main/configs/page.config";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { handleLogin } = useAuth()
  const { handleSubmit, control, formState, setError } = useForm()
  const navigate = useNavigate()

  const onSubmit = (data: any) => {
    setIsSubmitting(true)
    handleLogin(data)
    setTimeout(() => {
      setIsSubmitting(false)
    }, 1000)
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleSubmit(onSubmit)()
    }
  }

  return (
    <Container centerContent minH={'100vh'}>
      <Flex minH={'100vh'}>
        <Center>
          <Box
            borderRadius="md"
            boxShadow={{ base: 'none', md: 'md' }}
            h="fit-content"
            px={10}
            py={20}
          >
            <Image
              style={{ transition: '.2s', cursor: 'pointer' }}
              _hover={{
                transform: 'scale(1.08)',
                transition: '.2s',
              }}
              onClick={() => {
                navigate('/')
              }}
              src="/assets/images/Logo.png"
              mx="auto"
            />
            <form onSubmit={e => e.preventDefault()} style={{ minWidth: 300 }}>
              <VStack spacing={5}>
                <Controller
                  control={control}
                  name="phone"
                  rules={{ required: 'Field is required.' }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormControl isInvalid={!!error?.message} isRequired>
                      <FormLabel>Phone:</FormLabel>
                      <PhoneInput
                        value={value}
                        onChange={onChange}
                        setError={(value?: string) =>
                          setError('phone', { message: value })
                        }
                      />

                      {/* <Input type='text' value={value} onChange={onChange} /> */}
                      {error && (
                        <FormErrorMessage>{error.message}</FormErrorMessage>
                      )}
                    </FormControl>
                  )}
                />
                <Controller
                  control={control}
                  name="password"
                  rules={{
                    required: 'Field is required.',
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormControl isInvalid={!!error} isRequired>
                      <FormLabel>Password</FormLabel>
                      <InputGroup>
                        <Input
                          type={`${showPassword ? 'text' : 'password'}`}
                          value={value}
                          onChange={onChange}
                          onKeyDown={handleKeyDown}
                        />
                        <InputRightElement
                          onClick={() => setShowPassword(!showPassword)}
                          sx={{
                            '&:hover': {
                              cursor: 'pointer',
                            },
                          }}
                        >
                          {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                        </InputRightElement>
                      </InputGroup>
                      {error && (
                        <FormErrorMessage>{error.message}</FormErrorMessage>
                      )}
                    </FormControl>
                  )}
                />
                <Button
                  backgroundColor="#E3022A"
                  color={'#fff'}
                  variant="solid"
                  onClick={handleSubmit(onSubmit)}
                  isLoading={isSubmitting}
                  marginTop={4}
                  width={'100%'}
                  sx={{ '&:hover': { backgroundColor: '#CC0024' } }}
                >
                  Log In
                </Button>
                <Text mt={3}>
                  Don't have an account?
                  <Text ml={1} color="red" as="span">
                    <Link to="/register">Register</Link>
                  </Text>
                </Text>
              </VStack>
            </form>
          </Box>
        </Center>
      </Flex>
    </Container>
  )
}

// Login.authGuard = false;
// Login.getLayout = (page: ReactElement) => <>{page}</>;
export default Login
