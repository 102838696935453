import React, { useEffect } from 'react'
import './App.css'
import { Route, Routes, Navigate } from 'react-router-dom'
import { healthCareRoutes, homecareRoutes } from './routes'
import { ThemeProvider, createTheme } from '@mui/material'
import Layout from './components/Layout'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  },
})

export default function App() {
  const homecareTheme = createTheme({
    palette: {
      primary: {
        main: '#F52A4C',
      },
      secondary: {
        main: '#BABABA',
      },
      background: {
        default: '#FFFFFF',
      },
      custom: {
        black: '#000000',
        border: '#BABABA',
        gray: '#626262',
        button: '#EEEEEE',
        btnText: '#7B7B7B',
        link: '#585858',
        text: '#818181',
        success: '#51B614',
        bgRegister: '#F5F9FE',
        registerInput: '#F6F6F6',
      },
    },
    typography: {
      fontFamily: ['Inter'],
      button: {
        textTransform: 'none',
      },
      h1: {
        fontSize: '24px',
        fontWeight: '400',
        lineHeight: '36px',
      },
      h6: {
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '20px',
        marginRight: '20px',
      },
      body1: {
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '19px',
        color: '#626262',
      },
      body2: {
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '19px',
        color: '#000000',
      },
      body3: {
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '19px',
        color: '#626262',
      },
    },
  })

  useEffect(() => {
    const visitedBefore = localStorage.getItem('visitedBefore')
    if (!visitedBefore) {
      localStorage.setItem('visitedBefore', 'true')
    }
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={homecareTheme}>
        <Routes>
          {homecareRoutes.routes.map((router, index) => (
            <Route
              key={index}
              path={router.path}
              element={<Layout>{router.component}</Layout>}
            />
          ))}
          {healthCareRoutes.routes.map((router, index) => (
            <Route key={index} path={router.path} element={router.component} />
          ))}
          {!localStorage.getItem('visitedBefore') && (
            <Route path="/" element={<Navigate to="/individual/dashboard" />} />
          )}
        </Routes>
      </ThemeProvider>
    </QueryClientProvider>
  )
}
