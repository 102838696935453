// @ts-nocheck
import { Button } from '@mui/material'
import React from 'react'
import styles from './CancelledShiftCard.module.css'
import { DateHelper } from 'helper'

function CancelledShiftCard({ onViewDetails, status, item }) {
  return (
    <div className={styles.container}>
      {/* row 1 */}
      <div className={styles.row}>
        <div className={styles.column1}>
          <div className={styles.heading}>
            {item?.facilites?.facility_name || item?.individual?.full_name}
          </div>
        </div>
        <div className={styles.column2}>
          <div className={styles.label}>{`#${item?.id}`}</div>
        </div>
        <div className={styles.column3}>
          <div className={styles.label}></div>
        </div>
      </div>

      {/* row 2 and row 3 */}
      <div className={styles.buttonRow}>
        <div className={styles.buttonCol}>
          {/* row 2 */}
          <div className={styles.row}>
            <div className={styles.column1}>
              <div className={styles.label}>Homecare Shift</div>
            </div>
            <div className={styles.column4}>
              <div className={styles.label}>
                {`Start time: ${DateHelper.getDateTimeFromDate(
                  item?.start_time
                )}`}
              </div>
            </div>
            <div className={styles.column3}>
              <div className={styles.label} />
            </div>
          </div>

          {/* row 3 */}
          <div className={styles.row}>
            <div className={styles.column1}>
              <div className={styles.label}>
                {item?.staff?.first_name
                  ? `Staff: ${item?.staff?.first_name} ${item?.staff?.last_name}`
                  : 'No Staff Data'}
              </div>
            </div>
            <div className={styles.column4}>
              <div
                className={styles.label}
              >{`Cancel: ${DateHelper.getDateTimeFromDate(
                item?.canceled_time
              )}`}</div>
            </div>
            <div className={styles.column3}>
              <div className={styles.label} />
            </div>
          </div>
        </div>

        <Button
          variant="outlined"
          className={styles.btnView}
          onClick={onViewDetails}
        >
          View Details
        </Button>
      </div>

      {/* row 4 */}
      <div className={styles.row}>
        <div className={styles.column1}>
          <div className={styles.label}>{`${item?.city} ${item?.state}`}</div>
        </div>
        <div className={styles.column2}>
          <div className={`${styles.label} ${styles.reported}`}>{status}</div>
        </div>
        <div className={styles.column3}>
          <div className={styles.label} />
        </div>
      </div>
    </div>
  )
}

export default CancelledShiftCard
