import React, { useState, useEffect } from 'react'
import {
  Box,
  Stack,
  Typography,
  Menu,
  ListItemIcon,
  MenuItem,
  Link,
  Paper,
  Divider,
  LinearProgress,
} from '@mui/material'
import { KeyboardArrowDown, Logout } from '@mui/icons-material'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import { Logo } from '../../assets/svg'
import { useAuth } from 'main/hooks'
import { Link as RouterLink } from 'react-router-dom'
import Notifications from '../Notifications/Notifications'
import { useQuery } from '@tanstack/react-query'
import { DashboardService } from 'homecare/services'
import HistoryIcon from '@mui/icons-material/History'
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { api } from 'main/configs/axios.config'

export default function HomeCareHeader({ heading, isLoggedin }) {
  const { handleLogout } = useAuth()
  const [isLoading, setIsLoading] = useState(true)
  const [accountData, setAccountData] = useState(true)
  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const [stickyOpen, setStickyOpen] = useState(false)

  const handleToggleSticky = () => {
    setStickyOpen(prevState => !prevState)
  }

  const getNotifications = useQuery({
    queryKey: ['getNotifications'],
    queryFn: DashboardService.getNotifications,
  })

  useEffect(() => {
    setIsLoading(true)
    try {
      api.get('individuals/account').then(data => {
        setIsLoading(false)
        setAccountData(data)
      })
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }, [])

  if (getNotifications.isError)
    return <pre>{JSON.stringify(getNotifications.error)}</pre>

  //? IF SUCCESS
  const notifications = getNotifications.data

  return (
    <Box
      sx={{
        width: '100%',
        height: '10vh',
        minHeight: '80px',
        background: 'white',
        display: 'flex',
        justifyContent: { isLoggedin: 'center' },
        alignItems: 'center',
        paddingLeft: { xs: '5px', sm: '5px', md: '100px' },
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      }}
    >
      <Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
        <img
          src={Logo}
          alt="Nurse Billboard"
          style={{
            width: '50px',
            height: '50px',
            marginRight: '25px',
          }}
        />
      </Box>
      {!isLoggedin && <Typography sx={sxStyles.heading}>{heading}</Typography>}

      {isLoggedin ? (
        <Stack
          direction={'row'}
          justifyContent="space-between"
          alignItems="center"
          width="90%"
        >
          <Stack
            direction="row"
            width="100%"
            justifyContent={{
              xs: 'space-evenly',
              sm: 'space-evenly',
              md: 'flex-start',
            }}
            gap={{ xs: '25px', sm: '25px', md: '40px' }}
          >
            <Box>
              <RouterLink
                onClick={() => {
                  setStickyOpen(false)
                }}
                style={{
                  textDecoration: 'none',
                  color:
                    window.location.pathname === '/individual/dashboard'
                      ? '#E3022A'
                      : '#585858',
                  fontSize: '16px',
                  fontWeight: '600',
                }}
                to="/individual/dashboard"
              >
                Home
              </RouterLink>
            </Box>
            <Box display={{ xs: 'none', sm: 'none', md: 'flex' }}>
              <RouterLink
                onClick={() => {
                  setStickyOpen(false)
                }}
                style={{
                  textDecoration: 'none',
                  color:
                    window.location.pathname === '/individual/scheduled_shifts'
                      ? '#E3022A'
                      : '#585858',
                  fontSize: '16px',
                  fontWeight: '600',
                }}
                to="/individual/scheduled_shifts"
              >
                Scheduled shifts
              </RouterLink>
            </Box>
            <Box display={{ xs: 'flex', sm: 'flex', md: 'none' }}>
              <RouterLink
                onClick={() => {
                  setStickyOpen(false)
                }}
                style={{
                  textDecoration: 'none',
                  color:
                    window.location.pathname === '/individual/scheduled_shifts'
                      ? '#E3022A'
                      : '#585858',
                  fontSize: '16px',
                  fontWeight: '600',
                }}
                to="/individual/scheduled_shifts"
              >
                Shifts
              </RouterLink>
            </Box>
            <RouterLink
              onClick={() => {
                setStickyOpen(false)
              }}
              style={{
                textDecoration: 'none',
                color:
                  window.location.pathname === '/individual/messages'
                    ? '#E3022A'
                    : '#585858',
                fontSize: '16px',
                fontWeight: '600',
              }}
              to="/individual/messages"
            >
              Messages
            </RouterLink>

            <Box>
              <Link
                display={{ xs: 'none', sm: 'none', md: 'flex' }}
                onClick={() => {
                  handleToggleSticky()
                }}
                style={{
                  cursor: 'pointer',
                  textDecoration: 'none',
                  color:
                    window.location.pathname === '/individual/notifications'
                      ? '#E3022A'
                      : '#585858',
                  fontSize: '16px',
                  fontWeight: '600',
                }}
              >
                Notifications
              </Link>

              <Box
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  handleToggleSticky()
                }}
                display={{ xs: 'flex', sm: 'flex', md: 'none' }}
              >
                <NotificationsIcon
                  sx={{
                    color:
                      window.location.pathname === '/individual/notifications'
                        ? '#E3022A'
                        : '#585858',
                  }}
                  fontSize="small"
                />
              </Box>
            </Box>

            {stickyOpen && (
              <Paper
                sx={{
                  width: { xs: '100%', sm: '100%', md: '440px' },
                  position: 'fixed',
                  top: 70,
                  left: { xs: 10, sm: 10, md: 300 },
                  right: 0,
                  zIndex: 10000,
                  p: 2,
                  bg: 'primary.main',
                  color: 'primary.contrastText',
                  boxShadow: 'none',
                  filter: 'drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.2))',
                  borderRadius: '4px',
                  overflow: 'auto',
                  height: '500px',
                  '&::-webkit-scrollbar': {
                    width: '10px',
                    backgroundColor: '#F5F5F5',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#888',
                    borderRadius: '20px',
                    border: '3px solid #F5F5F5',
                  },
                }}
              >
                <Typography
                  mb="5px"
                  fontSize="14px"
                  fontWeight="600"
                  color="#1A1F36"
                >
                  Notifications
                </Typography>
                {getNotifications.isLoading ? (
                  <LinearProgress sx={{ height: 4 }} color="primary" />
                ) : (
                  notifications.map(notification => (
                    <>
                      <Notifications
                        key={notification.id}
                        title={notification.title}
                        body={notification.body}
                        date={notification.date}
                      />
                      <Divider sx={{ margin: 0, color: '#A5ACB8', p: '0' }} />
                    </>
                  ))
                )}
              </Paper>
            )}
          </Stack>

          <Stack
            marginLeft="10px"
            sx={{ cursor: 'pointer' }}
            direction="row"
            alignItems="center"
            onClick={handleClick}
          >
            {isLoading ? (
              <Typography
                width="max-content"
                display={{ xs: 'none', sm: 'none', md: 'flex' }}
              >
                Get information..
              </Typography>
            ) : (
              <Typography
                width="max-content"
                display={{ xs: 'none', sm: 'none', md: 'flex' }}
              >
                hello, {accountData?.full_name?.split(' ')[0]}
              </Typography>
            )}

            <KeyboardArrowDown />
          </Stack>

          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem
              onClick={() => {
                setStickyOpen(false)
              }}
            >
              <RouterLink
                onClick={() => {
                  setStickyOpen(false)
                }}
                style={{
                  textDecoration: 'none',
                  color:
                    window.location.pathname === '/individual/account'
                      ? '#E3022A'
                      : '#585858',
                  fontSize: '16px',
                  // fontWeight: '600',
                  display: 'flex',
                  alignItems: 'center',
                }}
                to="/individual/account"
              >
                <ListItemIcon>
                  <PersonOutlineIcon
                    sx={{
                      color:
                        window.location.pathname === '/individual/account'
                          ? '#E3022A'
                          : '#585858',
                    }}
                    fontSize="small"
                  />
                </ListItemIcon>
                Account
              </RouterLink>
            </MenuItem>

            <MenuItem
              onClick={() => {
                setStickyOpen(false)
              }}
            >
              <RouterLink
                onClick={() => {
                  setStickyOpen(false)
                }}
                style={{
                  textDecoration: 'none',
                  color:
                    window.location.pathname === '/individual/history'
                      ? '#E3022A'
                      : '#585858',
                  fontSize: '16px',
                  // fontWeight: '600',
                  display: 'flex',
                  alignItems: 'center',
                }}
                to="/individual/history"
              >
                <ListItemIcon>
                  <HistoryIcon
                    sx={{
                      color:
                        window.location.pathname === '/individual/history'
                          ? '#E3022A'
                          : '#585858',
                    }}
                    fontSize="small"
                  />
                </ListItemIcon>
                History
              </RouterLink>
            </MenuItem>

            <MenuItem
              onClick={() => {
                setStickyOpen(false)
              }}
            >
              <RouterLink
                onClick={() => {
                  setStickyOpen(false)
                }}
                style={{
                  textDecoration: 'none',
                  color:
                    window.location.pathname === '/individual/cards'
                      ? '#E3022A'
                      : '#585858',
                  fontSize: '16px',
                  // fontWeight: '600',
                  display: 'flex',
                  alignItems: 'center',
                }}
                to="/individual/cards"
              >
                <ListItemIcon>
                  <CreditCardOutlinedIcon
                    sx={{
                      color:
                        window.location.pathname === '/individual/cards'
                          ? '#E3022A'
                          : '#585858',
                    }}
                    fontSize="small"
                  />
                </ListItemIcon>
                Cards
              </RouterLink>
            </MenuItem>

            <MenuItem
              onClick={() => {
                handleLogout()
                window.location.reload()
              }}
            >
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Stack>
      ) : null}
    </Box>
  )
}

const sxStyles = {
  header: {
    width: '100%',
    height: '8vh',
    minHeight: '80px',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '100px',
    paddingTop: '20px',
    paddingBottom: '20px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  logo: {
    width: '50px',
    height: '50px',
    marginRight: '25px',
  },
  heading: {
    color: 'custom.black',
    fontWeight: '400',
    fontSize: '24px',
    lineHeight: '36px',
    marginLeft: '15px',
  },
}
