import React, { useState, useEffect } from 'react'
import { Box, Button, Card, CardContent, Typography } from '@mui/material'
import { HomeCareHeader } from '../../components'
import { PDFCheckBox, PDFDocument, PDFTextField } from 'pdf-lib'
import { Buffer } from 'buffer'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { DashboardService } from 'homecare/services'

export default function SignContract() {
  const [pdfData, setPdfData] = useState(null)
  const navigate = useNavigate()
  var requiredData = {}

  function handleClick() {
    navigate('/individual/healthcare-add-sign')
  }

  const getAccount = useQuery({
    queryKey: ['getAccount'],
    queryFn: DashboardService.getAccount,
  })

  useEffect(() => {
    const savedData = sessionStorage.getItem('RequiredDetails')
    if (savedData) {
      requiredData = JSON.parse(savedData)
    } else {
      navigate('/individual/dashboard')
    }
  })

  async function addTextToPdf() {
    const url = '/individual_contract.pdf'
    const res = await fetch(url).then(res => res.arrayBuffer())
    const pdfDoc = await PDFDocument.load(res)

    const form = pdfDoc.getForm()
    // Loop over each field and extract its name
    form.getFields().forEach(field => {
      console.log(field.getName())
    })
    const clientName = form.getTextField('client name')
    const nameOfPaitent = form.getTextField('name of paitent')
    const address = form.getTextField('address')
    const zip = form.getTextField('zip')
    const phone = form.getTextField('phone')
    const clientName2 = form.getTextField('client name2')
    const datedAtCity = form.getTextField('dated at city')
    const dayInput = form.getTextField('day')
    const monthInput = form.getTextField('month')
    const yearInput = form.getTextField('year')
    const company = form.getTextField('company')
    const fees1 = form.getTextField('fees1')
    const fees2 = form.getTextField('fees2')
    const fees3 = form.getTextField('fees3')
    const fees4 = form.getTextField('fees4')
    const fees5 = form.getTextField('fees5')
    const fees6 = form.getTextField('fees6')
    const fees7 = form.getTextField('fees7')
    const currentDate = new Date()
    const day = currentDate.getDate()
    const month = currentDate.getMonth() + 1
    const year = currentDate.getFullYear()
    const fullName = requiredData?.first_name + ' ' + requiredData?.last_name
    const [firstLetter, lastLetter] = fullName
      .split(' ')
      .map(str => str.charAt(0))

    clientName.setText(`${fullName}`)
    address.setText(`${requiredData?.address}`)
    zip.setText(`${requiredData?.postal_code}`)
    phone.setText(`${getAccount?.data?.phone}`)
    nameOfPaitent.setText(`${requiredData?.patient_name}`)
    clientName2.setText(`${fullName}`)
    datedAtCity.setText(`${requiredData?.city}`)
    dayInput.setText(`${day}`)
    monthInput.setText(`${month}`)
    yearInput.setText(`${year}`)
    company.setText('Wynalda Tataw')
    fees1.setText(`${firstLetter + lastLetter}`)
    fees2.setText(`${firstLetter + lastLetter}`)
    fees3.setText(`${firstLetter + lastLetter}`)
    fees4.setText(`${firstLetter + lastLetter}`)
    fees5.setText(`${firstLetter + lastLetter}`)
    fees6.setText(`${firstLetter + lastLetter}`)
    fees7.setText(`${firstLetter + lastLetter}`)

    const pdfBytes = await pdfDoc.save()
    const base64PdfString = Buffer.from(pdfBytes).toString('base64')
    const srcValue = 'data:application/pdf;base64,' + base64PdfString
    setPdfData(srcValue)
  }

  useEffect(() => {
    addTextToPdf()
  }, [])

  const renderPdf = () => {
    return (
      <div style={{ height: '75vh' }}>
        {pdfData && (
          <iframe
            style={{
              border: 'none',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
              width: '100%',
            }}
            title="PDF Viewer"
            src={pdfData}
            height="100%"
          />
        )}
      </div>
    )
  }
  return (
    <Box sx={sxStyles.container}>
      <HomeCareHeader heading="Sign Contract" />

      <Box sx={sxStyles.cardContainer}>
        <Card sx={sxStyles.card}>
          <CardContent
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '10px',
              flexDirection: { xs: 'column', sm: 'row', md: 'row' },
            }}
          >
            <Typography
              sx={sxStyles.cardHeading}
              color="custom.black"
              gutterBottom
            >
              Contract
            </Typography>
            <Button
              onClick={handleClick}
              sx={sxStyles.contractBtn}
              variant="contained"
            >
              Sign Contract
            </Button>
          </CardContent>
          {renderPdf()}
        </Card>
      </Box>
    </Box>
  )
}

const sxStyles = {
  container: {
    flex: 1,
    backgroundColor: 'background.default',
  },
  cardContainer: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    marginTop: 5,
  },
  card: {
    padding: 2,
    boxShadow: '0px 0px 80px #EFEFEF',
    width: '80%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardHeading: {
    fontSize: 30,
    fontWeight: 600,
  },
  contractBtn: {
    width: '150px',
    borderRadius: '56px',
  },
}
