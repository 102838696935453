// @ts-nocheck
import { api } from "main/configs/axios.config";

export const getReports = async ({ tab, date, time_zone }) => {
  const json = await api.get(
    `operation_panel/reported_shifts/${
      tab === "FR" ? "facility_reports" : "individual_reports"
    }?date=${date}&time_zone=${time_zone}`
  );
  return json;
};

export const refundIndividual = async (shift_id) => {
  const json = await api.post(
    `operation_panel/reported_shifts/individual_refund?shift_id=${shift_id}`
  );
  return json;
};

export const payStaff = async (shift_id) => {
  const json = await api.post(
    `operation_panel/reported_shifts/individual_pay_staff?shift_id=${shift_id}`
  );
  return json;
};

export const completeFacility = async (shift_id) => {
  const json = await api.post(
    `operation_panel/reported_shifts/facility_complete?shift_id=${shift_id}`
  );
  return json;
};

export const closeFacility = async (shift_id) => {
  const json = await api.post(
    `operation_panel/reported_shifts/facility_close?shift_id=${shift_id}`
  );
  return json;
};
