// @ts-nocheck
import { api } from "main/configs/axios.config";

export const getApproveFacility = async (page, page_size) => {
  const approveFacility = await api.get(
    `sales/approve_facility/new_facilities?page_number=${page}&page_size=${page_size}`
  );

  return approveFacility;
};

export const approveFacilities = async (ids) => {
  const approveFacility = await api.post(
    `sales/approve_facility/approve_facilities`,
    {
      ids,
    }
  );

  return approveFacility;
};

export const viewContract = async (filename) => {
  const contract = await api.get(`contracts/${filename}`, {
    responseType: "blob", //Force to receive data in a Blob Format
  });

  return contract;
};
