import React, { useEffect, useState } from 'react'
import styles from './FacilityClientDashboard.module.css'
import { Box, Grid } from '@mui/material'
import { Tune } from '@mui/icons-material'
import {
  Dropdown,
  InvoiceCard,
  SearchInput,
  ShiftCard,
  TasksContainer,
} from '../../components'
import Calendar from 'react-calendar'
import { FacilityDashboardService } from 'operational-sales-panel/services'
import { api } from 'main/configs/axios.config'
import { TimeZoneHelper } from 'helper'

function FacilityClientDashboard() {
  const [filter, setFilter] = useState({
    city: 'Choose city',
    role: 'Choose staff role',
  })
  const [value, onChange] = useState(new Date())
  const [facility, setFacility] = useState()
  const [shifts, setShifts] = useState()
  const [currentId, setCurrentId] = useState()

  useEffect(() => {
    const year = value.getFullYear()
    const month = String(value.getMonth() + 1).padStart(2, '0')
    const day = String(value.getDate()).padStart(2, '0')

    if (currentId) {
      getShiftsDetails(currentId, `${year}-${month}-${day}`)
    }
  }, [value])

  useEffect(() => {
    getAll()
  }, [])

  const getAll = async () => {
    const [facilities, shifts] = await Promise.all([
      FacilityDashboardService.getFacilityDashboard(),
    ])

    setFacility(facilities)
  }

  const getShiftsDetails = async (id, date = '2023-06-11') => {
    api
      .get(
        `operation_panel/facility_dashboard/shifts?date=${date}&time_zone=${TimeZoneHelper.getUserTimeZone()}&facility_id=${id}`
      )
      .then(res => setShifts(res))
  }

  return (
    <div className={styles.container}>
      <Grid container columnGap={2}>
        <Grid item md={8.5} xs={12}>
          <div className={styles.filterRow}>
            <div className={styles.row}>
              <SearchInput placeholder="Search by shift, name, etc" />
              <Dropdown
                bgWhite={true}
                placeholder="Choose city"
                options={['New York', 'Los Angeles', 'Chicago', 'Chicago']}
                value={filter.city}
                onChange={event =>
                  setFilter({ ...filter, city: event.target.value })
                }
              />
            </div>
            <div className={styles.filter}>
              <Tune className={styles.filterIcon} />
            </div>
          </div>

          <div className={styles.shiftRow}>
            {facility?.map((item, index) => (
              <ShiftCard
                key={index}
                heading={item?.facility_name}
                subHeading={item?.admin_title}
                shiftDetails={item?.address}
                phone={item?.phone}
                onClick={() => {
                  const year = value.getFullYear()
                  const month = String(value.getMonth() + 1).padStart(2, '0')
                  const day = String(value.getDate()).padStart(2, '0')
                  const id = item.id
                  setCurrentId(id)
                  getShiftsDetails(id, `${year}-${month}-${day}`)
                }}
              />
            ))}
          </div>
        </Grid>
        <Grid item md={3} xs={12}>
          <div className={styles.invoicesCard}>
            <div className={styles.heading}>Facility Details</div>
            <Calendar
              onChange={onChange}
              value={value}
              className={styles.calendar}
            />
            {shifts?.map((item, index) => (
              <InvoiceCard
                key={index}
                item={item}
                date={item?.requested_time}
                // time={item.time}
                nurse={item.nurse}
                rate={item.rate}
              />
            ))}
          </div>
          <TasksContainer
            tasks={[
              '12 facility cancel stafs',
              'Complete past one interviews',
              'Complete past one interviews over call',
            ]}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default FacilityClientDashboard
