// @ts-nocheck
import React, { useEffect, useState } from 'react'
import {
  Box,
  Pagination,
  Checkbox,
  Typography,
  Button,
  Modal,
  TextareaAutosize,
  Grid,
  Backdrop,
  Fade,
  Paper,
  Snackbar,
} from '@mui/material'
import {
  StaffLeadCard,
  FilterHeader,
  ContactFacilityModal,
  AutoComplete,
} from '../../components'
import { StaffLeadsService } from 'operational-sales-panel/services'
import { CitiesOptions } from 'constants'
import { api } from 'main/configs/axios.config'
import { states } from 'constants/states'
import { can } from 'main/configs/roles.config'

function StaffLeads() {
  const [open, setOpen] = useState(false)
  const [staffLeads, setStaffLeads] = useState()
  const [staffLeadsModal, setStaffLeadsModal] = useState()
  const [contactName, setContactName] = useState()
  const [contactStatus, setContactStatus] = useState()
  const [rowsPerPage, setRowsPerPage] = useState(9)
  const [filter, setFilter] = useState({
    search: '',
    city: '',
    state: '',
  })
  const [hotStaffChecked, setHotStaffChecked] = useState(false)
  const [schoolsStaffChecked, setSchoolsStaffChecked] = useState(false)
  const [hideContactChecked, setHideContactChecked] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [sending, setSending] = useState(false)
  const cold = '/assets/icons/cold.png'
  const hot = '/assets/icons/hot.png'
  const school = '/assets/icons/school.png'
  const [smsModalOpen, setSmsModalOpen] = useState(false)
  const [smsText, setSmsText] = useState('')
  const [toastOpen, setToastOpen] = useState(false)
  const [selectedState, setSelectedState] = useState('')
  const [selectedCities, setSelectedCities] = useState([])

  const openSmsModal = () => {
    setSmsModalOpen(true)
  }

  const closeSmsModal = () => {
    setSmsModalOpen(false)
    setSmsText('')
  }

  const handleBulkSms = async () => {
    let isValid = false

    if (!smsText) {
    } else {
      isValid = true
    }

    if (isValid) {
      try {
        setSending(true)
        const response = await api.post('/sales/bulk_sms', {
          text: smsText,
        })
        setSending(false)
        closeSmsModal()
        setSmsText('')
        setToastOpen(true)
      } catch (error) {
        console.error('Error adding note:', error)
      }
    }
  }

  const handlePageChange = (event, page) => {
    setCurrentPage(page)
  }

  const handleHotStaffChange = event => {
    setHotStaffChecked(event.target.checked)
  }

  const handleSchoolsStaffChange = event => {
    setSchoolsStaffChecked(event.target.checked)
  }

  const handleHideContactChange = event => {
    setHideContactChecked(event.target.checked)
  }

  useEffect(() => {
    StaffLeadsService.getStaffLeads({
      currentPage,
      page_size: rowsPerPage,
      search: filter?.search,
      city: filter.city,
      state: filter?.state,
      hot_staff: hotStaffChecked,
      is_school: schoolsStaffChecked,
      hide_contacted: hideContactChecked,
    })
      .then(data => setStaffLeads(data))
      .catch(err => console.log(err))
  }, [
    rowsPerPage,
    filter?.city,
    filter?.search,
    filter?.state,
    hotStaffChecked,
    schoolsStaffChecked,
    hideContactChecked,
    open,
    currentPage,
  ])

  const onMarkAsContacted = () => {
    StaffLeadsService.markContacted({
      id: staffLeadsModal?.id,
      contact_name: contactName,
      contact_status: contactStatus,
    })
      .then(() => setOpen(false))
      .catch(err => console.log(err))
  }

  const onMarkAsNotInterested = () => {
    StaffLeadsService.markNotInterested({
      id: staffLeadsModal?.id,
    })
      .then(() => setOpen(false))
      .catch(err => console.log(err))
  }

  const onMarkAsNoAnswer = () => {
    StaffLeadsService.markNoAnswer({
      id: staffLeadsModal?.id,
    })
      .then(() => setOpen(false))
      .catch(err => console.log(err))
  }

  const onChangeDropdown2 = (e, value) => {
    setSelectedState(value)
    setSelectedCities(states[value] || [])
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <FilterHeader
        placeholderSearch="Search staff"
        value={filter.search}
        onChange={e => setFilter({ ...filter, search: e?.target?.value })}
        placeholderDropdown2="City"
        dropdown2Options={selectedCities}
        dropdown2Value={filter.city}
        onChangeDropdown2={(e, value) => setFilter({ ...filter, city: value })}
        placeholderDropdown1="State"
        dropdown1Options={Object.keys(states)}
        dropdown1Value={filter.state}
        onChangeDropdown1={(e, value) => {
          setSelectedState(value)
          setSelectedCities(states[value] || [])
          setFilter({ ...filter, state: value })
        }}
      />

      <Box sx={{ minHeight: '82vh', overflowX: 'hidden' }}>
        {staffLeads?.staff?.length ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '25px 0 0 25px',
              flexWrap: 'wrap',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: '20px',
              }}
            >
              <Checkbox
                id="hotStaffCheckbox"
                checked={hotStaffChecked}
                onChange={handleHotStaffChange}
                color="primary"
              />
              <label htmlFor="hotStaffCheckbox">Hot staff</label>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: '20px',
              }}
            >
              <Checkbox
                id="schoolStaffCheckbox"
                checked={schoolsStaffChecked}
                onChange={handleSchoolsStaffChange}
                color="primary"
              />
              <label htmlFor="schoolStaffCheckbox">Schools staff</label>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                id="hideContacted"
                checked={hideContactChecked}
                onChange={handleHideContactChange}
                color="primary"
              />
              <label htmlFor="hideContacted">Hide contacted</label>
            </div>
            {can('bulk_sms') && (
              <Button
                sx={{
                  marginLeft: 'auto',
                  marginRight: '30px',
                  minWidth: '185px',
                  width: { xs: '100%', md: 'auto' },
                  marginTop: { xs: '10px', md: 'revert' },
                }}
                onClick={openSmsModal}
                variant="contained"
                color="primary"
              >
                Send SMS to everyone
              </Button>
            )}
          </div>
        ) : null}

        {staffLeads?.staff?.length === 0 ? (
          <Typography
            sx={{
              width: '100%',
              paddingLeft: '30px',
              paddingRight: '30px',
              marginTop: '15px',
              color: '#8694B0',
              fontSize: '20px',
            }}
          >
            No Data Avaialble!
          </Typography>
        ) : null}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            gap: '2%',
            justifyContent: {
              xs: 'flex-start',
              sm: 'flex-start',
              md: 'space-between',
              lg: 'flex-start',
            },
            paddingLeft: {
              xs: '15px',
              sm: '15px',
              md: '30px',
              lg: '30px',
            },
            paddingRight: {
              xs: '15px',
              sm: '15px',
              md: '30px',
              lg: '30px',
            },
            marginTop: '15px',
          }}
        >
          {staffLeads?.staff?.map((item, index) => {
            const [username, domain] =
              item?.email !== null ? item?.email?.split('@') : [null, null]
            return (
              <StaffLeadCard
                key={item?.id}
                name={`${item.first_name} ${item.last_name}`}
                phone={item?.phone}
                username={username}
                domain={domain}
                location={item?.city}
                location2={item?.country}
                state={item?.state}
                btnText="Contact"
                onClick={() => {
                  setStaffLeadsModal(item)
                  setOpen(true)
                }}
                isHotIcon={item.hot_staff ? hot : cold}
                isSchoolIcon={item.school_id === null ? null : school}
              />
            )
          })}
        </Box>
      </Box>

      {staffLeads?.staff?.length ? (
        <Pagination
          sx={{ marginTop: '20px', marginBottom: '10px' }}
          count={staffLeads?.total_pages}
          page={currentPage}
          onChange={handlePageChange}
        />
      ) : null}

      {open && (
        <ContactFacilityModal
          item={staffLeadsModal}
          open={open}
          onClose={() => {
            setOpen(false)
            setStaffLeadsModal()
          }}
          contactName={contactName}
          onChangeContactName={e => setContactName(e)}
          contactStatus={contactStatus}
          onChangeContactStatus={e => setContactStatus(e)}
          onMarkAsContacted={onMarkAsContacted}
          onMarkAsNotInterested={onMarkAsNotInterested}
          onMarkAsNoAnswer={onMarkAsNoAnswer}
          emailURL="/sales/staff_leads/email"
          notesURL="/sales/staff_leads/notes"
          smsURL="/sales/staff_leads/sms"
          noCall={true}
          noChat={true}
        />
      )}

      <Modal
        open={smsModalOpen}
        onClose={closeSmsModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={smsModalOpen}>
          <Paper
            elevation={3}
            style={{
              width: 400,
              padding: 20,
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'white',
            }}
          >
            <Typography variant="h6" gutterBottom>
              Write the <b style={{ color: '#3b5193' }}>SMS</b>
            </Typography>
            <TextareaAutosize
              rowsMin={4}
              placeholder="Write your SMS..."
              value={smsText}
              onChange={e => setSmsText(e.target.value)}
              style={{
                height: '175px',
                width: '100%',
                marginBottom: 10,
                border: '1px solid #ccc',
                borderRadius: 4,
                padding: 8,
              }}
            />
            <Typography variant="caption" color="textSecondary" gutterBottom>
              <b
                style={{
                  color: '#3b5193',
                }}
              >
                {smsText.length}
              </b>{' '}
              characters
            </Typography>

            <Grid container justifyContent="flex-end">
              <Button
                onClick={handleBulkSms}
                variant="contained"
                color="primary"
                sx={{ marginRight: '5px' }}
              >
                {sending ? 'sending..' : 'Send'}
              </Button>
              <Button
                onClick={closeSmsModal}
                color="secondary"
                style={{ marginRight: 10 }}
              >
                Cancel
              </Button>
            </Grid>
          </Paper>
        </Fade>
      </Modal>
      <Snackbar
        open={toastOpen}
        autoHideDuration={3000}
        onClose={() => setToastOpen(false)}
        message="The message was delivered successfully"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        color="success"
      />
    </Box>
  )
}

export default StaffLeads
