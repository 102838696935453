import { React, useState, useEffect } from 'react'
import {
  Card,
  Stack,
  Typography,
  CircularProgress,
  useMediaQuery,
  useTheme,
  IconButton,
  Divider,
  Box,
} from '@mui/material'

import { DashboardService } from 'homecare/services'
import { HiOutlineDocumentChartBar } from 'react-icons/hi2'
import { MdOutlineTune } from 'react-icons/md'
import { WiTime3, WiTime5 } from 'react-icons/wi'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import DateRangeIcon from '@mui/icons-material/DateRange'
import { api } from 'main/configs/axios.config'
import { useQuery } from '@tanstack/react-query'

export default function History() {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  const getHistory = useQuery({
    queryKey: ['getHistory'],
    queryFn: DashboardService.getHistory,
  })

  if (getHistory.isLoading) {
    // console.log('LOADING')
  }

  function displayShiftCard(history) {
    return (
      <Box
        sx={{
          background: '#F6F6F6',
          padding: '16px',
          borderRadius: '6px',
          width: '100%',
          marginBottom: '20px',
        }}
        key={history?.id}
      >
        <Stack mb="8px" direction="row" justifyContent="space-between">
          <Typography sx={sxStyles.subHeading}>SHIFT #{history?.id}</Typography>
        </Stack>
        <Stack mb="4px" direction="row" justifyContent="space-between">
          <Typography sx={sxStyles.details}>
            <HiOutlineDocumentChartBar size="22px" />
            <span style={{ marginLeft: '6px' }}>
              Tasks: {history?.tasks?.length}
            </span>
          </Typography>
          <Typography sx={sxStyles.details}>
            Total:
            <span
              style={{
                color: '#F52A4C',
                fontWeight: 'bold',
                marginLeft: '3px',
              }}
            >
              ${history?.invoice_rate}
            </span>
          </Typography>
        </Stack>
        <Stack mb="4px" direction="row" justifyContent="space-between">
          <Typography sx={sxStyles.details}>
            <MdOutlineTune size="22px" />
            <span style={{ marginLeft: '6px' }}>Role</span>
          </Typography>
          <Typography sx={sxStyles.details}>{history?.role}</Typography>
        </Stack>
        <Stack mb="4px" direction="row" justifyContent="space-between">
          <Typography
            sx={{
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '19px',
              color: '#818181',
              marginBottom: 1,
              display: { xs: 'none', sm: 'flex', md: 'flex' },
              alignItems: 'center',
            }}
          >
            <WiTime3 size="22px" />
            <span
              style={{
                marginLeft: '6px',
              }}
            >
              Start time
            </span>
          </Typography>
          <Box
            sx={{
              display: { xs: 'flex', sm: 'none', md: 'none' },
            }}
          >
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '16px',
                lineHeight: '19px',
                color: '#818181',
                marginBottom: 1,
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <WiTime3 size="22px" />
              <span
                style={{
                  marginLeft: '6px',
                }}
              >
                Start:
              </span>
            </Typography>
          </Box>
          <Typography sx={sxStyles.details}>{history?.start_time}</Typography>
        </Stack>
        <Stack mb="4px" direction="row" justifyContent="space-between">
          <Typography
            sx={{
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '19px',
              color: '#818181',
              marginBottom: 1,
              display: { xs: 'none', sm: 'flex', md: 'flex' },
              alignItems: 'center',
              display: { xs: 'none', sm: 'flex', md: 'flex' },
            }}
          >
            <WiTime5 size="22px" />
            <span style={{ marginLeft: '6px' }}>End time</span>
          </Typography>
          <Box sx={{ display: { xs: 'flex', sm: 'none', md: 'none' } }}>
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '16px',
                lineHeight: '19px',
                color: '#818181',
                marginBottom: 1,
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <WiTime5 size="22px" />
              <span
                style={{
                  marginLeft: '6px',
                }}
              >
                End:
              </span>
            </Typography>
          </Box>
          <Typography sx={sxStyles.details}>{history?.end_time}</Typography>
        </Stack>
        <Typography sx={sxStyles.details}>
          <InfoOutlinedIcon size="22px" />
          {/* HERE */}
          <span style={{ marginLeft: '6px' }}>
            Status: {history?.shift_status}
          </span>
        </Typography>

        {/* <Typography sx={[sxStyles.details, { marginTop: 2 }]}>
          Status: {history?.shift_status}
        </Typography> */}
      </Box>
    )
  }

  return (
    <Card
      sx={{
        zIndex: 100,
        position: 'absolute',
        top: 150,
        padding: { xs: '12px', sm: '40px', md: '40px' },
        width: { xs: '95%', sm: '95%', md: '520px' },
        minHeight: '500px',
        maxHeight: '76vh',
        left: { xs: 10, sm: 10, md: 50 },
        boxShadow: 'none',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
          width: '10px',
          backgroundColor: '#F5F5F5',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#D2D2D2',
          borderRadius: '20px',
          border: '3px solid #F5F5F5',
        },
      }}
    >
      <Typography
        sx={{
          marginBottom: { xs: '8px', sm: '8px', md: '18px' },
          fontWeight: '700',
          fontSize: { xs: '1.5rem', sm: '1.5rem', md: '3rem' },
          lineHeight: '50px',
          color: 'custom.black',
        }}
      >
        History
      </Typography>
      <Stack>
        {getHistory.isLoading ? (
          <Stack
            sx={{
              height: '500px',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Stack>
        ) : !getHistory.data.length ? (
          <Stack
            sx={{
              height: '500px',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{ fontSize: '22px', color: '#818181', fontWeight: '600' }}
            >
              No available shifts
            </Typography>
          </Stack>
        ) : (
          <Box>{getHistory.data.map(history => displayShiftCard(history))}</Box>
        )}
      </Stack>
    </Card>
  )
}

const sxStyles = {
  card: {
    zIndex: 1,
    position: 'absolute',
    top: 150,
    padding: '40px 25px',
    width: '660px',
  },
  heading: {
    fontWeight: '700',
    fontSize: '48px',
    lineHeight: '58px',
    color: 'custom.black',
  },
  summary: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#000000',
  },
  fullInput: {
    width: '100%',
    backgroundColor: 'custom.registerInput',
    marginBottom: 2,
    padding: '10px',
    borderRadius: '6px',
  },
  halfInput: {
    width: '48%',
    backgroundColor: 'custom.registerInput',
    marginBottom: 2,
    padding: '10px',
    borderRadius: '6px',
  },
  halfSelect: {
    width: '48%',
    marginBottom: 2,
    padding: '10px',
    borderRadius: '6px',
  },
  scheduleBtn: {
    borderRadius: '56px',
    backgroundColor: 'custom.registerInput',
    color: 'custom.black',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '19px',
  },
  detailsCard: {
    background: '#F6F6F6',
    padding: '16px',
    borderRadius: '6px',
    width: '100%',
  },
  subHeading: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#667684',
    marginBottom: 1,
  },
  details: {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#818181',
    marginBottom: 1,
    display: 'flex',
    alignItems: 'center',
  },
}
