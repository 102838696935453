// @ts-nocheck
import { api } from "main/configs/axios.config";

export const getCancelled = async ({ tab, date, time_zone }) => {
  const url = `operation_panel/canceled_shifts/${
    tab === "FC" ? "facility_canceled" : "individual_canceled"
  }?date=${date}&time_zone=${time_zone}`;

  const json = await api.get(url);
  return json;
};
