import React from 'react'
import { Link, Stack } from '@mui/material'

export default function LinksFooter() {
  return (
    <Stack direction="row" justifyContent="flex-end">
      <Link color="secondary.main" href="#" sx={sxStyles.link}>
        Help
      </Link>
      <Link color="secondary.main" href="#" sx={sxStyles.link}>
        Privacy
      </Link>
      <Link color="secondary.main" href="#" sx={sxStyles.link}>
        Terms
      </Link>
    </Stack>
  )
}

const sxStyles = {
  link: {
    marginLeft: '30px',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    textDecoration: 'none',
  },
}
