import React, { useRef, useEffect } from 'react'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import SignaturePad from 'react-signature-canvas'
import { HomeCareHeader } from '../../components'
import SignatureCanvas from 'react-signature-canvas'
import { useNavigate } from 'react-router-dom'

export default function AddSign() {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const navigate = useNavigate()
  const signatureCanvasRef = useRef(null)

  useEffect(() => {
    const savedData = sessionStorage.getItem('RequiredDetails')
    if (savedData) {
      const fullData = JSON.parse(savedData)
    } else {
      navigate('/individual/dashboard')
    }
  })

  const clear = () => {
    signatureCanvasRef?.current?.clear()
  }

  const undo = () => {
    let data = signatureCanvasRef?.current?.toData()
    if (data) {
      data?.pop()
      signatureCanvasRef?.current?.fromData(data)
    }
  }

  const handleSave = () => {
    const signature = signatureCanvasRef.current.toDataURL('image/png')
    const base64 = signature.replace(/^data:image\/(png|jpg);base64,/, '')
    sessionStorage.setItem('signatureBase64', JSON.stringify(base64))
    navigate('/individual/healthcare-submit-contract')
  }

  return (
    <Box sx={sxStyles.container}>
      <HomeCareHeader heading="Sign Contract" />

      <Box sx={sxStyles.cardContainer}>
        <Card sx={[sxStyles.card, { maxWidth: matches ? '550px' : '80%' }]}>
          <CardContent>
            <Typography
              sx={sxStyles.cardHeading}
              color="custom.black"
              gutterBottom
            >
              Sign Contract
            </Typography>
          </CardContent>

          <Stack direction="row" justifyContent="space-evenly">
            <Button
              variant="text"
              onClick={clear}
              sx={{
                color: 'custom.black',
                fontWeight: '500',
                fontSize: '18px',
              }}
            >
              Clear
            </Button>
            <Button
              variant="text"
              onClick={undo}
              sx={{
                color: 'custom.black',
                fontWeight: '500',
                fontSize: '18px',
              }}
            >
              Undo
            </Button>
          </Stack>

          <Box sx={sxStyles.sigContainer}>
            <SignatureCanvas
              ref={signatureCanvasRef}
              penColor="black"
              canvasProps={{
                width: 500,
                height: 200,
                className: 'signature-canvas',
              }}
            />
          </Box>

          <CardActions>
            <Button
              onClick={handleSave}
              sx={sxStyles.contractBtn}
              variant="contained"
            >
              Add Sign
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Box>
  )
}

const sxStyles = {
  container: {
    flex: 1,
    backgroundColor: 'background.default',
  },
  cardContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 5,
    height: '80vh',
  },
  card: {
    padding: 2,
    boxShadow: '0px 0px 80px #EFEFEF',
    width: '80%',
    boxShadow: '0px 0px 80px #EFEFEF',
    borderRadius: '20px',
  },
  cardHeading: {
    fontSize: '30px',
    fontWeight: 600,
  },
  sigContainer: {
    width: '90%',
    height: '200px',
    backgroundColor: 'custom.white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F0F0F0',
    borderRadius: '20px',
    boxShadow: '0px 0px 80px #EFEFEF',
    margin: '20px auto',
  },
  contractBtn: {
    width: '90%',
    borderRadius: '56px',
    padding: '15px',
    margin: '0 auto',
  },
}
