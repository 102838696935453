import React from 'react'
import { Box, Typography, TextField, Button, FormControl } from '@mui/material'
import { HomeCareHeader, LinksFooter } from '../../components'

export default function HomeCareLogin() {
  return (
    <Box sx={sxStyles.container}>
      <HomeCareHeader heading="Login" />

      <FormControl sx={sxStyles.form}>
        <Typography variant="h1" sx={sxStyles.heading}>
          What's your phone number?
        </Typography>
        <TextField label="+1" variant="outlined" sx={sxStyles.input} />
        <TextField label="Password" variant="outlined" sx={sxStyles.input} />
        <Button sx={sxStyles.btn} variant="contained">
          Continue
        </Button>

        <LinksFooter />
      </FormControl>
    </Box>
  )
}

const sxStyles = {
  container: {
    flexGrow: 1,
    width: '100vw',
    backgroundColor: 'white',
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 15,
  },
  heading: {
    marginBottom: 1,
  },
  radioItem: {
    width: '160px',
    border: '1px solid',
    borderRadius: '8px',
  },
  input: {
    width: '330px',
    margin: '15px 0px',
    marginLeft: -3,
    backgroundColor: 'custom.button',
  },
  btn: {
    backgroundColor: 'custom.black',
    width: '330px',
    height: '50px',
    marginBottom: '15px',
    marginLeft: -3,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '19px',
  },
}
