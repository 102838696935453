// @ts-nocheck
import React from 'react'
import styles from './ProgressCounter.module.css'

function ProgressCounter({ step, currentStep, label, last, time, date }) {
  return (
    <>
      <div className={styles.row}>
        <div className={styles.dateTimeBox}>
          <div className={styles.time}>{date}</div>
          <div className={styles.time}>{time}</div>
        </div>
        <div
          className={`${styles.outerCircle} ${
            step === currentStep
              ? styles.outer_circle_unseccess
              : styles.outer_circle_unseccessfull
          }`}
        >
          <div
            className={`${styles.inner_circle} ${
              step === currentStep
                ? styles.inner_circle_seccessfull
                : styles.inner_circle_unseccessfull
            }`}
          />
        </div>
        <div className={styles.label}>{label}</div>
      </div>
      {last ? null : (
        <div
          className={`${styles.progress} ${
            step === currentStep
              ? styles.currentProgress
              : styles.notCurrentProgress
          }`}
        />
      )}
    </>
  )
}

export default ProgressCounter
