import { Box } from '@mui/material';

export default function Divider() {
  return (
    <Box
      sx={{
        width: '100%',
        height: '0.0625rem',
        opacity: '0.3',
        background: '#8F95B2',
        margin: '20px 0',
      }}
    ></Box>
  );
}
