import React from 'react'
import { FormControl, InputLabel, InputBase } from '@mui/material'
import { styled } from '@mui/material/styles'

export default function ModalInput({
  label,
  defaultValue,
  multiline,
  rows,
  fullWidth,
  width,
  marginTop,
  value,
  onChange,
}) {
  return (
    <FormControl variant="standard" sx={{ marginTop, width }}>
      <InputLabel shrink htmlFor="bootstrap-input" sx={style.label}>
        {label}
      </InputLabel>
      <BootstrapInput
        defaultValue={defaultValue}
        id="bootstrap-input"
        multiline={multiline}
        rows={rows}
        fullWidth={fullWidth}
        sx={{ width: width || '100%' }}
        value={value}
        onChange={onChange}
      />
    </FormControl>
  )
}

const style = {
  label: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    color: 'custom.black',
  },
}

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'white',
    border: '1px solid #DCDCDC',
    fontSize: '14px',
    padding: '10px 12px',
    '&:focus': {
      borderColor: '#DCDCDC',
    },
  },
}))
