import ReactPhoneInput from 'react-phone-number-input/input';
import { useState } from 'react';
import React from 'react';

import { E164Number } from 'libphonenumber-js/types';
import { parsePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import { Text } from '@chakra-ui/react';

type PhoneInputProps = {
  value?: string | null;
  onChange?: (value: any) => void;
  error?: any;
  setError?: (value?: string) => void;
  disabled?: boolean;
  id?: string;
};

const PhoneInput = (props: PhoneInputProps) => {
  const { value, onChange, error, id, disabled = false, setError } = props;

  const [val, setVal] = useState<E164Number | undefined>(
    parsePhoneNumber(value ? value : '')?.number
  );

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0.7rem 0.6rem',
        borderRadius: '4px',
        outline: '2px solid transparent',
        border: '1px solid #E2E8F0',
      }}
    >
      <Text sx={{ margin: '0 10px' }}>{`+1`}</Text>
      <ReactPhoneInput
        international
        // countryCallingCodeEditable={false}
        defaultCountry="US"
        country={'US'}
        value={val}
        onChange={(value: E164Number | undefined) => {
          const phoneNumber = value && parsePhoneNumber(value.toString(), 'US');
          const isValid = value && isValidPhoneNumber(value.toString(), 'US');
          if (!isValid && phoneNumber && setError) {
            setError('Not Valid');
          } else {
            setError && setError();
          }
          value && setVal(value);
          value && onChange && onChange(value.toString());
        }}
        // limitMaxLength
        style={{
          border: 0,
          outline: 0,
          fontWeight: '400',
          fontSize: '1rem',
          lineHeight: '1.5',
          letterSpacing: '0.15px',
          backgroundColor: 'inherit',
          width: '100%',
        }}
        // isValid={val && isValidPhoneNumber(val.toString())}
        disabled={disabled}
      />
    </div>
  );
};

export default PhoneInput;
