import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  Center,
  Box,
  InputRightElement,
  InputGroup,
  VStack,
  Image,
  Text,
} from '@chakra-ui/react'
import { useForm, Controller } from 'react-hook-form'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { useContext, useEffect, useState } from 'react'
import { useAuth } from 'main/hooks'
import { Link } from 'react-router-dom'
import { api } from 'main/configs/axios.config'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

type PhoneConfirmationProps = {
  phone: string
}
const PhoneConfirmation = (props: PhoneConfirmationProps) => {
  const navigate = useNavigate()
  const { phone } = props
  const [showPassword, setShowPassword] = useState(false)
  const { handleSubmit, control, formState } = useForm()
  let [accountType, setAccountType] = useState('')

  useEffect(() => {
    const storedAccountType = sessionStorage.getItem('accountType')
    if (storedAccountType) {
      setAccountType(storedAccountType)
    }
  }, [])

  const onSubmit = async (data: any) => {
    const response: any = await api.post(
      'authentication/register/confirmation',
      {
        ...data,
        type: accountType.toLowerCase(),
        phone,
      }
    )
    if (response) {
      toast.success('Confirmation successful')
      sessionStorage.removeItem('accountType')
      navigate('/login')
    }
  }
  return (
    <Center>
      <Box
        borderRadius="md"
        boxShadow={{ base: 'none', md: 'md' }}
        h="fit-content"
        px={10}
        py={20}
      >
        <Image src="/assets/images/Logo.png" mx="auto" mb={3} />

        <form onSubmit={e => e.preventDefault()} style={{ minWidth: 300 }}>
          <VStack spacing={5}>
            <Controller
              control={control}
              name="code"
              rules={{ required: 'Field is required.' }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl isInvalid={!!error} isRequired>
                  <FormLabel>Confirmation Code:</FormLabel>
                  <Input type="number" value={value} onChange={onChange} />
                  {error && (
                    <FormErrorMessage>{error.message}</FormErrorMessage>
                  )}
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="password"
              rules={{
                required: 'Field is required.',
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl isInvalid={!!error} isRequired>
                  <FormLabel>Create a Password</FormLabel>
                  <InputGroup>
                    <Input
                      type={`${showPassword ? 'text' : 'password'}`}
                      value={value}
                      onChange={onChange}
                      onKeyDown={(e: any) => {
                        if (e.code === 'Enter') {
                          handleSubmit(onSubmit)
                        }
                      }}
                    />
                    <InputRightElement
                      onClick={() => setShowPassword(!showPassword)}
                      sx={{
                        '&:hover': {
                          cursor: 'pointer',
                        },
                      }}
                    >
                      {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                    </InputRightElement>
                  </InputGroup>
                  {error && (
                    <FormErrorMessage>{error.message}</FormErrorMessage>
                  )}
                </FormControl>
              )}
            />
            <Button
              backgroundColor="#E3022A"
              color={'#fff'}
              variant="solid"
              onClick={handleSubmit(onSubmit)}
              isLoading={formState.isSubmitting}
              marginTop={4}
              width={'100%'}
              sx={{ '&:hover': { backgroundColor: '#CC0024' } }}
            >
              Confirm
            </Button>
            <Text mt={3}>
              Already have an account?
              <Text ml={1} color="red" as="span">
                <Link to="/login">Login</Link>
              </Text>
            </Text>
          </VStack>
        </form>
      </Box>
    </Center>
  )
}
export default PhoneConfirmation
