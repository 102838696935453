import React from 'react'
import ReactDOM from 'react-dom/client'
import MainApp from 'app'
import { Provider } from 'react-redux'
import { store } from 'main/store'
import { BrowserRouter } from 'react-router-dom'

const App = () => (
  <BrowserRouter>
    <Provider store={store}>
      <MainApp />
    </Provider>
  </BrowserRouter>
)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(<App />)
