import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  Slice,
} from '@reduxjs/toolkit'
import {
  accountTypeKeyName,
  api,
  getAccessToken,
} from 'main/configs/axios.config'

type AuthSliceType = {
  user: any
  isError: boolean
  isSuccess: boolean
  isLoading: boolean
  message: string
}
const initialState: AuthSliceType = {
  user: null,
  isError: false,
  isSuccess: false,
  isLoading: true,
  message: '',
}

// Register user
export const register = createAsyncThunk(
  'auth/register',
  async (data: any, thunkAPI) => {
    const response = await api.post('authentication/register', {
      phone: data.phone,
    })
  }
)

// Login user
export const login = createAsyncThunk(
  'auth/login',
  async (userDTO: any, thunkAPI) => {
    const response = await api.post('authentication/login', {
      phone: userDTO.phone,
      password: userDTO.password,
    })
  }
)

export const schoolLogin = createAsyncThunk(
  'auth/schoolLogin',
  async (userDTO: any, thunkAPI) => {
    const response = await api.post('schools/login', {
      id: userDTO.id,
      password: userDTO.password,
    })
  }
)

// Get user
export const getUser = createAsyncThunk(
  'auth/getUser',
  async (undefined, thunkAPI) => {
    const token: string | undefined = getAccessToken()
    const userType: any = localStorage.getItem(accountTypeKeyName)
    if (token && JSON.parse(userType) === 'human_resources') {
      const user = await api.get('human_resources/account')
      return user
    } else if (token && JSON.parse(userType) === 'facility') {
      const user = await api.get('facilities/account')
      return user
    } else if (token && JSON.parse(userType) === 'individual') {
      const user = await api.get('individuals/account')
      return user
    } else if (token && JSON.parse(userType) === 'operation_panel') {
      const user = await api.get('operation_panel/account')
      return user
    } else if (token && JSON.parse(userType) === 'sales') {
      // const user = await api.get('sales/account')
      // return user
      return
    } else if (token && JSON.parse(userType) === 'school') {
      const user = await api.get('schools/account')
      return user
    } else if (token && JSON.parse(userType) === 'company') {
      const user = await api.get('company/account')
      return user
    } else if (token && JSON.parse(userType) === 'staff') {
      return
    }

    return null
  }
)

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (forgotPasswordDTO: any, thunkAPI) => {
    // const openApi = new OpenApi<any>();
    // const res: any | null = await openApi.fetchData({
    //   method: eRequestMethod.Post,
    //   url: "/authentication/reset-password",
    //   body: forgotPasswordDTO,
    // });
  }
)

export const logout = createAsyncThunk('auth/logout', async () => {
  localStorage.clear()
})

export const authSlice: Slice<any> = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: state => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
    },
    updateUser: (state, action) => {
      state.user = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(register.pending, state => {
        state.isLoading = true
      })
      .addCase(register.fulfilled, state => {
        state.isLoading = false
        state.isSuccess = true
      })
      .addCase(register.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.user = null
      })
      .addCase(schoolLogin.pending, state => {
        state.isLoading = true
      })
      .addCase(schoolLogin.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false
        state.isSuccess = true
        state.user = action.payload
      })
      .addCase(schoolLogin.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.user = null
      })
      .addCase(login.pending, state => {
        state.isLoading = true
      })
      .addCase(login.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false
        state.isSuccess = true
        state.user = action.payload
      })
      .addCase(login.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.user = null
      })
      .addCase(getUser.pending, state => {
        state.isLoading = true
      })
      .addCase(getUser.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.user = null
      })
      .addCase(getUser.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false
        state.user = action.payload
      })
      .addCase(logout.fulfilled, state => {
        state.isLoading = false
        state.user = null
      })
  },
})

export const { reset, updateUser } = authSlice.actions
export const userData = (state: { auth: { user: any } }) => state.auth.user
export default authSlice.reducer
