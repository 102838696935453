import { Box, Typography } from '@mui/material';

export default function Card({ price, hasLabel, data, color }) {
  return (
    <Box
      flexGrow="1"
      flex="1"
      sx={{
        minWidth: '250px',
        boxShadow: '0px 0px 15px 0px rgba(171, 171, 171, 0.09)',
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          borderRadius: '8px',
          border: `1px solid ${color} `,
          background: '#FFF',
        }}
      >
        <Typography
          sx={{
            color: '#081735',
            textAlign: 'center',
            fontSize: '1.5rem',
            fontWeight: '700',
            padding: '18px 0 10px 0',
          }}
        >
          ${price}
        </Typography>
        {hasLabel && (
          <Typography
            sx={{
              color: '#8F95B2',
              textAlign: 'center',
              fontSize: '1rem',
              paddingBottom: '8px',
            }}
          >
            All Time
          </Typography>
        )}

        <Box
          sx={{
            width: '100%',
            height: '0.0625rem',
            opacity: '0.3',
            background: '#8F95B2',
          }}
        ></Box>
        <Typography
          sx={{
            color: { color },
            textAlign: 'center',
            fontSize: '1rem',
            fontWeight: 600,
            padding: '16px',
          }}
        >
          {data}
        </Typography>
      </Box>
    </Box>
  );
}
