import React from 'react'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import {
  HireNurseIcon,
  RequestCareIcon,
  RequestLoginIcon,
} from '../../assets/svg'
import { RequestHealthCareBanner } from '../../assets/img'
import { RequestHealthCareCard } from '../../components'

export default function RequestHealthCare() {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Box sx={sxStyles.container}>
      <div style={sxStyles.cover}>
        <RequestHealthCareCard />
      </div>

      <Typography sx={sxStyles.heading}>How it Works?</Typography>

      <Box sx={[sxStyles.row, { flexDirection: matches ? 'row' : 'column' }]}>
        <Box
          sx={[
            sxStyles.card,
            { width: matches ? '25%' : '80%', marginBottom: !matches && 5 },
          ]}
        >
          <RequestCareIcon />
          <Typography sx={sxStyles.cardHeading}>Request Care</Typography>
          <Typography sx={sxStyles.cardDetails}>
            Easily Find and Request the closest caregivers or nurses nearby,
            directly to you.
          </Typography>
          <Typography sx={sxStyles.count}>1</Typography>
        </Box>
        <Box
          sx={[
            sxStyles.card,
            { width: matches ? '25%' : '80%', marginBottom: !matches && 5 },
          ]}
        >
          <RequestLoginIcon />
          <Typography sx={sxStyles.cardHeading}>Register/Login</Typography>
          <Typography sx={sxStyles.cardDetails}>
            Simply Enter your phone number to Login or the otp code sent to your
            phone to Register.
          </Typography>
          <Typography sx={sxStyles.count}>2</Typography>
        </Box>
        <Box
          sx={[
            sxStyles.card,
            { width: matches ? '25%' : '80%', marginBottom: !matches && 5 },
          ]}
        >
          <HireNurseIcon />
          <Typography sx={sxStyles.cardHeading}>
            Hire Caregiver/Nurse
          </Typography>
          <Typography sx={sxStyles.cardDetails}>
            Choose from a list of nearby caregivers/nurses to Hire, Schedule &
            Care is on the way!
          </Typography>
          <Typography sx={sxStyles.count}>3</Typography>
        </Box>
      </Box>
    </Box>
  )
}

const sxStyles = {
  container: {
    flexGrow: 1,
    backgroundColor: 'custom.bgRegister',
  },
  cover: {
    borderTop: '1px solid #fff',
    backgroundImage: `url(${RequestHealthCareBanner})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '99vh',
  },
  heading: {
    fontWeight: '700',
    fontSize: '42px',
    textAlign: 'center',
    color: 'custom.black',
    marginTop: 10,
    marginBottom: 10,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginBottom: 20,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '300px',
    backgroundColor: 'white',
    borderRadius: '2px',
    padding: 5,
    position: 'relative',
  },
  cardHeading: {
    fontWeight: '600',
    fontSize: '24px',
    color: '#0F2F64',
    marginTop: 2,
    marginBottom: 2,
  },
  cardDetails: {
    fontWeight: '400',
    fontSize: '16px',
    color: '#536288',
  },
  count: {
    color: 'rgba(60, 80, 224, 0.03)',
    fontSize: '254px',
    position: 'absolute',
    right: '10px',
  },
}
