import React from 'react'
import styles from './InvoiceCard.module.css'
import { Chip } from '@mui/material'

function InvoiceCard({ date, time, nurse, rate, item }) {
  return (
    <div className={styles.card}>
      <div className={styles.row}>
        <div className={styles.text}>{date?.slice(0, 10)}</div>
        <div className={styles.text}>{date?.slice(11)}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.text}>Registered Nurse</div>
        <div className={styles.text}>#{item.id}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.text}>Invoice Rate</div>
        <Chip
          label={
            item.status === 0
              ? 'Open'
              : item.status === 1
              ? 'Live'
              : item.status === 2
              ? 'Reported'
              : item.status === 3
              ? 'Cancelled'
              : item.status === 4
              ? 'Completed'
              : 'Hired'
          }
          className={`${styles.chip} 
          ${item.invoice_option === 0 && styles.open}
          ${item.invoice_option === 1 && styles.live}
          ${item.invoice_option === 2 && styles.reported}
          ${item.invoice_option === 3 && styles.cancelled}
          ${item.invoice_option === 4 && styles.completed}
          ${item.invoice_option === 5 && styles.hired}
          `}
        />
      </div>
      <div className={styles.row}>
        <div className={styles.text}>Sift rate</div>
      </div>
    </div>
  )
}

export default InvoiceCard
