import {FlexProps} from "@chakra-ui/react";
import {ReactText} from "react";
import {IconType} from "react-icons";
import {FiHome, FiTruck, FiUsers} from "react-icons/fi";
import {ImHome} from "react-icons/im";
import {SiGooglemessages} from "react-icons/si";
import {BsBellFill} from "react-icons/bs";
import {MdFreeCancellation} from "react-icons/md";
import {FaUserClock, FaUser, FaFileInvoice} from "react-icons/fa";
import {
  RiAdminLine,
  RiUserSettingsLine,
  RiMapPinUserLine,
} from "react-icons/ri";
import {BiCar} from "react-icons/bi";
import {BsHandbag} from "react-icons/bs";

type NavItemType = {
  icon?: IconType;
  name: ReactText;
  itemPath: string;
  subItems?: NavItemType[];
};

const getNavigationContent = (role: string) => {
  let navItems: NavItemType[] = [];
  switch (role) {
    case "admin":
      navItems = [
        {name: "Users", icon: FiUsers, itemPath: "/users"},
        {name: "Companies", icon: FiTruck, itemPath: "/customers"},
      ];
      break;
    case "supermanager":
      navItems = [
        {name: "Home", icon: FiHome, itemPath: "/dashboard"},
        {
          name: "Administrators",
          icon: RiAdminLine,
          itemPath: "/administrators",
        },
        {name: "Managers", icon: RiUserSettingsLine, itemPath: "/managers"},
        {name: "Clients", icon: RiMapPinUserLine, itemPath: "/clients"},
        {name: "Drivers", icon: BiCar, itemPath: "/drivers"},
        {name: "Jobs", icon: BsHandbag, itemPath: "/jobs"},
        {name: "Invoices", icon: BsHandbag, itemPath: "/invoices"},
      ];
      break;
    case "manager":
      navItems = [
        {name: "Home", icon: FiHome, itemPath: "/dashboard"},
        {name: "Managers", icon: RiUserSettingsLine, itemPath: "/managers"},
        {name: "Clients", icon: RiMapPinUserLine, itemPath: "/clients"},
        {name: "Drivers", icon: BiCar, itemPath: "/drivers"},
        {name: "Jobs", icon: BsHandbag, itemPath: "/jobs"},
        {name: "Invoices", icon: BsHandbag, itemPath: "/invoices"},
      ];
      break;
    case "driver":
      navItems = [
        {name: "Home", icon: FiHome, itemPath: "/dashboard"},
        {name: "Job Requests", icon: FiHome, itemPath: "/job-request"},
        {name: "Jobs", icon: BsHandbag, itemPath: "/jobs"},
      ];
      break;
    default:
      navItems = [
        {name: "Dashboard", icon: ImHome, itemPath: "/facility/dashboard"},
        {name: "Shifts", icon: FaUserClock, itemPath: "/facility/shifts"},
        {
          name: "Messages",
          icon: SiGooglemessages,
          itemPath: "/facility/messages",
        },
        {
          name: "Invoices",
          icon: FaFileInvoice,
          itemPath: "/facility/invoices",
          subItems: [
            {
              name: "Current",
              itemPath: "/facility/invoices-current",
            },
            {
              name: "History",
              itemPath: "/facility/invoices-history",
            },
          ],
        },
        {
          name: "Canceled | Reported Shifts",
          icon: MdFreeCancellation,
          itemPath: "/facility/canceled-reported",
        },
      ];
      break;
  }

  return navItems;
};

export type {NavItemType};
export {getNavigationContent};
