import { Flex, Text } from '@chakra-ui/react'
import { FcLock } from 'react-icons/fc'

const NoPermissions = () => {
  return (
    <Flex
      w="100%"
      h="100vh"
      gap="2px"
      justifyContent="center"
      alignItems="center"
    >
      <Text fontSize="20px">You don't have a permission for this page</Text>
      <FcLock />
    </Flex>
  )
}

export default NoPermissions
