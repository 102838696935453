import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";
import {getMessaging, onMessage} from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBnbr0mDNM-TkWkzP1feW-HlSwxIFsQ-Bg",
  authDomain: "nursebillboard-9f1c4.firebaseapp.com",
  databaseURL: "https://nursebillboard-9f1c4-default-rtdb.firebaseio.com",
  projectId: "nursebillboard-9f1c4",
  storageBucket: "nursebillboard-9f1c4.appspot.com",
  messagingSenderId: "77972181516",
  appId: "1:77972181516:web:4745bf47c324ba89d61429",
  measurementId: "G-VFR3YK2W1F",
};

// Initialize Firebase and Firebase Authentication
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
onMessage(messaging, (payload) => {
  console.log("Message received. ", payload);
  // ...
});
export const firebaseAuth = getAuth(app);
async function registerWorker() {
  const registration = await navigator.serviceWorker.register(
    "/firebase-messaging-sw.js",
    {scope: "/"}
  );
  const subscription = await registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey:
      "BB5_gPU5YjyBs6uBjjPXAxqG2HL5EMeCnJF07GXqTsrYtQ0xr3ZGlbmyjYW6KN2xRBQwnOO4YxFYzSeTi7eFJCo",
  });
}
if ("serviceWorker" in navigator) {
  registerWorker().catch((error) => console.error("error", error));
}
