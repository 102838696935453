import React from "react";
import styles from "./TasksContainer.module.css";
import TasksCard from "../TasksCard/TasksCard";

function TasksContainer({ tasks }) {
  return (
    <div className={styles.container}>
      <div className={styles.heading}>Tasks</div>
      {tasks?.map((item, index) => (
        <TasksCard key={index} task={item} />
      ))}
    </div>
  );
}

export default TasksContainer;
