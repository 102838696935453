import React from 'react'
import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  TextField,
  InputAdornment,
  Snackbar,
} from '@mui/material'
import { Call, SmsRounded, LocationOnOutlined } from '@mui/icons-material'

import { Link as RouterLink } from 'react-router-dom'
import { HiOutlineDocumentChartBar } from 'react-icons/hi2'
import { MdOutlineTune } from 'react-icons/md'
import { WiTime3, WiTime5 } from 'react-icons/wi'
import { api } from 'main/configs/axios.config'
import { Buffer } from 'buffer'

export default function DashboardActiveRequestCard({ data, setActiveShift }) {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const [isLoading, setIsLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [successMessage, setSuccessMessage] = React.useState('')
  const [imageSrc, setImageSrc] = React.useState('')
  const [open, setOpen] = React.useState(false)

  const handleUserImage = async () => {
    let srcValue = ''
    const response = await api
      .get(`profile-pics/${data.shift.staff.staff_details.profile_pic}`, {
        responseType: 'arraybuffer',
      })
      .then((res: any) => {
        let base64ImageString = Buffer.from(res, 'binary').toString('base64')
        srcValue = 'data:image/png;base64,' + base64ImageString
      })
    setImageSrc(srcValue)
  }

  React.useEffect(() => {
    handleUserImage()
  }, [imageSrc])

  async function handleCancel() {
    setIsLoading(true)

    try {
      const response = await api.post('individuals/shift/cancel')
      setSuccessMessage('Shift cancelled successfully!')
      setOpen(true)
      setActiveShift('next')
    } catch (error) {
      setErrorMessage(error.response.data.message)
      setOpen(true)
    }

    setIsLoading(false)
  }

  const handlePhoneClick = () => {
    alert(`Phone number: ${data.shift.staff.staff_details.phone}`)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const PitfalImages = [
    'https://occ-0-2041-3662.1.nflxso.net/art/0d282/eb648e0fd0b2676dbb7317310a48f9bdc2b0d282.png',
    'https://ih1.redbubble.net/image.618427277.3222/flat,800x800,075,f.u2.jpg',
    'https://upload.wikimedia.org/wikipedia/commons/0/0b/Netflix-avatar.png',
    'https://i.pinimg.com/736x/eb/05/eb/eb05eb6fb0880b5c811669b60b0278bb.jpg',
    'https://i.pinimg.com/550x/e3/94/30/e39430434d2b8207188f880ac66c6411.jpg',
    'https://i.pinimg.com/550x/b6/77/cd/b677cd1cde292f261166533d6fe75872.jpg',
    'https://i.pinimg.com/736x/bd/ee/4c/bdee4c328550aaf21aa9f43fd19e2136.jpg',
  ]

  function displayCard() {
    return (
      <Box sx={sxStyles.detailsStaff}>
        <Stack
          direction="row"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          gap={{ xs: '20px', sm: '20px', md: '20' }}
          direction={{ xs: 'column', sm: 'column', md: 'row' }}
        >
          <Box
            key={data.shift.staff.staff_details.id}
            sx={sxStyles.detailsStaff}
          >
            <Stack alignItems="center" direction="row">
              {imageSrc ? (
                <img src={imageSrc} alt="user" style={sxStyles.userImg} />
              ) : (
                <img
                  src={
                    PitfalImages[
                      Math.floor(Math.random() * PitfalImages.length)
                    ]
                  }
                  alt="user"
                  style={sxStyles.userImg}
                />
              )}
              <Box>
                <Typography
                  fontSize={{ xs: '16px', sm: '16px', md: '20px' }}
                  sx={{ color: '#000000', fontWeight: '600' }}
                >
                  {data.shift.staff.staff_details.first_name +
                    ' ' +
                    data.shift.staff.staff_details.last_name}
                </Typography>
                <div style={{ marginTop: '8px' }}>
                  <span
                    style={{
                      marginRight: '16px',
                      color: '#848484',
                      fontSize: '16px',
                    }}
                  >
                    {data.shift.staff.staff_distance_miles} miles
                  </span>
                  <span style={{ color: '#848484', fontSize: '16px' }}>
                    mileage: {data.shift.staff.mileage}
                  </span>
                </div>
              </Box>
            </Stack>
          </Box>
          <Stack
            direction="row"
            alignItems="center"
            width={{ xs: '100%', sm: '100%', md: 'auto' }}
            justifyContent={{
              xs: 'space-evenly',
              sm: 'space-evenly',
              md: 'flex-start',
            }}
          >
            <IconButton
              aria-label="call"
              color="white"
              sx={{
                backgroundColor: 'custom.success',
                color: 'white',
                marginRight: '5px',
                '&:hover': {
                  background: '#3E8E41',
                },
              }}
              onClick={handlePhoneClick}
            >
              <Call />
            </IconButton>
            <RouterLink
              to="/individual/messages"
              style={{ textDecoration: 'none' }}
            >
              <IconButton
                aria-label="delete"
                sx={{
                  backgroundColor: 'primary.main',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#C10928',
                  },
                }}
              >
                <SmsRounded />
              </IconButton>
            </RouterLink>
          </Stack>
        </Stack>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        background: 'background',
        boxShadow: '0px 20px 50px rgba(171, 171, 171, 0.25)',
        zIndex: 1,
        position: 'absolute',
        top: 150,
        left: { xs: 5, sm: 5, md: 50 },
        padding: { xs: '32px 10px', sm: '32px 10px', md: '32px 32px' },
        maxHeight: '70vh',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '10px',
          backgroundColor: '#F5F5F5',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888',
          borderRadius: '20px',
          border: '3px solid #F5F5F5',
        },
        width: { xs: '97%', sm: '97%', md: '517px' },
      }}
    >
      <Typography fontSize="40px" sx={sxStyles.cardHeading}>
        Active Requests
      </Typography>
      <Box sx={sxStyles.detailsCard}>
        <TextField
          disabled
          sx={sxStyles.fullInput}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LocationOnOutlined size={10} />
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
          variant="standard"
          value={data?.shift?.address}
        />
      </Box>

      <Stack mb="8px" direction="row" justifyContent="space-between">
        <Typography sx={sxStyles.subHeading2}>Active Shifts</Typography>
      </Stack>

      <Box sx={sxStyles.detailsCard}>
        <Stack mb="8px" direction="row" justifyContent="space-between">
          <Typography sx={sxStyles.subHeading}>Shift Details</Typography>
        </Stack>
        <Stack mb="4px" direction="row" justifyContent="space-between">
          <Typography sx={sxStyles.details}>
            <HiOutlineDocumentChartBar size="22px" />
            <span style={{ marginLeft: '6px' }}>
              Tasks: {data?.shift?.tasks?.length}
            </span>
          </Typography>
          <Typography sx={sxStyles.details}>
            Total:
            <span
              style={{
                color: '#F52A4C',
                fontWeight: 'bold',
                marginLeft: '3px',
              }}
            >
              ${data?.shift?.invoice_rate}
            </span>
          </Typography>
        </Stack>
        <Stack mb="4px" direction="row" justifyContent="space-between">
          <Typography sx={sxStyles.details}>
            <MdOutlineTune size="22px" />
            <span style={{ marginLeft: '6px' }}>Role</span>
          </Typography>
          <Typography sx={sxStyles.details}>{data?.shift?.role}</Typography>
        </Stack>
        <Stack mb="4px" direction="row" justifyContent="space-between">
          <Typography sx={sxStyles.details}>
            <WiTime3 size="22px" />
            <span style={{ marginLeft: '6px' }}>Start time</span>
          </Typography>
          <Typography sx={sxStyles.details}>
            {data?.shift?.start_time}
          </Typography>
        </Stack>
        <Stack mb="4px" direction="row" justifyContent="space-between">
          <Typography sx={sxStyles.details}>
            <WiTime5 size="22px" />
            <span style={{ marginLeft: '6px' }}>End time</span>
          </Typography>
          <Typography sx={sxStyles.details}>{data?.shift?.end_time}</Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Typography style={{ marginLeft: '5px' }} sx={sxStyles.details}>
            Status
          </Typography>
          <Typography style={{ color: '#F52A4C' }} sx={sxStyles.details}>
            {data.shift.shift_status}
          </Typography>
        </Stack>

        <Typography sx={[sxStyles.details, { marginTop: 2 }]}>
          Note: You will pay ${data.shift.invoice_rate} plus the mileage.
        </Typography>

        <Button
          disabled={isLoading}
          onClick={handleCancel}
          variant="contained"
          sx={sxStyles.btn}
        >
          {isLoading ? 'Cancelling...' : 'Cancel'}
        </Button>
      </Box>
      <Typography
        sx={{
          color: '#000000',
          fontSize: '18px',
          fontWeight: '500',
          m: '20px 0',
        }}
      >
        Hired
      </Typography>

      {displayCard()}

      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={errorMessage}
      />
    </Box>
  )
}

const sxStyles = {
  container: {
    background: 'background',
    boxShadow: '0px 20px 50px rgba(171, 171, 171, 0.25)',
    zIndex: 1,
    position: 'absolute',
    top: 150,
    left: 50,
    padding: 4,
    maxHeight: '70vh',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '10px',
      backgroundColor: '#F5F5F5',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '20px',
      border: '3px solid #F5F5F5',
    },
  },
  cardHeading: {
    fontSize: 30,
    fontWeight: 700,
    marginBottom: 5,
    color: 'custom.black',
  },
  detailsCard: {
    background: '#f6f6f6',
    padding: 1.5,
    borderRadius: '6px',
  },
  detailsStaff: {
    padding: 1.5,
    borderRadius: '6px',
  },
  subHeading: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '19px',
    color: 'custom.black',
    marginBottom: 1,
  },
  subHeading2: {
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '19px',
    color: 'custom.black',
    marginBottom: '10px',
    marginTop: '20px',
  },
  details: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '19px',
    color: 'custom.text',
    marginBottom: 1,
    display: 'flex',
    alignItems: 'center',
  },
  heading: {
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '21px',
    marginTop: 1,
    marginBottom: 1,
  },
  userImg: {
    height: '50px',
    width: '50px',
    borderRadius: '50%',
    marginRight: '15px',
    objectFit: 'cover',
  },
  btn: {
    width: '100%',
    borderRadius: '47px',
    marginTop: '10px',
    backgroundColor: '#E3022A',
  },
  fullInput: {
    width: '100%',
    backgroundColor: 'custom.registerInput',
    borderRadius: '6px',
  },
}
