import { ContactFacilityModal, FilterHeader } from '../../components'
import { CitiesOptions } from 'constants'
import { useState, useEffect } from 'react'
import { CiLocationOn } from 'react-icons/ci'

import { Box, Typography, Pagination, Button } from '@mui/material'
import { api } from 'main/configs/axios.config'

const SchoolLeads = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [activeData, setActiveData] = useState([])
  const [inactiveData, setInactiveData] = useState([])
  const [totalActivePages, setTotalActivePages] = useState(1)
  const [totalInactivePages, setTotalInactivePages] = useState(1)
  const [activeTab, setActiveTab] = useState(0)
  const [schoolModal, setSchoolModal] = useState(0)
  const [filter, setFilter] = useState({
    search: '',
    city: '',
  })
  const [open, setOpen] = useState(false)
  const [contactName, setContactName] = useState()

  const handleContactButtonClick = item => {
    setSchoolModal(item)
    setOpen(true)
  }

  const handlePageChange = (event, page) => {
    setCurrentPage(page)
  }

  const fetchActiveData = async () => {
    const response = api.get(
      `sales/schools?page_number=${currentPage}&page_size=12${
        filter.city === '' || filter.city === null ? '' : `&city=${filter.city}`
      }${
        filter.search === '' || filter.search === null
          ? ''
          : `&search=${filter.search}`
      }`
    )
    const data = await response
    setActiveData(data.schools)
    setTotalActivePages(data.total_pages)
  }
  useEffect(() => {
    fetchActiveData()
  }, [currentPage, filter.city, filter.search])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <FilterHeader
        placeholderSearch="Search school"
        value={filter.search}
        onChange={e => setFilter({ ...filter, search: e?.target?.value })}
        placeholderDropdown1="City"
        dropdown1Options={CitiesOptions.cities}
        dropdown1Value={filter.city}
        onChangeDropdown1={(e, value) => setFilter({ ...filter, city: value })}
      />

      <Box sx={{ minHeight: '83vh', overflowX: 'hidden' }}>
        {(activeTab === 0 ? activeData : inactiveData).length > 0 ? (
          <Box
            sx={{
              marginTop: '30px',
              display: 'flex',
              width: '100%',
              flexWrap: 'wrap',
              gap: '1%',
              alignItems: 'flex-start',
              padding: '20px 30px',
            }}
          >
            {(activeTab === 0 ? activeData : inactiveData).map(item => {
              return (
                <SchoolCard
                  key={item.id}
                  name={item.school_name}
                  city={item.city === '' ? 'NO LOCATION!' : item.city}
                  active={activeTab}
                  handleClick={() => {
                    handleContactButtonClick(item)
                  }}
                />
              )
            })}
          </Box>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '75vh',
            }}
          >
            No data available
          </div>
        )}
      </Box>
      <Pagination
        count={activeTab === 0 ? totalActivePages : totalInactivePages}
        page={currentPage}
        onChange={handlePageChange}
      />

      {open && (
        <ContactFacilityModal
          item={schoolModal}
          open={open}
          onClose={() => {
            setOpen(false)
          }}
          contactName={contactName}
          onChangeContactName={e => setContactName(e)}
          emailURL="/sales/schools/email"
          notesURL="/sales/schools/notes"
          isNoAnswer={true}
          sendTemplate={true}
          noCallHistory={true}
          noChatTab={true}
          baseURL={'/sales/schools/'}
          fetchActiveData={fetchActiveData}
          noChat={true}
        />
      )}
    </Box>
  )
}

const SchoolCard = props => {
  return (
    <Box
      sx={{
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        padding: '18px',
        backgroundColor: '#fff',
        borderRadius: '6px',
        boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.04)',
        width: {
          xs: '100%',
          sm: '100%',
          md: '48%',
          lg: '32%',
        },
        flexWrap: 'wrap',
        marginBottom: '25px',
        justifyContent: {
          xs: 'space-between',
          sm: 'space-between',
          md: 'space-between',
          lg: 'space-between',
        },
      }}
    >
      <Typography
        sx={{
          width: '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',

          fontSize: {
            xs: '18px',
            sm: '18px',
            md: '18px',
            lg: '22px',
          },
          color: '#0C0C0D',
          fontWeight: '700',
        }}
      >
        {props.name}
      </Typography>
      <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
        <CiLocationOn color="#3F5263" />
        <Typography
          sx={{
            fontSize: '16px',
            color: '#3F5263',
            fontWeight: '400',
          }}
        >
          {props.city}
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
        <Typography
          sx={{
            fontSize: '16px',
            color: '#3F5263',
            fontWeight: '400',
          }}
        >
          {props.active === 0 ? 'Active' : 'Inactive'}
        </Typography>
      </Box>

      <Button
        sx={{
          marginLeft: 0,
          color: '#D95624',
          outlineColor: '#D95624',
          borderColor: '#D95624',
          width: '100%',
          marginTop: '10px',
        }}
        variant="outlined"
        onClick={props.handleClick}
      >
        More details
      </Button>
    </Box>
  )
}

export default SchoolLeads
