// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { Box, Pagination, Typography } from '@mui/material'
import {
  FacilityLeadCard,
  FilterHeader,
  ContactFacilityModal,
} from '../../components'
import { FacilityLeadsService } from 'operational-sales-panel/services'
import { CountyOptions } from 'constants'
import { CapacityOptions } from 'constants'

function FacilityLeadsContacted() {
  const [open, setOpen] = useState()
  const [facilityLeads, setFacilityLeads] = useState([])
  const [facilityLeadsModal, setFacilityLeadsModal] = useState()
  const [rowsPerPage, setRowsPerPage] = useState(9)
  const [contactName, setContactName] = useState()
  const [contactStatus, setContactStatus] = useState()
  const [currentPage, setCurrentPage] = useState(1)

  const [filter, setFilter] = useState({
    search: '',
    county: '',
    capacity: null,
  })

  useEffect(() => {
    FacilityLeadsService.getFacilityLeadsContacted({
      currentPage,
      page_size: rowsPerPage,
      search: filter?.search,
      capacity: filter?.capacity,
      county: filter?.county,
    })
      .then(data => setFacilityLeads(data))
      .catch(err => console.log(err))
  }, [
    rowsPerPage,
    filter?.capacity,
    filter?.county,
    filter?.search,
    open,
    currentPage,
  ])

  const handlePageChange = (event, page) => {
    setCurrentPage(page)
  }

  const onMarkAsNotInterested = () => {
    FacilityLeadsService.markNotInterested({
      id: facilityLeadsModal?.id,
    })
      .then(() => setOpen(false))
      .catch(err => console.log(err))
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <FilterHeader
        placeholderSearch="Search facility"
        placeholderDropdown1="Capacity"
        dropdown1Options={CapacityOptions.capacity}
        dropdown1Value={filter.capacity}
        onChangeDropdown1={(e, value) =>
          setFilter({ ...filter, capacity: value })
        }
        placeholderDropdown2="County"
        dropdown2Options={CountyOptions.county}
        dropdown2Value={filter.county}
        onChangeDropdown2={(e, value) =>
          setFilter({ ...filter, county: value })
        }
      />

      <Box sx={{ minHeight: '82vh', overflowX: 'hidden' }}>
        {facilityLeads?.facilities?.length === 0 ? (
          <Typography
            sx={{
              width: '100%',
              paddingLeft: '30px',
              paddingRight: '30px',
              marginTop: '15px',
              color: '#8694B0',
              fontSize: '20px',
            }}
          >
            No Data Avaialble!
          </Typography>
        ) : null}

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            gap: '2%',
            justifyContent: {
              xs: 'flex-start',
              sm: 'flex-start',
              md: 'space-between',
              lg: 'flex-start',
            },
            paddingLeft: '30px',
            paddingRight: '30px',
            marginTop: '15px',
          }}
        >
          {facilityLeads?.facilities?.map((item, index) => {
            const [username, domain] =
              item?.email !== null ? item?.email?.split('@') : [null, null]
            return (
              <FacilityLeadCard
                key={index}
                name={item?.facility_name}
                location={item?.district}
                location2={item?.address}
                phone={item?.phone}
                capacity={item?.capacity}
                availability={item?.availability}
                btnText="View Details"
                username={username}
                domain={domain}
                onClick={() => {
                  setFacilityLeadsModal(item)
                  setOpen(true)
                }}
              />
            )
          })}
        </Box>
      </Box>
      {facilityLeads?.facilities?.length ? (
        <Pagination
          sx={{ marginTop: '20px', marginBottom: '10px' }}
          count={facilityLeads?.total_pages}
          page={currentPage}
          onChange={handlePageChange}
        />
      ) : null}

      {open && (
        <ContactFacilityModal
          isContact={true}
          item={facilityLeadsModal}
          open={open}
          onClose={() => {
            setOpen(false)
            setFacilityLeadsModal()
          }}
          contactName={contactName}
          onChangeContactName={e => setContactName(e)}
          contactStatus={contactStatus}
          onChangeContactStatus={e => setContactStatus(e)}
          // onMarkAsContacted={onMarkAsContacted}
          onMarkAsNotInterested={onMarkAsNotInterested}
          emailURL="/sales/facility_leads/email"
          notesURL="/sales/facility_leads/notes"
          isNoAnswer={true}
          sendTemplate={true}
        />
      )}
    </Box>
  )
}

export default FacilityLeadsContacted
