import React, { useEffect } from 'react';
import styles from './SalesSidebar.module.css';
import { salesRoutes } from '../../routes';
import SalesMenuItem from '../SalesMenuItem/SalesMenuItem';
import { Logo, Logout } from '../../assets/svg';
import { useTheme, useMediaQuery, Drawer } from '@mui/material';
import { useAuth } from 'main/hooks';
import { api } from 'main/configs/axios.config';

export default function SalesSidebar({
  isMobileDrawerOpen,
  onClose,
}) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const { handleLogout } = useAuth();

  useEffect(() => {
    heartBeat();
    const interval = setInterval(() => {
      heartBeat();
    }, 5 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  const heartBeat = () => {
    console.log('Send!');
    api.post('/clock_record/heartbeat');
  };

  return (
    <Drawer
      variant={matches ? 'permanent' : 'temporary'}
      open={matches || isMobileDrawerOpen}
      sx={sxStyles.drawer}
      onClose={onClose}
    >
      <div className={styles.logoWrapper}>
        <img
          src={Logo}
          alt="Nurse Billboard"
          className={styles.logo}
        />
      </div>

      <div className={styles.menuWrapper}>
        {salesRoutes?.routes?.map((item, index) => {
          if (!item.menu) return null;

          return (
            <SalesMenuItem
              key={index}
              more={item?.more}
              icon={item.icon}
              name={item.name}
              path={item?.path}
            />
          );
        })}
      </div>

      <div
        className={styles.row}
        onClick={() => {
          handleLogout();
        }}
      >
        <img
          src={Logout}
          alt="logout"
          className={styles.logoutIcon}
        />
        <span className={styles.logoutText}>Logout</span>
      </div>
    </Drawer>
  );
}

const sxStyles = {
  drawer: {
    width: 280,
    paddingTop: 25,
  },
};
