// ** Toolkit imports
import {configureStore} from "@reduxjs/toolkit";

// ** Reducers
import {auth} from "main/store/features";
import {getUser} from "./features/auth";
import {getAccessToken} from "main/configs/axios.config";

const initStore = () => {
  const store = configureStore({
    reducer: {
      auth,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
  !!getAccessToken() && store.dispatch<any>(getUser());
  return store;
};
export const store = initStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
