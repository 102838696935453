import { BellIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useColorModeValue,
  VStack,
  Text,
  IconButton,
  Heading,
  Icon,
} from "@chakra-ui/react";
import Breadcrumbs from "main/components/breadcrumb";
import { getNavigationContent } from "main/configs/navigation.config";
import moment from "moment";
import { BsBellFill } from "react-icons/bs";
import { FiMenu } from "react-icons/fi";
import { Link } from "react-router-dom";

type AppBarProps = {
  fixed: boolean;
  userName: string;
  userRole: string;
  handleLogout: () => void;
  handleOpenMenu: () => void;
};
const AppBar = (props: AppBarProps) => {
  const { fixed, userName, userRole, handleLogout, handleOpenMenu } = props;

  return (
    <Flex
      justifyContent='space-between'
      ml={{ base: 0, md: fixed ? 80 : 20 }}
      alignItems='center'
      p={3}
      shadow='md'
      position={"sticky"}
      top={0}
      zIndex={"docked"}
      bg='#fff'
    >
      <HStack display={{ base: "none", md: "block" }}>
        <Heading fontSize='md'>{moment().format("MMMM D, yyyy")}</Heading>
      </HStack>
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={handleOpenMenu}
        variant='ghost'
        aria-label='open menu'
        icon={<FiMenu />}
      />
      <HStack alignItems='center'>
        <Link to='/facility/notifications'>
          <IconButton
            size='lg'
            aria-label='notifications'
            icon={<Icon as={BsBellFill} />}
            mr={3}
            color='#E3022A'
            variant='ghost'
          />
        </Link>
        <Menu>
          <MenuButton transition='all 0.3s' _focus={{ boxShadow: "none" }}>
            <HStack>
              <Box>
                <Heading size='md'> {userName}</Heading>
                <Text fontSize='sm' textAlign='right'>
                  Scheduler
                </Text>
              </Box>
              <Avatar size={"md"} src='/assets/images/avatars/client.png' />
            </HStack>
          </MenuButton>
          <MenuList zIndex={9}>
            <Link to={"/facility/account"}>
              <MenuItem _hover={{ bg: "red.100" }}>Account</MenuItem>
            </Link>
            <MenuDivider />
            <MenuItem _hover={{ bg: "red.100" }} onClick={handleLogout}>
              Log out
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
    </Flex>
  );
};
export default AppBar;
