import React from 'react'

function SendMessage({ profile, message, time }) {
  return (
    <div
      style={{
        padding: '20px',
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingTop: '0',
      }}
    >
      {/* {profile ? (
        <img
          src={profile}
          alt="user"
          style={{
            height: '38px',
            width: '38px',
            borderRadius: '100%',
            marginLeft: '10px',
          }}
        />
      ) : (
        <div
          style={{
            height: '38px',
            width: '38px',
            borderRadius: '100%',
            marginLeft: '10px',
          }}
        />
      )} */}
      <div
        style={{
          backgroundColor: 'rgba(143, 149, 178, 0.15)',
          borderRadius: '20px',
          padding: '20px 15px',
          maxWidth: '70%',
          flexFlow: 'row',
        }}
      >
        <label
          style={{
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '24px',
            letterSpacing: '0.1px',
            color: '#081735',
            padding: '15px 20px',
            borderRadius: '20px',
          }}
        >
          {message}
        </label>
      </div>
      <label
        style={{
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '24px',
          letterSpacing: '0.1px',
          color: '#8f95b2',
          marginRight: '10px',
        }}
      >
        {time}
      </label>
    </div>
  )
}

export default SendMessage
