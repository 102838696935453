import {
  Box,
  Collapse,
  Flex,
  FlexProps,
  HStack,
  Icon,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { NavItemType } from "main/configs/navigation.config";

import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { Link, useMatch } from "react-router-dom";
import { useLocation } from "react-router-dom";
type NavItemProps = {
  fixed: boolean;
  hidden: boolean;
  navHover: boolean;
  isSub?: boolean;
  setHidden: (value: boolean) => void;
} & NavItemType &
  FlexProps;

const NavItem = ({
  icon,
  name,
  itemPath,
  fixed,
  navHover,
  hidden,
  setHidden,
  subItems,
  isSub,
  ...rest
}: NavItemProps) => {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  let pathname: string = useLocation().pathname;
  const isNavLinkActive = () => {
    if (pathname && pathname.includes(itemPath)) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Link
      to={subItems && subItems.length > 0 ? "" : itemPath}
      style={{ textDecoration: "none" }}
    >
      <Flex
        onClick={() => {
          if (!subItems || subItems.length === 0) {
            setHidden(true);
          }
          onToggle();

        }}
        align='center'
        py={3}
        px={5}
        gap={3}
        borderRadius='lg'
        role='group'
        cursor='pointer'
        transition={"background 0.3s ease"}
        justifyContent='space-between'
        fontWeight={isNavLinkActive() ? "medium" : "normal"}
        sx={{
          bg: isNavLinkActive() && !isSub ? "#E3022A" : undefined,
          color: isNavLinkActive() ? (isSub ? "gray.900" : "#fff") : undefined,
          fontWeight: isNavLinkActive() && isSub ? "bold" : "",
        }}
        _hover={{
          bg: isNavLinkActive() ? undefined : "red.100",
        }}
        {...rest}
      >
        <HStack>
          {icon && (
            <Icon
              fontSize='16'
              as={icon}
              color={isNavLinkActive() ? "#fff" : "#E3022A"}
            />
          )}
          <Text
            color='inherit'
            sx={{
              opacity: fixed || navHover ? 1 : 0,
              transition: "opacity 0.3s ease-in",
            }}
            whiteSpace='nowrap'
          >
            {name}
          </Text>
        </HStack>
        {subItems && subItems.length > 0 && (
          <Icon
            marginRight={0}
            fontSize='16'
            as={IoIosArrowForward}
            color={isNavLinkActive() ? "#fff" : "#E3022A"}
            transform={isOpen ? "rotate(90deg)" : ""}
            transition='0.3s transform ease'
          />
        )}
      </Flex>
      {subItems && subItems.length > 0 && (
        <Collapse in={isOpen && (fixed || (!fixed && navHover))}>
          <Box pl={5}>
            {subItems.map((link: NavItemType) => (
              <NavItem
                isSub={true}
                marginLeft={2}
                hidden={hidden}
                key={link.name}
                {...link}
                transition='visibility 0.2s ease'
                visibility={{
                  base: hidden ? "hidden" : "visible",
                  md: "visible",
                }}
                m={1}
                fixed={fixed}
                navHover={navHover}
                setHidden={setHidden}
              />
            ))}
          </Box>
        </Collapse>
      )}
    </Link>
  );
};
export default NavItem;
