// @ts-nocheck
export const dateFormater = (seconds) => {
  if (!seconds) return;

  let selectedDate;
  let months;
  const date = new Date(seconds);

  if (date.getMonth() < 10) {
    months = `0${date.getMonth() + 1}`;
  } else {
    months = date.getMonth() + 1;
  }

  if (date.getDate() < 10) {
    selectedDate = `0${date.getDate()}`;
  } else {
    selectedDate = date.getDate();
  }

  return `${date.getFullYear()}-${months}-${selectedDate}`;
};

export const getTimeFromDate = (time) => {
  if (!time) return;

  const options = { hour12: true, hour: "numeric", minute: "numeric" };
  const timeString = new Date(time).toLocaleTimeString("en-US", options);

  return timeString;
};

export const getDateTimeFromDate = (time) => {
  if (time === null || !time) return;

  const date = new Date(time);

  const options = { hour12: true, hour: "numeric", minute: "numeric" };
  const timeString = date.toLocaleTimeString("en-US", options);

  const month = date.getMonth() + 1;

  return `${date.getDate()}/${month}/${date.getFullYear()} ${timeString}`;
};

export const dayDateFormater = (seconds) => {
  if (!seconds) return;

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const date = new Date(seconds);
  return `${months[date.getMonth()]} ${date.getDate()}`;
};
