import React, { useState, useEffect } from 'react'
import {
  Button,
  Card,
  Stack,
  TextField,
  Typography,
  CircularProgress,
  useMediaQuery,
  useTheme,
  IconButton,
  Divider,
  Box,
  Snackbar,
} from '@mui/material'
import { DashboardService } from 'homecare/services'
import io from 'socket.io-client'

import { HiOutlineDocumentChartBar } from 'react-icons/hi2'
import { MdOutlineTune } from 'react-icons/md'
import { WiTime3, WiTime5 } from 'react-icons/wi'
import { FcEmptyFilter } from 'react-icons/fc'

import DateRangeIcon from '@mui/icons-material/DateRange'
import { api } from 'main/configs/axios.config'

import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { useQuery } from '@tanstack/react-query'
import { Buffer } from 'buffer'
import StaffCard from '../StaffCard/StaffCard'

export default function StaffApplied({ shift, setShow, setTrack }) {
  const [isLoading, setIsLoading] = useState(false)
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  const [isOpen, setIsOpen] = useState(false)
  const [emptyMessage, setEmptyMessage] = useState('')
  const [open, setOpen] = useState(false)

  const activeShifts = async () => {
    const res = await api.get(
      `individuals/shift/staff-applying?shift_id=${shift?.id}`
    )
    return res
  }

  const getActiveShifts = useQuery({
    queryKey: ['getActiveShifts', shift?.id],
    queryFn: activeShifts,
    enabled: true,
  })

  useEffect(() => {
    if (!getActiveShifts.isFetching && !getActiveShifts.isError) {
      getActiveShifts.refetch()
    }
  }, [])

  function displayStaffApplied() {
    return (
      <Box>
        <Typography
          sx={{
            color: '#000000',
            fontSize: '25px',
            fontWeight: '500',
            m: '20px 0',
          }}
        >
          Staffs Applied
        </Typography>
        {getActiveShifts.isLoading ? (
          <Box
            display="flex"
            width="100%"
            justifyContent="center"
            alignItems="center"
            height="250px"
          >
            <CircularProgress size={24} />
          </Box>
        ) : getActiveShifts?.data.length !== 0 ? (
          getActiveShifts?.data?.map(staff => (
            <StaffCard
              staff={staff}
              shift={shift}
              setShow={setShow}
              setTrack={setTrack}
              key={staff.id}
            />
          ))
        ) : (
          <Box ml="5px" display="flex" gap="5px">
            <Typography
              style={{
                color: '#B5AFAF',
                fontWeight: 'normal',
                letterSpacing: '1.5',
              }}
            >
              NO ACTIVE STAFF
            </Typography>
            <FcEmptyFilter />
          </Box>
        )}
      </Box>
    )
  }

  return (
    <Card
      sx={{
        zIndex: 100,
        position: 'absolute',
        top: 150,
        padding: { xs: '12px', sm: '40px', md: '40px' },
        width: { xs: '95%', sm: '95%', md: '437px' },
        minHeight: '500px',
        maxHeight: '70vh',
        left: { xs: 10, sm: 10, md: 50 },
        boxShadow: 'none',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
          width: '10px',
          backgroundColor: '#F5F5F5',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#D2D2D2',
          borderRadius: '20px',
          border: '3px solid #F5F5F5',
        },
      }}
    >
      {displayStaffApplied()}
    </Card>
  )
}

const sxStyles = {
  heading: {
    fontWeight: '700',
    fontSize: '48px',
    lineHeight: '58px',
    color: 'custom.black',
  },
  summary: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#000000',
  },
  fullInput: {
    width: '100%',
    backgroundColor: 'custom.registerInput',
    marginBottom: 2,
    padding: '10px',
    borderRadius: '6px',
  },
  halfInput: {
    width: '48%',
    backgroundColor: 'custom.registerInput',
    marginBottom: 2,
    padding: '10px',
    borderRadius: '6px',
  },
  halfSelect: {
    width: '48%',
    marginBottom: 2,
    padding: '10px',
    borderRadius: '6px',
  },
  scheduleBtn: {
    borderRadius: '56px',
    backgroundColor: 'custom.registerInput',
    color: 'custom.black',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '19px',
  },
  detailsCard: {
    background: '#F6F6F6',
    padding: '16px',
    borderRadius: '6px',
    width: '100%',
  },
  subHeading: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#000000',
    marginBottom: 1,
  },
  details: {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#818181',
    marginBottom: 1,
    display: 'flex',
    alignItems: 'center',
  },
  userImg: {
    height: '50px',
    width: '50px',
    borderRadius: '50%',
    marginRight: '15px',
    objectFit: 'cover',
  },
  btn: {
    width: '100px',
    borderRadius: '47px',
    marginTop: '20px',
  },
}
