import { React, useState, useEffect, useCallback, useRef } from 'react'
import {
  Box,
  Text,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Image,
  IconButton,
  Avatar,
} from '@chakra-ui/react'
import { Search2Icon } from '@chakra-ui/icons'
import io from 'socket.io-client'
import Messages from '../../assets/img/message.png'
import { api } from 'main/configs/axios.config'
import moment from 'moment'
import { IoSendSharp } from 'react-icons/io5'
import { useQuery } from '@tanstack/react-query'
import { DashboardService } from 'homecare/services'
import { extendTheme } from '@chakra-ui/react'
import { ChakraProvider } from '@chakra-ui/react'

const baseURL = process.env.REACT_APP_API_URL
const settings = {
  url: baseURL,
  path: '/message',
  transports: ['websocket', 'polling'],
  port: 8000,
  transportOptions: {
    polling: {
      extraHeaders: {
        'Access-Control-Allow-Origin': baseURL,
      },
    },
  },
  query: {
    token: localStorage.getItem('access_token') || '',
  },
  withCredentials: true,
  rejectUnauthorized: true,
  timeout: 20000,
  reconnection: true,
  reconnectionAttempts: 3,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  cors: {
    origin: baseURL,
  },
  secure: true,
  headers: {
    'Access-Control-Allow-Origin': baseURL,
  },
}

let socket
export default function DashboardActiveRequestCard() {
  const [connected, setConnected] = useState(false)
  const [messages, setMessages] = useState([])
  const [message, setMessage] = useState('')
  const [chats, setChats] = useState([])
  const [selectedChat, setSelectedChat] = useState(chats[0])
  const messagesEndRef = useRef()
  const [loadUserMessageStatus, setLoadUserMessageStatus] = useState({
    state: 'loading',
  })

  const [loadMessagesStatus, setLoadMessagesStatus] = useState({
    state: 'loading',
  })

  const [sendMessageLoad, setSendMessageLoad] = useState({
    state: 'idle',
  })

  const getUserData = useQuery({
    queryKey: ['getUserData'],
    queryFn: DashboardService.getUserData,
  })

  const getMessages = useCallback(async index => {
    try {
      const data = await api
        .get('messages', {
          params: {
            time_zone: moment().local().format('Z').toString(),
          },
        })
        .then((response: any) => {
          setLoadMessagesStatus({ ...loadMessagesStatus, state: 'sucess' })
          setChats(response)
          setSelectedChat(response[index || 0])
          return response
        })
    } catch (error) {
      setLoadMessagesStatus({ ...loadMessagesStatus, state: 'error' })
    }
  }, [])

  const getMessagesWithUser = useCallback(async () => {
    if (selectedChat) {
      try {
        const data = await api
          .get(`messages/${selectedChat.staff?.phone}`, {
            params: {
              shift_id: selectedChat.id,
              time_zone: moment().local().format('Z').toString(),
            },
          })
          .then(response => {
            setMessages(response)
            setLoadUserMessageStatus({
              ...loadUserMessageStatus,
              state: 'sucess',
            })
            return response
          })
      } catch (error) {
        setLoadUserMessageStatus({ ...loadUserMessageStatus, state: 'error' })
      }
    }
  }, [selectedChat])

  useEffect(() => {
    const token = localStorage.getItem('access_token')

    if (token) {
      socket = io(
        `${baseURL}?token=${
          localStorage.getItem('access_token')
            ? JSON.parse(localStorage.getItem('access_token')?.toString() || '')
            : ''
        }`,
        settings
      )

      const onConnect = () => {
        setConnected(true)
        // console.log('Connect')
      }

      const onDisconnect = () => {
        setConnected(false)
        socket.off('connect', onConnect)
        socket.off('disconnect', onDisconnect)
        socket.off('connect_error', onConnectError)
        // console.log('Disconnect')
      }

      const onConnectError = (error: any) => {
        console.log('connect_error', error?.message)
      }

      socket.on('connect', onConnect)
      socket.on('disconnect', onDisconnect)
      socket.on('connect_error', onConnectError)
    }

    return () => {
      if (socket) {
        socket.disconnect()
      }
    }
  }, [])

  useEffect(() => {
    getMessages()
  }, [getMessages])

  useEffect(() => {
    getMessagesWithUser()
  }, [getMessagesWithUser])

  useEffect(() => {
    socket.on('new_message', (msg: string) => {
      getMessages()
      setSendMessageLoad({ ...sendMessageLoad, state: 'sucess' })
    })
  }, [socket])

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  function handleSubmit() {
    if (message === '') {
      // HANDLE
    } else {
      setSendMessageLoad({ ...sendMessageLoad, state: 'loading' })
      socket.open()
      socket.emit('send_message', {
        message: message,
        receiver: selectedChat?.staff?.phone,
        shift_id: selectedChat?.id,
        time_zone: moment().local().format('Z').toString(),
      })

      setMessage('')
    }
  }

  function onChangeMessage(e: any) {
    setMessage(e.target.value)
  }

  const breakpoints = {
    xs: '0',
    sm: '600px',
    md: '900px',
    lg: '1200px',
    xl: '1536px',
  }

  const theme = extendTheme({
    breakpoints,
    fonts: {
      body: 'Inter',
      heading: 'Inter',
    },
    typography: {
      button: {
        textTransform: 'none',
      },
      h1: {
        fontSize: '24px',
        fontWeight: '400',
        lineHeight: '36px',
      },
      h6: {
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '20px',
        marginRight: '20px',
      },
      body1: {
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '19px',
        color: '#626262',
      },
      body2: {
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '19px',
        color: '#000000',
      },
      body3: {
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '19px',
        color: '#626262',
      },
    },
  })

  function userCard(name, date, msg, index, chat) {
    return (
      <Flex
        transition="all 0.2s ease-in-out"
        _hover={{ backgroundColor: '#f2f2f2' }}
        cursor="pointer"
        w="100%"
        p="15px 20px"
        bg={
          selectedChat?.id === chat.id
            ? '#f2f2f2'
            : chat.unread_messages || chat.unread_messages > 0
            ? 'white'
            : ''
        }
        onClick={() => {
          getMessages(index)
        }}
      >
        <Flex w="100%" alignItems="center" gap="11px">
          <Avatar
            borderRadius="50%"
            w={['35px', '35px', '35px', '35px', '50px']}
            h={['35px', '35px', '35px', '35px', '50px']}
            bgColor="#E3022A"
            color="white"
            name={name}
            src=""
          />
          <Flex
            display={['none', 'none', 'none', 'none', 'flex']}
            w="100%"
            flexDir="column"
          >
            <Flex w="100%" justifyContent="space-between">
              <Text color="#202020" fontSize="14px" fontWeight="bold">
                {name}
              </Text>
              <Text color="#898989" fontSize="12px" fontWeight="normal">
                {date}
              </Text>
            </Flex>
            <Text
              w="180px"
              fontSize="14px"
              fontWeight="normal"
              color="#898989"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {msg}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    )
  }

  return loadMessagesStatus.state === 'sucess' ? (
    chats.length !== 0 ? (
      <ChakraProvider theme={{ breakpoints }}>
        <Box
          position="absolute"
          zIndex={1}
          top={75}
          left={[5, 5, 5, 5, 110]}
          filter="drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.12))"
          display="flex"
          w={['90vw', '90vw', '90vw', '90vw', '70vw']}
          borderStyle="none"
        >
          <Flex
            borderRight="1px solid #DCDCDC"
            h="80vh"
            w={['75px', '75px', '75px', '75px', '40%']}
            backgroundColor="white"
            flexDir="column"
          >
            <Flex
              gap="16px"
              flexDir="column"
              p={['10px', '10px', '10px', '10px', '25px']}
            >
              <Text color="#202020" fontSize="18px" fontWeight="semibold">
                Chats
              </Text>
              <InputGroup display={['none', 'none', 'none', 'none', 'flex']}>
                <InputLeftElement
                  p="10px"
                  pointerEvents="none"
                  children={<Search2Icon color="#898989" />}
                />
                <Input
                  size="md"
                  type="text"
                  variant="outline"
                  outlineColor="#DCDCDC"
                  placeholder="Search chat"
                  border="1px solid #DCDCDC"
                  borderRadius="8px"
                  p="10px"
                  paddingLeft="35px"
                  w="100%"
                  _placeholder={{ color: '#898989' }}
                />
              </InputGroup>
            </Flex>

            {chats.map((chat, index) => (
              <Box key={index}>
                {userCard(
                  chat.staff?.first_name + ' ' + chat.staff?.last_name,
                  moment(chat?.last_message?.message_date).format('hh:mm A'),
                  chat?.last_message?.message,
                  index,
                  chat
                )}
              </Box>
            ))}
          </Flex>
          <Flex borderStyle="none" w="100%" backgroundColor="#F9F9F9">
            <Flex
              borderStyle="none"
              w="100%"
              flexDir="column"
              alignItems="center"
            >
              <Flex
                style={{
                  filter: 'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.08))',
                }}
                h="80px"
                w="100%"
                backgroundColor="white"
                alignItems="center"
              >
                <Text
                  fontSize="14px"
                  fontWeight="bold"
                  p="10px"
                  pl="30px"
                  color="#202020"
                >
                  {selectedChat.staff?.first_name}{' '}
                  {selectedChat.staff?.last_name}
                </Text>
              </Flex>

              <Box
                borderLeftWidth={['0', '0', '0', '1px', '1px']}
                w={'100%'}
                h="100%"
                borderStyle="none"
              >
                <Box
                  w="100%"
                  borderStyle="none"
                  position="relative"
                  height={'100%'}
                  px={5}
                >
                  <Box
                    width={'100%'}
                    paddingLeft={['5px', '5px', '5px', '5px', '72px']}
                    paddingRight="24px"
                    overflowY="auto"
                    height="70vh"
                    css={{
                      '&::-webkit-scrollbar': {
                        width: '10px',
                        backgroundColor: '#F5F5F5',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#888',
                        borderRadius: '20px',
                        border: '3px solid #F5F5F5',
                      },
                    }}
                    // HERE
                  >
                    {messages
                      ?.map(item =>
                        moment(item.message_date).format('D MMM YYYY')
                      )
                      .filter((item, i, ar) => ar.indexOf(item) === i)
                      .map(msg_date => {
                        return (
                          <Flex
                            key={msg_date}
                            flexDir="column"
                            marginBottom="5rem"
                          >
                            {
                              <Text
                                fontSize="14px"
                                color="#898989"
                                textAlign="center"
                                mt="16px"
                              >
                                {moment(msg_date).isSame(moment(), 'day')
                                  ? 'Today'
                                  : moment(msg_date).format('D MMM YYYY')}
                              </Text>
                            }
                            {messages
                              .filter(
                                msg =>
                                  moment(msg.message_date).format(
                                    'D MMM YYYY'
                                  ) === msg_date
                              )
                              .map(msg => {
                                return (
                                  <Flex
                                    flexDir="column"
                                    justifyContent="center"
                                    alignItems={
                                      msg?.sender_phone ===
                                      getUserData?.data?.phone
                                        ? 'end'
                                        : 'start'
                                    }
                                    gap={2}
                                    key={msg.id}
                                  >
                                    <Flex
                                      backgroundColor={
                                        msg?.sender_phone !==
                                        getUserData?.data?.phone
                                          ? '#fff'
                                          : '#E3022A'
                                      }
                                      borderRadius={
                                        msg?.sender_phone !==
                                        getUserData?.data?.phone
                                          ? '8px 8px 8px 0'
                                          : '8px 8px 0 8px'
                                      }
                                      color={
                                        msg?.sender_phone !==
                                        getUserData?.data?.phone
                                          ? '#202020'
                                          : '#FFFFFF'
                                      }
                                      padding="8px 12px"
                                      filter="drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.08))"
                                      key={msg.id}
                                      border="none"
                                      width="fit-content"
                                      mb="4px"
                                      fontSize="16px"
                                      gap="7px"
                                      ref={messagesEndRef}
                                    >
                                      <Text>{msg.message}</Text>
                                      <Text
                                        minW="fit-content"
                                        color={
                                          msg?.sender_phone !==
                                          getUserData?.data?.phone
                                            ? '#AEAEAE'
                                            : '#FFFFFF'
                                        }
                                        fontSize="11px"
                                        marginTop="10px"
                                      >
                                        {moment(msg.message_date).format(
                                          'hh:mm A'
                                        )}
                                      </Text>
                                    </Flex>
                                    {/* <Text fontSize="sm" color="GrayText">
                                    {moment(msg.message_date).format('hh:mm A')}
                                  </Text> */}
                                  </Flex>
                                )
                              })}
                          </Flex>
                        )
                      })}
                  </Box>
                </Box>
              </Box>
              <Box
                w={['60%', '60%', '60%', '60%', '60%']}
                position="absolute"
                bottom="20"
                mt={5}
                backgroundColor="#fff"
                borderRadius="16px"
              >
                <form onSubmit={e => e.preventDefault()}>
                  <InputGroup>
                    <Input
                      value={message}
                      onChange={onChangeMessage}
                      h="auto"
                      minH="64px"
                      size="md"
                      type="textarea"
                      variant="outline"
                      outlineColor="#DCDCDC"
                      placeholder="Write a message…"
                      border="1px solid #DCDCDC"
                      filter="drop-shadow(0px 12px 32px rgba(0, 0, 0, 0.04))"
                      borderRadius="16px"
                      p="10px"
                      paddingLeft="35px"
                      w="100%"
                      _placeholder={{ color: '#898989' }}
                      pr="4.5rem"
                    />
                    <IconButton
                      w="36px"
                      h="36px"
                      position="absolute"
                      right="16px"
                      bottom="14px"
                      backgroundColor="#E3022A"
                      border="none"
                      borderRadius="50%"
                      color={'#fff'}
                      variant="solid"
                      onClick={handleSubmit}
                      sx={{ '&:hover': { backgroundColor: '#CC0024' } }}
                      isLoading={sendMessageLoad.state === 'loading'}
                      cursor="pointer"
                      icon={<IoSendSharp size="16px" />}
                    ></IconButton>
                  </InputGroup>
                </form>
              </Box>
            </Flex>
          </Flex>
        </Box>
      </ChakraProvider>
    ) : (
      <ChakraProvider theme={theme}>
        <Flex
          flexDir="column"
          justifyContent="center"
          alignItems="center"
          h="80vh"
          width={['90vw', '90vw', '90vw', '90vw', '70%']}
          overflow="auto"
          position="absolute"
          zIndex={1}
          top={75}
          left={[15, 15, 15, 15, 110]}
          filter="drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.12))"
          display={['flex']}
          backgroundColor="white"
        >
          <Flex w="100%" flexDir="column" alignItems="center">
            <Flex
              style={{ filter: 'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.08))' }}
              h="80px"
              w="100%"
              backgroundColor="white"
              alignItems="center"
              position="absolute"
              top="0"
            >
              <Text p="10px" color="#898989"></Text>
            </Flex>
          </Flex>
          <Image
            mb="30px"
            w={['200px', '200px', '250px', '250px', '250px']}
            src={Messages}
          />
          <Text
            fontSize={['16px', '16px', '20px', '20px', '20px']}
            color="#9B9B9B"
            letterSpacing="1.5px"
          >
            NO MESSAGES
          </Text>
        </Flex>
      </ChakraProvider>
    )
  ) : (
    <Text>Loading</Text>
  )
}
