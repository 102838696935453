import { Container, Flex } from "@chakra-ui/react";
import PhoneConfirmation from "main/views/register/phone-confirmation";
import RegisterForm from "main/views/register/register";
import { useState } from "react";

const Register = () => {
  const [confirmationSent, setConfirmationSent] = useState(false);
  const [phone, setPhone] = useState<string>();
  return (
    <Container centerContent minH={"100vh"}>
      <Flex minH={"100vh"}>
        {phone && confirmationSent ? (
          <PhoneConfirmation phone={phone} />
        ) : (
          <RegisterForm
            setPhone={setPhone}
            onSubmit={() => setConfirmationSent(true)}
          />
        )}
      </Flex>
    </Container>
  );
};

export default Register;
