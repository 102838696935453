import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Snackbar,
  CircularProgress,
} from '@mui/material'
import { HomeCareHeader } from '../../components'
import { PDFCheckBox, PDFDocument, PDFTextField } from 'pdf-lib'
import { Buffer } from 'buffer'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { DashboardService } from 'homecare/services'
import { api } from 'main/configs/axios.config'

export default function SubmitContract() {
  const [pdfData, setPdfData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [emptyMessage, setEmptyMessage] = useState('')
  const [open, setOpen] = useState(false)

  const navigate = useNavigate()
  var requiredData = {}
  var signatureBase64 = ''

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  const handleClick = () => {
    setOpen(true)
  }

  const getAccount = useQuery({
    queryKey: ['getAccount'],
    queryFn: DashboardService.getAccount,
  })

  async function handleSubmit() {
    const signature_base64 = signatureBase64
    const bodyData = { ...requiredData, signature_base64 }

    setIsLoading(true)
    try {
      await api.post('individuals/contract', bodyData)

      setEmptyMessage('Done ✔')
      if (sessionStorage.getItem('shiftData')) {
        const shiftData = JSON.parse(sessionStorage.getItem('shiftData'))
        await api
          .post('individuals/shift/request', shiftData)
          .then(() => {
            handleClick()
            navigate('/individual/dashboard')
          })
          .catch(e => {
            handleClick()
            navigate('/individual/dashboard')
          })
          .finally(() => {
            handleClick()
            navigate('/individual/dashboard')
          })
      } else {
        handleClick()
        navigate('/individual/dashboard')
      }
    } catch (error) {
      setEmptyMessage(error.response.data.message)
      handleClick()
    }

    setIsLoading(false)
  }

  useEffect(() => {
    const savedData = sessionStorage.getItem('RequiredDetails')
    const savedImage = sessionStorage.getItem('signatureBase64')
    if (savedImage) {
      signatureBase64 = JSON.parse(savedImage)
    }

    if (savedData) {
      requiredData = JSON.parse(savedData)
    } else {
      navigate('/individual/dashboard')
    }
  })

  async function addTextToPdf() {
    const url = '/individual_contract.pdf'
    const res = await fetch(url).then(res => res.arrayBuffer())
    const pdfDoc = await PDFDocument.load(res)

    const form = pdfDoc.getForm()
    // form.getFields().forEach(field => {
    //   console.log(field.getName())
    // })
    const clientName = form.getTextField('client name')
    const nameOfPaitent = form.getTextField('name of paitent')
    const address = form.getTextField('address')
    const zip = form.getTextField('zip')
    const phone = form.getTextField('phone')
    const clientName2 = form.getTextField('client name2')
    const datedAtCity = form.getTextField('dated at city')
    const dayInput = form.getTextField('day')
    const monthInput = form.getTextField('month')
    const yearInput = form.getTextField('year')
    const company = form.getTextField('company')
    const fees1 = form.getTextField('fees1')
    const fees2 = form.getTextField('fees2')
    const fees3 = form.getTextField('fees3')
    const fees4 = form.getTextField('fees4')
    const fees5 = form.getTextField('fees5')
    const fees6 = form.getTextField('fees6')
    const fees7 = form.getTextField('fees7')
    const currentDate = new Date()
    const day = currentDate.getDate()
    const month = currentDate.getMonth() + 1
    const year = currentDate.getFullYear()
    const fullName = requiredData?.first_name + ' ' + requiredData?.last_name
    const [firstLetter, lastLetter] = fullName
      .split(' ')
      .map(str => str.charAt(0))

    clientName.setText(`${fullName}`)
    address.setText(`${requiredData?.address}`)
    zip.setText(`${requiredData?.postal_code}`)
    phone.setText(`${getAccount?.data?.phone}`)
    nameOfPaitent.setText(`${requiredData?.patient_name}`)
    clientName2.setText(`${fullName}`)
    datedAtCity.setText(`${requiredData?.city}`)
    dayInput.setText(`${day}`)
    monthInput.setText(`${month}`)
    yearInput.setText(`${year}`)
    company.setText('Wynalda Tataw')
    fees1.setText(`${firstLetter + lastLetter}`)
    fees2.setText(`${firstLetter + lastLetter}`)
    fees3.setText(`${firstLetter + lastLetter}`)
    fees4.setText(`${firstLetter + lastLetter}`)
    fees5.setText(`${firstLetter + lastLetter}`)
    fees6.setText(`${firstLetter + lastLetter}`)
    fees7.setText(`${firstLetter + lastLetter}`)

    const page = pdfDoc.getPages()[1]
    //********************* Load Will signature  *********************/
    const imageUrl = '/Images/signature.png'
    const imageResponse = await fetch(imageUrl)
    const imageBytes = await imageResponse.arrayBuffer()
    const imageUint8Array = new Uint8Array(imageBytes)

    const image = await pdfDoc.embedPng(imageUint8Array)

    const imageWidth = 100
    const imageHeight = (imageWidth * image.height) / image.width
    const imageX = 350
    const imageY = page.getHeight() - 630 - imageHeight

    //********************* Load Client Signature  *********************/
    const signatureUrl = `data:image/png;base64,${signatureBase64}`
    const signatureResponse = await fetch(signatureUrl)
    const signatureBytes = await signatureResponse.arrayBuffer()
    const signatureUint8Array = new Uint8Array(signatureBytes)
    const signature = await pdfDoc.embedPng(signatureUint8Array)

    const signatureWidth = 250
    const signatureHeight =
      (signatureWidth * signature.height) / signature.width
    const signatureX = 400
    const signatureY = page.getHeight() - 560 - signatureHeight

    // Draw the signature image
    page.drawImage(image, {
      x: imageX,
      y: imageY,
      width: imageWidth,
      height: imageHeight,
    })

    // Draw the user-drawn signature
    page.drawImage(signature, {
      x: signatureX,
      y: signatureY,
      width: signatureWidth,
      height: signatureHeight,
    })

    const pdfBytes = await pdfDoc.save()
    const base64PdfString = Buffer.from(pdfBytes).toString('base64')
    const srcValue = 'data:application/pdf;base64,' + base64PdfString
    setPdfData(srcValue)
  }

  useEffect(() => {
    addTextToPdf()
  }, [])

  const renderPdf = () => {
    return (
      <div style={{ height: '75vh' }}>
        {pdfData && (
          <iframe
            style={{
              border: 'none',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
              width: '100%',
            }}
            title="PDF Viewer"
            src={pdfData}
            height="100%"
          />
        )}
      </div>
    )
  }
  return (
    <Box sx={sxStyles.container}>
      <HomeCareHeader heading="Submit Contract" />

      <Box sx={sxStyles.cardContainer}>
        <Card sx={sxStyles.card}>
          <CardContent
            sx={{
              gap: '10px',
              flexDirection: { xs: 'column', sm: 'row', md: 'row' },
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={sxStyles.cardHeading}
              color="custom.black"
              gutterBottom
            >
              Contract
            </Typography>
            <Button
              onClick={handleSubmit}
              sx={sxStyles.contractBtn}
              variant="contained"
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} /> : 'Submit'}
            </Button>
          </CardContent>
          {renderPdf()}

          <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            message={emptyMessage}
          />
        </Card>
      </Box>
    </Box>
  )
}

const sxStyles = {
  container: {
    flex: 1,
    backgroundColor: 'background.default',
  },
  cardContainer: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    marginTop: 5,
  },
  card: {
    padding: 2,
    boxShadow: '0px 0px 80px #EFEFEF',
    width: '80%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardHeading: {
    fontSize: 30,
    fontWeight: 600,
  },
  contractBtn: {
    width: '150px',
    borderRadius: '56px',
  },
}
