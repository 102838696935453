import { api } from "main/configs/axios.config";

export const getDetails = async () => {
  const details = await api.get("operation_panel/dashboard/details");
  return details;
};

export const getStaff = async () => {
  const staff = await api.get("operation_panel/dashboard/staff");
  return staff;
};
