import { React, useEffect, useState } from 'react'
import {
  Button,
  Card,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  ListItemButton,
  ListItemText,
  Snackbar,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
} from '@mui/material'
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import moment from 'moment'
import {
  AccessTimeOutlined,
  Flag,
  Description,
  LocationOnOutlined,
  TuneOutlined,
  Wc,
} from '@mui/icons-material'
import { api } from 'main/configs/axios.config'

export default function RequestHealthCareCardDashboard({ setActiveShift }) {
  const theme = useTheme()
  const [formData, setFormData] = useState({
    location: '',
    role: '',
    tasks: [],
    shiftHours: '',
    language: '',
    details: '',
    gender: '',
  })

  const [lat, setLat] = useState('')
  const [long, setLong] = useState('')
  const [address, setAddress] = useState('')
  const [state, setState] = useState('')
  const [city, setCity] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [country, setCountry] = useState('')
  const [selectedDate, setSelectedDate] = useState(null)

  //*************** GET LOCATION SUGGESTIONS ****************/
  const [inputValue, setInputValue] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const [emptyMessage, setEmptyMessage] = useState('')
  const handleInputChange = event => {
    setInputValue(event.target.value)
    if (!event.target.value) {
      setAddress('')
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.get(
        `location-suggestions?search=${inputValue}`
      )
      const data = await response
      setSuggestions(data)
    }

    fetchData()
  }, [inputValue])

  const [open, setOpen] = useState(false)
  const handleClick = () => {
    setOpen(true)
  }

  const [isLoading, setIsLoading] = useState(false)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const handleFormSubmit = async e => {
    var isEmpty = !address
      ? 'Location is require'
      : !formData.role
      ? 'Role is require'
      : !formData.tasks
      ? 'Tasks is require'
      : !formData.shiftHours
      ? 'Shift Hours is require'
      : !formData.language
      ? 'Language is require'
      : !formData.gender
      ? 'Gender is require'
      : !formData.details
      ? 'Details is require'
      : ''

    setEmptyMessage(isEmpty)
    if (isEmpty) {
      handleClick()
    } else {
      const tasks = [...formData.tasks]
      const scheduled_date = selectedDate
      const fullAddress = {
        language: formData.language,
        gender: formData.gender,
        lat: lat,
        long: long,
        details: formData.details,
        role: formData.role,
        hours: formData.shiftHours,
        address: address,
        city: city,
        state: state,
        postal_code: postalCode,
        country: country,
        tasks: tasks.join('/'),
        time_zone: moment().local().format('Z').toString(),
      }

      const bodyData =
        scheduled_date === null
          ? { ...fullAddress }
          : { ...fullAddress, scheduled_date }

      setIsLoading(true)
      try {
        const response = await api.post('individuals/shift/request', bodyData)

        setEmptyMessage('Done ✔')
        handleClick()
        setSelectedDate(null)
        // setActiveShift('next')
        setFormData({
          location: '',
          role: '',
          tasks: [],
          shiftHours: '',
          language: '',
          details: '',
          gender: '',
        })
        setLat('')
        setLong('')
        setAddress('')
        setCity('')
        setState('')
        setPostalCode('')
        setCountry('')
      } catch (error) {
        setEmptyMessage(error.response.data.message)
        handleClick()
      }

      setIsLoading(false)
    }
  }

  const handleRoleChange = e => {
    setFormData({ ...formData, role: e.target.value })
  }

  const handleTasksChange = e => {
    setFormData({ ...formData, tasks: e.target.value })
  }

  const handleShiftHoursChange = e => {
    setFormData({ ...formData, shiftHours: e.target.value })
  }

  const handleLanguageChange = e => {
    setFormData({ ...formData, language: e.target.value })
  }

  const handleDetailsChange = e => {
    setFormData({ ...formData, details: e.target.value })
  }

  const handleGenderChange = e => {
    setFormData({ ...formData, gender: e.target.value })
  }

  const handleDateChange = date => {
    const newDate = new Date(date)
    const year = newDate.getFullYear()
    const month = ('0' + (newDate.getMonth() + 1)).slice(-2)
    const day = ('0' + newDate.getDate()).slice(-2)
    const hours = ('0' + newDate.getHours()).slice(-2)
    const minutes = ('0' + newDate.getMinutes()).slice(-2)
    const seconds = ('0' + newDate.getSeconds()).slice(-2)
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    setSelectedDate(formattedDate)
  }

  return (
    <Card
      sx={{
        zIndex: 100,
        position: 'absolute',
        top: 150,
        padding: '40px 25px',
        width: { xs: '95%', sm: '95%', md: '660px' },
        left: { xs: 10, sm: 10, md: 50 },
        boxShadow: 'none',
      }}
    >
      <Typography
        sx={{
          marginBottom: { xs: '8px', sm: '8px', md: '18px' },
          fontWeight: '700',
          fontSize: { xs: '1.5rem', sm: '1.5rem', md: '3rem' },
          lineHeight: '50px',
          color: 'custom.black',
        }}
      >
        Request Healthcare now
      </Typography>
      <Typography
        sx={{
          fontWeight: '400',
          fontSize: { xs: '14px', sm: '14px', md: '16px' },
          lineHeight: '19px',
          color: '#757575',
          margonTop: { xs: '0', sm: '0', md: '24px' },
          marginBottom: { xs: '22px', sm: '22px', md: '24px' },
        }}
      >
        Short Summary
      </Typography>
      <TextField
        sx={sxStyles.fullInput}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LocationOnOutlined size={10} />
            </InputAdornment>
          ),
          disableUnderline: true,
        }}
        variant="standard"
        placeholder="Enter and select your location"
        value={inputValue}
        onChange={handleInputChange}
      />
      {suggestions.length === 0 && (
        <Typography
          sx={[
            sxStyles.summary,
            { marginLeft: '15px', color: '#D0D0D0', fontSize: '14px' },
          ]}
        >
          No suggestions
        </Typography>
      )}
      {suggestions.map(suggestion => (
        <ListItemButton
          style={{
            marginBottom: '15px',
            display: address ? 'none' : 'flex',
          }}
          key={suggestion.id}
          onClick={() => {
            setInputValue(suggestion?.place_name)
            setLat(suggestion?.latitude)
            setLong(suggestion?.longitude)
            setAddress(suggestion?.place_name)
            setCity(suggestion?.city)
            setState(suggestion?.state)
            setPostalCode(suggestion?.postal_code)
            setCountry(suggestion?.country)
          }}
        >
          <ListItemText key={suggestion.id} primary={suggestion.place_name} />
        </ListItemButton>
      ))}

      <Stack
        sx={{ flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}
        justifyContent="space-between"
      >
        <FormControl
          sx={{
            width: { xs: '100%', sm: '100%', md: '48%' },
            marginBottom: 2,
            padding: '10px',
            borderRadius: '6px',
          }}
        >
          <InputLabel id="role-label">Role</InputLabel>
          <Select
            labelId="role-label"
            id="role"
            name="role"
            value={formData.role}
            onChange={handleRoleChange}
            startAdornment={
              <InputAdornment position="start">
                <TuneOutlined size={10} />
              </InputAdornment>
            }
          >
            <MenuItem value="RN">RN</MenuItem>
            <MenuItem value="CNA">CNA</MenuItem>
            <MenuItem value="LVN | LPN">LVN | LPN</MenuItem>
            <MenuItem value="HomeCare Aide">HomeCare Aide</MenuItem>
          </Select>
        </FormControl>

        <FormControl
          sx={{
            width: { xs: '100%', sm: '100%', md: '48%' },
            marginBottom: 2,
            padding: '10px',
            borderRadius: '6px',
          }}
        >
          <InputLabel id="tasks-label">Tasks</InputLabel>
          <Select
            labelId="tasks-label"
            id="tasks"
            name="tasks"
            multiple
            value={formData.tasks}
            onChange={handleTasksChange}
            startAdornment={
              <InputAdornment position="start">
                <Description size={10} />
              </InputAdornment>
            }
            renderValue={selected => selected.join('/')}
          >
            <MenuItem value="Bathing, Dressing, Toileting">
              Bathing, Dressing, Toileting
            </MenuItem>
            <MenuItem value="Meal Planning and Feeding">
              Meal Planning and Feeding
            </MenuItem>
            <MenuItem value="HouseKeeping">HouseKeeping</MenuItem>
            <MenuItem value="Companion ship and med reminders">
              Companion ship and med reminders
            </MenuItem>
            <MenuItem value="Shopping and errands">
              Shopping and errands
            </MenuItem>
          </Select>
        </FormControl>
      </Stack>

      <Stack
        sx={{ flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}
        justifyContent="space-between"
      >
        <FormControl
          sx={{
            width: { xs: '100%', sm: '100%', md: '48%' },
            marginBottom: 2,
            padding: '10px',
            borderRadius: '6px',
          }}
        >
          <InputLabel id="shiftHours-label">Shift Hours</InputLabel>
          <Select
            labelId="shiftHours-label"
            id="shiftHours"
            name="shiftHours"
            value={formData.shiftHours}
            onChange={handleShiftHoursChange}
            startAdornment={
              <InputAdornment position="start">
                <AccessTimeOutlined size={10} />
              </InputAdornment>
            }
          >
            <MenuItem value="1">1 Hr</MenuItem>
            <MenuItem value="2">2 Hr's</MenuItem>
            <MenuItem value="4">4 Hr's</MenuItem>
            <MenuItem value="6">6 Hr's</MenuItem>
          </Select>
        </FormControl>

        <FormControl
          sx={{
            width: { xs: '100%', sm: '100%', md: '48%' },
            marginBottom: 2,
            padding: '10px',
            borderRadius: '6px',
          }}
        >
          <InputLabel id="language-label">Language</InputLabel>
          <Select
            labelId="language-label"
            id="language"
            name="language"
            value={formData.language}
            onChange={handleLanguageChange}
            startAdornment={
              <InputAdornment position="start">
                <Flag size={10} />
              </InputAdornment>
            }
          >
            <MenuItem value="English">English</MenuItem>
            <MenuItem value="Spanish">Spanish</MenuItem>
          </Select>
        </FormControl>
      </Stack>

      <Stack
        alignItems="center"
        sx={{ flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}
        justifyContent="space-between"
      >
        <FormControl
          sx={{
            width: { xs: '100%', sm: '100%', md: '48%' },
            marginBottom: 2,
            padding: '10px',
            borderRadius: '6px',
          }}
        >
          <InputLabel id="gender-label">Gender</InputLabel>
          <Select
            labelId="gender-label"
            id="gender"
            name="gender"
            value={formData.gender}
            onChange={handleGenderChange}
            startAdornment={
              <InputAdornment position="start">
                <Wc size={10} />
              </InputAdornment>
            }
          >
            <MenuItem value="Male">Male</MenuItem>
            <MenuItem value="Female">Female</MenuItem>
          </Select>
        </FormControl>

        <FormControl
          sx={{
            width: { xs: '100%', sm: '100%', md: '48%' },
            marginBottom: 2,
            padding: '10px',
            borderRadius: '6px',
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDateTimePicker
              value={selectedDate}
              onChange={handleDateChange}
              format="YYYY-MM-DD HH:mm:ss"
              label="Schedule Shift (Optional)"
            />
          </LocalizationProvider>
        </FormControl>
      </Stack>
      <TextField
        sx={{
          width: { xs: '100%', sm: '100%', md: '100%' },
          backgroundColor: 'custom.registerInput',
          marginBottom: 2,
          padding: '10px',
          borderRadius: '6px',
        }}
        variant="standard"
        placeholder="Write Details"
        InputProps={{ disableUnderline: true }}
        name="details"
        value={formData.details}
        onChange={handleDetailsChange}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ marginTop: 2 }}
      >
        <Button
          variant="contained"
          sx={{
            borderRadius: '61px',
            boxShadow: 'none',
            width: { xs: '100%', sm: 'auto', md: 'auto' },
          }}
          onClick={handleFormSubmit}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={24} /> : 'Request Now'}
        </Button>
      </Stack>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={emptyMessage}
      />
    </Card>
  )
}

const sxStyles = {
  card: {
    zIndex: 1,
    position: 'absolute',
    top: 150,
    padding: '40px 25px',
    width: '660px',
  },
  heading: {
    fontWeight: '700',
    fontSize: '48px',
    lineHeight: '58px',
    color: 'custom.black',
  },
  summary: {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#757575',
    margonTop: 2,
    marginBottom: 3,
  },
  fullInput: {
    width: '100%',
    backgroundColor: 'custom.registerInput',
    marginBottom: 2,
    padding: '10px',
    borderRadius: '6px',
  },
  halfInput: {
    width: '48%',
    backgroundColor: 'custom.registerInput',
    marginBottom: 2,
    padding: '10px',
    borderRadius: '6px',
  },
  halfSelect: {
    width: '48%',
    marginBottom: 2,
    padding: '10px',
    borderRadius: '6px',
  },
  scheduleBtn: {
    borderRadius: '56px',
    backgroundColor: 'custom.registerInput',
    color: 'custom.black',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '19px',
  },
}
