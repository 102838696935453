import Account from 'homecare/views/Account/Account'
import {
  HealthCareDashboard,
  HomeCareLogin,
  HomeCareRegister,
  RegisterOtp,
} from '../views'
import Messages from 'homecare/views/Messages/Messages'
import ScheduledShifts from 'homecare/views/ScheduledShifts/scheduledShifts'
import History from 'homecare/views/History/History'
import Cards from 'homecare/views/Cards/Cards'
import AddCard from 'homecare/views/AddCard/AddCard'

export const routes = [
  {
    name: 'Dashboard',
    path: '/individual/dashboard',
    component: <HealthCareDashboard />,
  },

  {
    name: 'Messages',
    path: '/individual/messages',
    component: <Messages />,
  },
  {
    name: 'Account',
    path: '/individual/account',
    component: <Account />,
  },
  {
    name: 'Scheduled Shifts',
    path: '/individual/scheduled_shifts',
    component: <ScheduledShifts />,
  },

  {
    name: 'History',
    path: '/individual/history',
    component: <History />,
  },

  {
    name: 'Cards',
    path: '/individual/cards',
    component: <Cards />,
  },

  {
    name: 'Add Cards',
    path: '/individual/add-cards',
    component: <AddCard />,
  },

  {
    name: 'Register',
    path: '/individual/register-healthcare',
    component: <HomeCareRegister />,
  },
  {
    name: 'Login',
    path: '/individual/healthcare-login',
    component: <HomeCareLogin />,
  },
  {
    name: 'Login',
    path: '/individual/healthcare-otp',
    component: <RegisterOtp />,
  },
]
