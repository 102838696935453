import React from "react";
import styles from "./AdminNotesInput.module.css";
import { Button } from "@mui/material";

function AdminNotesInput() {
  return (
    <div className={styles.container}>
      <textarea placeholder="Enter admin notes here......." />
      <Button variant="outlined" color="secondary">
        Add notes
      </Button>
    </div>
  );
}

export default AdminNotesInput;
