import {
  Box,
  Card,
  FlexProps,
  Icon,
  IconButton,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import moment from 'moment'
import { useEffect, useState } from 'react'
import DatePickerReact from 'react-datepicker'
import { BsCalendar3 } from 'react-icons/bs'

type DatePickerProps = {
  onChangeDate: (value: string) => void
  value: string
  disabled?: boolean
  minValue?: Date
  isInput?: boolean
} & FlexProps

const DatePicker = (props: DatePickerProps) => {
  const {
    onChangeDate,
    value,
    disabled = false,
    minValue,
    isInput = false,
    ...style
  } = props

  const [startDate, setStartDate] = useState<Date>()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const handleSetDate = (dateTime: Date) => {
    const mo2 = moment(dateTime).local()
    setStartDate(mo2.toDate())
    onChangeDate(mo2.toDate().toISOString().split('T')[0])
  }
  const handleOnChange = (date: Date) => {
    if (date) {
      handleSetDate(date)
      onClose()
    }
  }
  useEffect(() => {
    handleSetDate(value ? new Date(value) : new Date())
  }, [])
  const StyledDatepicker = styled(Card)(props => ({
    width: '100%',
    padding: '1rem',
    backgroundColor: '#fff',
    '.react-datepicker': {
      width: '100%',
      minWidth: '200px',
      fontSize: '1rem',
      border: 0,
    },
    '.react-datepicker__month-container': {
      width: '100%',
      border: 0,
    },
    '.react-datepicker__current-month': { marginBottom: '1rem' },
    '.react-datepicker__header': {
      backgroundColor: '#fff',
      border: 0,
      borderBottom: '1px solid lightgray',
      paddingBottom: '1rem',
    },

    '.react-datepicker__navigation': {
      top: '0.5rem',
    },
    '.react-datepicker__day--outside-month': { color: 'lightgray' },
    '.react-datepicker__day-names': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      border: 0,
    },
    '.react-datepicker__week': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    '.react-datepicker__day': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '1rem 0',
    },
    '.react-datepicker__day--selected,.react-datepicker__day--keyboard-selected':
      {
        color: '#fff',
        backgroundColor: '#E3022A',
        '&:hover': { backgroundColor: '#E3022A' },
      },
    '.react-datepicker__month': {
      border: 0,
    },
  }))

  return (
    <>
      {!isInput ? (
        <StyledDatepicker boxShadow="md" {...style}>
          <DatePickerReact
            selected={startDate}
            onChange={handleOnChange}
            shouldCloseOnSelect={true}
            onClickOutside={onClose}
            inline
            onCalendarClose={onClose}
            dateFormat="dd/mm/yyyy"
            useWeekdaysShort={true}
          />
        </StyledDatepicker>
      ) : (
        <InputGroup
          size="sm"
          px={4}
          my={2}
          borderRadius="md"
          sx={{ border: '1px solid #E2E8F0', padding: '0.2rem' }}
        >
          <Input
            focusBorderColor="none"
            outline={0}
            border={0}
            value={value}
            onClick={disabled ? undefined : (e: any) => onOpen()}
            disabled={disabled ? disabled : false}
          />
          <IconButton
            title="Open"
            aria-label="Open"
            bg="none"
            border="none"
            onClick={onOpen}
            icon={<Icon as={BsCalendar3} />}
          />
        </InputGroup>
      )}

      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent mx={{ base: 5, md: 0 }}>
          <ModalHeader>Choose Date</ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={5}>
            <StyledDatepicker {...style}>
              <DatePickerReact
                selected={startDate}
                onChange={handleOnChange}
                shouldCloseOnSelect={true}
                onClickOutside={onClose}
                inline
                onCalendarClose={onClose}
                dateFormat="dd/mm/yyyy"
                minDate={minValue ? minValue : new Date()}
              />
            </StyledDatepicker>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default DatePicker
