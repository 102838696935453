// @ts-nocheck
export const cities = [
  "Chico",
  "Dorris",
  "Sacramento",
  "Banning",
  "Tracy",
  "Long Beach",
  "Daly City",
  "Valley Springs",
  "Oakland",
  "Carson",
  "Irvine",
  "Chula Vista",
  "Granada Hills",
  "Los Angeles",
  "Oak Park",
  "Riverside",
  "Oxnard",
  "San Jose",
  "Oceanside",
  "Crescent City",
  "Seaside",
  "Merced",
  "Santa Rosa",
  "Santa Clara",
  "Glendale",
  "Kenwood",
  "Anaheim",
  "Garden Grove",
  "Costa Mesa",
  "American Canyon",
  "Downey",
  "Lancaster",
  "Garverville",
  "Huntington Beach",
  "Alameda",
  "San Mateo",
  "Castro Valley",
  "Murrieta",
  "La Mesa",
  "Roseville",
  "Pinole",
  "Riverbank",
  "San Jacinto",
  "La Jolla",
  "Antelope",
  "Redondo Beach",
  "National City",
  "Spring Valley",
  "Sun Valley",
  "Corona",
  "Orange",
  "Visalia",
  "San Francisco",
  "Carlsbad",
  "Buena Park",
  "Red Bluff",
  "San Diego",
  "Hesperia",
  "Redding",
  "Palmdale",
  "Encinitas",
  "West Sacramento",
  "Encino",
  "Upland",
  "Redlands",
  "Richmond",
  "Chatsworth",
  "San Ramon",
  "Bakersfield",
  "Knightsen",
  "San Fernando",
  "Brentwood",
  "Los Alamitos",
  "Mountain View",
  "Dove Canyon",
  "Mission Viejo",
  "Monterey",
  "Valley Village",
  "Cathedral City",
  "Lomita",
  "Petaluma",
  "Lemoore",
  "Imperial Beach",
  "Victorville",
  "San Pedro",
  "San Bernadino",
  "Vandenberg Afb",
  "Reseda",
  "Santa Maria",
  "Fairfield",
  "Pomona",
  "Emeryville",
  "Elk Grove",
  "Torrance",
  "Northridge",
  "Pasadena",
  "Lompoc",
  "Sunnyvale",
  "Westminster",
  "Moreno Valley",
  "Santa Cruz",
  "Winnetka",
  "Seal Beach",
  "Oroville",
  "Palo Alto",
  "Yuba City",
  "Gilroy",
  "Glendora",
  "Lemon Grove",
  "Beale Afb",
  "Cypress",
  "Tustin",
  "Inglewood",
  "Hemet",
  "Brea",
  "Fresno",
  "Gardena",
  "Travis Afb",
  "Campbell",
  "Palm Desert",
  "Represa",
  "Highland",
  "Dana Point",
  "El Centro",
  "Arcadia",
  "San Clemente",
  "Vallejo",
  "West Covina",
  "Loma Linda",
  "Healdsburg",
  "Santee",
  "Modesto",
  "Martinez",
  "Rancho Bernardo",
  "Simi Valley",
  "Valencia",
  "Thousand Oaks",
  "Apple Valley",
  "Cameron Park",
  "Bonita",
  "Emoryville",
  "San Marcos",
  "Escondido",
  "Rancho Santa Margari",
  "Red Wood Valley",
  "El Cajon",
  "Laguna Niguel",
  "Salinas",
  "San Juan Capistrano",
  "Mission Hills",
  "Walnut Creek",
  "South San Francisco",
  "Goleta",
  "Mill Valley",
  "Descanso",
  "Fortuna",
  "Ventura",
  "Castroville",
  "Scotts Valley",
  "Loyalton",
  "Playa Vista",
  "Camarillo",
  "Manteca",
  "Tehachapi",
  "Hillsboro",
  "Stanford",
  "Wildomar",
  "Pleasanton",
  "St Helena",
  "South Gate",
  "Beverly Hills",
  "Berkeley",
  "Foster City",
  "Hollywood",
  "Del Mar",
  "Colusa",
  "Auburn",
  "So San Francisco",
  "Dublin",
  "Creston",
  "Placerville",
  "Pittsburg",
  "Marina Del Rey",
  "Lawndale",
  "Sherman Oaks",
  "Tulare",
  "Fremont",
  "Vista",
  "Clearlake",
  "San Bernardino",
  "Danville",
  "Vacaville",
  "San Rafael",
  "Santa Monica",
  "Pebble Beach",
  "North Hollywood",
  "Lathrop",
  "Santa Paula",
  "Camp Pendleton",
  "Paradise",
  "Sebastopol",
  "Coronado",
  "Truckee",
  "Windsor",
  "Poway",
  "Woodland Hills",
  "Salida",
  "Montebello",
  "Indio",
  "San Luis Obispo",
  "Hacienda Heights",
  "Studio City",
  "Novato",
  "Culver City",
  "Desert Hot Springs",
  "Burlingame",
  "East Long Beach",
  "Concord",
  "Palm Springs",
  "Lake Elsinore",
  "Santa Ana",
  "Playa Del Rey",
  "Harbor City",
  "Pacifica",
  "Rohnert Park",
  "Lincoln",
  "Redwood City",
  "Napa",
  "Alhambra",
  "Antioch",
  "South Lake Tahoe",
  "Santa Barbara",
  "Burbank",
  "Greenbrae",
  "Hanford",
  "Hawthorne",
  "Lodi",
  "Monrovia",
  "Ridgecrest",
  "El Sobrante",
  "Marysville",
  "Rancho Cucamonga",
  "Sun City",
  "Alta Loma",
  "Union City",
  "Stockton",
  "Rancho Cordovaq",
  "Diamond Bar",
  "San Leandro",
  "Watsonville",
  "Rancho Cordova",
  "Folsom",
  "Orangevale",
  "Chino Hills",
  "Morro Bay",
  "Grass Valley",
  "Madera",
  "Temecula",
  "Ukiah",
  "Carmel Valley",
  "Rancho Palos Verdes",
  "San Bruno",
  "Blythe",
  "Lafayette",
  "Westlake Village",
  "Fountain Valley",
  "Prunedale",
  "Menifee",
  "Shingle Springs",
  "Laguna Beach",
  "Fort Irwin",
  "Mather",
  "Woodland",
  "Los Gatos",
  "Tarzana",
  "Mammoth Lakes",
  "Chino",
  "Eureka",
  "Duarte",
  "Joshua Tree",
  "Suisun City",
  "Templeton",
  "West Hills",
  "Citrus Heights",
  "San Carlos",
  "Covina",
  "Newport Beach",
  "North Hills",
  "Sutter",
  "Aliso Viejo",
  "El Dorado Hills",
  "Livermore",
  "Stevenson Ranch",
  "Kentfield",
  "French Camp",
  "Rsm",
  "Fallbrook",
  "Guerneville",
  "Pleasant Hill",
  "Lakeside",
  "Hermosa Beach",
  "Winchester",
  "Fall River Mills",
  "Reedley",
  "Imperial",
  "Fort Bragg",
  "La Habra Hts",
  "North Highlands",
  "Santa Rosa Valley",
  "Laguna Woods",
  "Santa Clarita",
  "Lemoore Naval Air St",
  "Crockett",
  "Lakewood",
  "Laguna Hills",
  "Anderson",
  "Jackson",
  "Alamo",
  "Foothill Ranch",
  "Panorama City",
  "Milpitas",
  "Mountainview",
  "Fair Oaks",
  "Sylmar",
  "San Pablo",
  "Fullerton",
  "La Mirada",
  "Saint Petersburg",
  "North Richmond",
  "Arcata",
  "Half Moon Bay",
  "Panama City",
  "Norwalk",
  "Hollister",
  "South San Franciso",
  "Mountain Ranch",
  "Los Feliz",
  "Fontana",
  "Morgan Hill",
  "Oakdale",
  "Aptos",
  "Delano",
  "El Monte",
  "Ramona",
  "Canyon Country",
  "Millbrae",
  "Clovis",
  "Rocklin",
  "Carmichael",
  "Sanjose",
  "Lake Forest",
  "Eastvale",
  "Arroyo Grande",
  "West Hollywood",
  "Alturas",
  "Sonora",
  "Ontario",
  "Cardiff",
  "Riversdie",
  "Yreka",
  "Grand Terrace",
  "Sandiego",
  "Selma",
  "Sunland",
  "Whittier",
  "Pacific Beach",
  "Bellflower",
  "Cerritos",
  "Shadow Hills",
  "Clermont",
  "Los Altos",
  "San Marino",
  "Ventra",
  "Elmira",
  "Canoga Park",
  "Loomis",
  "Cardiff By The Sea",
  "Beaumont",
  "Malibu",
  "Willits",
  "Rancho Mirage",
  "Pacoima",
  "Rescue",
  "Galt",
  "Van Nuys",
  "San Andreas",
  "Atascadero",
  "Hayward",
  "Nas Lemoore",
  "Wilmington",
  "Perris",
  "Lindsay",
  "Barstow",
  "Trabuco Canyon",
  "Gasquet",
  "Olympic Valley",
  "Exeter",
  "El Cerrito",
  "Miami",
  "Capistrano Beach",
  "Pismo Beach",
  "South Pasadena",
  "Cucamonga",
  "Manhattan Beach",
  "Oakley",
  "La Palma",
  "Venice",
  "Lynwood",
  "Mount Shasta",
  "Portola Valley",
  "Las Vegas",
  "Newbury Park",
  "La Habra",
  "Lake Isabella",
  "Moorpark",
  "March Air Reserve Ba",
  "Gridley",
  "Los Angels",
  "Daly",
  "Simivalley",
  "Mira Loma",
  "Twentynine Palms",
  "Porterville",
  "Sand Diego",
  "Diamond Springs",
  "El Segundo",
  "Mendota",
  "Squaw Valley",
  "Davis",
  "San Lorenzo",
  "Avenal",
  "Avila Beach",
  "Penryn",
  "Yucca Valley",
  "Linden",
  "Boulder Creek",
  "Azusa",
  "Panorama",
  "Sand City",
  "Richvale",
  "Bakersfiel",
  "Beach Center",
  "Fort White",
  "Baldwin Park",
  "Mckinleyville",
  "La Quinta",
  "Turlock",
  "Sanger",
  "Glenale",
  "Lakeport",
  "Menlo Park",
  "Porter Ranch",
  "Moraga",
  "Los Angelos",
  "Sausalito",
  "Los Angelas",
  "Tujunga",
  "Capitola",
  "San Anselmo",
  "Anaiem",
  "Saugus",
  "Los Angles",
  "Weaverville",
  "Newark",
  "Rio Vista",
  "Atwater",
  "Belmont",
  "Coarsegold",
  "Mountain House",
  "Colfax",
  "Monterey Park",
  "Carmel",
  "Walnut Crrek",
  "Panorma City",
  "Colton",
  "Susanville",
  "Plumas Lake",
  "Compton",
  "Presidio Of Monterey",
  "Santamaria",
  "Murrietta",
  "Kingsburg",
  "Berkely",
  "Hume",
  "Cupertino",
  "Colma",
  "Altadena",
  "Weimar",
  "King City",
  "Sutter Creek",
  "Patterson",
  "La Grange",
  "Ceres",
  "Calabasas",
  "Palos Verdes Estates",
  "Montrose",
  "Green Valley",
  "Ladera Ranch",
  "Bay Point",
  "Westmin Ster",
  "Marina",
  "Newport Coast",
  "Placentia",
  "Montclair",
  "Palm Springs Municip",
  "La Crescenta",
  "Mar Vista",
  "Paramount",
  "San Gabriel",
  "Walnut",
  "Artesia",
  "W Sacramento",
  "Benicia",
  "Oak Hills",
  "Huntington Park",
  "Oak View",
  "Alpine",
  "Bethel Island",
  "North San Juan",
  "Hercules",
  "Sonoma",
  "Edwards",
  "Lake Hughes",
  "Corte Madera",
  "Newcastle",
  "Santaclara",
  "Fallbrok",
  "Claremont",
  "San Franicisco",
  "Windsor Hills",
  "Dinuba",
  "Rancho Santa Fe",
  "Moreno Vally",
  "Tahoe Vista",
  "Orinda",
  "Piedmont",
  "Meadow Vista",
  "Cottonwood",
  "Georgetown",
  "Oak Run",
  "El Dorado",
  "Orleans",
  "Mount Aukum",
  "Penn Valley",
  "Springville",
  "Lone Pine",
  "Rosamond",
  "Signal Hill",
  "Clayton",
  "Crowley Lake",
  "Fayetteville",
  "Winters",
  "Calexico",
  "Bermuda Dunes",
  "La Puente",
  "Temple City",
  "Yorba Linda",
  "Rosemead",
  "San Dimas",
  "E Palo Alto",
  "Barkerfield",
  "Newhall",
  "Fellows",
  "Gibsonton",
  "Laytonville",
  "Mendocino",
  "Tafb",
  "Upper Lake",
  "Occidental",
  "Jurupa Valley",
  "Redwood",
  "Edwards Air Force Ba",
  "Nipomo",
  "Valley Center",
  "Bishop",
  "Los Osos",
  "Lebec",
  "Brawley",
  "La Verne",
  "North Palm Springs",
  "Olancha",
  "Weed",
  "Cathedral",
  "Corona Hills",
  "Yucaipa",
  "Newbury",
  "San Miguel",
  "Port Hueneme",
  "Discovery Bay",
  "Pacific Palisades",
  "Cotati",
  "Topanga",
  "Palm Dessert",
  "N Hollywood",
  "Foresthill",
  "Castaic",
  "Big Bear Lake",
  "La Canada",
  "Gonzales",
  "Royal Oaks",
  "Angels Camp",
  "East Garrison",
  "Death Valley Jct",
  "Chulavista",
  "Agoura",
  "Moreno Valleymoreno",
  "Calimesa",
  "Rialto",
  "Wilton",
  "Herlong",
  "Granite Bay",
  "Orland",
  "Pilot Hill",
  "Kelseyville",
  "Calistoga",
  "Paso Robles",
  "El Toro",
  "Ripon",
  "Irivne",
  "Idyllwild",
  "Rowland Heights",
  "Bakersfiled",
  "El Macero",
  "Belvedere Tiburon",
  "Corona Del Mar",
];
