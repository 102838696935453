import React from "react";
import styles from "./ShiftDetailsCard.module.css";
import { CardContent, Typography } from "@mui/material";
import { RemoveRedEyeOutlined } from "@mui/icons-material";

function ShiftDetailsCard({ name, nursId, location }) {
  return (
    <div className={styles.card}>
      <div className={styles.cardHeader}>
        <div>{name}</div>
        <div className={styles.horizontalSeparator} />
        <RemoveRedEyeOutlined className={styles.eye} />
      </div>
      <CardContent>
        <Typography className={styles.details}>Registered Nurse</Typography>
        <Typography className={styles.details}>{nursId || "nursId"}</Typography>
        <Typography className={styles.details}>
          {location || "location"}
        </Typography>
      </CardContent>
    </div>
  );
}

export default ShiftDetailsCard;
