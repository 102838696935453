import { Box, Typography } from '@mui/material'

export default function Notifications({ title, body, date }) {
  return (
    <Box
      sx={{
        cursor: 'default',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        transition: 'all 0.3s ease-in-out',
        transform: 'scale(1)',
        '&:hover': {
          transform: 'scale(1.05)',
          backgroundColor: '#f2f2f2',
        },
      }}
    >
      <Box mb="12px">
        <Typography
          fontSize="14px"
          fontWeight="800"
          color="#1A1F36"
          display="inline"
          mr="3.5px"
          lineHeight="1.5"
          letterSpacing="0.2px"
        >
          {title}
        </Typography>
        <Typography
          fontSize="14px"
          fontWeight="500"
          color="#1A1F36"
          display="inline"
          letterSpacing="0.2px"
        >
          {body}
        </Typography>
      </Box>
      <Typography fontSize="14px" color="#A5ACB8">
        {date}
      </Typography>
    </Box>
  )
}
