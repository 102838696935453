// @ts-nocheck
import styles from './FacilityView.module.css'
import { Tune } from '@mui/icons-material'
import Calendar from 'react-calendar'
import SearchInput from '../SearchInput/SearchInput'
import CancelledShiftCard from '../CancelledShiftCard/CancelledShiftCard'
import { Box } from '@mui/system'

function FacilityView({ reports, value, onChange, onViewDetails, status }) {
  return (
    <div className={styles.container}>
      <div className={styles.calendarWrapper}>
        <Calendar
          onChange={onChange}
          value={value || new Date()}
          className={styles.calendar}
        />
      </div>

      <div className={styles.filterRow}>
        <SearchInput
          width="40%"
          placeholder="Search staff, shift id, etc...."
        />
        <p className={styles.shiftReports}>{`${
          reports?.length || 0
        } Shifts Reported`}</p>
        <div className={styles.filter}>
          <Tune className={styles.filterIcon} />
        </div>
      </div>
      {reports?.length === 0 && (
        <Box
          sx={{
            padding: '20px',
            paddingLeft: '30px',
          }}
        >
          <p style={{ color: '#8F95B2' }}>No Data Available</p>
        </Box>
      )}
      {reports?.map((item, index) => (
        <CancelledShiftCard
          key={index}
          item={item}
          onViewDetails={() => onViewDetails(item)}
          status={status}
        />
      ))}
    </div>
  )
}

export default FacilityView
