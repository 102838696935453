// views
import ScheduledDemo from 'operational-sales-panel/views/ScheduledDemo/ScheduledDemo';
import {
  Dashboard,
  ClientLiveDashboard,
  FacilityClientDashboard,
  ComapnyClientDashboard,
  CancelledShifts,
  ReportedShifts,
  Messages,
  Accounts,
} from '../views';
import NoPermissions from 'main/views/noPermissions';
import Financials from 'operational-sales-panel/views/Financials/Financials';

export const routes = [
  {
    name: 'Dashboard',
    path: '/operation_panel/dashboard',
    component: <Dashboard />,
    menu: true,
  },
  {
    name: 'Live Shifts',
    path: '/operation_panel/clients',
    component: <ClientLiveDashboard />,
    menu: true,
    more: [
      {
        name: 'Homecare Client',
        path: '/operation_panel/clients',
        component: <ClientLiveDashboard />,
      },
      {
        name: 'Facility Client',
        path: '/operation_panel/clients-facility',
        component: <FacilityClientDashboard />,
      },
      {
        name: 'Company Client',
        path: '/operation_panel/clients-company',
        component: <ComapnyClientDashboard />,
      },
    ],
  },
  {
    name: 'Financials',
    path: '/operation_panel/financials',
    component: <Financials />,
    menu: true,
  },
  {
    name: 'Refunds',
    path: '/operation_panel/cancelled-shifts',
    component: <CancelledShifts />,
    menu: true,
    more: [
      {
        name: 'Canceled Shift',
        path: '/operation_panel/cancelled-shifts',
        component: <CancelledShifts />,
      },
      {
        name: 'Reported Shifts',
        path: '/operation_panel/reported-shifts',
        component: <ReportedShifts />,
      },
    ],
  },

  {
    name: 'Messages',
    path: '/operation_panel/messages',
    component: <Messages />,
    menu: true,
  },
  {
    name: 'Accounts',
    path: '/operation_panel/accounts',
    component: <Accounts />,
    menu: true,
  },

  {
    name: 'Scheduled Demo',
    path: '/operation_panel/scheduled-demo',
    component: <ScheduledDemo />,
    menu: true,
  },
];
