import { React, useState } from 'react'
import {
  Button,
  Card,
  Stack,
  TextField,
  Typography,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Snackbar,
} from '@mui/material'

import { api } from 'main/configs/axios.config'

export default function Account() {
  const [formData, setFormData] = useState({
    email: '',
    full_name: '',
  })

  const [emptyMessage, setEmptyMessage] = useState('')
  const [open, setOpen] = useState(false)

  //*************** GET LOCATION SUGGESTIONS ****************/

  const [isLoading, setIsLoading] = useState(false)
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  const handleClick = () => {
    setOpen(true)
  }

  const handleFormSubmit = async e => {
    setIsLoading(true)
    try {
      const response = await api.put('individuals/account', undefined, {
        params: {
          email: formData.email,
          full_name: formData.full_name,
        },
      })

      // handle success
      setFormData({
        email: '',
        full_name: '',
      })
      window.location.reload()
      setEmptyMessage('Done ✔')
      handleClick()
    } catch (error) {
      console.log(error)
    }

    setIsLoading(false)
  }

  const handleEmailChange = e => {
    setFormData({ ...formData, email: e.target.value })
  }

  const handleFullNameChange = e => {
    setFormData({ ...formData, full_name: e.target.value })
  }

  return (
    <Card
      sx={{
        zIndex: 100,
        position: 'absolute',
        top: 150,
        padding: '40px 25px',
        width: { xs: '95%', sm: '95%', md: '660px' },
        left: { xs: 10, sm: 10, md: 50 },
        boxShadow: 'none',
      }}
    >
      <Typography
        mb="18px"
        sx={{
          marginBottom: { xs: '8px', sm: '8px', md: '18px' },
          fontWeight: '700',
          fontSize: { xs: '1.5rem', sm: '1.5rem', md: '3rem' },
          lineHeight: '50px',
          color: 'custom.black',
        }}
      >
        Account Setting
      </Typography>
      <Typography sx={sxStyles.summary}>Edit profile</Typography>

      <Stack
        sx={{ flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}
        alignItems="center"
        justifyContent="space-between"
      >
        <TextField
          sx={{
            width: { xs: '100%', sm: '100%', md: '48%' },
            backgroundColor: 'custom.registerInput',
            marginBottom: 2,
            padding: '10px',
            borderRadius: '6px',
          }}
          variant="standard"
          placeholder="email"
          InputProps={{ disableUnderline: true }}
          name="email"
          value={formData.email}
          onChange={handleEmailChange}
        />

        <TextField
          sx={{
            width: { xs: '100%', sm: '100%', md: '48%' },
            backgroundColor: 'custom.registerInput',
            marginBottom: 2,
            padding: '10px',
            borderRadius: '6px',
          }}
          variant="standard"
          placeholder="full name"
          InputProps={{ disableUnderline: true }}
          name="full_name"
          value={formData.full_name}
          onChange={handleFullNameChange}
        />
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ marginTop: 2 }}
      >
        <Button
          variant="contained"
          sx={{
            borderRadius: '61px',
            boxShadow: 'none',
            width: { xs: '100%', sm: 'auto', md: 'auto' },
          }}
          onClick={handleFormSubmit}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={24} /> : 'Update'}
        </Button>
      </Stack>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={emptyMessage}
      />
    </Card>
  )
}

const sxStyles = {
  card: {
    zIndex: 1,
    position: 'absolute',
    top: 150,
    padding: '40px 25px',
    width: '660px',
  },
  heading: {
    fontWeight: '700',
    fontSize: '48px',
    lineHeight: '58px',
    color: 'custom.black',
  },
  summary: {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#757575',
    margonTop: 2,
    marginBottom: 3,
  },
  fullInput: {
    width: '100%',
    backgroundColor: 'custom.registerInput',
    marginBottom: 2,
    padding: '10px',
    borderRadius: '6px',
  },
  halfInput: {
    width: '48%',
    backgroundColor: 'custom.registerInput',
    marginBottom: 2,
    padding: '10px',
    borderRadius: '6px',
  },
  halfSelect: {
    width: '48%',
    marginBottom: 2,
    padding: '10px',
    borderRadius: '6px',
  },
  scheduleBtn: {
    borderRadius: '56px',
    backgroundColor: 'custom.registerInput',
    color: 'custom.black',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '19px',
  },
}
