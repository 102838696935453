// @ts-nocheck
import React, { useState, useEffect } from 'react'
import styles from './ClientLiveDashboard.module.css'
import { Grid } from '@mui/material'
import {
  ActiveTable,
  ProfileCard,
  SearchInput,
  TasksContainer,
  WelcomingTopBar,
  AutoComplete,
} from '../../components'
import { Tune } from '@mui/icons-material'
import Map, { Marker } from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { ClientLiveDashboardService } from 'operational-sales-panel/services'
import { CitiesOptions } from 'constants'
// import Dummy from "./Dummy.json";
import { NursePin } from '../../assets/img'
import { getUserTimeZone } from 'helper/userTimeZone'

function ClientLiveDashboard() {
  const [filter, setFilter] = useState({
    city: '',
    role: '',
  })
  const [activeShifts, setActiveShifts] = useState()
  const [mapDetails, setMapDetails] = useState()

  useEffect(() => {
    ClientLiveDashboardService.getActiveShifts({
      page: 1,
      page_size: 10,
      city: filter?.city,
      role: filter?.role,
      time_zone: getUserTimeZone(),
    })
      .then(data => setActiveShifts(data))
      .catch(err => console.log(err))
  }, [filter?.city, filter?.role])

  return (
    <div className={styles.container}>
      <WelcomingTopBar name="Jane Eyre" time="01:30 m" />
      <Grid container className={styles.gridContainer} columnGap={2}>
        <Grid item md={8.5} xs={12} className={styles.topGrid}>
          <div className={styles.filterRow}>
            <div className={styles.row}>
              <AutoComplete
                backgroundColor="white"
                placeholder="Choose city"
                options={CitiesOptions.cities}
                value={filter.city}
                onChange={(e, value) => setFilter({ ...filter, city: value })}
              />
              <AutoComplete
                backgroundColor="white"
                placeholder="Choose staff role"
                options={['RN', 'LVN | LPN', 'Homcare Aide']}
                value={filter.role}
                onChange={(e, value) => setFilter({ ...filter, role: value })}
              />
            </div>
            <div className={styles.filter}>
              <Tune className={styles.filterIcon} />
            </div>
          </div>
        </Grid>
        <Grid item md={3} xs={12} className={styles.topGrid}>
          <SearchInput placeholder="Search by shift, name, etc" />
        </Grid>

        <Grid item md={8.5} xs={12}>
          <div className={styles.map}>
            {mapDetails && <ProfileCard data={mapDetails} />}
            <Map
              mapboxAccessToken={
                'pk.eyJ1IjoibnVyc2ViaWxsYm9hcmQiLCJhIjoiY2xlb3Y2YmdnMDZhZTNwbzczaDN3c2ljayJ9.cJTw74qANprssuGBV-crwQ'
              }
              initialViewState={{
                longitude: mapDetails?.lng || -118.445605,
                latitude: mapDetails?.lat || 35.018119,
                zoom: 3.5,
              }}
              mapStyle="mapbox://styles/mapbox/light-v9"
              style={{ width: '100%', height: '100%' }}
            >
              {mapDetails && (
                <>
                  <Marker
                    longitude={mapDetails.lng}
                    latitude={mapDetails.lat}
                    anchor="bottom"
                  >
                    <img src={NursePin} alt="nurse pin" />
                  </Marker>
                  <Marker
                    longitude={mapDetails.staff_lng}
                    latitude={mapDetails.staff_lat}
                    anchor="bottom"
                  >
                    <img src={NursePin} alt="nurse pin" />
                  </Marker>
                </>
              )}
            </Map>
          </div>
        </Grid>
        <Grid item md={3} xs={12}>
          <ActiveTable
            heading="Active Shifts"
            data={activeShifts}
            onDetails={item => {
              mapDetails?.id === item?.id
                ? setMapDetails()
                : setMapDetails(item)
            }}
          />
          <TasksContainer
            tasks={['12 facility cancel stafs', 'Complete past one interviews']}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default ClientLiveDashboard
