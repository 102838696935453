// @ts-nocheck
import { api } from 'main/configs/axios.config'

export const getActiveShifts = async ({
  page,
  page_size,
  city,
  role,
  time_zone,
}) => {
  let url = `operation_panel/homecare_dashboard/active-shifts`
  const queries = []

  if (page) queries.push(`page_number=${page}`)
  if (page_size) queries.push(`page_size=${page_size}`)
  if (time_zone) queries.push(`time_zone=${time_zone}`)
  if (city) queries.push(`city=${city}`)
  if (role) queries.push(`role=${role}`)

  const query = queries?.join('&')

  if (query?.length) url += `?${query}`

  const details = await api.get(url)
  return details
}
