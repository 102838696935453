import { React, useState, useEffect } from 'react'
import {
  Card,
  Stack,
  Typography,
  CircularProgress,
  useMediaQuery,
  useTheme,
  IconButton,
  Divider,
  Box,
  Button,
  Snackbar,
} from '@mui/material'

import { Link as RouterLink } from 'react-router-dom'
import { DashboardService } from 'homecare/services'
import { api } from 'main/configs/axios.config'
import { useQuery } from '@tanstack/react-query'

export default function Cards() {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const [emptyMessage, setEmptyMessage] = useState('')
  const [open, setOpen] = useState(false)

  const getCards = useQuery({
    queryKey: ['getPayment'],
    queryFn: DashboardService.getPayment,
    enabled: true,
    onError: e => {
      setEmptyMessage(e.response.data.message)
      handleClick()
    },
  })

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  const handleClick = () => {
    setOpen(true)
  }

  useEffect(() => {
    if (!getCards.isFetching && !getCards.isError) {
      getCards.refetch().catch(e => {
        setEmptyMessage(e.response.data.message)
        handleClick()
      })
    }
  }, [])

  function cardBox(card) {
    return (
      <Box
        key={card.id}
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          background: '#FFFFFF',
          boxShadow: '2px 2px 8px 2px rgba(180, 180, 180, 0.25)',
          borderRadius: '10px',
          padding: '20px',
          marginBottom: '15px',
        }}
      >
        <Typography
          sx={{
            padding: '10px 15px',
            border: '2px solid #828282',
            color: '#0034AE',
            fontWeight: '600',
            cursor: 'default',
            textTransform: 'uppercase',
            fontStyle: 'italic',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              fontStyle: 'normal',
            },
          }}
        >
          {card.card.brand}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <Typography sx={{ fontWeight: '600', cursor: 'default' }}>
            **** **** **** {card.card.last4}
          </Typography>
          <Typography
            sx={{ fontWeight: '600', alignSelf: 'self-end', cursor: 'default' }}
          >
            {card.card.exp_month.toLocaleString('en-US', {
              minimumIntegerDigits: 2,
            })}
            /{card.card.exp_year.toString().slice(-2)}
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <Card
      sx={{
        zIndex: 100,
        position: 'absolute',
        top: 150,
        padding: { xs: '12px', sm: '40px', md: '40px' },
        width: { xs: '95%', sm: '95%', md: '520px' },
        minHeight: '650px',
        maxHeight: '76vh',
        left: { xs: 10, sm: 10, md: 50 },
        boxShadow: 'none',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
          width: '10px',
          backgroundColor: '#F5F5F5',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#D2D2D2',
          borderRadius: '20px',
          border: '3px solid #F5F5F5',
        },
      }}
    >
      <Typography
        sx={{
          marginBottom: { xs: '15px', sm: '15px', md: '25px' },
          fontWeight: '700',
          fontSize: { xs: '1.5rem', sm: '1.5rem', md: '3rem' },
          lineHeight: '50px',
          color: 'custom.black',
        }}
      >
        Cards
      </Typography>

      {getCards.isLoading ? (
        <Box
          display="flex"
          width="100%"
          justifyContent="center"
          alignItems="center"
          sx={{ minHeight: '250px', maxHeight: '76vh' }}
        >
          <CircularProgress size={24} />
        </Box>
      ) : getCards?.data?.length !== 0 ? (
        getCards.data?.data.map(card => cardBox(card))
      ) : (
        <Typography
          sx={{
            marginBottom: { xs: '15px', sm: '15px', md: '25px' },
            fontWeight: '800',
            fontSize: { xs: '1.5rem', sm: '1.5rem', md: '3rem' },
            lineHeight: '50px',
            color: '#C5C5C5',
          }}
        >
          NO CARDS
        </Typography>
      )}

      <RouterLink to="/individual/add-cards">
        <Button
          sx={{
            backgroundColor: '#F52A4C',
            color: 'white',
            fontSize: '22px',
            fontWeight: '500',
            borderRadius: '50%',
            width: '60px',
            height: '60px',
            minWidth: '60px',
            boxShadow: '0px 4px 14px 5px rgba(171, 171, 171, 0.4)',
            position: 'absolute',
            bottom: 27,
            right: 28,
            zIndex: '100',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              backgroundColor: '#F52A4C',
              transform: 'scale(1.1)',
            },
          }}
        >
          +
        </Button>
      </RouterLink>

      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={emptyMessage}
      />
    </Card>
  )
}

const sxStyles = {
  card: {
    zIndex: 1,
    position: 'absolute',
    top: 150,
    padding: '40px 25px',
    width: '660px',
  },
  heading: {
    fontWeight: '700',
    fontSize: '48px',
    lineHeight: '58px',
    color: 'custom.black',
  },
  summary: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#000000',
  },
  fullInput: {
    width: '100%',
    backgroundColor: 'custom.registerInput',
    marginBottom: 2,
    padding: '10px',
    borderRadius: '6px',
  },
  halfInput: {
    width: '48%',
    backgroundColor: 'custom.registerInput',
    marginBottom: 2,
    padding: '10px',
    borderRadius: '6px',
  },
  halfSelect: {
    width: '48%',
    marginBottom: 2,
    padding: '10px',
    borderRadius: '6px',
  },
  scheduleBtn: {
    borderRadius: '56px',
    backgroundColor: 'custom.registerInput',
    color: 'custom.black',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '19px',
  },
  detailsCard: {
    background: '#F6F6F6',
    padding: '16px',
    borderRadius: '6px',
    width: '100%',
  },
  subHeading: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#667684',
    marginBottom: 1,
  },
  details: {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#818181',
    marginBottom: 1,
    display: 'flex',
    alignItems: 'center',
  },
}
