import './App.css';
import { MuiAppBar, SalesSidebar, Sidebar } from './components';
import { Route, Routes } from 'react-router-dom';
import { appRoutes, salesRoutes } from './routes';
import 'react-calendar/dist/Calendar.css';
import { useState, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material';
import './index.css';
import '@fontsource/inter'; // Defaults to weight 400.
import { useNavigate } from 'react-router-dom';
import { accountTypeKeyName } from 'main/configs/axios.config';

import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  },
});

export default function App() {
  const [accountType, setAccountType] = useState('sales');
  const navigate = useNavigate();

  useEffect(() => {
    let moduleName = localStorage.getItem(accountTypeKeyName);
    if (moduleName) {
      moduleName = JSON.parse(moduleName);
      setAccountType(moduleName);
    }
  }, []);

  useEffect(() => {
    if (accountType === 'operation_panel')
      navigate('/operation_panel/dashboard');
    else {
      navigate('/sales/dashboard');
    }
  }, [accountType]);

  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);

  const saleTheme = createTheme({
    palette: {
      primary: {
        main: '#216fed',
      },
      secondary: {
        main: '#97a0af',
      },
      background: {
        default: '#F8FAFC',
      },
      custom: {
        black: '#081735',
        warning: '#f2994a',
        success: '#219653',
        danger: '#ff442b',
        border: 'rgba(8, 23, 53, 0.1)',
      },
    },
    typography: {
      fontFamily: ['Inter'],
      button: {
        textTransform: 'none',
      },
      h1: {
        fontSize: '30px',
        fontWeight: '700',
        lineHeight: '48px',
        color: '#081735',
      },
      h2: {
        fontSize: '28px',
        fontWeight: '600',
        lineHeight: '48px',
        color: '#081735',
        marginTop: '30px',
        marginBottom: '20px',
      },
      h3: {
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#081735',
      },
      subtitle1: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '24px',
        color: '#97a0af',
      },
    },
  });

  const operationPanelTheme = createTheme({
    palette: {
      primary: {
        main: '#6c5dd3',
      },
      secondary: {
        main: '#8f95b2',
      },
      background: {
        default: '#f5f5f5',
      },
    },
    typography: {
      fontFamily: ['Inter'],
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider
        theme={
          accountType === 'sales' ? saleTheme : operationPanelTheme
        }
      >
        <div className="App" style={{ display: 'flex' }}>
          <MuiAppBar
            onClickLeft={() => setIsMobileDrawerOpen(true)}
          />

          {accountType === 'sales' ? (
            <SalesSidebar
              isMobileDrawerOpen={isMobileDrawerOpen}
              onClose={() => setIsMobileDrawerOpen(false)}
            />
          ) : (
            <Sidebar
              isMobileDrawerOpen={isMobileDrawerOpen}
              onClose={() => setIsMobileDrawerOpen(false)}
            />
          )}

          <Routes>
            {accountType === 'sales'
              ? salesRoutes.routes?.map((router, index) => (
                  <>
                    <Route
                      key={index}
                      path={router.path}
                      element={router.component}
                    />
                    {router?.more?.length &&
                      router?.more?.map((subRouter, index) => (
                        <Route
                          key={index}
                          path={subRouter.path}
                          element={subRouter.component}
                        />
                      ))}
                  </>
                ))
              : appRoutes.routes?.map((router, index) => (
                  <>
                    <Route
                      key={index}
                      path={router.path}
                      element={router.component}
                    />
                    {router?.more?.length &&
                      router?.more?.map((subRouter, index) => (
                        <Route
                          key={index}
                          path={subRouter.path}
                          element={subRouter.component}
                        />
                      ))}
                  </>
                ))}
          </Routes>
        </div>
      </ThemeProvider>
    </QueryClientProvider>
  );
}
