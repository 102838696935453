import { Box, Button, Stack, Typography } from '@mui/material'
import { HomeCareHeader } from '../../components'
import Map, { Marker } from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { NursePin } from '../../assets/img'
import { useState, useEffect } from 'react'
import { api } from 'main/configs/axios.config'
import { useNavigate } from 'react-router-dom'

const Layout = (props: LayoutProps) => {
  const { children } = props
  const markers = [
    {
      longitude: -95,
      latitude: 40,
    },
    {
      longitude: -102,
      latitude: 45,
    },
    {
      longitude: -105,
      latitude: 35,
    },
    {
      longitude: -108,
      latitude: 35,
    },
    {
      longitude: -90,
      latitude: 35,
    },
  ]

  const [isLoading, setIsLoading] = useState(true)
  const [processCompleted, setProcessCompleted] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    setIsLoading(true)
    try {
      api.get('individuals/account').then(data => {
        setProcessCompleted(data?.contract_pdf)
        setIsLoading(false)
      })
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }, [processCompleted])

  return (
    <>
      {isLoading ? (
        <></>
      ) : processCompleted ? (
        <>
          <Box>
            <HomeCareHeader
              heading="Welcome to Nurse Billboard"
              isLoggedin={processCompleted}
            />
            <Box sx={{ height: '100vh', width: '100%' }}>
              <Map
                mapboxAccessToken="pk.eyJ1IjoibnVyc2ViaWxsYm9hcmQiLCJhIjoiY2xlb3Y2YmdnMDZhZTNwbzczaDN3c2ljayJ9.cJTw74qANprssuGBV-crwQ"
                initialViewState={{
                  longitude: -100,
                  latitude: 40,
                  zoom: 3.5,
                }}
                mapStyle="mapbox://styles/mapbox/light-v9"
              >
                {markers?.map((item, index) => (
                  <Marker
                    key={index}
                    longitude={item.longitude}
                    latitude={item.latitude}
                    anchor="bottom"
                  >
                    <img src={NursePin} alt="nurse pin" />
                  </Marker>
                ))}
              </Map>
            </Box>
          </Box>
          <Box>
            <Box>
              <Box>{children}</Box>
            </Box>
          </Box>
        </>
      ) : (
        <>{noContract()}</>
      )}
    </>
  )

  function noContract() {
    return (
      <Box>
        <HomeCareHeader
          heading="Welcome to Nurse Billboard"
          isLoggedin={processCompleted}
        />
        <Box
          sx={{
            height: '90vh',
            maxWidth: '100vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Stack direction="column" alignItems="center">
            <Typography
              fontSize={{ xs: '16px', sm: '16px', md: '18px' }}
              sx={{
                fontWeight: '500',
                lineHeight: '22px',
                marginBottom: '20px',
                textAlign: 'center',
                padding: '0 8px',
              }}
            >
              Please complete the login process and sign the contract to access
              the dashboard.
            </Typography>

            <Button
              width={{ xs: '90%', sm: '90%', md: '300px' }}
              variant="contained"
              sx={{
                height: '50px',
                borderRadius: '57px',
                fontWeight: '500',
                fontSize: '18px',
                lineHeight: '20px',
              }}
              onClick={() =>
                navigate('/individual/healthcare-required-details')
              }
            >
              Complete process ->
            </Button>
          </Stack>
        </Box>
      </Box>
    )
  }
}

export default Layout
