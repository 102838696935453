import {
  Box,
  Text,
  CloseButton,
  Flex,
  Icon,
  IconButton,
  Image,
  Avatar,
  HStack,
  BoxProps,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import {
  getNavigationContent,
  NavItemType,
} from "main/configs/navigation.config";
import { useRef } from "react";
import { RxDoubleArrowRight, RxDoubleArrowLeft } from "react-icons/rx";
import PerfectScrollbar from "react-perfect-scrollbar";
import NavItem from "./navitem";

type SideBarProps = {
  hidden: boolean;
  setHidden: (value: boolean) => void;
  fixed: boolean;
  setFixed: (value: boolean) => void;
  navHover: boolean;
  setNavHover: (value: boolean) => void;
  userRole: string;
  handleCloseMenu: () => void;
};

const SideBar = (props: SideBarProps) => {
  const {
    hidden,
    userRole,
    fixed,
    navHover,
    setFixed,
    setNavHover,
    handleCloseMenu,
    setHidden,
  } = props;
  const shadowRef = useRef(null);
  // ** Fixes Navigation InfiniteScroll
  const handleInfiniteScroll = (ref: HTMLElement) => {
    if (ref) {
      // @ts-ignore
      ref._getBoundingClientRect = ref.getBoundingClientRect;

      ref.getBoundingClientRect = () => {
        // @ts-ignore
        const original = ref._getBoundingClientRect();

        return { ...original, height: Math.floor(original.height) };
      };
    }
  };

  // ** Scroll Menu
  const scrollMenu = (container: any) => {
    container = hidden ? container.target : container;
    if (shadowRef && container.scrollTop > 0) {
      // @ts-ignore
      if (!shadowRef.current.classList.contains("d-block")) {
        // @ts-ignore
        shadowRef.current.classList.add("d-block");
      }
    } else {
      // @ts-ignore
      shadowRef.current.classList.remove("d-block");
    }
  };
  const StyledBoxForShadow = styled(Box)((props) => ({
    top: 60,
    left: -8,
    zIndex: 2,
    display: "none",
    position: "absolute",
    pointerEvents: "none",
    width: "calc(100% + 15px)",
    "&.d-block": {
      display: "block",
    },
  }));
  const ScrollWrapper = hidden ? Box : PerfectScrollbar;
  return (
    <Box
      transition={"0.5s ease"}
      position={"fixed"}
      top={0}
      left={0}
      w={{ base: hidden ? 0 : "full", md: fixed || navHover ? 80 : 20 }}
      height='full'
      zIndex={999}
      bg='#fff'
      shadow={"md"}
      pb={15}
      onMouseEnter={() => {
        setNavHover(true);
      }}
      onMouseLeave={() => {
        setNavHover(false);
      }}
    >
      <Flex
        px={3}
        transition={"0.2s ease"}
        alignItems='center'
        justifyContent='space-between'
        visibility={{ base: hidden ? "hidden" : "visible", md: "visible" }}
      >
        <Box flex={1} px={hidden ? 0 : 3}>
          <Image
            src='/assets/images/Logo.png'
            height='20'
            width='20'
            objectFit='contain'
            mt={3}
            mx='auto'
          />
        </Box>
        <IconButton
          size={"sm"}
          variant='ghost'
          colorScheme='red'
          aria-label='toggle-nav'
          icon={<Icon as={fixed ? RxDoubleArrowLeft : RxDoubleArrowRight} />}
          display={{ base: "none", md: fixed || navHover ? "flex" : "none" }}
          sx={{ opacity: navHover ? 1 : 0 }}
          onClick={() => {
            setFixed(!fixed);
          }}
        />
        <CloseButton
          display={{ base: "block", md: "none" }}
          onClick={handleCloseMenu}
        />
      </Flex>
      <StyledBoxForShadow ref={shadowRef} />
      <Box mt={8}>
        <ScrollWrapper
          ref={(ref: any) => handleInfiniteScroll(ref)}
          {...(hidden
            ? {
              onScroll: (container: any) => scrollMenu(container),
              sx: { height: "100%", overflowY: "auto", overflowX: "hidden" },
            }
            : {
              options: { wheelPropagation: false },
              onScrollY: (container: any) => scrollMenu(container),
            })}
        >
          {getNavigationContent(userRole).map((link: NavItemType) => {
            return (
              <NavItem
                hidden={hidden}
                m='2'
                key={link.name}
                {...link}
                transition='visibility 0.2s ease'
                visibility={{
                  base: hidden ? "hidden" : "visible",
                  md: "visible",
                }}
                fixed={fixed}
                navHover={navHover}
                setHidden={setHidden}
              />
            );
          })}
        </ScrollWrapper>
      </Box>
    </Box>
  );
};
export default SideBar;
