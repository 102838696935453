// @ts-nocheck
import { useEffect, useState } from 'react'
import {
  Box,
  Checkbox,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  Button,
  Modal,
  Typography,
  Snackbar,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { ApproveFacilityHeader, Pagination } from '../../components'
import { ApproveFacilitiesService } from 'operational-sales-panel/services'
import { api } from 'main/configs/axios.config'
import { Buffer } from 'buffer'

const headCells = ['Mark All', '', '', 'Payment', '']

function ApproveFacility() {
  const [selected, setSelected] = useState([])
  const [approveFacilites, setApproveFacilites] = useState()
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [page, setPage] = useState(1)
  const [openContractModal, setOpenContractModal] = useState(false)
  const [pdfContractData, setPdfContractData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [emptyMessage, setEmptyMessage] = useState('')
  const [snackbarOpen, setSnackbarOpen] = useState(false)

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
  }

  const handleCloseModal = () => {
    setPdfContractData(null)
    setOpenContractModal(false)
  }

  const handleRows = event => {
    setRowsPerPage(event.target.value)
  }

  const handleSelectAllClick = event => {
    if (!selected.length) {
      const newSelected = approveFacilites?.facilities?.map(n => n.id)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  useEffect(() => {
    ApproveFacilitiesService.getApproveFacility(page, rowsPerPage)
      .then(data => setApproveFacilites(data))
      .catch(err => console.log(err))
  }, [rowsPerPage, page, emptyMessage])

  const onApproveFacilites = async approveAll => {
    let bodyData = []
    if (approveAll) {
      const allSelected = approveFacilites?.facilities?.map(n => n.id)
      bodyData = allSelected
    } else {
      bodyData = selected
    }
    if (bodyData.length > 0) {
      try {
        await api.post('sales/approve_facility/approve_facilities', bodyData)
        setEmptyMessage('Done ✔')
        setSnackbarOpen(true)
      } catch (error) {
        setEmptyMessage(error.response.data.message)
        setSnackbarOpen(true)
      }
    }
  }

  async function fetchContract(filename) {
    let srcValue = ''
    setIsLoading(true)
    await api
      .get(`contracts/${filename}`, {
        responseType: 'arraybuffer',
      })
      .then((res: any) => {
        let base64ImageString = Buffer.from(res, 'binary').toString('base64')
        srcValue = 'data:application/pdf;base64,' + base64ImageString
        setIsLoading(false)
      })
    setPdfContractData(srcValue)
  }

  const renderPdf = (): JSX.Element => {
    return (
      <div style={{ height: '75vh', width: '100%' }}>
        {pdfContractData ? (
          <iframe
            style={{
              border: 'none',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
              width: '100%',
            }}
            title="PDF Viewer"
            src={pdfContractData}
            height="100%"
          />
        ) : (
          <Typography
            sx={{
              textAlign: 'center',
              fontWeight: 'semibold',
            }}
          >
            No Documents
          </Typography>
        )}
      </div>
    )
  }

  return (
    <Box sx={sxStyles.container}>
      <ApproveFacilityHeader
        heading={`${approveFacilites?.facilities?.length} New Facilities`}
        onApproveSelection={() => onApproveFacilites(false)}
        onApproveAll={() => onApproveFacilites(true)}
      />
      <Box sx={sxStyles.grid}>
        <Paper
          elevation={0}
          sx={{ backgroundColor: 'white', borderRadius: '12px' }}
        >
          <TableContainer>
            <Table aria-labelledby="tableTitle">
              <EnhancedTableHead onSelectAllClick={handleSelectAllClick} />
              <TableBody>
                {approveFacilites?.facilities?.map((row, index) => {
                  const isItemSelected = selected.indexOf(row?.id) !== -1
                  const labelId = `enhanced-table-checkbox-${index}`

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                    >
                      <TableCell
                        padding="checkbox"
                        align="center"
                        sx={sxStyles.borderTable}
                      >
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                          onClick={event => handleClick(event, row?.id)}
                        />
                      </TableCell>
                      <TableCell align="left" sx={sxStyles.borderTable}>
                        {`${row?.facility_name} \n${row?.company_name} \n${row?.admin_name} \n${row?.admin_title}`}
                      </TableCell>
                      <TableCell align="left" sx={sxStyles.borderTable}>
                        {`${row?.city} \n${row?.country} \n${row?.state}`}
                      </TableCell>
                      <TableCell align="left" sx={sxStyles.borderTable}>
                        {row?.payment_option}
                      </TableCell>
                      <TableCell sx={sxStyles.borderTable}>
                        <Button
                          variant="outlined"
                          sx={sxStyles.btn}
                          onClick={() => {
                            setOpenContractModal(true)
                            fetchContract(row?.contract_pdf)
                          }}
                        >
                          View Contract
                        </Button>
                        {/* <Button
                          variant="outlined"
                          sx={[sxStyles.btn, { marginTop: '10px' }]}
                        >
                          View Payment Details
                        </Button> */}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>

          {approveFacilites?.total_pages > 0 ? (
            <Pagination
              totalItems={`${page} - ${rowsPerPage} of ${approveFacilites?.total_pages}`}
              count={approveFacilites?.total_pages}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={handleRows}
              onClick={(e, value) => setPage(value)}
            />
          ) : null}
        </Paper>
      </Box>

      <Modal open={openContractModal} onClose={handleCloseModal}>
        <Box>
          {isLoading ? (
            <Typography>Loading...</Typography>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {renderPdf()}
            </Box>
          )}
        </Box>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: 'green',
            color: 'white',
            p: 2,
          }}
        >
          <Typography variant="body1">{emptyMessage}</Typography>
          <Button color="inherit" size="small" onClick={handleSnackbarClose}>
            <Close fontSize="small" />
          </Button>
        </Box>
      </Snackbar>
    </Box>
  )
}

function EnhancedTableHead({ onSelectAllClick }) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={index}
            align={index === 0 ? 'center' : 'left'}
            sx={
              index === 0 ? sxStyles.headerTableMarkAll : sxStyles.headerTable
            }
            onClick={index === 0 ? onSelectAllClick : null}
          >
            {headCell}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const sxStyles = {
  container: {
    flexGrow: 1,
    width: '100vw',
  },
  grid: {
    paddingLeft: '30px',
    paddingRight: '30px',
    margin: '20px 0px',
  },
  actionsRow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  btn: {
    width: '80%',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '24px',
    color: 'custom.black',
    borderColor: 'custom.black',
  },
  borderTable: {
    whiteSpace: 'pre-wrap',
    borderRight: '1px solid',
    borderColor: 'custom.border',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
  },
  headerTableMarkAll: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '24px',
    cursor: 'pointer',
    width: '95px',
  },
  headerTable: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
  },
}

export default ApproveFacility
