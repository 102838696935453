import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

export default function MuiAppBar({ onClickLeft }) {
	return (
		<AppBar
			sx={{
				display: { md: "none" }
			}}
		>
			<Toolbar>
				<IconButton
					onClick={onClickLeft}
					size="large"
					edge="start"
					color="inherit"
					aria-label="menu"
					sx={{ mr: 2 }}
				>
					<MenuIcon />
				</IconButton>
			</Toolbar>
		</AppBar>
	);
}
