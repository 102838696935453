import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { format, addDays } from 'date-fns';
import './style.css';
import { FaCaretDown } from 'react-icons/fa';
import Card from './components/Card';
import Divider from './components/Divider';
import { api } from 'main/configs/axios.config';

export default function Financials() {
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [data, setData] = React.useState({});
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  React.useEffect(() => {
    api
      .get(
        `/operation_panel/financials?page_number=${pageNumber}&page_size=${pageSize}&day1=${startDate}&day2=${endDate}`
      )
      .then(res => {
        console.log(res);
      })
      .catch(e => {
        console.log(e);
      });
  }, [data, endDate, pageNumber, pageSize, startDate]);

  const [showStartDatePicker, setShowStartDatePicker] =
    React.useState(false);
  const [showEndDatePicker, setShowEndDatePicker] =
    React.useState(false);

  const handleStartDateChange = date => {
    setStartDate(date);
    if (endDate && date > endDate) {
      setEndDate(null);
    }
  };

  const handleEndDateChange = date => {
    if (startDate && date < startDate) {
      return;
    }
    setEndDate(date);
  };

  const handleStartDateIconClick = () => {
    setShowStartDatePicker(!showStartDatePicker);
    setShowEndDatePicker(false);
  };

  const handleEndDateIconClick = () => {
    setShowEndDatePicker(!showEndDatePicker);
    setShowStartDatePicker(false);
  };

  const getCurrentDateFormatted = () => {
    return format(new Date(), 'yyyy MMM d');
  };

  const getEndDatePlaceholder = () => {
    const newDate = new Date();
    const increasedEndDate = addDays(newDate, 1);
    return format(increasedEndDate, 'yyyy MMM d');
  };

  const handleStartDateBlur = () => {
    setShowStartDatePicker(false);
  };

  const handleEndDateBlur = () => {
    setShowEndDatePicker(false);
  };

  return (
    <Box display="flex" flexDirection="column">
      {/* TOPBAR */}
      <Box
        flexWrap="wrap"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        padding="20px 40px"
        width="100%"
        gap="10px"
        sx={{
          background: '#fff',
          borderBottom: '1px solid rgb(230, 232, 235)',

          marginTop: {
            xs: '60px',
            sm: '65px',
            md: '0',
            lg: '0',
          },

          flexDirection: {
            xs: 'column',
            sm: 'row',
          },

          alignItems: {
            xs: 'flex-start',
            sm: 'center',
          },

          padding: {
            xs: '12px 30px',
            sm: '20px 40px',
          },
        }}
      >
        <Typography
          color="#000"
          sx={{
            fontSize: '1.2rem',
            fontWeight: '700',
          }}
        >
          Financials
        </Typography>
        <Box display="flex" alignItems="center" gap="10px">
          {/* DATE */}
          <Box
            display="flex"
            alignItems="center"
            gap="20px"
            sx={{
              background: 'white',
              boxShadow: ' 0px 0px 50px 0px rgba(26, 25, 33, 0.03)',
              borderRadius: '10px',
              padding: '10px',
            }}
          >
            <Box display="flex">
              <DatePicker
                readOnly
                wrapperClassName="h_datePicker"
                selected={startDate}
                onChange={handleStartDateChange}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="yyyy MMM d"
                placeholderText={getCurrentDateFormatted()}
                open={showStartDatePicker}
                onBlur={handleStartDateBlur}
                onClickOutside={handleStartDateBlur}
                onInputClick={handleStartDateBlur}
                onSelect={handleStartDateBlur}
                onClick={() => setShowStartDatePicker(true)}
              />

              <Box sx={{ cursor: 'pointer' }}>
                <FaCaretDown
                  color="#8F95B2"
                  onClick={handleStartDateIconClick}
                />
              </Box>
            </Box>

            <Box
              display="flex"
              sx={{
                padding: '0 0 0 20px',
                borderLeft: 'solid 1px #8f95b257',
              }}
            >
              <DatePicker
                readOnly
                wrapperClassName="h_datePicker"
                selected={endDate}
                onChange={handleEndDateChange}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                dateFormat="yyyy MMM d"
                placeholderText={getEndDatePlaceholder()}
                onClick={showEndDatePicker}
                open={showEndDatePicker}
                onBlur={handleEndDateBlur}
                onClickOutside={handleEndDateBlur}
                onSelect={handleEndDateBlur}
              />
              <Box sx={{ cursor: 'pointer' }}>
                <FaCaretDown
                  color="#8F95B2"
                  onClick={handleEndDateIconClick}
                />
              </Box>
            </Box>
          </Box>

          {/* FILTER */}
          {/* <Box
            display="flex"
            alignItems="center"
            gap="20px"
            sx={{
              background: 'white',
              boxShadow: ' 0px 0px 50px 0px rgba(26, 25, 33, 0.03)',
              borderRadius: '10px',
              padding: '10px',
            }}
          >
            <Typography color="#081735">Filter by:</Typography>
            <Select value={filter} onChange={handleFilterChange}>
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Homecare">Homecare</MenuItem>
              <MenuItem value="Facility">Facility</MenuItem>
              <MenuItem value="Company">Company</MenuItem>
            </Select>
          </Box> */}
        </Box>
      </Box>

      <Box padding="20px 40px">
        <Box
          sx={{
            flexWrap: 'wrap',
          }}
          display="flex"
          gap="20px"
        >
          <Card
            price="105,000"
            hasLabel={true}
            data="Stripe Balance"
            color="#0049C6"
          />
          <Card
            price="32,450"
            hasLabel={true}
            data="Stripe Pending"
            color="#FF442B"
          />
          <Card
            price="32,450"
            hasLabel={true}
            data="Stripe Payouts (Pending)"
            color="#F2994A"
          />
        </Box>
        {/* Divider  */}
      </Box>
      <Divider />

      <Box padding="20px 40px">
        <Box
          sx={{
            flexWrap: 'wrap',
          }}
          display="flex"
          gap="20px"
        >
          <Card
            price="25,000"
            hasLabel={false}
            data="Gross Revenue"
            color="#0049C6"
          />
          <Card
            price="5,000"
            hasLabel={false}
            data="Net Revenue"
            color="#219653"
          />
          <Card
            price="19,000"
            hasLabel={false}
            data="Staff Revenue"
            color="#F2994A"
          />
          <Card
            price="1,000"
            hasLabel={false}
            data="Stripe fees"
            color="#FF442B"
          />
        </Box>
        {/* Divider  */}
      </Box>
    </Box>
  );
}
