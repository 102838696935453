// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { DashboardCard, Pagination } from '../../components'
import {
  Box,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Table,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import { SalesHomeService } from 'operational-sales-panel/services'

const facilitiesColumns = [
  'State',
  'County',
  'Available Facilities',
  'Onboarding Facilities',
  'Target Left',
]
const staffColumns = [
  'State',
  'County',
  'Available Leads',
  'Onboarding Staff',
  'Target Left',
]

function Home() {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  const [details, setDetails] = useState()
  const [facilites, setFacilities] = useState()
  const [staff, setStaff] = useState()
  const [rowsPerPage1, setRowsPerPage1] = useState(5)
  const [rowsPerPage2, setRowsPerPage2] = useState(5)
  const [pageFacilities, setPageFacilities] = useState(1)
  const [pageStaff, setPageStaff] = useState(1)

  const handleRows1 = event => {
    setRowsPerPage1(event.target.value)
  }

  const handleRows2 = event => {
    setRowsPerPage2(event.target.value)
  }

  useEffect(() => {
    getAll()
  }, [rowsPerPage1, rowsPerPage2])

  const getAll = async () => {
    const [details, facilites, staff] = await Promise.all([
      SalesHomeService.getDetails(),
      SalesHomeService.getFacilities(pageFacilities, rowsPerPage1),
      SalesHomeService.getStaff(pageStaff, rowsPerPage2),
    ])

    setDetails(details)
    setFacilities(facilites)
    setStaff(staff)
  }

  const getMoreFacilities = page => {
    SalesHomeService.getFacilities(page, rowsPerPage1)
      .then(data => {
        setPageFacilities(page)
        setFacilities(data)
      })
      .catch(err => console.log(err))
  }

  const getMoreStaff = page => {
    SalesHomeService.getStaff(page, rowsPerPage2)
      .then(data => {
        setPageStaff(page)
        setStaff(data)
      })
      .catch(err => console.log(err))
  }

  return (
    <Box sx={[sxStyles.container, { marginTop: !matches && 5 }]}>
      <Box
        sx={[
          sxStyles.cardsRow,
          { flexWrap: !matches && 'wrap', marginBottom: matches && '30px' },
        ]}
      >
        <DashboardCard
          counter={details?.facilites_onboard}
          text="Facilities Onboard"
        />
        <DashboardCard
          counter={details?.facilites_pending}
          text="Facilities Pending"
        />
        <DashboardCard
          counter={details?.facilites_contacted}
          text="Facilities Contacted"
        />
        <DashboardCard
          counter={details?.facilites_not_interested}
          text="Facilities Not Interested"
        />
      </Box>
      <Box sx={[sxStyles.cardsRow, { flexWrap: !matches && 'wrap' }]}>
        <DashboardCard counter={details?.staff_onboard} text="Staff Onboard" />
        <DashboardCard counter={details?.staff_pending} text="Staff Pending" />
        <DashboardCard
          counter={details?.staff_contacted}
          text="Staff Contacted"
        />
        <DashboardCard
          counter={details?.staff_not_interested}
          text="Staff Not Interested"
        />
      </Box>

      <Box
        sx={[
          sxStyles.cardsRow,
          { flexWrap: !matches && 'wrap', marginTop: matches && '30px' },
        ]}
      >
        <DashboardCard
          counter={details?.school_onboard}
          text="School Onboard"
        />
        <DashboardCard
          counter={details?.school_pending}
          text="School Pending"
        />
        <DashboardCard
          counter={details?.school_contacted}
          text="School Contacted"
        />
        <DashboardCard
          counter={details?.school_not_interested}
          text="School Not Interested"
        />
      </Box>

      <Box
        sx={[
          sxStyles.cardsRow,
          { flexWrap: !matches && 'wrap', marginTop: matches && '30px' },
        ]}
      >
        <DashboardCard
          counter={details?.references_pending}
          text="References Pending"
        />
        <DashboardCard
          counter={details?.references_contacted}
          text="References Contacted"
        />
        <DashboardCard
          counter={details?.references_not_interested}
          text="References Not Interested"
        />
        <DashboardCard
          visible={true}
          counter={details?.references_pending}
          text="References Onboard"
        />
      </Box>

      <Typography variant="h2">Facilities</Typography>

      <Paper elevation={0} sx={sxStyles.tableWrapper}>
        <TableContainer sx={{ maxHeight: 400, tableLayout: 'fixed' }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{ overflowX: 'hidden' }}
          >
            <TableHead>
              <TableRow>
                {facilitiesColumns.map((column, index) => (
                  <TableCell
                    key={index + '2'}
                    align={'center'}
                    sx={sxStyles.tableCellHeader}
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {facilites?.facilities?.map((row, index) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={index + '1'}>
                  <TableCell align="center" sx={sxStyles.tabelCell}>
                    {'California'}
                  </TableCell>
                  <TableCell align="center" sx={sxStyles.tabelCell}>
                    {row?.county}
                  </TableCell>
                  <TableCell align="center" sx={sxStyles.tabelCell}>
                    {row?.available_facilities_count}
                  </TableCell>
                  <TableCell align="center" sx={sxStyles.tabelCell}>
                    {row?.facilities_onboarding_count}
                  </TableCell>
                  <TableCell align="center" sx={sxStyles.tabelCell}>
                    {row?.target_left_count}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          totalItems={`${pageFacilities} - ${rowsPerPage1} of ${facilites?.total_pages}`}
          count={facilites?.total_pages}
          rowsPerPage={rowsPerPage1}
          setRowsPerPage={handleRows1}
          onClick={(e, value) => getMoreFacilities(value)}
        />
      </Paper>

      <Typography variant="h2">Staffs</Typography>
      <Paper sx={sxStyles.tableWrapper}>
        <TableContainer sx={{ maxHeight: 400 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {staffColumns.map((column, index) => (
                  <TableCell
                    key={index + '3'}
                    align="center"
                    sx={sxStyles.tableCellHeader}
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {staff?.staff?.map((row, index) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={index + '4'}>
                  <TableCell align="center" sx={sxStyles.tabelCell}>
                    {'California'}
                  </TableCell>
                  <TableCell align="center" sx={sxStyles.tabelCell}>
                    {row?.city}
                  </TableCell>
                  <TableCell align="center" sx={sxStyles.tabelCell}>
                    {row?.available_staff_count}
                  </TableCell>
                  <TableCell align="center" sx={sxStyles.tabelCell}>
                    {row?.staff_onboarding_count}
                  </TableCell>
                  <TableCell align="center" sx={sxStyles.tabelCell}>
                    {row?.target_left_count}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          totalItems={`${pageStaff} - ${rowsPerPage2} of ${staff?.total_pages}`}
          count={staff?.total_pages}
          rowsPerPage={rowsPerPage2}
          setRowsPerPage={handleRows2}
          onClick={(e, value) => getMoreStaff(value)}
        />
      </Paper>
    </Box>
  )
}

const sxStyles = {
  container: {
    flexGrow: 1,
    padding: '30px',
    width: '100vw',
  },
  cardsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tableWrapper: {
    overflow: 'hidden',
    borderRadius: '12px',
    width: 'auto',
    overflowX: 'auto',
  },
  tableCellHeader: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    color: 'custom.black',
    borderRight: '1px solid',
    borderColor: 'custom.border',
    backgroundColor: 'white',
  },
  tabelCell: {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    color: 'custom.black',
    borderRight: '1px solid',
    borderColor: 'custom.border',
  },
  cursor: {
    cursor: 'pointer',
  },
}

export default Home
