import React, { useEffect, useState } from 'react';
import styles from './Sidebar.module.css';
import { appRoutes } from '../../routes';
import { useLocation } from 'react-router-dom';
import MenuItem from '../MenuItem/MenuItem';
import { Logo, Logout, Arrow } from '../../assets/svg';
import Drawer from '@mui/material/Drawer';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAuth } from 'main/hooks';
import { useQuery } from '@tanstack/react-query';
import { getAccountData } from 'operational-sales-panel/callbacks';

const Sidebar = ({ isMobileDrawerOpen, onClose }) => {
  const location = useLocation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const { handleLogout } = useAuth();

  //*************** START GET DATA FROM ACCOUNT ***************//
  const getAccount = useQuery({
    queryKey: ['account'],
    queryFn: getAccountData,
  });

  //? IF ERROR
  if (getAccount.isError)
    return <pre>{JSON.stringify(getAccount.error)}</pre>;

  //? IF SUCESS
  const account = getAccount.data;
  //*************** END GET DATA FROM ACCOUNT ***************//

  return (
    <Drawer
      variant={matches ? 'permanent' : 'temporary'}
      open={matches || isMobileDrawerOpen}
      sx={sxStyles.drawer}
      onClose={onClose}
    >
      <div className={styles.logoWrapper}>
        <img
          src={Logo}
          alt="Nurse Billboard"
          className={styles.logo}
        />
      </div>
      <div className={styles.userCard}>
        <img
          style={{
            clipPath: 'circle()',
            w: '55px',
            h: 'auto',
          }}
          src={
            account?.img ??
            'https://img.freepik.com/free-vector/hand-drawn-flat-design-shrug-illustration_23-2149318820.jpg?w=2000'
          }
          alt="user"
        />
        <div>
          <div className={styles.username}>
            {account?.full_name ?? 'LOADING..'}
          </div>
          <div className={styles.role}>Operation admin</div>
        </div>
        <img src={Arrow} alt="arrow" className={styles.arrow} />
      </div>

      <div className={styles.menuWrapper}>
        {appRoutes?.routes?.map((item, index) => {
          if (!item.menu) return null;

          const active = location?.pathname === item?.path;

          return (
            <MenuItem
              icon={item?.icon}
              key={index}
              active={active}
              name={item?.name}
              path={item?.path}
              more={item?.more}
            />
          );
        })}
      </div>

      <div
        className={styles.row}
        onClick={() => {
          handleLogout();
        }}
      >
        <img
          src={Logout}
          alt="logout"
          className={styles.logoutIcon}
        />
        <span className={styles.logoutText}>Logout</span>
      </div>
    </Drawer>
  );
};

const sxStyles = {
  drawer: {
    width: 257,
    paddingTop: 25,
  },
};

export default Sidebar;
