import { React, useEffect, useState } from 'react'
import {
  Button as MUIButton,
  Card,
  InputAdornment as MUIInputAdornment,
  Stack as MUIStack,
  TextField as MUITextField,
  Typography as MUITypography,
  useMediaQuery as MUIuseMediaQuery,
  useTheme as MUIuseTheme,
  ListItemButton as MUIListItemButton,
  ListItemText as MUIListItemText,
  Snackbar as MUISnackbar,
  FormControl as MUIFormControl,
  InputLabel as MUIInputLabel,
  MenuItem as MUIMenuItem,
  Select as MUISelect,
  CircularProgress as MUICircularProgress,
  Box as MUIBox,
} from '@mui/material'
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import moment from 'moment'
import {
  ThemeProvider as MUIThemeProvider,
  createTheme as MUIcreateTheme,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import {
  AccessTimeOutlined,
  Flag,
  Description,
  LocationOnOutlined,
  TuneOutlined,
  Wc,
} from '@mui/icons-material'
import { api } from 'main/configs/axios.config'

export default function RequestHealthCareCard() {
  const homecareTheme = MUIcreateTheme({
    palette: {
      primary: {
        main: '#F52A4C',
      },
      secondary: {
        main: '#BABABA',
      },
      background: {
        default: '#FFFFFF',
      },
      custom: {
        black: '#000000',
        border: '#BABABA',
        gray: '#626262',
        button: '#EEEEEE',
        btnText: '#7B7B7B',
        link: '#585858',
        text: '#818181',
        success: '#51B614',
        bgRegister: '#F5F9FE',
        registerInput: '#F6F6F6',
      },
    },
    typography: {
      fontFamily: ['Inter'],
      button: {
        textTransform: 'none',
      },
      h1: {
        fontSize: '24px',
        fontWeight: '400',
        lineHeight: '36px',
      },
      h6: {
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '20px',
        marginRight: '20px',
      },
      body1: {
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '19px',
        color: '#626262',
      },
      body2: {
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '19px',
        color: '#000000',
      },
      body3: {
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '19px',
        color: '#626262',
      },
    },
  })

  const [emptyMessage, setEmptyMessage] = useState('')
  // const inputRef = useRef(null)
  const [formData, setFormData] = useState({
    location: '',
    role: '',
    tasks: [],
    shiftHours: '',
    language: '',
    details: '',
    gender: '',
  })
  const [selectedDate, setSelectedDate] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const [long, setLong] = useState('')
  const [address, setAddress] = useState('')
  const [state, setState] = useState('')
  const [city, setCity] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [country, setCountry] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const [lat, setLat] = useState('')
  const [showOptions, setShowOptions] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.get(
        `location-suggestions?search=${inputValue}`
      )
      const data = await response
      setSuggestions(data)
    }

    fetchData()
  }, [inputValue])

  const handleInputChange = event => {
    setInputValue(event.target.value)
    if (!event.target.value) {
      setAddress('')
    }
  }

  const handleRoleChange = e => {
    setFormData({ ...formData, role: e.target.value })
  }

  const handleTasksChange = e => {
    setFormData({ ...formData, tasks: e.target.value })
  }

  const handleShiftHoursChange = e => {
    setFormData({ ...formData, shiftHours: e.target.value })
  }

  const handleLanguageChange = e => {
    setFormData({ ...formData, language: e.target.value })
  }

  const handleDetailsChange = e => {
    setFormData({ ...formData, details: e.target.value })
  }

  const handleGenderChange = e => {
    setFormData({ ...formData, gender: e.target.value })
  }

  const handleDateChange = date => {
    const newDate = new Date(date)
    const year = newDate.getFullYear()
    const month = ('0' + (newDate.getMonth() + 1)).slice(-2)
    const day = ('0' + newDate.getDate()).slice(-2)
    const hours = ('0' + newDate.getHours()).slice(-2)
    const minutes = ('0' + newDate.getMinutes()).slice(-2)
    const seconds = ('0' + newDate.getSeconds()).slice(-2)
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    setSelectedDate(formattedDate)
  }

  const [open, setOpen] = useState(false)
  const handleClick = () => {
    setOpen(true)
  }

  const [isLoading, setIsLoading] = useState(false)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const handleFormSubmit = async e => {
    var isEmpty = !address
      ? 'Location is require'
      : !formData.role
      ? 'Role is require'
      : !formData.tasks
      ? 'Tasks is require'
      : !formData.shiftHours
      ? 'Shift Hours is require'
      : !formData.language
      ? 'Language is require'
      : !formData.gender
      ? 'Gender is require'
      : !formData.details
      ? 'Details is require'
      : ''

    setEmptyMessage(isEmpty)
    if (isEmpty) {
      handleClick()
    } else {
      const tasks = [...formData.tasks]
      const scheduled_date = selectedDate
      const fullAddress = {
        language: formData.language,
        gender: formData.gender,
        lat: lat,
        long: long,
        details: formData.details,
        role: formData.role,
        hours: formData.shiftHours,
        address: address,
        city: city,
        state: state,
        postal_code: postalCode,
        country: country,
        tasks: tasks.join('/'),
        time_zone: moment().local().format('Z').toString(),
      }

      const bodyData =
        scheduled_date === null
          ? { ...fullAddress }
          : { ...fullAddress, scheduled_date }

      sessionStorage.setItem('shiftData', JSON.stringify(bodyData))
      navigate('/register')
    }
  }

  return (
    <MUIThemeProvider theme={homecareTheme}>
      <MUIBox
        sx={{
          // position: 'absolute',
          marginTop: { xs: '50px', sm: '50px', md: '8vh' },
          marginLeft: { xs: '5px', sm: '5px', md: '10vw' },
          backgroundColor: 'white',
          borderRadius: '10px',
          padding: { xs: '10px 40px', sm: '10px 40px', md: '50px 40px' },
          width: { xs: '98%', sm: '98%', md: '660px' },
          // left: { xs: 10, sm: 10, md: '10vw' },
          // top: { xs: '10vh', sm: '10vh', md: '20vh' },
          boxShadow: {
            xs: '2px 4px 15px rgba(151, 151, 151, 0.25)',
            sm: '2px 4px 15px rgba(151, 151, 151, 0.25)',
            md: 'none',
          },
          marginBottom: { xs: '40px', sm: '40px', md: '40px' },
        }}
      >
        <MUITypography
          sx={{
            marginBottom: { xs: '8px', sm: '8px', md: '18px' },
            fontWeight: '700',
            fontSize: { xs: '1.5rem', sm: '1.5rem', md: '3rem' },
            lineHeight: '50px',
            color: 'custom.black',
          }}
        >
          Request Healthcare now
        </MUITypography>
        <MUITypography
          sx={{
            fontWeight: '400',
            fontSize: { xs: '14px', sm: '14px', md: '16px' },
            lineHeight: '19px',
            color: '#757575',
            margonTop: { xs: '0', sm: '0', md: '24px' },
            marginBottom: { xs: '22px', sm: '22px', md: '24px' },
          }}
        >
          Short Summary
        </MUITypography>
        <MUITextField
          sx={sxStyles.fullInput}
          InputProps={{
            startAdornment: (
              <MUIInputAdornment position="start">
                <LocationOnOutlined size={10} />
              </MUIInputAdornment>
            ),
            disableUnderline: true,
          }}
          variant="standard"
          placeholder="Enter and select your location"
          value={inputValue}
          onChange={handleInputChange}
        />
        {suggestions.length === 0 && (
          <MUITypography
            sx={[
              sxStyles.summary,
              { marginLeft: '15px', color: '#D0D0D0', fontSize: '14px' },
            ]}
          >
            No suggestions
          </MUITypography>
        )}
        {suggestions.map(suggestion => (
          <MUIListItemButton
            style={{
              marginBottom: '15px',
              display: address ? 'none' : 'flex',
            }}
            key={suggestion.id}
            onClick={() => {
              setInputValue(suggestion?.place_name)
              setLat(suggestion?.latitude)
              setLong(suggestion?.longitude)
              setAddress(suggestion?.place_name)
              setCity(suggestion?.city)
              setState(suggestion?.state)
              setPostalCode(suggestion?.postal_code)
              setCountry(suggestion?.country)
            }}
          >
            <MUIListItemText
              key={suggestion.id}
              primary={suggestion.place_name}
            />
          </MUIListItemButton>
        ))}

        <MUIStack
          sx={{ flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}
          justifyContent="space-between"
        >
          <MUIFormControl
            sx={{
              width: { xs: '100%', sm: '100%', md: '48%' },
              marginBottom: 2,
              padding: '10px',
              borderRadius: '6px',
            }}
          >
            <MUIInputLabel id="role-label">Role</MUIInputLabel>
            <MUISelect
              labelId="role-label"
              id="role"
              name="role"
              value={formData.role}
              onChange={handleRoleChange}
              startAdornment={
                <MUIInputAdornment position="start">
                  <TuneOutlined size={10} />
                </MUIInputAdornment>
              }
            >
              <MUIMenuItem value="RN">RN</MUIMenuItem>
              <MUIMenuItem value="CNA">CNA</MUIMenuItem>
              <MUIMenuItem value="LVN | LPN">LVN | LPN</MUIMenuItem>
              <MUIMenuItem value="HomeCare Aide">HomeCare Aide</MUIMenuItem>
            </MUISelect>
          </MUIFormControl>

          <MUIFormControl
            sx={{
              width: { xs: '100%', sm: '100%', md: '48%' },
              marginBottom: 2,
              padding: '10px',
              borderRadius: '6px',
            }}
          >
            <MUIInputLabel id="tasks-label">Tasks</MUIInputLabel>
            <MUISelect
              labelId="tasks-label"
              id="tasks"
              name="tasks"
              multiple
              value={formData.tasks}
              onChange={handleTasksChange}
              startAdornment={
                <MUIInputAdornment position="start">
                  <Description size={10} />
                </MUIInputAdornment>
              }
              renderValue={selected => selected.join('/')}
            >
              <MUIMenuItem value="Bathing, Dressing, Toileting">
                Bathing, Dressing, Toileting
              </MUIMenuItem>
              <MUIMenuItem value="Meal Planning and Feeding">
                Meal Planning and Feeding
              </MUIMenuItem>
              <MUIMenuItem value="HouseKeeping">HouseKeeping</MUIMenuItem>
              <MUIMenuItem value="Companion ship and med reminders">
                Companion ship and med reminders
              </MUIMenuItem>
              <MUIMenuItem value="Shopping and errands">
                Shopping and errands
              </MUIMenuItem>
            </MUISelect>
          </MUIFormControl>
        </MUIStack>

        <MUIStack
          sx={{ flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}
          justifyContent="space-between"
        >
          <MUIFormControl
            sx={{
              width: { xs: '100%', sm: '100%', md: '48%' },
              marginBottom: 2,
              padding: '10px',
              borderRadius: '6px',
            }}
          >
            <MUIInputLabel id="shiftHours-label">Shift Hours</MUIInputLabel>
            <MUISelect
              labelId="shiftHours-label"
              id="shiftHours"
              name="shiftHours"
              value={formData.shiftHours}
              onChange={handleShiftHoursChange}
              startAdornment={
                <MUIInputAdornment position="start">
                  <AccessTimeOutlined size={10} />
                </MUIInputAdornment>
              }
            >
              <MUIMenuItem value="1">1 Hr</MUIMenuItem>
              <MUIMenuItem value="2">2 Hr's</MUIMenuItem>
              <MUIMenuItem value="4">4 Hr's</MUIMenuItem>
              <MUIMenuItem value="6">6 Hr's</MUIMenuItem>
            </MUISelect>
          </MUIFormControl>

          <MUIFormControl
            sx={{
              width: { xs: '100%', sm: '100%', md: '48%' },
              marginBottom: 2,
              padding: '10px',
              borderRadius: '6px',
            }}
          >
            <MUIInputLabel id="language-label">Language</MUIInputLabel>
            <MUISelect
              labelId="language-label"
              id="language"
              name="language"
              value={formData.language}
              onChange={handleLanguageChange}
              startAdornment={
                <MUIInputAdornment position="start">
                  <Flag size={10} />
                </MUIInputAdornment>
              }
            >
              <MUIMenuItem value="English">English</MUIMenuItem>
              <MUIMenuItem value="Spanish">Spanish</MUIMenuItem>
            </MUISelect>
          </MUIFormControl>
        </MUIStack>

        <MUIStack
          alignItems="center"
          sx={{ flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}
          justifyContent="space-between"
        >
          <MUIFormControl
            sx={{
              width: { xs: '100%', sm: '100%', md: '48%' },
              marginBottom: 2,
              padding: '10px',
              borderRadius: '6px',
            }}
          >
            <MUIInputLabel id="gender-label">Gender</MUIInputLabel>
            <MUISelect
              labelId="gender-label"
              id="gender"
              name="gender"
              value={formData.gender}
              onChange={handleGenderChange}
              startAdornment={
                <MUIInputAdornment position="start">
                  <Wc size={10} />
                </MUIInputAdornment>
              }
            >
              <MUIMenuItem value="Male">Male</MUIMenuItem>
              <MUIMenuItem value="Female">Female</MUIMenuItem>
            </MUISelect>
          </MUIFormControl>

          <MUIFormControl
            sx={{
              width: { xs: '100%', sm: '100%', md: '48%' },
              marginBottom: 2,
              padding: '10px',
              borderRadius: '6px',
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDateTimePicker
                value={selectedDate}
                onChange={handleDateChange}
                format="YYYY-MM-DD HH:mm:ss"
                label="Schedule Shift (Optional)"
              />
            </LocalizationProvider>
          </MUIFormControl>
        </MUIStack>
        <MUITextField
          sx={{
            width: { xs: '100%', sm: '100%', md: '100%' },
            backgroundColor: 'custom.registerInput',
            marginBottom: 2,
            padding: '10px',
            borderRadius: '6px',
          }}
          variant="standard"
          placeholder="Write Details"
          InputProps={{ disableUnderline: true }}
          name="details"
          value={formData.details}
          onChange={handleDetailsChange}
        />
        <MUIStack
          direction="row"
          justifyContent="space-between"
          sx={{ marginTop: 2 }}
        >
          <MUIButton
            variant="contained"
            sx={{
              borderRadius: '61px',
              boxShadow: 'none',
              width: { xs: '100%', sm: 'auto', md: 'auto' },
            }}
            onClick={handleFormSubmit}
            disabled={isLoading}
          >
            {isLoading ? <MUICircularProgress size={24} /> : 'Request Now'}
          </MUIButton>
        </MUIStack>
        <MUISnackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          message={emptyMessage}
        />
      </MUIBox>
    </MUIThemeProvider>
  )
}

const sxStyles = {
  card: {
    zIndex: 1,
    position: 'absolute',
    top: 150,
    padding: '40px 25px',
    width: '660px',
  },
  heading: {
    fontWeight: '700',
    fontSize: '48px',
    lineHeight: '58px',
    color: 'custom.black',
  },
  summary: {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#757575',
    margonTop: 2,
    marginBottom: 3,
  },
  fullInput: {
    width: '100%',
    backgroundColor: 'custom.registerInput',
    marginBottom: 2,
    padding: '10px',
    borderRadius: '6px',
  },
  halfInput: {
    width: '48%',
    backgroundColor: 'custom.registerInput',
    marginBottom: 2,
    padding: '10px',
    borderRadius: '6px',
  },
  halfSelect: {
    width: '48%',
    marginBottom: 2,
    padding: '10px',
    borderRadius: '6px',
  },
  scheduleBtn: {
    borderRadius: '56px',
    backgroundColor: 'custom.registerInput',
    color: 'custom.black',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '19px',
  },
}
