import { api } from "main/configs/axios.config";

export const getDetails = async () => {
  const details = await api.get("sales/dashboard/details");
  return details;
};

export const getFacilities = async (page_number, page_size) => {
  const staff = await api.get(
    `sales/dashboard/facilites?page_number=${page_number}&page_size=${page_size}`
  );
  return staff;
};

export const getStaff = async (page_number, page_size) => {
  const staff = await api.get(
    `sales/dashboard/staff?page_number=${page_number}&page_size=${page_size}`
  );
  return staff;
};
