import React from 'react';
import { FormControl, Select, MenuItem } from '@mui/material';

function Dropdown({
  placeholder,
  value,
  onChange,
  options,
  background,
}) {
  return (
    <FormControl sx={{ m: 1, minWidth: 85 }}>
      <Select
        value={value || placeholder}
        onChange={onChange}
        displayEmpty
        id="demo-simple-select-standard"
        autoWidth
        sx={style.dropdown}
        style={{ background: background || '#ffffff' }}
      >
        <MenuItem value={placeholder}>{placeholder}</MenuItem>
        {options?.map((item, index) => (
          <MenuItem key={index} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

const style = {
  dropdown: {
    borderRadius: '12px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    height: '48px',
    color: 'secondary.main',
  },
};

export default Dropdown;
