import React, { useState } from 'react'
import styles from './ComapnyClientDashboard.module.css'
import { Grid, Tab, Tabs, Box } from '@mui/material'
import {
  CompanyClientCard,
  Dropdown,
  LateClockin,
  SearchInput,
  ShiftOverviewChart,
  TasksContainer,
  WeeklyCalendar,
} from '../../components'
import { Tune } from '@mui/icons-material'
import moment from 'moment'
import { clockin } from './Dummy'

function ComapnyClientDashboard() {
  const [tabs, setTabs] = useState('open')
  const [filter, setFilter] = useState({
    city: 'Choose city',
    role: 'Choose staff role',
  })
  const [selectedDate, setSelectedDate] = useState(moment())

  const handleTabs = (event, newValue) => {
    setTabs(newValue)
  }

  return (
    <Box className={styles.container}>
      <p style={{ color: '#8F95B2', marginTop: '20px' }}>
        UNDER DEVELOPMENT 👨‍💻
      </p>
    </Box>

    // <div className={styles.container}>
    //   <Grid container className={styles.gridContainer} columnGap={2}>
    //     <Grid item md={7.5} xs={12} className={styles.topGrid}>
    //       <div className={styles.filterRow}>
    //         <div className={styles.row}>
    //           <SearchInput placeholder="Search by shift, name, etc" />
    //           <Dropdown
    //             bgWhite={true}
    //             placeholder="Choose city"
    //             options={["New York", "Los Angeles", "Chicago"]}
    //             value={filter.city}
    //             onChange={(event) =>
    //               setFilter({ ...filter, city: event.target.value })
    //             }
    //           />
    //         </div>
    //         <div className={styles.filter}>
    //           <Tune className={styles.filterIcon} />
    //         </div>
    //       </div>

    //       <div className={styles.calendarCard}>
    //         <WeeklyCalendar
    //           shifts="434 Shift posted"
    //           selectedDate={selectedDate}
    //           onChange={(date) => {
    //             console.log(date);
    //             setSelectedDate(date);
    //           }}
    //         />

    //         <div className={styles.tabsRow}>
    //           <div className={styles.tabsView}>
    //             <Tabs
    //               value={tabs}
    //               onChange={handleTabs}
    //               indicatorColor="white"
    //               aria-label="secondary tabs"
    //             >
    //               <Tab
    //                 style={{
    //                   background: tabs === "open" && "#219653",
    //                   color: tabs === "open" && "white",
    //                 }}
    //                 className={styles.tab}
    //                 value="open"
    //                 label="Open"
    //               />
    //               <Tab
    //                 style={{
    //                   background: tabs === "hired" && "#6C5DD3",
    //                   color: tabs === "hired" && "white",
    //                 }}
    //                 className={styles.tab}
    //                 value="hired"
    //                 label="Hired"
    //               />
    //               <Tab
    //                 style={{
    //                   background: tabs === "completed" && "#FFA2C0",
    //                   color: tabs === "completed" && "white",
    //                 }}
    //                 className={styles.tab}
    //                 value="completed"
    //                 label="Completed"
    //               />
    //             </Tabs>
    //           </div>

    //           <div className={styles.filter}>
    //             <Tune className={styles.filterIcon} />
    //           </div>
    //         </div>

    //         <CompanyClientCard
    //           comapany="Umbrella Corporation"
    //           name="Dianne Russell"
    //           location="Revierside, California"
    //         />
    //         <CompanyClientCard
    //           comapany="Stark Industries"
    //           name="Jerome Bell"
    //           location="San Diego, California"
    //         />
    //         <CompanyClientCard
    //           comapany="ABC Care Center"
    //           name="Kathryn Murphy"
    //           location="Minneapolis, Miniesta "
    //         />
    //         <CompanyClientCard
    //           comapany="ABC Care Center"
    //           name="Kathryn Murphy"
    //           location="Minneapolis, Miniesta "
    //         />
    //       </div>

    //       <ShiftOverviewChart open="24" hired="68" cancelled="08" />
    //     </Grid>
    //     <Grid item md={4} xs={12} className={styles.topGrid}>
    //       <LateClockin data={clockin} />
    //       <TasksContainer
    //         tasks={[
    //           "12 facility cancel stafs",
    //           "Complete past one interviews",
    //           "Complete past one interviews over call",
    //         ]}
    //       />
    //     </Grid>
    //   </Grid>
    // </div>
  )
}

export default ComapnyClientDashboard
