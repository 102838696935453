// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Box, Button, Pagination, Typography } from '@mui/material';
import { ContactFacilityModal, FilterHeader } from '../../components';
import { FacilityLeadsService } from 'operational-sales-panel/services';
import { CountyOptions } from 'constants';
import { CapacityOptions } from 'constants';
import { CiLocationOn } from 'react-icons/ci';
import { BiPhoneCall } from 'react-icons/bi';
import { HiOutlineMail } from 'react-icons/hi';
import { BsPeople } from 'react-icons/bs';

function CompanyLeads() {
  const [open, setOpen] = useState();
  const [companyLeads, setCompanyLeads] = useState([]);
  const [companyLeadsModal, setCompanyLeadsModal] = useState();
  const [contactName, setContactName] = useState();
  const [contactStatus, setContactStatus] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(9);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState({
    search: '',
    county: '',
    capacity: null,
  });

  useEffect(() => {
    FacilityLeadsService.getCompanyLeads({
      currentPage,
      page_size: rowsPerPage,
      search: filter?.search,
      capacity: filter?.capacity,
      county: filter?.county,
    })
      .then(data => {
        setCompanyLeads(data);
        console.log(data);
      })

      .catch(err => console.log(err));
  }, [
    rowsPerPage,
    filter?.capacity,
    filter?.county,
    filter?.search,
    open,
    currentPage,
  ]);

  const onMarkAsContacted = () => {
    FacilityLeadsService.markContactedCompany({
      id: companyLeadsModal?.id,
      contact_name: contactName,
      contact_status: contactStatus,
    })
      .then(() => setOpen(false))
      .catch(err => console.log(err));
  };

  const onMarkAsNotInterested = () => {
    FacilityLeadsService.markNotInterestedCompany({
      id: companyLeadsModal?.id,
    })
      .then(() => setOpen(false))
      .catch(err => console.log(err));
  };

  const handleContactButtonClick = item => {
    setCompanyLeadsModal(item);
    setOpen(true);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <FilterHeader
        placeholderSearch="Search facility"
        value={filter.search}
        onChange={e => setFilter({ ...filter, search: e?.target?.value })}
        placeholderDropdown1="Capacity"
        dropdown1Options={CapacityOptions.capacity}
        dropdown1Value={filter.capacity}
        onChangeDropdown1={(e, value) =>
          setFilter({ ...filter, capacity: value })
        }
        placeholderDropdown2="County"
        dropdown2Options={CountyOptions.county}
        dropdown2Value={filter.county}
        onChangeDropdown2={(e, value) => {
          setFilter({ ...filter, county: value });
        }}
      />

      <Box sx={{ minHeight: '82vh', overflowX: 'hidden' }}>
        {companyLeads?.companies?.length === 0 ? (
          <Typography
            sx={{
              width: '100%',
              paddingLeft: '30px',
              paddingRight: '30px',
              marginTop: '15px',
              color: '#8694B0',
              fontSize: '20px',
            }}
          >
            No Data Avaialble!
          </Typography>
        ) : null}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            gap: '2%',
            justifyContent: {
              xs: 'flex-start',
              sm: 'flex-start',
              md: 'space-between',
              lg: 'flex-start',
            },
            paddingLeft: '30px',
            paddingRight: '30px',
            marginTop: '15px',
          }}
        >
          {companyLeads?.companies?.map((item, index) => {
            const [username, domain] =
              item?.email !== null ? item?.email?.split('@') : [null, null];
            return (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '12px',
                  padding: '18px',
                  backgroundColor: '#fff',
                  borderRadius: '6px',
                  boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.04)',
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: '48%',
                    lg: '32%',
                  },
                  flexWrap: 'wrap',
                  marginTop: '25px',
                  justifyContent: {
                    xs: 'space-between',
                    sm: 'space-between',
                    md: 'space-between',
                    lg: 'space-between',
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: '18px',
                      sm: '18px',
                      md: '18px',
                      lg: '20px',
                    },
                    color: '#0C0C0D',
                    fontWeight: '700',
                  }}
                >
                  {item?.company_name}
                </Typography>
                <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                  <CiLocationOn color="#3F5263" />
                  <Typography
                    sx={{
                      fontSize: '16px',
                      color: '#3F5263',
                      fontWeight: '400',
                    }}
                  >
                    {item?.city + item?.county ?? `,${item?.county}`}
                  </Typography>
                </Box>

                <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                  <BiPhoneCall color="#3F5263" />
                  <Typography
                    sx={{
                      fontSize: '16px',
                      color: '#3F5263',
                      fontWeight: '400',
                    }}
                  >
                    {item?.phone}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    gap: '5px',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  <HiOutlineMail color="#3F5263" />
                  <Typography
                    sx={{
                      fontSize: '16px',
                      color: '#3F5263',
                      fontWeight: '400',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {username ? username : 'NO EMAIL!'}
                  </Typography>
                  <Typography
                    display={username ? 'flex' : 'none'}
                    sx={{
                      fontSize: '16px',
                      color: '#3F5263',
                      fontWeight: '400',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      marginLeft: '-5px',
                    }}
                  >
                    @{domain}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: {
                      xs: 'flex-start',
                      sm: 'flex-start',
                      md: 'flex-start',
                      lg: 'center',
                    },
                    justifyContent: 'space-between',
                    flexDirection: {
                      xs: 'column',
                      sm: 'column',
                      md: 'column',
                      lg: 'row',
                    },
                  }}
                >
                  <Button
                    sx={{
                      marginLeft: {
                        xs: '0',
                        sm: '0',
                        md: '0',
                        lg: '10px',
                      },
                      color: '#D95624',
                      outlineColor: '#D95624',
                      borderColor: '#D95624',
                      width: { xs: '100%', sm: '100%', md: '100%', lg: 'auto' },
                      marginTop: {
                        xs: '10px',
                        sm: '10px',
                        md: '10px',
                        lg: '0',
                      },
                    }}
                    variant="outlined"
                    onClick={() => handleContactButtonClick(item)}
                  >
                    Contact
                  </Button>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>

      {companyLeads?.companies?.length ? (
        <Pagination
          sx={{ marginTop: '20px', marginBottom: '10px' }}
          count={companyLeads?.total_pages}
          page={currentPage}
          onChange={handlePageChange}
        />
      ) : null}

      {open && (
        <ContactFacilityModal
          item={companyLeadsModal}
          open={open}
          onClose={() => {
            setOpen(false);
            setCompanyLeadsModal();
          }}
          contactName={contactName}
          onChangeContactName={e => setContactName(e)}
          contactStatus={contactStatus}
          onChangeContactStatus={e => setContactStatus(e)}
          onMarkAsContacted={onMarkAsContacted}
          onMarkAsNotInterested={onMarkAsNotInterested}
          emailURL="/sales/company_leads/email"
          notesURL="/sales/company_leads/notes"
          smsURL="/sales/company_leads/sms"
          isNoAnswer={true}
          sendTemplate={true}
          baseURL={'/sales/company_leads/'}
        />
      )}
    </Box>
  );
}

export default CompanyLeads;
