import { api } from 'main/configs/axios.config'
import { TimeZoneHelper } from 'helper'

export const getScheduleDemo = async ({
  currentPage,
  page_size,
  no_meeting_link,
  date,
}) => {
  let url = `operation_panel/scheduled_demo`
  const queries = []
  if (currentPage) queries.push(`page_number=${currentPage}`)
  if (page_size) queries.push(`page_size=${page_size}`)
  if (no_meeting_link) queries.push(`no_meeting_link=${no_meeting_link}`)
  if (date) queries.push(`date=${date}`)
  queries.push(`time_zone=${TimeZoneHelper.getUserTimeZone()}`)

  const query = queries?.join('&')

  if (query?.length) url += `?${query}`

  const details = await api.get(url)
  return details
}
