// @ts-nocheck
import { api } from 'main/configs/axios.config'

export const getStaffLeads = async ({
  currentPage,
  page_size,
  city,
  state,
  search,
  is_school,
  hot_staff,
  hide_contacted,
}) => {
  let url = `sales/staff_leads/available_staff`
  const queries = []
  if (currentPage) queries.push(`page_number=${currentPage}`)
  if (page_size) queries.push(`page_size=${page_size}`)
  if (city) queries.push(`city=${city}`)
  if (search) queries.push(`search=${search}`)
  if (is_school) queries.push(`is_school=${is_school}`)
  if (hot_staff) queries.push(`hot_staff=${hot_staff}`)
  if (hide_contacted) queries.push(`hide_contacted=${hide_contacted}`)
  if (state) queries.push(`state=${state}`)

  const query = queries?.join('&')

  if (query?.length) url += `?${query}`

  const details = await api.get(url)
  return details
}

export const getStaffReferences = async ({ currentPage, page_size }) => {
  let url = `sales/staff_references/available_staff`
  const queries = []

  if (currentPage) queries.push(`page_number=${currentPage}`)
  if (page_size) queries.push(`page_size=${page_size}`)

  const query = queries?.join('&')

  if (query?.length) url += `?${query}`

  const details = await api.get(url)
  return details
}

export const getStaffLeadsContacted = async ({
  currentPage,
  page_size,
  city,
  search,
}) => {
  let url = `sales/staff_leads/contacted`
  const queries = []

  if (currentPage) queries.push(`page_number=${currentPage}`)
  if (page_size) queries.push(`page_size=${page_size}`)
  if (city) queries.push(`city=${city}`)
  if (search) queries.push(`search=${search}`)

  const query = queries?.join('&')

  if (query?.length) url += `?${query}`

  const details = await api.get(url)
  return details
}

export const getStaffLeadsNoAnswer = async ({
  currentPage,
  page_size,
  city,
  search,
}) => {
  let url = `sales/staff_leads/no_answer`
  const queries = []

  if (currentPage) queries.push(`page_number=${currentPage}`)
  if (page_size) queries.push(`page_size=${page_size}`)
  if (city) queries.push(`city=${city}`)
  if (search) queries.push(`search=${search}`)

  const query = queries?.join('&')

  if (query?.length) url += `?${query}`

  const details = await api.get(url)
  return details
}

export const getStaffReferencesNoAnswer = async ({
  currentPage,
  page_size,
  city,
  search,
}) => {
  let url = `sales/staff_references/no_answer`
  const queries = []

  if (currentPage) queries.push(`page_number=${currentPage}`)
  if (page_size) queries.push(`page_size=${page_size}`)
  if (city) queries.push(`city=${city}`)
  if (search) queries.push(`search=${search}`)

  const query = queries?.join('&')

  if (query?.length) url += `?${query}`

  const details = await api.get(url)
  return details
}

export const getStaffReferencesContacted = async ({
  currentPage,
  page_size,
}) => {
  let url = `sales/staff_references/contacted`
  const queries = []

  if (currentPage) queries.push(`page_number=${currentPage}`)
  if (page_size) queries.push(`page_size=${page_size}`)

  const query = queries?.join('&')

  if (query?.length) url += `?${query}`

  const details = await api.get(url)
  return details
}

export const getStaffLeadsNotInterested = async ({
  currentPage,
  page_size,
  city,
  search,
}) => {
  let url = `sales/staff_leads/not_interested`
  const queries = []

  if (currentPage) queries.push(`page_number=${currentPage}`)
  if (page_size) queries.push(`page_size=${page_size}`)
  if (city) queries.push(`city=${city}`)
  if (search) queries.push(`search=${search}`)

  const query = queries?.join('&')

  if (query?.length) url += `?${query}`

  const details = await api.get(url)
  return details
}

export const getStaffReferencesNotInterested = async ({
  currentPage,
  page_size,
}) => {
  let url = `sales/staff_references/not_interested`
  const queries = []

  if (currentPage) queries.push(`page_number=${currentPage}`)
  if (page_size) queries.push(`page_size=${page_size}`)

  const query = queries?.join('&')

  if (query?.length) url += `?${query}`

  const details = await api.get(url)
  return details
}

export const markContacted = async ({ id, contact_name, contact_status }) => {
  const contacted = await api.post(`sales/staff_leads/contacted/${id}`)
  return contacted
}

export const markNoAnswer = async ({ id }) => {
  const contacted = await api.post(`sales/staff_leads/no_answer/${id}`)
  return contacted
}

export const markStaffRefNoAnswer = async ({ id }) => {
  const contacted = await api.post(`sales/staff_references/no_answer/${id}`)
  return contacted
}

export const markNotInterested = async ({ id }) => {
  const notInterested = await api.post(`sales/staff_leads/not_interested/${id}`)
  return notInterested
}

export const markStaffRefContacted = async ({
  id,
  contact_name,
  contact_status,
}) => {
  const contacted = await api.post(`sales/staff_references/contacted/${id}`)
  return contacted
}

export const markStaffRefNotInterested = async ({ id }) => {
  const notInterested = await api.post(
    `sales/staff_references/not_interested/${id}`
  )
  return notInterested
}
