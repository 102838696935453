import axios, { AxiosError } from 'axios'
import { toast } from 'react-toastify'

export const storageTokenKeyName = 'access_token'
export const accountTypeKeyName = 'account_type'
export const accountPermissions = 'account_permissions'
export const contractSubmittedKeyName = 'contract_submitted'
const baseURL = process.env.REACT_APP_API_URL!
export const api = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
})

api.interceptors.request.use(config => {
  const accessToken = localStorage.getItem(storageTokenKeyName)

  const access_token: string | null = accessToken
    ? JSON.parse(accessToken)
    : null

  if (access_token && config && config.headers) {
    config.headers.Authorization = `Bearer ${access_token}`
    config.headers['Access-Control-Allow-Origin'] = '*'
  }

  return config
})
api.interceptors.response.use(
  response => {
    if (response?.config?.url?.includes('authentication/login')) {
      setToken(response.data.accessToken)
      setData(response.data)
    } else if (response?.config?.url?.includes('schools/login')) {
      setToken(response.data.accessToken)
      setData(response.data)
    }

    return response.data
  },
  (error: AxiosError<any>) => {
    // check conditions to refresh token
    if (error.response?.status === 401) {
      clearToken()
    } else {
      toast.error(error.response?.data?.message)
    }
    return Promise.reject(error)
  }
)
const setToken = (access_token: string) => {
  localStorage.setItem(storageTokenKeyName, JSON.stringify(access_token))
}
const setData = (data: any) => {
  localStorage.setItem(accountTypeKeyName, JSON.stringify(data.account_type))
  localStorage.setItem(
    contractSubmittedKeyName,
    JSON.stringify(data.contract_submitted)
  )
  localStorage.setItem(accountPermissions, JSON.stringify(data.access ?? []))
}

export const clearToken = () => {
  localStorage.removeItem(storageTokenKeyName)
  localStorage.removeItem(accountPermissions)

  // Router.replace("/login");
}

export const getAccessToken = () => {
  if (typeof window !== 'undefined') {
    const accessToken = localStorage.getItem(storageTokenKeyName)
    const access_token: string | null = accessToken
      ? JSON.parse(accessToken)
      : null
    if (access_token) {
      const isTokenExpired: boolean = checkTokenExpiration(access_token)
      if (!isTokenExpired) return access_token
    }
  }
}
const checkTokenExpiration = (token: string) => {
  if (!token) return true
  const accessToken = parseJwt(token)
  const now = Math.round(new Date().getTime() / 1000)
  return accessToken.exp <= now
}

const parseJwt = (token: string) => {
  if (!token) {
    return
  }
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace('-', '+').replace('_', '/')
  return JSON.parse(window.atob(base64))
}
