// @ts-nocheck
import React, { useState, useEffect } from 'react'
import styles from './CancelledShifts.module.css'
import { Grid, Tab, Tabs } from '@mui/material'
import {
  BlankHeader,
  FacilityView,
  TasksContainer,
  TimelineCard,
} from '../../components'
import { CancelledService } from 'operational-sales-panel/services'
import { TimeZoneHelper, DateHelper } from 'helper'

function CancelledShifts() {
  const [tabs, setTabs] = useState('FC')
  const [date, setDate] = useState(new Date())
  const [cancelled, setCancelled] = useState()
  const [details, setDetails] = useState()

  const handleTabs = (event, newValue) => {
    setTabs(newValue)
  }

  useEffect(() => {
    CancelledService.getCancelled({
      tab: tabs,
      date: DateHelper.dateFormater(date),
      time_zone: TimeZoneHelper.getUserTimeZone(),
    })
      .then(data => {
        setDetails()
        setCancelled(data)
      })
      .catch(err => console.log(err))
  }, [tabs, date])

  return (
    <div className={styles.container}>
      <BlankHeader />
      <Grid container className={styles.gridContainer} columnGap={2}>
        <Grid item md={8.5} xs={12} className={styles.topGrid}>
          <div className={styles.facilityView}>
            <Tabs
              value={tabs}
              onChange={handleTabs}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs"
              sx={{
                '& .MuiTabs-flexContainer': {
                  flexWrap: 'wrap',
                },
              }}
            >
              <Tab
                className={styles.tab}
                value="FC"
                label="Facility Cancelation"
              />
              <Tab
                className={styles.tab}
                value="IC"
                label="Individual Cancelation"
              />
              {/* <Tab
                className={styles.tab}
                value="CC"
                label="Company Cancelation"
              /> */}
            </Tabs>

            <FacilityView
              reports={cancelled}
              value={date}
              onChange={e => setDate(e)}
              onViewDetails={item => setDetails(item)}
              status="Canceled"
            />
          </div>
        </Grid>
        <Grid item md={3} xs={12} className={styles.topGrid}>
          <TimelineCard date={date} details={details} />
          <TasksContainer
            tasks={[
              '12 facility cancel stafs',
              'Complete past one interviews',
              'Complete past one interviews over call',
            ]}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default CancelledShifts
