import React from 'react'
import styles from './ShiftCard.module.css'
import Arrow from '../../assets/svg/leftArrow.svg'
import { Chip } from '@mui/material'

function ShiftCard({
  heading,
  subHeading,
  shiftDetails,
  phone,
  date,
  onClick,
}) {
  return (
    <div
      style={{ cursor: 'pointer' }}
      onClick={onClick}
      className={styles.card}
    >
      <div className={styles.heading}>{heading || 'Faclilty Name'}</div>
      <div className={styles.subHeading}>{subHeading || 'No Data'}</div>
      <div className={styles.row}>
        <div className={styles.shift}>{shiftDetails || 'No Address'}</div>
        <img src={Arrow} alt="next" className={styles.arrow} />
      </div>
      <div style={{ marginTop: '3px' }} className={styles.shift}>
        {phone || 'phone NO'}
      </div>

      <div className={styles.chipRow}>
        <Chip label="Enrolled" className={styles.chip} />
        {/* <div className={styles.date}>{date || 'date'}</div> */}
      </div>
    </div>
  )
}

export default ShiftCard
