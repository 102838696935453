import {
  AddSign,
  HomeCareLogin,
  HomeCareRegister,
  RegisterOtp,
  RequiredDetails,
  SignContract,
  ViewContract,
} from '../views'
import SubmitContract from 'homecare/views/SubmitContract/SubmitContract'

export const routes = [
  {
    name: 'Register',
    path: '/individual/register-healthcare',
    component: <HomeCareRegister />,
  },
  {
    name: 'Login',
    path: '/individual/healthcare-login',
    component: <HomeCareLogin />,
  },
  {
    name: 'Login',
    path: '/individual/healthcare-otp',
    component: <RegisterOtp />,
  },
  {
    name: 'Contract',
    path: '/individual/healthcare-contract',
    component: <ViewContract />,
  },
  {
    name: 'Required Details',
    path: '/individual/healthcare-required-details',
    component: <RequiredDetails />,
  },
  {
    name: 'Sign Contract',
    path: '/individual/healthcare-sign-contract',
    component: <SignContract />,
  },
  {
    name: 'Add Sign',
    path: '/individual/healthcare-add-sign',
    component: <AddSign />,
  },
  {
    name: 'Submit Contract',
    path: '/individual/healthcare-submit-contract',
    component: <SubmitContract />,
  },
]
