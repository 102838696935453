import React, { useEffect, useState } from 'react'
import { Box, Pagination, Checkbox, Typography, Button } from '@mui/material'
import { FilterHeader, ContactFacilityModal } from '../../components'
import StaffReferencesCard from 'operational-sales-panel/components/StaffReferencesCard/StaffReferencesCard'
import { StaffLeadsService } from 'operational-sales-panel/services'
import { CitiesOptions } from 'constants'

function StaffReferencesContacted() {
  const [open, setOpen] = useState(false)
  const [staffLeads, setStaffLeads] = useState()
  const [staffLeadsModal, setStaffLeadsModal] = useState()
  const [contactName, setContactName] = useState()
  const [contactStatus, setContactStatus] = useState()
  const [rowsPerPage, setRowsPerPage] = useState(9)
  const [filter, setFilter] = useState({
    search: '',
    city: '',
  })
  const [currentPage, setCurrentPage] = useState(1)

  const handlePageChange = (event, page) => {
    setCurrentPage(page)
  }

  useEffect(() => {
    StaffLeadsService.getStaffReferencesContacted({
      currentPage,
      page_size: rowsPerPage,
    })
      .then(data => setStaffLeads(data))
      .catch(err => console.log(err))
  }, [rowsPerPage, filter?.city, filter?.search, open, currentPage])

  const onMarkAsContacted = () => {
    StaffLeadsService.markStaffRefContacted({
      id: staffLeadsModal?.id,
    })
      .then(() => setOpen(false))
      .catch(err => console.log(err))
  }

  const onMarkAsNoAnswer = () => {
    StaffLeadsService.markStaffRefNoAnswer({
      id: staffLeadsModal?.id,
    })
      .then(() => setOpen(false))
      .catch(err => console.log(err))
  }

  const onMarkAsNotInterested = () => {
    StaffLeadsService.markStaffRefNotInterested({
      id: staffLeadsModal?.id,
    })
      .then(() => setOpen(false))
      .catch(err => console.log(err))
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <FilterHeader
        placeholderSearch="Search staff"
        value={filter.search}
        onChange={e => setFilter({ ...filter, search: e?.target?.value })}
        placeholderDropdown1="City"
        dropdown1Options={CitiesOptions.cities}
        dropdown1Value={filter.city}
        onChangeDropdown1={(e, value) => setFilter({ ...filter, city: value })}
        isNotActive={true}
      />

      <Box sx={{ minHeight: '82vh', overflowX: 'hidden' }}>
        {staffLeads?.staff?.length === 0 ? (
          <Typography
            sx={{
              marginTop: '20px',
              paddingLeft: '25px',
              color: '#8694B0',
              fontSize: '20px',
            }}
          >
            No Data Avaialble!
          </Typography>
        ) : null}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            gap: '2%',
            justifyContent: {
              xs: 'flex-start',
              sm: 'flex-start',
              md: 'space-between',
              lg: 'flex-start',
            },
            paddingLeft: '30px',
            paddingRight: '30px',
            marginTop: '15px',
          }}
        >
          {staffLeads?.staff?.map((item, index) => {
            return (
              <StaffReferencesCard
                key={item?.id}
                name={`${item.full_name}`}
                relationship={item?.relationship}
                role={item?.role}
                btnText="View details"
                onClick={() => {
                  setStaffLeadsModal(item)
                  setOpen(true)
                }}
              />
            )
          })}
        </Box>
      </Box>

      {staffLeads?.staff?.length ? (
        <Pagination
          sx={{ marginTop: '20px', marginBottom: '10px' }}
          count={staffLeads?.total_pages}
          page={currentPage}
          onChange={handlePageChange}
        />
      ) : null}

      {open && (
        <ContactFacilityModal
          item={staffLeadsModal}
          open={open}
          onClose={() => {
            setOpen(false)
            setStaffLeadsModal()
          }}
          contactName={contactName}
          onChangeContactName={e => setContactName(e)}
          contactStatus={contactStatus}
          onChangeContactStatus={e => setContactStatus(e)}
          onMarkAsContacted={onMarkAsContacted}
          onMarkAsNotInterested={onMarkAsNotInterested}
          onMarkAsNoAnswer={onMarkAsNoAnswer}
          isContact={true}
          smsURL="/sales/staff_references/sms"
          noChat={true}
        />
      )}
    </Box>
  )
}

export default StaffReferencesContacted
