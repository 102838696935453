// icons
import { ReactComponent as HomeIcon } from '../assets/svg/home.svg';
import { ReactComponent as HomeInactiveIcon } from '../assets/svg/homeInactive.svg';
import { ReactComponent as UsersIcon } from '../assets/svg/3-user.svg';
import { ReactComponent as UsersInactiveIcon } from '../assets/svg/3-userInactive.svg';
import { ReactComponent as DocumentIcon } from '../assets/svg/document.svg';
import { ReactComponent as DocumentInactiveIcon } from '../assets/svg/documentInactive.svg';
import { ReactComponent as CalendarIcon } from '../assets/svg/calendar.svg';
import { ReactComponent as CalendarInactiveIcon } from '../assets/svg/calendarInactive.svg';
// views
import {
  ApproveFacility,
  FacilityLeads,
  FacilityLeadsContacted,
  FacilityLeadsNotInterested,
  Home,
  Reports,
  StaffLeads,
  StaffLeadsContacted,
  StaffLeadsNotInterested,
  StaffLeadsNoAnswer,
  StaffReferencesNoAnswer,
} from '../views';
import SchoolLeads from 'operational-sales-panel/views/SchoolLeads';
import StaffReferences from 'operational-sales-panel/views/StaffReferences/StaffReferences';
import StaffReferencesContacted from 'operational-sales-panel/views/StaffReferencesContacted/StaffReferencesContacted';
import StaffReferencesNotInterested from 'operational-sales-panel/views/StaffReferencesNotInterested/StaffReferencesNotInterested';
import { can } from 'main/configs/roles.config';
import NoPermissions from 'main/views/noPermissions';
import { accountPermissions } from 'main/configs/axios.config';
import CompanyLeads from 'operational-sales-panel/views/CompanyLeads/CompanyLeads';
import CompanyLeadsContacted from 'operational-sales-panel/views/CompanyLeadsContacted/CompanyLeadsContacted';
import CompanyLeadsNotInterested from 'operational-sales-panel/views/CompanyLeadsNotInterested/CompanyLeadsNotInterested';

export const routes = [
  {
    name: 'Home',
    path: '/sales/dashboard',
    component: <Home />,
    icon: [HomeIcon, HomeInactiveIcon],
    menu: true,
  },
  {
    name: 'Facility Leads',
    path: '/sales/facility-leads',
    component: can('facility_leads') ? (
      <FacilityLeads />
    ) : (
      <NoPermissions />
    ),
    icon: [UsersIcon, UsersInactiveIcon],
    menu: true,
    more: [
      {
        name: 'Available Facilites',
        path: '/sales/facility-leads',
        component: can('facility_leads') ? (
          <FacilityLeads />
        ) : (
          <NoPermissions />
        ),
      },
      {
        name: 'Contacted',
        path: '/sales/facility-leads/contacted',
        component: can('facility_leads') ? (
          <FacilityLeadsContacted />
        ) : (
          <NoPermissions />
        ),
      },
      {
        name: 'Not Interested',
        path: '/sales/facility-leads/not-interested',
        component: can('facility_leads') ? (
          <FacilityLeadsNotInterested />
        ) : (
          <NoPermissions />
        ),
      },
    ],
  },

  {
    name: 'Staff Leads',
    path: '/sales/staff-leads',
    component: can('staff_leads') ? (
      <StaffLeads />
    ) : (
      <NoPermissions />
    ),
    icon: [UsersIcon, UsersInactiveIcon],
    menu: true,
    more: [
      {
        name: 'Available Staff Leads',
        path: '/sales/staff-leads',
        component: can('staff_leads') ? (
          <StaffLeads />
        ) : (
          <NoPermissions />
        ),
      },
      {
        name: 'Contacted',
        path: '/sales/staff-leads/contacted',
        component: can('staff_leads') ? (
          <StaffLeadsContacted />
        ) : (
          <NoPermissions />
        ),
      },
      {
        name: 'No Answer',
        path: '/sales/staff-leads/no-answer',
        component: can('staff_leads') ? (
          <StaffLeadsNoAnswer />
        ) : (
          <NoPermissions />
        ),
      },
      {
        name: 'Not Interested',
        path: '/sales/staff-leads/not-interested',
        component: can('staff_leads') ? (
          <StaffLeadsNotInterested />
        ) : (
          <NoPermissions />
        ),
      },
    ],
  },
  {
    name: 'Staff References',
    path: '/sales/staff-references',
    component: can('staff_references') ? (
      <StaffReferences />
    ) : (
      <NoPermissions />
    ),
    icon: [UsersIcon, UsersInactiveIcon],
    menu: true,
    more: [
      {
        name: 'Available Staff',
        path: '/sales/staff-references',
        component: can('staff_references') ? (
          <StaffReferences />
        ) : (
          <NoPermissions />
        ),
      },
      {
        name: 'Contacted',
        path: '/sales/staff-references/contacted',
        component: can('staff_references') ? (
          <StaffReferencesContacted />
        ) : (
          <NoPermissions />
        ),
      },
      {
        name: 'No Answer',
        path: '/sales/staff-references/no-answer',
        component: can('staff_references') ? (
          <StaffReferencesNoAnswer />
        ) : (
          <NoPermissions />
        ),
      },
      {
        name: 'Not Interested',
        path: '/sales/staff-references/not-interested',
        component: can('staff_references') ? (
          <StaffReferencesNotInterested />
        ) : (
          <NoPermissions />
        ),
      },
    ],
  },
  {
    name: 'School Leads',
    path: '/sales/school-leads',
    component: can('school_leads') ? (
      <SchoolLeads />
    ) : (
      <NoPermissions />
    ),
    icon: [UsersIcon, UsersInactiveIcon],
    menu: true,
    more: true,
  },
  {
    name: 'Company Leads',
    path: '/sales/company-leads',
    component: can('company_leads') ? (
      <CompanyLeads />
    ) : (
      <NoPermissions />
    ),
    icon: [UsersIcon, UsersInactiveIcon],
    menu: true,
    more: [
      {
        name: 'Available Companies',
        path: '/sales/company-leads',
        component: can('company_leads') ? (
          <CompanyLeads />
        ) : (
          <NoPermissions />
        ),
      },
      {
        name: 'Contacted',
        path: '/sales/company-leads/contacted',
        component: can('company_leads') ? (
          <CompanyLeadsContacted />
        ) : (
          <NoPermissions />
        ),
      },
      {
        name: 'Not Interested',
        path: '/sales/company-leads/not-interested',
        component: can('company_leads') ? (
          <CompanyLeadsNotInterested />
        ) : (
          <NoPermissions />
        ),
      },
    ],
  },
  {
    name: 'Reports',
    path: '/sales/Reports',
    component: can('reports') ? <Reports /> : <NoPermissions />,
    icon: [DocumentIcon, DocumentInactiveIcon],
    menu: true,
    more: true,
  },

  {
    name: 'Approve Facility',
    path: '/sales/approve-facility',
    component: can('approve_facility') ? (
      <ApproveFacility />
    ) : (
      <NoPermissions />
    ),
    icon: [CalendarIcon, CalendarInactiveIcon],
    menu: true,
  },
  {
    name: 'Fallback',
    path: '/',
    component: <Home />,
    icon: [HomeIcon, HomeInactiveIcon],
    menu: false,
  },
];
