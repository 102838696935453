import React, { useState, useEffect } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { HomeCareHeader, OtpInput } from "../../components";
import { ArrowBackOutlined } from "@mui/icons-material";

export default function RegisterOtp() {
  const [countDown, setCountDown] = useState(20);

  useEffect(() => {
    if (countDown > 0) {
      const interval = setInterval(() => {
        setCountDown(countDown - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [countDown]);

  return (
    <Box sx={sxStyles.container}>
      <HomeCareHeader heading="Register OTP" />

      <Box sx={sxStyles.form}>
        <Typography variant="h1" sx={sxStyles.heading}>
          Enter the 4 digit code sent to <br /> you at **********25
        </Typography>

        <OtpInput />
        <OtpInput />
        <OtpInput />
        <OtpInput />

        <Box sx={sxStyles.counter}>
          {countDown > 0 ? (
            <Typography sx={sxStyles.resend}>
              {`You can resend OTP in ${countDown} Second`}
            </Typography>
          ) : (
            <Button sx={sxStyles.resendBtn}>Resend OTP</Button>
          )}
        </Box>

        <TextField id="filled-basic" label="New password" sx={sxStyles.input} />

        <Box sx={sxStyles.row}>
          <ArrowBackOutlined size={20} />
          <Button variant="contained" disabled={true} sx={sxStyles.nextBtn}>
            Next
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

const sxStyles = {
  container: {
    flexGrow: 1,
    width: "100vw",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    marginTop: 15,
  },
  heading: {
    marginBottom: 1,
  },
  counter: {
    marginTop: 2,
  },
  resend: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "17px",
    color: "custom.gray",
  },
  resendBtn: {
    width: "110px",
    height: "37px",
    borderRadius: "58px",
    backgroundColor: "custom.button",
    color: "custom.black",
  },
  input: {
    backgroundColor: "custom.button",
    width: "335px",
    borderRadius: "6px",
    marginTop: 2,
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 5,
  },
  nextBtn: {
    width: "66px",
    height: "39px",
    borderRadius: "58px",
  },
};
