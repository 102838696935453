import { api } from 'main/configs/axios.config'

export const getFacilityDashboard = async () => {
  const json = await api.get(`operation_panel/facility_dashboard/facilities`)
  return json
}

export const getShiftDashboard = async () => {
  const json = await api.get(
    `operation_panel/facility_dashboard/shifts?date=date&time_zone=time zone`
  )
  return json
}
