// @ts-nocheck
import React from "react";
import {
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";

export default function ApproveFacilityHeader({
  heading,
  onApproveSelection,
  onApproveAll,
}) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box sx={matches ? style.container : style.mobContainer}>
      <Typography variant="h1">{heading}</Typography>

      <Box sx={style.btnRow}>
        <Button
          variant="outlined"
          color="primary"
          sx={style.btn}
          onClick={onApproveSelection}
        >
          Approve Selections
        </Button>
        <Button
          onClick={onApproveAll}
          variant="outlined"
          sx={[
            style.btn,
            {
              color: "custom.black",
              borderColor: "custom.black",
              marginLeft: "20px",
            },
          ]}
        >
          Approve All
        </Button>
      </Box>
    </Box>
  );
}

const style = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "30px",
    borderBottom: 1,
    borderBottomColor: "custom.border",
  },
  mobContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 10,
    borderBottom: 1,
    borderBottomColor: "custom.border",
  },
  btnRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  btn: {
    textTransform: "none",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "24px",
  },
};
