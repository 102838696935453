import React, { useState, useEffect } from 'react'
import styles from './Accounts.module.css'
import {
  TextField,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Grid,
  Snackbar,
  Alert,
} from '@mui/material'
import { TasksContainer } from '../../components'
import { api } from 'main/configs/axios.config'
import { useQuery } from '@tanstack/react-query'
import { getAccountData } from 'operational-sales-panel/callbacks'

function Accounts() {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [gender, setGender] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [isSuccessSnackbarOpen, setSuccessSnackbarOpen] = useState(false)
  const [isErrorSnackbarOpen, setErrorSnackbarOpen] = useState(false)

  //*************** START GET DATA FROM ACCOUNT ***************//
  const getAccount = useQuery({
    queryKey: ['account'],
    queryFn: getAccountData,
  })

  useEffect(() => {
    if (getAccount.isSuccess) {
      setEmail(account.email)
      setGender(account.gender)
      setName(account.full_name)
    }
  }, [])

  //? IF ERROR
  if (getAccount.isError) return <pre>{JSON.stringify(getAccount.error)}</pre>

  //? IF SUCESS
  const account = getAccount.data
  //*************** END GET DATA FROM ACCOUNT ***************//

  const handleNameChange = event => {
    setName(event.target.value)
  }

  const handleEmailChange = event => {
    setEmail(event.target.value)
  }

  const handleGenderChange = event => {
    setGender(event.target.value)
  }

  function clearInputs() {
    setName('')
    setEmail('')
    setGender('')
  }

  async function handleSaveChanges() {
    try {
      if (!name || !email || !gender) {
        setErrorSnackbarOpen(true)
        return
      }

      setLoading(true)
      await api.put(`operation_panel/account/`, {
        full_name: name,
        email,
        gender,
      })

      setSuccessSnackbarOpen(true)
      clearInputs()
      window.location.reload()
    } catch (error) {
      setErrorSnackbarOpen(true)
    } finally {
      setLoading(false)
    }
  }

  const handleSnackbarClose = () => {
    setSuccessSnackbarOpen(false)
    setErrorSnackbarOpen(false)
  }

  return (
    <Box sx={{ flexGrow: 1, paddingBottom: '50px' }}>
      <div className={styles.header} />

      <Grid container className={styles.gridContainer} columnGap={2}>
        <Grid mb="20px" item lg={6} xs={12} className={styles.leftGrid}>
          <h5 className={styles.heading}>Edit Account</h5>
          <div className={styles.row}>
            {/* <div className={styles.user}>
              <img src={User} alt="user" className={styles.userImg} />
              <div className={styles.col}>
                <Button
                  variant="outlined"
                  color="primary"
                  component="button"
                  sx={{
                    marginRight: '20px',
                    textTransform: 'initial',
                    fontWeight: 600,
                    borderWidth: 2,
                    '&:hover': {
                      borderWidth: 2,
                    },
                  }}
                >
                  Upload new picture
                </Button>
                <Button variant="text" color="secondary">
                  Remove picture
                </Button>
              </div>
            </div> */}
            <div style={{ width: '100%' }}>
              <TextField
                value={name}
                onChange={handleNameChange}
                id="firstname"
                label="First Name"
                variant="standard"
                color="secondary"
                className={styles.input}
              />
              <TextField
                value={email}
                onChange={handleEmailChange}
                id="email"
                label="Email"
                variant="standard"
                color="secondary"
                className={styles.input}
              />
              <FormControl variant="standard" className={styles.input}>
                <InputLabel
                  id="demo-simple-select-standard-label"
                  color="secondary"
                >
                  Gender
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="gender"
                  value={gender}
                  onChange={handleGenderChange}
                  label="Age"
                  color="secondary"
                >
                  <MenuItem value={'male'}>male</MenuItem>
                  <MenuItem value={'female'}>female</MenuItem>
                </Select>
              </FormControl>

              <div className={styles.btnRow}>
                <Button
                  variant="outlined"
                  color="secondary"
                  sx={{ marginRight: '20px', textTransform: 'inherit' }}
                  onClick={clearInputs}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    boxShadow: 'none',
                    color: 'white',
                    textTransform: 'inherit',
                  }}
                  onClick={handleSaveChanges}
                >
                  {isLoading ? 'Loading' : 'Save Changes'}
                </Button>
              </div>
            </div>
          </div>
        </Grid>

        <Grid item lg={3} xs={12} className={styles.grid}>
          <TasksContainer
            tasks={[
              '12 facility cancel stafs',
              'Complete past one interviews',
              'Complete past one interviews over call',
            ]}
          />
        </Grid>
      </Grid>

      <Snackbar
        open={isSuccessSnackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert severity="success" onClose={handleSnackbarClose}>
          Changes saved successfully!
        </Alert>
      </Snackbar>

      <Snackbar
        open={isErrorSnackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert severity="error" onClose={handleSnackbarClose}>
          All of the inputs are required.
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default Accounts
