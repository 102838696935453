// @ts-nocheck
import { api } from 'main/configs/axios.config';

export const getFacilityLeads = async ({
  currentPage,
  page_size,
  capacity,
  county,
  search,
}) => {
  let url = `sales/facility_leads/available_facilites`;
  const queries = [];

  if (currentPage) queries.push(`page_number=${currentPage}`);
  if (page_size) queries.push(`page_size=${page_size}`);
  if (capacity) queries.push(`capacity=${capacity}`);
  if (county) queries.push(`county=${county}`);
  if (search) queries.push(`search=${search}`);

  const query = queries?.join('&');

  if (query?.length) url += `?${query}`;

  const details = await api.get(url);
  return details;
};

export const getCompanyLeads = async ({
  currentPage,
  page_size,
  capacity,
  county,
  search,
}) => {
  let url = `sales/company_leads/available_company`;
  const queries = [];

  if (currentPage) queries.push(`page_number=${currentPage}`);
  if (page_size) queries.push(`page_size=${page_size}`);
  if (capacity) queries.push(`capacity=${capacity}`);
  if (county) queries.push(`county=${county}`);
  if (search) queries.push(`search=${search}`);

  const query = queries?.join('&');

  if (query?.length) url += `?${query}`;

  const details = await api.get(url);
  return details;
};

export const getFacilityLeadsContacted = async ({
  currentPage,
  page_size,
  capacity,
  county,
  search,
}) => {
  let url = `sales/facility_leads/contacted`;
  const queries = [];

  if (currentPage) queries.push(`page_number=${currentPage}`);
  if (page_size) queries.push(`page_size=${page_size}`);
  if (capacity) queries.push(`capacity=${capacity}`);
  if (county) queries.push(`county=${county}`);
  if (search) queries.push(`search=${search}`);

  const query = queries?.join('&');

  if (query?.length) url += `?${query}`;

  const details = await api.get(url);
  return details;
};

export const getCompanyLeadsContacted = async ({
  currentPage,
  page_size,
  capacity,
  county,
  search,
}) => {
  let url = `sales/company_leads/contacted`;
  const queries = [];

  if (currentPage) queries.push(`page_number=${currentPage}`);
  if (page_size) queries.push(`page_size=${page_size}`);
  if (capacity) queries.push(`capacity=${capacity}`);
  if (county) queries.push(`county=${county}`);
  if (search) queries.push(`search=${search}`);

  const query = queries?.join('&');

  if (query?.length) url += `?${query}`;

  const details = await api.get(url);
  return details;
};

export const getFacilityLeadsNotInterested = async ({
  currentPage,
  page_size,
  capacity,
  county,
  search,
}) => {
  let url = `sales/facility_leads/not_interested`;
  const queries = [];

  if (currentPage) queries.push(`page_number=${currentPage}`);
  if (page_size) queries.push(`page_size=${page_size}`);
  if (capacity) queries.push(`capacity=${capacity}`);
  if (county) queries.push(`county=${county}`);
  if (search) queries.push(`search=${search}`);

  const query = queries?.join('&');

  if (query?.length) url += `?${query}`;

  const details = await api.get(url);
  return details;
};

export const getCompanyLeadsNotInterested = async ({
  currentPage,
  page_size,
  capacity,
  county,
  search,
}) => {
  let url = `sales/company_leads/not_interested`;
  const queries = [];

  if (currentPage) queries.push(`page_number=${currentPage}`);
  if (page_size) queries.push(`page_size=${page_size}`);
  if (capacity) queries.push(`capacity=${capacity}`);
  if (county) queries.push(`county=${county}`);
  if (search) queries.push(`search=${search}`);

  const query = queries?.join('&');

  if (query?.length) url += `?${query}`;

  const details = await api.get(url);
  return details;
};

export const markContacted = async ({ id, contact_name, contact_status }) => {
  const contacted = await api.post(
    `sales/facility_leads/contacted/${id}?contact_name=${contact_name}&contact_status=${contact_status}`
  );
  return contacted;
};

export const markContactedCompany = async ({
  id,
  contact_name,
  contact_status,
}) => {
  const contacted = await api.post(
    `sales/company_leads/contacted/${id}?contact_name=${contact_name}&contact_status=${contact_status}`
  );
  return contacted;
};

export const markNotInterested = async ({ id }) => {
  const notInterested = await api.post(
    `sales/facility_leads/not_interested/${id}`
  );
  return notInterested;
};

export const markNotInterestedCompany = async ({ id }) => {
  const notInterested = await api.post(
    `sales/company_leads/not_interested/${id}`
  );
  return notInterested;
};
