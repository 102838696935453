import React, { Suspense, useEffect, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import {
  ChakraProvider,
  Flex,
  Spinner,
  StyleFunctionProps,
  extendTheme,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Text,
} from '@chakra-ui/react'
import Login from 'login'
import { ToastContainer } from 'react-toastify'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-toastify/dist/ReactToastify.css'
import { useSelector } from 'react-redux'
import { RootState } from 'main/store'
import { onAuthStateChanged } from 'firebase/auth'
import { firebaseAuth } from '../firebase'
import Register from 'register'
import { accountTypeKeyName } from 'main/configs/axios.config'
import { useAuth } from 'main/hooks'
import OperationalSalesApp from 'operational-sales-panel/App'
import HomeCareApp from 'homecare/App'
const FacilityRoute = React.lazy(() => import('modules/facility/route'))
const LandingRoute = React.lazy(() => import('modules/landing/route'))
const StaffCompanyRoute = React.lazy(
  () => import('modules/staff-company/route')
)
const HumanResourcesRoute = React.lazy(() => import('modules/hr/route'))
const SchoolRoutes = React.lazy(() => import('modules/school/route'))
const CompanyRoutes = React.lazy(() => import('modules/company/route'))

function App() {
  const user = useSelector((state: RootState) => state.auth.user)
  const navigate = useNavigate()
  const [accountType, setAccountType] = useState<any>()
  const { handleLogout } = useAuth()
  const [showAlert, setShowAlert] = useState(false)

  useEffect(() => {
    let moduleName: any = localStorage.getItem(accountTypeKeyName)
    if (moduleName) moduleName = JSON.parse(moduleName)
    setAccountType(moduleName)
  }, [user])

  const theme = extendTheme({
    components: {
      Heading: {
        baseStyle: (props: any) => ({
          color: 'gray.600',
        }),
      },
      Text: {
        baseStyle: (props: any) => ({
          color: 'gray.500',
        }),
      },
    },
    styles: {
      global: (props: StyleFunctionProps) => ({
        body: {
          color: 'gray.700',
        },
      }),
    },
    fonts: {
      heading: `'Public Sans', sans-serif`,
      body: `'Open Sans', sans-serif`,
    },
    colors: {
      heading: 'grey',
      body: 'grey',
    },
  })

  useEffect(() => {
    onAuthStateChanged(firebaseAuth, user => {})
  }, [])

  // iF USER is logged in the dashboard => redirect him to the right dashboard
  useEffect(() => {
    switch (accountType) {
      case 'human_resources':
        navigate('/human_resources/dashboard')
        break
      case 'school':
        navigate('/school/dashboard')
        break
      case 'individual':
        navigate('/individual/dashboard')
        break
      case 'sales':
        navigate('/sales/dashboard')
        break
      case 'operation_panel':
        navigate('/operation_panel/dashboard')
        break
      case 'facility':
        navigate('/facility/dashboard')
        break
      case 'company':
        navigate('/company/dashboard')
        break
      case 'staff':
        handleStaffTypeAccount()
        break
      default:
        break
    }
  }, [accountType])

  function handleStaffTypeAccount() {
    setTimeout(() => {
      handleLogout()
    }, 3000)
    setShowAlert(true)
  }

  return (
    <div>
      {['sales', 'operation_panel'].includes(accountType) ? (
        <OperationalSalesApp />
      ) : ['individual'].includes(accountType) ? (
        <HomeCareApp />
      ) : (
        <ChakraProvider theme={theme}>
          <Suspense
            fallback={
              <Flex
                width="100vw"
                height="100vh"
                alignItems="center"
                justifyContent="center"
              >
                <Spinner color="#E3022A" />
              </Flex>
            }
          >
            {accountType === 'facility' ? (
              <FacilityRoute />
            ) : accountType === 'school' ? (
              <SchoolRoutes />
            ) : (
              <>
                {['human_resources'].includes(accountType) && (
                  <HumanResourcesRoute />
                )}

                {['company'].includes(accountType) && <CompanyRoutes />}
                <LandingRoute />
                <StaffCompanyRoute />

                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<Register />} />
                </Routes>
              </>
            )}
          </Suspense>
          <ToastContainer />
          {showAlert && (
            <Alert pos="absolute" top="0" w="100%" status="warning">
              <Flex
                w="100%"
                flexDir={['column', 'column', 'row', 'row', 'row']}
                justifyContent="center"
                alignItems="center"
              >
                <AlertIcon />
                <AlertTitle>This is a Staff account</AlertTitle>
                <AlertDescription>
                  Please login with the Staff App
                </AlertDescription>
                <Text
                  ml="10px"
                  fontSize="22px"
                  fontWeight="bold"
                  opacity="0.6"
                  color="black"
                ></Text>
              </Flex>
            </Alert>
          )}
        </ChakraProvider>
      )}
    </div>
  )
}
export default App
