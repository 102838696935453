import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { HomeCareHeader, LinksFooter } from "../../components";

export default function HomeCareRegister() {
  const [radio, setRadio] = useState("Facility");

  const handleRadio = (e) => {
    setRadio(e.target.value);
  };

  return (
    <Box sx={sxStyles.container}>
      <HomeCareHeader heading="Register" />

      <FormControl sx={sxStyles.form}>
        <Typography variant="h1" sx={sxStyles.heading}>
          What's your phone number?
        </Typography>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel
            value="Facility"
            checked={radio === "Facility"}
            control={<Radio />}
            label="Facility"
            sx={[
              sxStyles.radioItem,
              {
                borderColor:
                  radio === "Facility" ? "primary.main" : "secondary.main",
              },
            ]}
            onChange={handleRadio}
          />
          <FormControlLabel
            checked={radio === "Individual"}
            value="Individual"
            control={<Radio />}
            label="Individual"
            sx={[
              sxStyles.radioItem,
              {
                borderColor:
                  radio === "Individual" ? "primary.main" : "secondary.main",
              },
            ]}
            onChange={handleRadio}
          />
        </RadioGroup>

        <TextField
          label="Enter you phone number"
          variant="outlined"
          sx={sxStyles.input}
        />
        <Button sx={sxStyles.btn} variant="contained">
          Continue
        </Button>

        <LinksFooter />
      </FormControl>
    </Box>
  );
}

const sxStyles = {
  container: {
    flexGrow: 1,
    width: "100vw",
    backgroundColor: "white",
  },
  form: {
    display: "flex",
    alignItems: "center",
    marginTop: 15,
  },
  heading: {
    marginBottom: 1,
  },
  radioItem: {
    width: "160px",
    border: "1px solid",
    borderRadius: "8px",
  },
  input: {
    width: "330px",
    margin: "15px 0px",
    marginLeft: -3,
    backgroundColor: "custom.button",
  },
  btn: {
    backgroundColor: "custom.black",
    width: "330px",
    height: "50px",
    marginBottom: "15px",
    marginLeft: -3,
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "19px",
  },
};
