// @ts-nocheck
import React, { useState, useEffect } from 'react'
import styles from './Dashboard.module.css'
import { Grid } from '@mui/material'
import {
  Dropdown,
  SearchInput,
  ShiftDetailsCard,
  ShiftOverviewCard,
  TasksContainer,
  WelcomingTopBar,
} from '../../components'
import { DashboardService } from 'operational-sales-panel/services'
import { useNavigate } from 'react-router-dom'

function Dashboard() {
  const navigate = useNavigate()

  const [details, setDetails] = useState()
  const [shifts, setShift] = useState()
  const [searchedStaff, setSearchedStaff] = useState()

  const [filter, setFilter] = useState({
    city: 'Choose city',
    role: 'Choose Role',
    cities: [],
    roles: [],
  })

  var searchValue

  useEffect(() => {
    getAll()
  }, [])

  const handleSearchChange = event => {
    searchValue = event.target.value.trim()

    const filteredStaff =
      searchValue !== ''
        ? searchedStaff.filter(
            staff =>
              (staff.first_name
                .toLowerCase()
                .includes(searchValue.toLowerCase()) ||
                staff.last_name
                  .toLowerCase()
                  .includes(searchValue.toLowerCase())) &&
              staff.city === filter.city &&
              staff.role === filter.role
          )
        : shifts

    setSearchedStaff(filteredStaff)
  }

  const getAll = async () => {
    const [details, staff] = await Promise.all([
      DashboardService.getDetails(),
      DashboardService.getStaff(),
    ])

    const citiesArr = staff?.map(item => item?.city)
    const cities = [...new Set(citiesArr)]

    const rolesArr = staff?.map(item => item?.role)
    const roles = [...new Set(rolesArr)]

    setFilter({ ...filter, cities, roles })

    setDetails(details)
    setShift(staff)
    setSearchedStaff(staff)
  }

  return (
    <div className={styles.container}>
      <WelcomingTopBar name="Jane Eyre" time="01:30 m" />
      <Grid container className={styles.gridContainer} columnGap={2}>
        <Grid item md={8} xs={12} className={styles.leftGrid}>
          <div className={styles.cardsRow}>
            <ShiftOverviewCard
              name="Live Shifts"
              count={details?.live_shifts}
              onClick={() => navigate('/operation_panel/clients')}
            />
            <ShiftOverviewCard
              name="Canceled  Shifts"
              count={details?.canceled_shifts}
              onClick={() => navigate('/operation_panel/cancelled-shifts')}
            />
            <ShiftOverviewCard
              name="Reported Shifts"
              count={details?.reported_shifts}
              onClick={() => navigate('/operation_panel/reported-shifts')}
            />
          </div>

          <div className={styles.column}>
            <div className={styles.filterRow}>
              <div className={styles.row}>
                <SearchInput
                  value={searchValue}
                  onChange={handleSearchChange}
                />
                <Dropdown
                  placeholder="Choose city"
                  options={filter?.cities}
                  value={filter.city}
                  onChange={event => {
                    handleSearchChange(event)
                    setFilter({ ...filter, city: event.target.value })
                  }}
                />
                <Dropdown
                  placeholder="Choose Role"
                  options={filter.roles}
                  value={filter.role}
                  onChange={event => {
                    handleSearchChange(event)
                    setFilter({ ...filter, role: event.target.value })
                  }}
                />
              </div>
              {/* <div className={styles.filter}>
                <Tune className={styles.filterIcon} />
              </div> */}
            </div>

            <div className={styles.tasksRow}>
              {searchedStaff?.map((item, index) => (
                <ShiftDetailsCard
                  key={index}
                  name={item.first_name + item.last_name}
                  nursId={item.phone}
                  location={`${item.city}, ${item.state}`}
                />
              ))}
            </div>
          </div>
        </Grid>
        <Grid item md={3.5} xs={12} className={styles.rightGrid}>
          <TasksContainer
            tasks={[
              '12 facility cancel stafs',
              'Complete past one interviews',
              'Complete past one interviews over call',
            ]}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default Dashboard
