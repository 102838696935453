// @ts-nocheck
import React, { useEffect, useState } from 'react'
import styles from './ReportedShifts.module.css'
import { Grid, Tab, Tabs } from '@mui/material'
import {
  BlankHeader,
  FacilityView,
  TasksContainer,
  TimelineCard,
} from '../../components'
import { ReportedShiftsService } from 'operational-sales-panel/services'
import { TimeZoneHelper, DateHelper } from 'helper'

function ReportedShifts() {
  const [tabs, setTabs] = useState('FR')
  const [date, setDate] = useState(new Date())
  const [reports, setReports] = useState()
  const [details, setDetails] = useState(false)

  const handleTabs = (event, newValue) => {
    setTabs(newValue)
    setDate(new Date())
  }

  useEffect(() => {
    ReportedShiftsService.getReports({
      tab: tabs,
      date: DateHelper.dateFormater(date),
      time_zone: TimeZoneHelper.getUserTimeZone(),
    })
      .then(data => setReports(data))
      .catch(err => console.log(err))
  }, [tabs, date])

  const onRefund = shiftID => {
    ReportedShiftsService.refundIndividual(shiftID)
      .then(res => {})
      .catch(res => console.log(res))
  }

  const onPayStaff = shiftID => {
    ReportedShiftsService.payStaff(shiftID)
      .then(res => {})
      .catch(res => console.log(res))
  }

  return (
    <div className={styles.container}>
      <BlankHeader />
      <Grid container className={styles.gridContainer} columnGap={2}>
        <Grid item md={8.5} xs={12} className={styles.topGrid}>
          <div className={styles.facilityView}>
            <Tabs
              value={tabs}
              onChange={handleTabs}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs"
            >
              <Tab className={styles.tab} value="FR" label="Facility Reports" />
              <Tab
                className={styles.tab}
                value="IR"
                label="Individual Reports"
              />
              {/* <Tab className={styles.tab} value="CR" label="Company Reported" /> */}
            </Tabs>

            <FacilityView
              reports={reports}
              value={date}
              onChange={e => setDate(e)}
              onViewDetails={item => setDetails(item)}
              status="Reported"
            />
          </div>
        </Grid>
        <Grid item md={3} xs={12} className={styles.topGrid}>
          <TimelineCard
            details={details}
            date={date}
            onRefund={shiftID => onRefund(shiftID)}
            onPayStaff={shiftID => onPayStaff(shiftID)}
          />
          <TasksContainer
            tasks={[
              '12 facility cancel stafs',
              'Complete past one interviews',
              'Complete past one interviews over call',
            ]}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default ReportedShifts
