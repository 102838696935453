// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { Box, Pagination, Typography } from '@mui/material'
import { StaffLeadCard, FilterHeader } from '../../components'
import { StaffLeadsService } from 'operational-sales-panel/services'
import { CitiesOptions } from 'constants'

function StaffLeadsNotInterested() {
  const [open, setOpen] = useState(false)
  const [staffLeads, setStaffLeads] = useState()
  const [rowsPerPage, setRowsPerPage] = useState(9)
  const [filter, setFilter] = useState({
    search: '',
    city: '',
  })
  const [currentPage, setCurrentPage] = useState(1)

  const handlePageChange = (event, page) => {
    setCurrentPage(page)
  }

  useEffect(() => {
    StaffLeadsService.getStaffLeadsNotInterested({
      currentPage,
      page_size: rowsPerPage,
      search: filter?.search,
      city: filter.city,
    })
      .then(data => setStaffLeads(data))
      .catch(err => console.log(err))
  }, [rowsPerPage, filter?.city, filter?.search, currentPage])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <FilterHeader
        placeholderSearch="Search staff"
        value={filter.search}
        onChange={e => setFilter({ ...filter, search: e?.target?.value })}
        placeholderDropdown1="City"
        dropdown1Options={CitiesOptions.cities}
        dropdown1Value={filter.city}
        onChangeDropdown1={(e, value) => setFilter({ ...filter, city: value })}
      />

      <Box sx={{ minHeight: '82vh', overflowX: 'hidden' }}>
        {staffLeads?.staff?.length === 0 ? (
          <Typography
            sx={{
              width: '100%',
              paddingLeft: '30px',
              paddingRight: '30px',
              marginTop: '15px',
              color: '#8694B0',
              fontSize: '20px',
            }}
          >
            No Data Avaialble!
          </Typography>
        ) : null}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            gap: '2%',
            justifyContent: {
              xs: 'flex-start',
              sm: 'flex-start',
              md: 'space-between',
              lg: 'flex-start',
            },
            paddingLeft: '30px',
            paddingRight: '30px',
            marginTop: '15px',
          }}
        >
          {staffLeads?.staff?.map((item, index) => {
            const [username, domain] =
              item?.email !== null ? item?.email?.split('@') : [null, null]
            return (
              <StaffLeadCard
                name={`${item.first_name} ${item.last_name}`}
                location={item?.city}
                location2={item?.country}
                onClick={() => setOpen(true)}
                noButton={true}
                username={username}
                domain={domain}
                phone={item?.phone}
              />
            )
          })}
        </Box>
      </Box>

      {staffLeads?.staff?.length ? (
        <Pagination
          sx={{ marginTop: '20px', marginBottom: '10px' }}
          count={staffLeads?.total_pages}
          page={currentPage}
          onChange={handlePageChange}
        />
      ) : null}

      {/* <ContactLeadStaff open={open} onClose={() => setOpen(false)} /> */}
    </Box>
  )
}

export default StaffLeadsNotInterested
