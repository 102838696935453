// @ts-nocheck
import * as React from 'react'
import { TextField, Autocomplete, FormControl } from '@mui/material'

export default function ComboBox({
  placeholder,
  options,
  value,
  onChange,
  backgroundColor,
}) {
  return (
    <FormControl sx={[style.container, { backgroundColor }]}>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={options}
        sx={[style.dropdown, { backgroundColor }]}
        value={value}
        onChange={onChange}
        renderInput={params => (
          <TextField sx={{ width: '150px' }} {...params} label={placeholder} />
        )}
      />
    </FormControl>
  )
}

const style = {
  container: {
    m: 1,
    minWidth: 120,
    maxWidth: 180,
    height: '48px',
    borderRadius: '12px',
  },
  dropdown: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: 'secondary.main',
  },
}
