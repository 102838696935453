// @ts-nocheck
import React from "react";
import styles from "./ShiftOverviewCard.module.css";
import { CardActions, Button } from "@mui/material";

function ShiftOverviewCard({ name, count, onClick }) {
  return (
    <div className={styles.card} onClick={onClick}>
      <p>{name}</p>
      <h6>{count || 0}</h6>
      <CardActions>
        <Button size="small">View</Button>
      </CardActions>
    </div>
  );
}

export default ShiftOverviewCard;
