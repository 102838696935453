import {
  getUser,
  login,
  logout,
  register,
  schoolLogin,
} from 'main/store/features/auth'
import { useAppDispatch } from 'main/hooks'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from 'main/store'
import { useNavigate } from 'react-router-dom'
import { accountTypeKeyName } from 'main/configs/axios.config'

const useAuth = () => {
  const dispatch = useAppDispatch()
  const state = useSelector((state: RootState) => state.auth, shallowEqual)
  const user = useSelector((state: RootState) => state.auth.user)

  const navigate = useNavigate()

  const handleLogin = (data: any) => {
    dispatch(login(data))
      .then(() => handleGetUser())
      .then((res: any) => {
        const moduleName: any = localStorage.getItem(accountTypeKeyName)
        res.payload && moduleName
          ? navigate(`/${JSON.parse(moduleName)}/dashboard`)
          : console.log('Pass OR Email Incorrect!')

        if (JSON.parse(moduleName) === 'sales') window.location.reload()
      })
  }

  const handleSchoolLogin = (data: any) => {
    dispatch(schoolLogin(data))
      .then(() => handleGetUser())
      .then((res: any) => {
        const moduleName = localStorage.getItem(accountTypeKeyName)
        res.payload && moduleName
          ? navigate(`/${JSON.parse(moduleName)}/dashboard`)
          : navigate(`/`)
      })
  }

  const handleLogout = () => {
    dispatch(logout()).then(() => {
      navigate('/login')
      window.location.reload()
    })
  }

  const handleSchoolLogout = () => {
    dispatch(logout()).then(() => {
      navigate('/schools_portal/login')
      window.location.reload()
    })
  }

  const handleGetUser = () => {
    return dispatch(getUser())
  }
  const handleRegister = (data: any) => {
    return dispatch(register(data))
  }
  return {
    state,
    user,
    handleLogin,
    handleGetUser,
    handleLogout,
    handleRegister,
    handleSchoolLogin,
    handleSchoolLogout,
  }
}
export default useAuth
