import React, { useEffect } from 'react'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { HomeCareHeader } from '../../components'
import { useNavigate } from 'react-router-dom'

export default function ViewContract() {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const navigate = useNavigate()

  function handleClick() {
    navigate('/individual/healthcare-sign-contract')
  }

  useEffect(() => {
    const savedData = sessionStorage.getItem('RequiredDetails')
    if (savedData) {
      const fullData = JSON.parse(savedData)
    } else {
      navigate('/individual/dashboard')
    }
  })

  return (
    <Box sx={sxStyles.container}>
      <HomeCareHeader heading="Contract" />

      <Box sx={sxStyles.cardContainer}>
        <Card sx={[sxStyles.card, { maxWidth: matches ? '575px' : '95%' }]}>
          <CardContent>
            <Typography
              sx={sxStyles.cardHeading}
              color="custom.black"
              gutterBottom
            >
              Contract
            </Typography>
            <Typography
              fontSize="18px"
              variant="body2"
              marginTop="24px"
              color="#6B6B6B"
            >
              We would like to inform you that in order to use our system and
              request shifts, it is required to sign a contract with your
              electronic signature. This contract outlines the terms and
              conditions of our service, and confirms your agreement to abide by
              them. <br /> <br />
              By signing the contract with your electronic signature, you
              acknowledge that you have read and understood the terms and
              conditions, and agree to comply with them. Your electronic
              signature serves as a legally binding confirmation of your
              acceptance of the terms and conditions.
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              onClick={handleClick}
              sx={{
                width: '100%',
                borderRadius: '56px',
                fontSize: '18px',
                padding: '15px',
                boxShadow: 'none',
              }}
              variant="contained"
            >
              view contract
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Box>
  )
}

const sxStyles = {
  container: {
    flex: 1,
    backgroundColor: 'background.default',
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 12,
    height: '70vh',
  },
  card: {
    padding: 2,
    boxShadow: '0px 0px 29px #C2C2C2',
    borderRadius: '16px',
  },
  cardHeading: {
    fontSize: 30,
    fontWeight: 600,
  },
  contractBtn: {
    width: '100%',
    borderRadius: '56px',
  },
}
