export const county = [
  "FRESNO",
  "LOS ANGELES",
  "GLENN",
  "STANISLAUS",
  "MARIN",
  "CONTRA COSTA",
  "SAN DIEGO",
  "PLACER",
  "ORANGE",
  "RIVERSIDE",
  "ALAMEDA",
  "SACRAMENTO",
  "TRINITY",
  "SAN LUIS OBISPO",
  "SANTA BARBARA",
  "SANTA CLARA",
  "KERN",
  "TULARE",
  "SAN MATEO",
  "SAN FRANCISCO",
  "NEVADA",
  "MODOC",
  "SAN BERNARDINO",
  "INYO",
  "BUTTE",
  "SAN JOAQUIN",
  "SONOMA",
  "VENTURA",
  "MERCED",
  "EL DORADO",
  "PLUMAS",
  "MONTEREY",
  "MADERA",
  "SANTA CRUZ",
  "SUTTER",
  "TEHAMA",
  "YOLO",
  "SHASTA",
  "SOLANO",
  "MENDOCINO",
  "NAPA",
  "MARIPOSA",
  "LASSEN",
  "AMADOR",
  "HUMBOLDT",
  "YUBA",
  "DEL NORTE",
  "IMPERIAL",
  "KINGS",
  "LAKE",
  "SAN BENITO",
  "SISKIYOU",
  "CALAVERAS",
  "TUOLUMNE",
  "COLUSA",
  "SIERRA",
];
