import React from 'react'

function ReceivedMessage({ profile, message, time }) {
  return (
    <div
      style={{
        padding: '20px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center',
        paddingTop: '0',
      }}
    >
      {/* {profile ? (
        <img
          src={profile}
          alt="user"
          style={{
            height: '38px',
            width: '38px',
            borderRadius: '100%',
            marginRight: '10px',
          }}
        />
      ) : (
        <div
          style={{
            height: '38px',
            width: '38px',
            borderRadius: '100%',
            marginRight: '10px',
          }}
        />
      )} */}
      <label
        style={{
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '24px',
          letterSpacing: '0.1px',
          padding: '15px 20px',
          position: 'relative',
          borderRadius: '20px',
          color: '#081735',
          border: '0.2px solid #8f95b2',
          maxWidth: '70%',
        }}
      >
        {message}
      </label>
      <label
        style={{
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '24px',
          letterSpacing: '0.1px',
          color: '#8f95b2',
          marginLeft: '10px',
        }}
      >
        {time}
      </label>
    </div>
  )
}

export default ReceivedMessage
