import { React, useState, useEffect } from 'react'
import {
  Card,
  Stack,
  Typography,
  CircularProgress,
  useMediaQuery,
  useTheme,
  IconButton,
  Divider,
  Box,
  Button,
  TextField,
  InputAdornment,
  Snackbar,
} from '@mui/material'

import { DashboardService } from 'homecare/services'
import { api } from 'main/configs/axios.config'
import { useQuery } from '@tanstack/react-query'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined'

export default function AddCard() {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  const [isLoading, setIsLoading] = useState(false)
  const [emptyMessage, setEmptyMessage] = useState('')
  const [open, setOpen] = useState(false)
  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const [formData, setFormData] = useState({
    billingName: '',
    cardNumber: '',
    cardExpMonth: '',
    cardExpYear: '',
    cardCvc: '',
  })

  const handleBillingChange = e => {
    setFormData({ ...formData, billingName: e.target.value })
  }

  const handleCardChange = e => {
    let cardNumber = e.target.value.replace(/ /g, '')
    cardNumber = cardNumber.replace(/(.{4})/g, '$1 ')
    setFormData({ ...formData, cardNumber })
  }

  const handleYearChange = e => {
    setFormData({ ...formData, cardExpYear: e.target.value })
  }

  const handleMonthChange = e => {
    setFormData({ ...formData, cardExpMonth: e.target.value })
  }

  const handleCvvChange = e => {
    setFormData({ ...formData, cardCvc: e.target.value })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    var isEmpty = !formData.billingName
      ? 'Card Name is require'
      : !formData.cardNumber
      ? 'Card Number is require'
      : !formData.cardExpYear
      ? 'Expire Year is require'
      : !formData.cardExpMonth
      ? 'Expire Month is require is require'
      : !formData.cardCvc
      ? 'CVV is require'
      : ''

    setEmptyMessage(isEmpty)
    if (isEmpty) {
      handleClick()
    } else {
      // console.log(formData.billingName)
      // console.log(formData.cardCvc)
      // console.log(formData.cardExpMonth)
      // console.log(formData.cardNumber.trim().replace(/\s/g, ''))
      // console.log(formData.cardExpYear)

      setIsLoading(true)
      try {
        await api.post(
          `individuals/connect-payment?billing_name=${formData.billingName}&card_number=${formData.cardNumber}&card_exp_month=${formData.cardExpMonth}&card_exp_year=${formData.cardExpYear}&card_cvc=${formData.cardCvc}`
        )

        setEmptyMessage('Done ✔')
        handleClick()
        setFormData({
          billingName: '',
          cardNumber: '',
          cardExpMonth: '',
          cardExpYear: '',
          cardCvc: '',
        })
      } catch (error) {
        setEmptyMessage(error.response.data.message)
        handleClick()
      }

      setIsLoading(false)
    }
  }

  return (
    <Card
      sx={{
        zIndex: 100,
        position: 'absolute',
        top: 150,
        padding: { xs: '12px', sm: '40px', md: '40px' },
        width: { xs: '95%', sm: '95%', md: '520px' },
        height: 'auto',
        maxHeight: '76vh',
        left: { xs: 10, sm: 10, md: 50 },
        boxShadow: 'none',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
          width: '10px',
          backgroundColor: '#F5F5F5',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#D2D2D2',
          borderRadius: '20px',
          border: '3px solid #F5F5F5',
        },
      }}
    >
      <Typography
        sx={{
          marginBottom: { xs: '15px', sm: '15px', md: '25px' },
          fontWeight: '700',
          fontSize: { xs: '1.5rem', sm: '1.5rem', md: '3rem' },
          lineHeight: '50px',
          color: 'custom.black',
        }}
      >
        Add Card
      </Typography>

      <TextField
        sx={{
          marginBottom: 2,
        }}
        variant="outlined"
        fullWidth
        placeholder="Card Holder Name"
        label="Card Holder Name"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccountCircleOutlinedIcon size={10} />
            </InputAdornment>
          ),
        }}
        name="billingName"
        value={formData.billingName}
        onChange={handleBillingChange}
      />

      <TextField
        sx={{
          marginBottom: 2,
        }}
        variant="outlined"
        fullWidth
        placeholder="**** **** **** ****"
        label="Card Number"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CreditCardOutlinedIcon size={10} />
            </InputAdornment>
          ),
        }}
        name="cardNumber"
        value={formData.cardNumber}
        onChange={handleCardChange}
      />

      <TextField
        sx={{
          marginBottom: 2,
        }}
        variant="outlined"
        fullWidth
        label="Expiration"
        disabled
        InputProps={{
          endAdornment: (
            <>
              <InputAdornment position="end">
                <TextField
                  size="small"
                  placeholder="MM"
                  label="MM"
                  onChange={handleMonthChange}
                  inputProps={{ maxLength: 2 }}
                />
              </InputAdornment>

              <InputAdornment position="end">
                <TextField
                  size="small"
                  placeholder="YY"
                  label="YY"
                  onChange={handleYearChange}
                  inputProps={{ maxLength: 2 }}
                />
              </InputAdornment>
            </>
          ),
        }}
      />

      <TextField
        sx={{
          marginBottom: 2,
        }}
        variant="outlined"
        fullWidth
        placeholder="CVC"
        InputProps={{
          startAdornment: <InputAdornment position="start">CVC</InputAdornment>,
        }}
        name="cvc"
        value={formData.cardCvc}
        onChange={handleCvvChange}
      />

      <Button
        disabled={isLoading}
        onClick={handleSubmit}
        variant="contained"
        type="submit"
      >
        {isLoading ? <CircularProgress size={24} /> : 'Add Card'}
      </Button>

      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={emptyMessage}
      />
    </Card>
  )
}

const sxStyles = {
  card: {
    zIndex: 1,
    position: 'absolute',
    top: 150,
    padding: '40px 25px',
    width: '660px',
  },
  heading: {
    fontWeight: '700',
    fontSize: '48px',
    lineHeight: '58px',
    color: 'custom.black',
  },
  summary: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#000000',
  },
  fullInput: {
    width: '100%',
    backgroundColor: 'custom.registerInput',
    marginBottom: 2,
    padding: '10px',
    borderRadius: '6px',
  },
  halfInput: {
    width: '48%',
    backgroundColor: 'custom.registerInput',
    marginBottom: 2,
    padding: '10px',
    borderRadius: '6px',
  },
  halfSelect: {
    width: '48%',
    marginBottom: 2,
    padding: '10px',
    borderRadius: '6px',
  },
  scheduleBtn: {
    borderRadius: '56px',
    backgroundColor: 'custom.registerInput',
    color: 'custom.black',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '19px',
  },
  detailsCard: {
    background: '#F6F6F6',
    padding: '16px',
    borderRadius: '6px',
    width: '100%',
  },
  subHeading: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#667684',
    marginBottom: 1,
  },
  details: {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#818181',
    marginBottom: 1,
    display: 'flex',
    alignItems: 'center',
  },
}
