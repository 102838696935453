import React, { useLayoutEffect, useRef, useState } from 'react'

function ChatCard({ selected, profile, name, message, onClick, unread }) {
  const ref = useRef(null)

  const [height, setHeight] = useState(0)
  const [width, setWidth] = useState(0)

  useLayoutEffect(() => {
    setWidth(ref.current.offsetWidth)
    setHeight(ref.current.offsetHeight)
  }, [])

  return (
    <div
      ref={ref}
      style={
        selected
          ? {
              borderRadius: '15px',
              padding: '15px 15px 10px 15px',
              marginTop: '5px',
              backgroundColor: '#6c5dd3',
              color: '#fff',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }
          : {
              borderRadius: '15px',
              padding: '15px 15px 10px 15px',
              marginTop: '5px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              position: 'relative',
              cursor: 'pointer',
            }
      }
      onClick={onClick}
    >
      {/* <img
        src={profile}
        alt="user"
        style={{
          height: '42px',
          width: '42px',
          borderRadius: '100%',
          marginRight: '10px',
        }}
      /> */}
      <div style={{ overflow: 'hidden' }}>
        <div
          style={{
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '20px',
          }}
        >
          {name}
        </div>
        <label
          style={{
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#8F95B2',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {message}
        </label>
      </div>
      {!selected && (
        <div
          style={{
            height,
            width,
            borderRadius: '15px',
            position: 'absolute',
            left: '0',
            paddingBottom: '5px',
            marginBottom: '5px',
          }}
          onClick={onClick}
        />
      )}
    </div>
  )
}

export default ChatCard
