export const getUserTimeZone = () => {
  const date = new Date();
  const offset = date.getTimezoneOffset();
  const sign = offset < 0 ? "+" : "-";
  const hours = Math.floor(Math.abs(offset) / 60)
    .toString()
    .padStart(2, "0");
  const minutes = Math.abs(offset % 60)
    .toString()
    .padStart(2, "0");
  return `${sign}${hours}:${minutes}`;
};
