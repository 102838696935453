import React, { useState } from 'react'
import styles from './MessageTopHeader.module.css'
import { IconButton } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { Modal, Box, Button, Snackbar, Alert } from '@mui/material'
import { api } from 'main/configs/axios.config'

function MessageTopHeader({
  profile,
  id,
  status,
  onClick,
  setCancel,
  userName,
  date,
}) {
  const [modalOpen, setModalOpen] = useState(false)

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  async function handleCancel() {
    try {
      await api
        .post(`operation_panel/messages/close-ticket?ticket_id=${id}`)
        .then(res => {
          closeModal()
          setCancel(true)
        })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Box
      sx={{
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        position: 'sticky',
        top: 0,
        zIndex: 10,
      }}
    >
      <div className={styles.container}>
        <div className={styles.row}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={onClick}
            sx={{ mr: 2, display: { lg: 'none' }, color: 'secondary' }}
          >
            <MenuIcon />
          </IconButton>
          {/* <div className={styles.imageWrapper}>
          <img src={profile} alt="user" />
          <div className={styles.onlineBadge} />
        </div> */}
          <div className={styles.flex}>
            <div className={styles.name}>{userName}</div>
            <div className={styles.date}>{date}</div>
            {/* <label className={styles.status}>{status}</label> */}
          </div>
          {/* <div className={styles.separator} />
        <div className={styles.online}>Online</div> */}
        </div>

        <button
          style={{
            backgroundColor: '#E74343',
            padding: '10px 25px',
            color: 'white',
            cursor: 'pointer',
            border: 'none',
          }}
          onClick={openModal}
        >
          Close Ticket
        </button>
        <Modal open={modalOpen} onClose={closeModal}>
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '10px',
            }}
          >
            <div style={{ fontSize: '20px' }}>
              Are you sure that you want to close this ticket?
            </div>
            <Button
              onClick={handleCancel}
              variant="contained"
              color="primary"
              style={{ marginTop: '20px', fontWeight: 'normal' }}
            >
              Confirm
            </Button>
          </div>
        </Modal>
      </div>
    </Box>
  )
}

export default MessageTopHeader
