import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  ListItemText,
  ListItemButton,
  Snackbar,
} from '@mui/material'
import { HomeCareHeader } from '../../components'
import { api } from 'main/configs/axios.config'
import { useNavigate } from 'react-router-dom'

export default function RequiredDetails() {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    patientName: '',
    q1: true,
    q2: true,
    q3: true,
    q4: true,
  })

  const [inputValue, setInputValue] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [emptyMessage, setEmptyMessage] = useState('')
  const [open, setOpen] = useState(false)

  const handleInputChange = event => {
    setInputValue(event.target.value)
    if (!event.target.value) {
      setAddress('')
    }
  }

  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const handleFirstNameChange = e => {
    setFormData({ ...formData, firstName: e.target.value })
  }

  const handleLastNameChange = e => {
    setFormData({ ...formData, lastName: e.target.value })
  }

  const handlePatientNameChange = e => {
    setFormData({ ...formData, patientName: e.target.value })
  }

  const handleQ1Change = e => {
    setFormData({ ...formData, q1: e.target.value === 'yes' })
  }

  const handleQ2Change = e => {
    setFormData({ ...formData, q2: e.target.value === 'yes' })
  }

  const handleQ3Change = e => {
    setFormData({ ...formData, q3: e.target.value === 'yes' })
  }

  const handleQ4Change = e => {
    setFormData({ ...formData, q4: e.target.value === 'yes' })
  }

  const handleFormSubmit = () => {
    var isEmpty = !formData.firstName
      ? 'First name is require'
      : !formData.lastName
      ? 'Last name is require'
      : !formData.patientName
      ? 'Patient Name is require'
      : !address
      ? 'Address is require'
      : ''

    setEmptyMessage(isEmpty)
    if (isEmpty) {
      handleClick()
    } else {
      const requiredDetails = {
        q1: formData.q1,
        q2: formData.q2,
        q3: formData.q3,
        q4: formData.q4,
        first_name: formData.firstName,
        last_name: formData.lastName,
        address: address,
        city: city,
        postal_code: postalCode,
        patient_name: formData.patientName,
      }
      sessionStorage.setItem('RequiredDetails', JSON.stringify(requiredDetails))
      navigate('/individual/healthcare-contract')
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.get(
        `location-suggestions?search=${inputValue}`
      )
      const data = await response
      setSuggestions(data)
    }

    fetchData()
  }, [inputValue])

  return (
    <Box sx={sxStyles.container}>
      <HomeCareHeader heading="Required Details" />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: { xs: '30px 16px', sm: '30px 16px', md: '64px' },
        }}
      >
        <Card sx={[sxStyles.card, { maxWidth: matches ? '620px' : '100%' }]}>
          <CardContent>
            <Typography
              sx={sxStyles.cardHeading}
              color="custom.black"
              gutterBottom
            >
              Required Details
            </Typography>

            <Stack direction="column">
              <TextField
                label="First name"
                variant="filled"
                sx={[sxStyles.input, { width: matches ? '335px' : '100%' }]}
                value={formData.firstName}
                onChange={handleFirstNameChange}
              />

              <TextField
                style={{
                  background: '#EEEEEE',
                }}
                label="Last name"
                variant="filled"
                sx={[sxStyles.input, { width: matches ? '335px' : '100%' }]}
                value={formData.lastName}
                onChange={handleLastNameChange}
              />

              <TextField
                label="Patient name"
                variant="filled"
                sx={[sxStyles.input, { width: matches ? '335px' : '100%' }]}
                value={formData.patientName}
                onChange={handlePatientNameChange}
              />

              <TextField
                label="Enter and select your location"
                variant="filled"
                sx={[sxStyles.input, { width: matches ? '335px' : '100%' }]}
                value={inputValue}
                onChange={handleInputChange}
              />

              {suggestions.length === 0 && (
                <Typography
                  sx={[
                    sxStyles.summary,
                    {
                      marginLeft: '15px',
                      marginBottom: '5px',
                      color: '#D0D0D0',
                      fontSize: '14px',
                    },
                  ]}
                >
                  No suggestions
                </Typography>
              )}
              {suggestions.map(suggestion => (
                <ListItemButton
                  style={{
                    marginBottom: '15px',
                    display: address ? 'none' : 'flex',
                  }}
                  key={suggestion.id}
                  onClick={() => {
                    setInputValue(suggestion?.place_name)
                    setAddress(suggestion?.place_name)
                    setCity(suggestion?.city)
                    setPostalCode(suggestion?.postal_code)
                  }}
                >
                  <ListItemText
                    key={suggestion.id}
                    primary={suggestion.place_name}
                  />
                </ListItemButton>
              ))}
            </Stack>

            {/* health questions */}
            <Box sx={{ marginBottom: 2, marginTop: 2 }}>
              <Typography variant="body2">Health questions</Typography>
              <Typography variant="body3">
                To Help us prevent the spread of COVID-19,we ask you to read
                this carefully and answer the questions below.
              </Typography>
            </Box>

            {/* question1 */}
            <Typography variant="body1">
              1- Are you experiencing any symptoms?
            </Typography>
            <RadioGroup
              row
              value={formData.q1 ? 'yes' : 'no'}
              onChange={handleQ1Change}
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>

            {/* question2 */}
            <Typography variant="body1">
              2- Has anyone in your household experienced any symptoms in the
              past 14 days? (Note:if the symptomatic person in your household
              has received a negative COVID-19 test result please answer 'NO')
            </Typography>
            <RadioGroup
              row
              value={formData.q2 ? 'yes' : 'no'}
              onChange={handleQ2Change}
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>

            {/* question3 */}
            <Typography variant="body1">
              3- In the past 14 days, have you been identified as a close
              contact of someone with suspected or confirmed COVID-19?
            </Typography>
            <RadioGroup
              row
              value={formData.q3 ? 'yes' : 'no'}
              onChange={handleQ3Change}
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>

            {/* question4 */}
            <Typography variant="body1">
              4- Have you travelled outside US in the past 14 days or been
              contact with someone who has travelled outside US in the past 14
              days?
            </Typography>
            <RadioGroup
              row
              value={formData.q4 ? 'yes' : 'no'}
              onChange={handleQ4Change}
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </CardContent>
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <Button
              onClick={handleFormSubmit}
              sx={sxStyles.contractBtn}
              variant="contained"
            >
              Next
            </Button>
          </CardActions>
        </Card>
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={emptyMessage}
      />
    </Box>
  )
}

const sxStyles = {
  container: {
    flexGrow: 1,
    backgroundColor: 'background.default',
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '64px',
  },
  card: {
    padding: 2,
    boxShadow: '0px 0px 80px #EFEFEF',
  },
  cardHeading: {
    fontSize: 30,
    fontWeight: 600,
    marginBottom: 5,
  },
  input: {
    marginBottom: 2,
    input: {
      backgroundColor: '#EEEEEE',
      outline: 'none',
      border: 'none',
    },
    '.css-1v499c1-MuiInputBase-root-MuiFilledInput-root:before': {
      borderBottom: 'none',
    },
    '.css-1v499c1-MuiInputBase-root-MuiFilledInput-root:hover:not(.Mui-disabled, .Mui-error):before':
      {
        borderBottom: 'none',
      },
  },
  contractBtn: {
    borderRadius: '56px',
    width: '175px',
  },
}
