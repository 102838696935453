import { accountPermissions } from 'main/configs/axios.config'

export function can(role) {
  let hasPermession
  if (!accountPermissions) {
    return
  } else {
    let roles = JSON.parse(localStorage.getItem(accountPermissions))
    if (!roles) {
      return
    }

    roles.forEach(per => {
      if (role === per) {
        hasPermession = true
        return
      }
    })
  }

  return hasPermession
}
