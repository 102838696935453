import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Collapse,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

export default function Menu({ icon, name, path, more }) {
  const location = useLocation();

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const active = location?.pathname === path;
  const subMenuActive =
    Array.isArray(more) &&
    more.some(item => location?.pathname === item?.path);

  const Icon = active ? icon[0] : icon[1];
  return (
    <>
      <ListItemButton
        sx={styles.menuItem}
        selected={active || subMenuActive}
        to={!more?.length && path}
        component={Link}
        onClick={handleClick}
      >
        <Icon style={{ marginRight: '15px' }} />
        <ListItemText primary={name} />
        {more?.length ? (
          open || subMenuActive ? (
            <ExpandLess />
          ) : (
            <ExpandMore />
          )
        ) : null}
      </ListItemButton>

      {/* collapsable menu */}
      {Array.isArray(more) && (
        <Collapse
          in={open || subMenuActive}
          timeout="auto"
          unmountOnExit
        >
          {more.map((item, index) => {
            const isActive = location?.pathname === item?.path;
            return (
              <ListItemButton
                component={Link}
                key={index}
                sx={{ pl: 6 }}
                to={item.path}
              >
                <ListItemText
                  primary={item.name}
                  sx={[
                    styles.subMenuItem,
                    {
                      color: isActive
                        ? 'primary.main'
                        : 'secondary.main',
                    },
                  ]}
                />
              </ListItemButton>
            );
          })}
        </Collapse>
      )}
    </>
  );
}

const styles = {
  menuItem: {
    borderRadius: 3,
    height: '50px',
    width: '232px',
    mb: 1,
    px: 2.5,
    fontSize: '14px',
    color: 'secondary.main',
    '&.Mui-selected': {
      backgroundColor: 'primary.main',
      color: 'white',
    },
    '&:hover': {
      color: 'primary.main',
    },
  },
  subMenuItem: {
    color: 'primary.main',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
  },
};
