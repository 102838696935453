// @ts-nocheck
import React, { useState } from "react";
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Select,
  MenuItem,
  Stack,
  Pagination,
  PaginationItem,
} from "@mui/material";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { Arrows } from "../../assets/svg";

export default function DataPagination({
  totalItems,
  count,
  rowsPerPage,
  setRowsPerPage,
  onClick,
}) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const [rows, setRows] = useState(rowsPerPage || 5);

  const handleChange = (event) => {
    setRows(event.target.value);
  };

  return (
    <Box sx={matches ? sxStyles.pagination : sxStyles.mobPagintaion}>
      <Typography variant="subtitle1" sx={{ marginBottom: !matches && "20px" }}>
        {totalItems || 0} items
      </Typography>

      <nav style={{ display: "flex" }}>
        <Pagination
          count={count}
          variant="outlined"
          onChange={onClick}
          renderItem={(item) => (
            <PaginationItem
              slots={{ previous: NavigateBefore, next: NavigateNext }}
              {...item}
              sx={sxStyles.paginationItem}
            />
          )}
        />
        <Box sx={sxStyles.filterRow}>
          <Select
            value={rows}
            onChange={(event) => {
              handleChange(event);
              setRowsPerPage(event);
            }}
            displayEmpty
            IconComponent={null}
            sx={sxStyles.select}
          >
            <MenuItem value={5}>05</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
          <Stack direction="column" alignItems="center" justifyContent="center">
            <Arrows />
          </Stack>
        </Box>
      </nav>
    </Box>
  );
}

const sxStyles = {
  paginationItem: {
    border: 0,
    color: "secondary.main",
    "&.MuiPaginationItem-previousNext": {
      border: 1,
      borderRadius: "8px",
    },
    "&.MuiPaginationItem-root.Mui-selected": {
      backgroundColor: "transparent",
      color: "primary.main",
      fontWeight: "700",
    },
  },
  pagination: {
    padding: "30px 20px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  mobPagintaion: {
    padding: "20px 5px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  filterRow: {
    display: "flex",
    height: "35px",
    alignItems: "center",
    border: "1px solid",
    borderColor: "secondary.main",
    borderRadius: "8px",
    marginLeft: "15px",
  },
  select: {
    ".MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&.Mui-focused": {
      border: "none",
      borderRadius: "0px",
      borderRight: "1px solid",
    },
    boxShadow: "none",
    height: "32px",
    borderRadius: "0px",
    borderRight: "1px solid",
    borderRightColor: "secondary.main",
    color: "secondary.main",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "24px",
  },
};
