import { api } from 'main/configs/axios.config'
import { TimeZoneHelper } from 'helper'
import moment from 'moment'

export const getActiveShifts = async () => {
  const activeShifts = await api.get(
    `individuals/shift/active-shift?time_zone=${moment()
      .local()
      .format('Z')
      .toString()}`
  )
  return activeShifts
}

// export const getActiveShifts = async () => {
//   const activeShifts = await api.get(
//     `individuals/shift/active-shift?time_zone=${TimeZoneHelper.getUserTimeZone()}`
//   )
//   return activeShifts
// }

export const getNotifications = async () => {
  const notifications = await api.get(
    `individuals/notifications?time_zone=${moment()
      .local()
      .format('Z')
      .toString()}`
  )
  return notifications
}

export const getActiveStaff = async () => {
  const staff = await api.get(`individuals/shift/staff-applying`)
  return staff
}

export const getPayment = async () => {
  const payment = await api.get(`individuals/connected-payments`)
  return payment
}

export const getMessages = async () => {
  const messages = await api.get(
    `messages?time_zone=${moment().local().format('Z').toString()}`
  )
  return messages
}

export const getUserData = async () => {
  const user = await api.get(`individuals/account`)
  return user
}

export const getHistory = async () => {
  const history = await api.get(
    `individuals/history?time_zone=${moment().local().format('Z').toString()}`
  )
  return history
}

export const getAccount = async () => {
  const account = await api.get(`individuals/account`)
  return account
}
