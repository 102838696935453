import { Box, Typography } from '@mui/material'
const AudioComponent = ({ recording }) => {
  const { dateCreated, startTime, mediaUrl } = recording

  // Convert date and time formats
  const createdDate = new Date(dateCreated)
  const formattedDate = createdDate.toLocaleDateString('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })
  const formattedTime = new Date(startTime).toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  })

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        pointerEvents: 'auto',
        justifyContent: 'space-around',
      }}
    >
      <Typography sx={{ marginRight: '16px' }}>{formattedDate}</Typography>
      <Typography sx={{ marginRight: '16px' }}>{formattedTime}</Typography>

      <audio
        style={{
          transition: 'none',
        }}
        src={mediaUrl}
        controls
      />
    </Box>
  )
}

export default AudioComponent
