import React from 'react'
import styles from './MessageInput.module.css'
import { Send } from '@mui/icons-material'

function MessageInput(props) {
  return (
    <div className={styles.inputContainer}>
      <div className={styles.container}>
        <div className={styles.row} style={{ width: '80%' }}>
          <textarea
            value={props.value}
            onChange={props.onChange}
            className={styles.input}
            placeholder="Send your message…"
          />
        </div>
        <div className={styles.row}>
          <button
            onClick={props.handleSubmit}
            style={{
              cursor: 'pointer',
            }}
            className={styles.send}
          >
            <Send className={styles.sendIcon} />
          </button>
        </div>
      </div>
    </div>
  )
}

export default MessageInput
