// @ts-nocheck
import styles from "./ProfileCard.module.css";
import { Work, Duotone, Task } from "../../assets/svg";

function ProfileCard({ data }) {
  return (
    <div className={styles.card}>
      <div className={styles.row}>
        <div className={styles.justRow}>
          <div>ID</div>
          <span>{`SHIFT #${data?.id}`}</span>
        </div>
        <span className={styles.clockedin}>CLOCKED IN</span>
      </div>

      {/* profile area */}
      <div className={styles.row}>
        <div className={styles.profileWrapper}>
          <img src={data?.staff?.staff_details?.profile_pic} alt="staff" />
          <div>{`${data?.staff?.staff_details?.first_name} ${data?.staff?.staff_details?.last_name}`}</div>
          <span>Staff</span>
        </div>

        <div className={styles.profileWrapper}>
          <img src={data?.client?.profile_pic} alt="user" />
          <div>{data?.client?.full_name}</div>
          <span>Client</span>
        </div>
      </div>

      <div className={styles.fieldRow}>
        <span>{`${data?.staff?.staff_distance_miles} miles`}</span>
        <span>{`${data?.staff?.mileage} mileage`}</span>
      </div>

      <div className={styles.fieldRow}>
        <span>
          <Task className={styles.fieldIcon} /> {`Tasks: ${data?.tasks}`}
        </span>
        <div>
          Total:{" "}
          <span
            className={styles.amount}
          >{`$${data?.invoice_rate_staff}`}</span>
        </div>
      </div>

      <div className={styles.fieldRow}>
        <span>
          <Work className={styles.fieldIcon} /> Role
        </span>
        <div>{data?.role}</div>
      </div>

      <div className={styles.fieldRow}>
        <span>
          <Duotone className={styles.fieldIcon} /> Applied Time
        </span>
        <div className={styles.time}>{data?.applied_time}</div>
      </div>

      <div className={styles.fieldRow}>
        <span>
          <Duotone className={styles.fieldIcon} /> Hired Time
        </span>
        <div className={styles.time}>{data?.hiring_time}</div>
      </div>

      <div className={styles.fieldRow}>
        <span>
          <Duotone className={styles.fieldIcon} /> Start Time
        </span>
        <div className={styles.time}>{data?.start_time}</div>
      </div>

      <div className={styles.fieldRow}>
        <span>
          <Duotone className={styles.fieldIcon} /> End Time
        </span>
        <div className={styles.time}>{data?.end_time}</div>
      </div>

      <div className={styles.fieldRow}>
        <span>
          <Duotone className={styles.fieldIcon} /> Clockin Time
        </span>
        <div className={styles.time}>{data?.clockin_time}</div>
      </div>

      <div className={styles.statusRow}>
        <div className={styles.badgeRow}>
          <div className={styles.ondutyBadge} />
          <span className={styles.label}>Clocked in</span>
        </div>
        <div className={styles.badgeRow}>
          <div className={styles.wayBadge} />
          <span className={styles.label}>On the way</span>
        </div>
      </div>
    </div>
  );
}

export default ProfileCard;
