import { Checkbox } from "@mui/material";
import React from "react";
import styles from "./TasksCard.module.css";
import {
  CheckBoxOutlined,
  CheckBoxOutlineBlankOutlined,
} from "@mui/icons-material";

function TasksCard({ task }) {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.bullet} />
        <div className={styles.task}>{task}</div>
      </div>
      <Checkbox
        className={styles.checkbox}
        icon={<CheckBoxOutlineBlankOutlined />}
        checkedIcon={<CheckBoxOutlined />}
        sx={{
          "&.Mui-checked": {
            color: "#081735",
          },
        }}
      />
    </div>
  );
}

export default TasksCard;
