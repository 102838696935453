import { TimeZoneHelper } from 'helper'
import { api } from 'main/configs/axios.config'

export const getOpendTickets = async () => {
  const tickets = await api.get(
    `operation_panel/messages/opened-tickets?time_zone=${TimeZoneHelper.getUserTimeZone()}`
  )
  return tickets
}

export const getAccountData = async () => {
  const admin = await api.get(`operation_panel/account`)
  return admin
}
