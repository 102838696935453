import { TextField } from "@mui/material";
import React from "react";

export default function OtpInput({ value, onChange }) {
  return (
    <TextField
      id="outlined-basic"
      variant="outlined"
      value={value}
      onChange={onChange}
      sx={sxStyles.input}
    />
  );
}

const sxStyles = {
  input: {
    width: "45px",
    backgroundColor: "custom.button",
    marginRight: "10px",
    textAlign: "center",
  },
};
