// @ts-nocheck
import { Box, Button } from '@mui/material'
import React, { useRef, useEffect } from 'react'
import ProgressCounter from '../ProgressCounter/ProgressCounter'
import styles from './TimelineCard.module.css'
import { DateHelper } from 'helper'

function TimelineCard({ details, date, onRefund, onPayStaff }) {
  const column1Ref = useRef(null) // Ref for the first column
  const column2Ref = useRef(null) // Ref for the second column
  const column3Ref = useRef(null) // Ref for the second column
  const column4Ref = useRef(null) // Ref for the second column

  useEffect(() => {
    // Function to calculate distance between two points
    const calculateDistance = (x1, y1, x2, y2) => {
      return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2))
    }

    // Get the positions of the two columns
    const column1Rect = column1Ref?.current?.getBoundingClientRect()
    const column2Rect = column2Ref?.current?.getBoundingClientRect()
    const column3Rect = column2Ref?.current?.getBoundingClientRect()
    const column4Rect = column2Ref?.current?.getBoundingClientRect()

    // Calculate the distance between the columns
    const distance = calculateDistance(
      column1Rect?.top,
      column2Rect?.top,
      column3Rect?.top,
      column4Rect?.top
    )
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.heading}>Details</div>
      {details ? (
        <div className={styles.cardBody}>
          <ProgressCounter
            ref={column1Ref}
            step={details?.requested_time && 1}
            currentStep={1}
            date={
              DateHelper.dayDateFormater(details?.requested_time) ||
              DateHelper.dayDateFormater(date)
            }
            time={DateHelper.getTimeFromDate(details?.requested_time)}
            label="Client Requests Care"
          />
          <ProgressCounter
            ref={column2Ref}
            step={details?.applied_time && 2}
            currentStep={2}
            date={
              DateHelper.dayDateFormater(details?.applied_time) ||
              DateHelper.dayDateFormater(date)
            }
            time={DateHelper.getTimeFromDate(details?.applied_time)}
            label="Staff Applied"
          />
          <ProgressCounter
            ref={column3Ref}
            step={details?.hiring_time && 3}
            currentStep={3}
            date={
              DateHelper.dayDateFormater(details?.hiring_time) ||
              DateHelper.dayDateFormater(date)
            }
            time={DateHelper.getTimeFromDate(details?.hiring_time)}
            label="Client Hired"
          />
          <ProgressCounter
            ref={column4Ref}
            last={true}
            step={details?.canceled_time && 4}
            currentStep={4}
            date={
              DateHelper.dayDateFormater(details?.canceled_time) ||
              DateHelper.dayDateFormater(date)
            }
            time={DateHelper.getTimeFromDate(details?.canceled_time)}
            label="Staff Canceled"
          />

          <div className={styles.row}>
            <div className={styles.text}>Hire time:</div>
            <div className={styles.text}>
              {DateHelper.getTimeFromDate(details?.hiring_time) ?? 'NO DATA'}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.text}>Start time:</div>
            <div className={styles.text}>
              {DateHelper.getTimeFromDate(details?.start_time)}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.text}>Report time:</div>
            <div className={styles.text}>
              {DateHelper.getTimeFromDate(details?.reported_time) ?? 'NO DATA'}
            </div>
          </div>

          {details?.cancelled_reason ||
            (details?.reason && (
              <>
                <div className={styles.subHeading}>Reason</div>
                <div className={styles.text}>
                  {details?.cancelled_reason || details?.reason}
                </div>
              </>
            ))}

          <Box
            sx={{ display: details?.cancelled_reason ? 'none' : 'flex' }}
            className={styles.row}
            style={{ marginTop: '20px' }}
          >
            <Button
              variant="outlined"
              className={`${styles.btn} ${styles.refund}`}
              onClick={onRefund}
            >
              Refund
            </Button>
            <Button
              variant="outlined"
              className={`${styles.btn} ${styles.payoff}`}
              onClick={onPayStaff}
            >
              Pay Staff
            </Button>
          </Box>
        </div>
      ) : (
        <div className={styles.cardBody} />
      )}
    </div>
  )
}

export default TimelineCard
