import { React, useState, useEffect } from 'react'
import {
  Button,
  Card,
  Stack,
  TextField,
  Typography,
  CircularProgress,
  useMediaQuery,
  useTheme,
  IconButton,
  Divider,
  Box,
  Snackbar,
} from '@mui/material'
import { DashboardService } from 'homecare/services'
import io from 'socket.io-client'

import { HiOutlineDocumentChartBar } from 'react-icons/hi2'
import { MdOutlineTune } from 'react-icons/md'
import { WiTime3, WiTime5 } from 'react-icons/wi'
import { FcEmptyFilter } from 'react-icons/fc'

import DateRangeIcon from '@mui/icons-material/DateRange'
import { api } from 'main/configs/axios.config'

import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import moment from 'moment'
import { useQuery } from '@tanstack/react-query'
import { Buffer } from 'buffer'
import StaffApplied from 'homecare/components/StaffApplied/StaffApplied'
import Arrive from '../../assets/img/arriving.png'
const baseURL = process.env.REACT_APP_API_URL
// SOCKET
const settings = {
  url: baseURL,
  path: '/tracking',
  transports: ['websocket', 'polling'],
  port: 8000,
  transportOptions: {
    polling: {
      extraHeaders: {
        'Access-Control-Allow-Origin': baseURL,
      },
    },
  },
  query: {
    token: localStorage.getItem('access_token') || '',
  },
  withCredentials: true,
  rejectUnauthorized: true,
  timeout: 20000,
  reconnection: true,
  reconnectionAttempts: 3,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  cors: {
    origin: baseURL,
  },
  secure: true,
  headers: {
    'Access-Control-Allow-Origin': baseURL,
  },
}

let socket

export default function ScheduledShifts() {
  const [isLoading, setIsLoading] = useState(false)
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  const [isOpen, setIsOpen] = useState(false)
  const [selectedDate, setSelectedDate] = useState(null)
  const [currentDate, setCurrentDate] = useState(null)

  const [connected, setConnected] = useState(false)
  const [trackingData, setTrackingData] = useState(null)

  const [isHireLoading, setHireIsLoading] = useState(false)
  const [emptyMessage, setEmptyMessage] = useState('')
  const [open, setOpen] = useState(false)
  const [imageSrc, setImageSrc] = useState('')
  const [currentShift, setCurrentShift] = useState('')

  const [show, setShow] = useState(false)
  const [track, setTrack] = useState(false)

  const handleClick = shift => {
    if (shift.staff_hired_id === null) {
      setShow(true)
      setTrack(false)
    } else {
      setShow(false)
      setTrack(true)
      if (connected) {
        socket.on('onClientWait', response => {
          setTrackingData(JSON.parse(response))
        })
      }
    }

    setCurrentShift(shift)
  }

  useEffect(() => {
    getShifts.refetch()
  }, [show, track])

  useEffect(() => {
    const token = localStorage.getItem('access_token')

    if (token) {
      socket = io(
        `${baseURL}?token=${JSON.parse(
          localStorage.getItem('access_token') || ''
        )}`,
        settings
      )

      const onConnect = () => {
        setConnected(true)
        // console.log('Connect')
      }

      const onDisconnect = () => {
        setConnected(false)
        // console.log('Disconnect')
      }

      const onConnectError = error => {
        console.log('connect_error', error?.message)
      }

      socket.on('connect', onConnect)
      socket.on('disconnect', onDisconnect)
      socket.on('connect_error', onConnectError)
    }

    return () => {
      if (socket) {
        socket.off('onClientWait')
        socket.disconnect()
      }
    }
  }, [])

  const handleDateChange = date => {
    const newDate = new Date(date)
    const year = newDate.getFullYear()
    const month = ('0' + (newDate.getMonth() + 1)).slice(-2)
    const day = ('0' + newDate.getDate()).slice(-2)
    const formattedDate = `${year}-${month}-${day}`
    setCurrentDate(formattedDate)
    setSelectedDate(date)
    setIsOpen(false)
    getShifts.refetch(formattedDate)
    setShow(false)
    setTrack(false)
  }

  const displayCurrentDate = () => {
    const newDate = new Date()
    const year = newDate.getFullYear()
    const month = ('0' + (newDate.getMonth() + 1)).slice(-2)
    const day = ('0' + newDate.getDate()).slice(-2)
    const formattedDate = `${year}-${month}-${day}`
    setCurrentDate(formattedDate)
    return formattedDate
  }

  const toggleDatePicker = () => {
    setIsOpen(!isOpen)
  }

  const getScheduledShifts = async () => {
    const scheduledShifts = await api.get(
      `individuals/shift/scheduled-shifts?time_zone=${moment()
        .local()
        .format('Z')
        .toString()}&date=${currentDate ?? displayCurrentDate()}`
    )
    return scheduledShifts
  }

  const getShifts = useQuery({
    queryKey: ['getScheduledShifts', currentDate ?? displayCurrentDate()],
    queryFn: getScheduledShifts,
    refetchOnWindowFocus: false,
    enabled: true,
  })

  useEffect(() => {
    if (!getShifts.isFetching && !getShifts.isError) {
      getShifts.refetch()
    }
  }, [])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  function displayShiftCard(shift) {
    return (
      <Box
        sx={{
          background: '#F6F6F6',
          padding: '16px',
          borderRadius: '6px',
          width: '100%',
          cursor: 'pointer',
          transition: 'all 0.2s ease-in-out',
          transform: 'scale(1)',
          '&:hover': {
            transform: 'scale(1.03)',
            backgroundColor: '#f2f2f2',
          },
          marginBottom: '20px',
        }}
        key={shift?.id}
        onClick={() => handleClick(shift)}
      >
        <Stack mb="8px" direction="row" justifyContent="space-between">
          <Typography sx={sxStyles.subHeading}>Shift Details</Typography>
        </Stack>
        <Stack mb="4px" direction="row" justifyContent="space-between">
          <Typography sx={sxStyles.details}>
            <HiOutlineDocumentChartBar size="22px" />
            <span style={{ marginLeft: '6px' }}>
              Tasks: {shift?.tasks?.length}
            </span>
          </Typography>
          <Typography sx={sxStyles.details}>
            Total:
            <span
              style={{
                color: '#F52A4C',
                fontWeight: 'bold',
                marginLeft: '3px',
              }}
            >
              ${shift?.invoice_rate}
            </span>
          </Typography>
        </Stack>
        <Stack mb="4px" direction="row" justifyContent="space-between">
          <Typography sx={sxStyles.details}>
            <MdOutlineTune size="22px" />
            <span style={{ marginLeft: '6px' }}>Role</span>
          </Typography>
          <Typography sx={sxStyles.details}>{shift?.role}</Typography>
        </Stack>
        <Stack mb="4px" direction="row" justifyContent="space-between">
          <Typography
            sx={{
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '19px',
              color: '#818181',
              marginBottom: 1,
              display: { xs: 'none', sm: 'flex', md: 'flex' },
              alignItems: 'center',
            }}
          >
            <WiTime3 size="22px" />
            <span
              style={{
                marginLeft: '6px',
              }}
            >
              Start time
            </span>
          </Typography>
          <Box
            sx={{
              display: { xs: 'flex', sm: 'none', md: 'none' },
            }}
          >
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '16px',
                lineHeight: '19px',
                color: '#818181',
                marginBottom: 1,
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <WiTime3 size="22px" />
              <span
                style={{
                  marginLeft: '6px',
                }}
              >
                Start:
              </span>
            </Typography>
          </Box>
          <Typography sx={sxStyles.details}>{shift?.start_time}</Typography>
        </Stack>
        <Stack mb="4px" direction="row" justifyContent="space-between">
          <Typography
            sx={{
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '19px',
              color: '#818181',
              marginBottom: 1,
              display: { xs: 'none', sm: 'flex', md: 'flex' },
              alignItems: 'center',
              display: { xs: 'none', sm: 'flex', md: 'flex' },
            }}
          >
            <WiTime5 size="22px" />
            <span style={{ marginLeft: '6px' }}>End time</span>
          </Typography>
          <Box sx={{ display: { xs: 'flex', sm: 'none', md: 'none' } }}>
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '16px',
                lineHeight: '19px',
                color: '#818181',
                marginBottom: 1,
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <WiTime5 size="22px" />
              <span
                style={{
                  marginLeft: '6px',
                }}
              >
                End:
              </span>
            </Typography>
          </Box>
          <Typography sx={sxStyles.details}>{shift?.end_time}</Typography>
        </Stack>

        <Typography sx={[sxStyles.details, { marginTop: 2 }]}>
          Note: You will pay ${shift?.invoice_rate} plus the mileage.
        </Typography>
      </Box>
    )
  }

  return (
    <>
      <Card
        sx={{
          zIndex: 100,
          position: 'absolute',
          top: 150,
          padding: { xs: '12px', sm: '40px', md: '40px' },
          width: { xs: '95%', sm: '95%', md: '520px' },
          minHeight: '500px',
          maxHeight: '70vh',
          left: { xs: 10, sm: 10, md: 50 },
          boxShadow: 'none',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: '10px',
            backgroundColor: '#F5F5F5',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#D2D2D2',
            borderRadius: '20px',
            border: '3px solid #F5F5F5',
          },
        }}
      >
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'relative',
            marginBottom: '10px',
          }}
        >
          <Typography sx={sxStyles.summary}>
            {currentDate ?? displayCurrentDate()}
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <IconButton
              sx={{
                position: 'relative',
                zIndex: '100',
              }}
              onClick={toggleDatePicker}
            >
              <DateRangeIcon sx={{ color: '#F52A4C' }} />
            </IconButton>

            {isOpen && (
              <DateCalendar
                sx={{
                  position: 'absolute',
                  zIndex: '50',
                  backgroundColor: '#F6F6F6',
                  right: 0,
                  top: 40,
                  boxShadow: '0px 0px 29px #C2C2C2',
                }}
                value={selectedDate}
                onChange={handleDateChange}
                format="YYYY/MM/DD"
              />
            )}
          </LocalizationProvider>
        </Stack>
        <Divider sx={{ bgcolor: 'primary.main', height: 2 }} />

        <Stack>
          {getShifts.isLoading ? (
            <Stack
              sx={{
                height: '500px',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Stack>
          ) : !getShifts.data.length ? (
            <Stack
              sx={{
                height: '500px',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{ fontSize: '22px', color: '#818181', fontWeight: '600' }}
              >
                No available shifts
              </Typography>
            </Stack>
          ) : (
            <Box sx={{ marginTop: '25px' }}>
              {getShifts.data.map(shift => displayShiftCard(shift))}
            </Box>
          )}
        </Stack>
      </Card>
      {show ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 100,
            top: { xs: 600, sm: 600, md: 0 },
            left: { xs: 0, sm: 0, md: 550 },
            width: { xs: '100%', sm: '100%', md: 'auto' },
          }}
        >
          <StaffApplied
            setShow={setShow}
            setTrack={setTrack}
            shift={currentShift}
          />
        </Box>
      ) : null}

      {track ? (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 100,
            top: { xs: 600, sm: 600, md: 0 },
            left: { xs: 0, sm: 0, md: 550 },
            width: { xs: '100%', sm: '100%', md: 'auto' },
            marginBottom: '50px',
          }}
        >
          <Card
            sx={{
              zIndex: 100,
              position: 'absolute',
              top: 150,
              padding: { xs: '12px', sm: '40px', md: '40px' },
              width: { xs: '95%', sm: '95%', md: '437px' },
              left: { xs: 10, sm: 10, md: 50 },
              boxShadow: 'none',
              overflow: 'auto',
              borderRadius: '25px',
              '&::-webkit-scrollbar': {
                width: '10px',
                backgroundColor: '#F5F5F5',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#D2D2D2',
                borderRadius: '20px',
                border: '3px solid #F5F5F5',
              },
            }}
          >
            <Box display="flex" gap="25px">
              <img src={Arrive} alt="Arriving image" />
              <Box display="flex" flexDirection="column">
                <Typography
                  sx={{
                    color: '#3F5263',
                  }}
                >
                  {trackingData?.client_street_name ?? 'Getting Information..'}
                </Typography>

                <Typography
                  sx={{
                    color: '#3F5263',
                    marginTop: '39px',
                  }}
                >
                  {trackingData?.staff_street_name ?? 'Getting Information..'}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                marginTop: '20px',
                backgroundColor: '#F5F6FA',
                display: 'flex',
                justifyContent: 'space-between',
                padding: '15px 30px',
                borderRadius: '15px',
              }}
            >
              <Typography sx={{ color: '#011629' }}>
                Nurse will arrive in
              </Typography>
              <Typography sx={{ color: '#E3032A', fontWeight: '500' }}>
                {trackingData?.arrive_in
                  ? trackingData?.arrive_in + ' minutes'
                  : 'Waiting on staff'}
              </Typography>
            </Box>

            <Box
              sx={{
                marginTop: '30px',
                display: 'flex',
                justifyContent: 'space-between',
                borderRadius: '15px',
              }}
            >
              <Typography sx={{ color: '#011629' }}>Estimated Time</Typography>
              <Typography sx={{ color: '#E3032A', fontWeight: '500' }}>
                {trackingData?.estimated_time ?? 'Waiting on staff'}
              </Typography>
            </Box>
          </Card>
        </Box>
      ) : null}
    </>
  )
}

const sxStyles = {
  card: {
    zIndex: 1,
    position: 'absolute',
    top: 150,
    padding: '40px 25px',
    width: '660px',
  },
  heading: {
    fontWeight: '700',
    fontSize: '48px',
    lineHeight: '58px',
    color: 'custom.black',
  },
  summary: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#000000',
  },
  fullInput: {
    width: '100%',
    backgroundColor: 'custom.registerInput',
    marginBottom: 2,
    padding: '10px',
    borderRadius: '6px',
  },
  halfInput: {
    width: '48%',
    backgroundColor: 'custom.registerInput',
    marginBottom: 2,
    padding: '10px',
    borderRadius: '6px',
  },
  halfSelect: {
    width: '48%',
    marginBottom: 2,
    padding: '10px',
    borderRadius: '6px',
  },
  scheduleBtn: {
    borderRadius: '56px',
    backgroundColor: 'custom.registerInput',
    color: 'custom.black',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '19px',
  },
  detailsCard: {
    background: '#F6F6F6',
    padding: '16px',
    borderRadius: '6px',
    width: '100%',
  },
  subHeading: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#000000',
    marginBottom: 1,
  },
  details: {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#818181',
    marginBottom: 1,
    display: 'flex',
    alignItems: 'center',
  },
  userImg: {
    height: '50px',
    width: '50px',
    borderRadius: '50%',
    marginRight: '15px',
    objectFit: 'cover',
  },
  btn: {
    width: '100px',
    borderRadius: '47px',
    marginTop: '20px',
  },
}
