import {Box, HStack, useRadio, useRadioGroup} from "@chakra-ui/react";
import React from "react";
type OptionType = {
  value: string;
  label: string;
};
type RadioButtonProps = {
  options: OptionType[];
  value: string | number;
  name: string;
  onChange: (value: string | number) => void;
  style?: Record<string, any>;
};
const RadioButton = (props: RadioButtonProps) => {
  const {options, value, onChange, name, style} = props;
  const {getRootProps, getRadioProps} = useRadioGroup({
    name: name,
    defaultValue: value.toString(),
    onChange: onChange,
  });
  const group = getRootProps();
  return (
    <HStack {...group} my={5} justifyContent='center'>
      {options.map((option: OptionType) => {
        const radio = getRadioProps({value: option.value});
        radio["aria-selected"] = option.value === value;
        return <RadioOption radioProps={radio} option={option} style={style} />;
      })}
    </HStack>
  );
};

const RadioOption = (props: any) => {
  const {option, radioProps, style} = props;
  const {getInputProps, getCheckboxProps} = useRadio(radioProps);
  const input = getInputProps();
  const checkbox = getCheckboxProps();
  return (
    <Box as='label'>
      <input {...input} />
      <Box
        {...checkbox}
        cursor='pointer'
        borderWidth='1px'
        rounded='full'
        boxShadow='sm'
        transition={"all 0.3s ease"}
        _checked={{
          bg: "#E3022A",
          color: "white",
          borderColor: "#E3022A",
        }}
        _hover={{
          bg: "#E3022A",
          color: "white",
          borderColor: "#E3022A",
        }}
        sx={style}
      >
        {option.value}
      </Box>
    </Box>
  );
};
export default RadioButton;
