import React, { useContext } from 'react'
import styles from './WelcomingTopBar.module.css'
import { useQuery } from '@tanstack/react-query'
import { getAccountData } from 'operational-sales-panel/callbacks'

function WelcomingTopBar({ name, time }) {
  //*************** START GET DATA FROM ACCOUNT ***************//
  const getAccount = useQuery({
    queryKey: ['account'],
    queryFn: getAccountData,
  })

  //? IF ERROR
  if (getAccount.isError) return <pre>{JSON.stringify(getAccount.error)}</pre>

  //? IF SUCESS
  const account = getAccount.data
  //*************** END GET DATA FROM ACCOUNT ***************//
  return (
    <div className={styles.header}>
      <div>
        <p>Hi {account?.full_name ?? 'LOADING..'},</p>
        <div>Welcome back👋</div>
      </div>
      {/* <div>
        <label className={styles.time}>{time || "time"}</label>
      </div> */}
    </div>
  )
}

export default WelcomingTopBar
