// @ts-nocheck
import { useState, useEffect } from 'react'
import { Box, CircularProgress, Button, Stack, Typography } from '@mui/material'
import {
  DashboardActiveRequestCard,
  DashboardRequestCard,
  HomeCareHeader,
  RequestHealthCareCardDashboard,
} from '../../components'
import 'mapbox-gl/dist/mapbox-gl.css'
import { DashboardService } from 'homecare/services'
import { useQuery } from '@tanstack/react-query'
import { Link } from 'react-router-dom'
import { api } from 'main/configs/axios.config'

export default function HealthCareDashboard() {
  const [activeShift, setActiveShift] = useState('')
  const [shiftState, setShiftState] = useState('')
  const [staffDetails, setStaffDetails] = useState()

  const getShifts = useQuery({
    queryKey: ['getShifts'],
    queryFn: DashboardService.getActiveShifts,
  })

  useEffect(() => {
    if (getShifts.isSuccess && getShifts.data) {
      setActiveShift(getShifts.data.status)
      setShiftState(getShifts.data.shift?.shift_status)
      setStaffDetails(getShifts.data.shift?.staff?.staff_details)
    }
  }, [getShifts.data, setActiveShift, setShiftState, setStaffDetails])

  useEffect(() => {
    if (activeShift || shiftState) {
      getShifts.refetch()
    }
  }, [activeShift, shiftState, getShifts.refetch])

  if (getShifts.isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100vw',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  if (getShifts.isError) {
    return <pre>{JSON.stringify(getShifts.error)}</pre>
  }

  return (
    <Box sx={sxStyles.container}>
      {activeShift === 'no_active_shift' && (
        <RequestHealthCareCardDashboard setActiveShift={setActiveShift} />
      )}
      {activeShift === 'active_shift' && shiftState === 'Waiting on Hire' && (
        <DashboardRequestCard
          setActiveShift={setActiveShift}
          data={getShifts.data}
        />
      )}
      {activeShift === 'active_shift' && shiftState !== 'Waiting on Hire' && (
        <DashboardActiveRequestCard
          data={getShifts.data}
          setActiveShift={setActiveShift}
        />
      )}
    </Box>
  )
}

const sxStyles = {
  container: {
    flex: 1,
    backgroundColor: 'background.default',
  },
  mapContainer: {
    height: '100vh',
    width: '100vw',
  },
  centerContainer: {
    height: '100vh',
    maxWidth: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  message: {
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '22px',
    marginBottom: '20px',
  },
  btn: {
    width: '300px',
    height: '50px',
    borderRadius: '57px',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '20px',
  },
  loading: {
    zIndex: 1,
    position: 'absolute',
    top: 350,
    left: 200,
  },
}
