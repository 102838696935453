import React, { useState, useEffect, useCallback, useRef } from 'react'
import styles from './Messages.module.css'
import { Send } from '@mui/icons-material'
import {
  Grid,
  Typography,
  Drawer,
  useMediaQuery,
  Tabs,
  Tab,
  Box,
  Button,
  Divider,
  Snackbar,
  Alert,
} from '@mui/material'
import {
  ChatCard,
  MessageTopHeader,
  ReceivedMessage,
  SendMessage,
} from '../../components'
import { styled } from '@mui/system'
import { useTheme } from '@mui/material/styles'
import io from 'socket.io-client'
import { useQuery } from '@tanstack/react-query'
import { getOpendTickets } from 'operational-sales-panel/callbacks'
import { api } from 'main/configs/axios.config'
import moment from 'moment'
import { TfiClose } from 'react-icons/tfi'
import { TimeZoneHelper } from 'helper'

const baseURL = process.env.REACT_APP_API_URL
const settings = {
  url: baseURL,
  path: '/help_center',
  transports: ['websocket', 'polling'],
  port: 8000,
  transportOptions: {
    polling: {
      extraHeaders: {
        'Access-Control-Allow-Origin': baseURL,
      },
    },
  },
  query: {
    token: localStorage.getItem('access_token') || '',
  },
  withCredentials: true,
  rejectUnauthorized: true,
  timeout: 20000,
  reconnection: true,
  reconnectionAttempts: 3,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  cors: {
    origin: baseURL,
  },
  secure: true,
  headers: {
    'Access-Control-Allow-Origin': baseURL,
  },
}

let socket

function Messages() {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('lg'))
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false)
  const messageContainerRef = useRef(null)

  const [chats, setChats] = useState([])
  const [selectedChat, setSelectedChat] = useState()
  const [selectedId, setSelectedId] = useState(null)
  const [selectedName, setSelectedName] = useState('')
  const [selectedDate, setSelectedDate] = useState('')
  const [selectedTab, setSelectedTab] = useState(0)
  const [message, setMessage] = useState('')
  const [openToast, setOpenToast] = useState(false)
  const [ticketsCancelled, setTicketsCancelled] = useState(false)
  const [toastAlert, setToastAlert] = useState('success')
  const [toastMessage, setToastMessage] = useState('')
  const [lastMessagesCheck, setLastMessagesCheck] = useState()

  const [loadTicketMessageStatus, setLoadTicketMessageStatus] = useState({
    state: 'loading',
  })

  const [loadMessagesStatus, setLoadMessagesStatus] = useState({
    state: 'loading',
  })

  const [sendMessageLoad, setSendMessageLoad] = useState({
    state: 'idle',
  })

  const getMessages = useCallback(
    async index => {
      try {
        await api
          .get(
            `operation_panel/messages/assigned-tickets?time_zone=${TimeZoneHelper.getUserTimeZone()}`
          )
          .then(response => {
            setLoadMessagesStatus({ ...loadMessagesStatus, state: 'sucess' })
            setChats(response)

            // Only update selectedId if it's not set yet (initial load)
            if (selectedId === null) {
              setSelectedId(response[0]?.id)
              setSelectedName(response[0]?.user_name)
              setSelectedDate(convertTo12HourFormat(response[0]?.ticket_date))
            }

            return response
          })
      } catch (error) {
        setLoadMessagesStatus({ ...loadMessagesStatus, state: 'error' })
      }
    },
    [selectedTab, ticketsCancelled, lastMessagesCheck]
  )

  const getTicketMessages = async () => {
    if (selectedId) {
      try {
        await api
          .get(
            `operation_panel/messages/ticket/messages?time_zone=${TimeZoneHelper.getUserTimeZone()}&ticket_id=${selectedId}`
          )
          .then(response => {
            setSelectedChat(response)
            setLoadTicketMessageStatus({
              ...loadTicketMessageStatus,
              state: 'sucess',
            })
            return response
          })
      } catch (error) {
        setLoadTicketMessageStatus({
          ...loadTicketMessageStatus,
          state: 'error',
        })
      }
    }
  }

  const convertTo12HourFormat = (dateTime: string) => {
    const inputDate = new Date(dateTime)
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    }
    const formattedDate = inputDate.toLocaleString('en-US', options)
    return formattedDate
  }

  useEffect(() => {
    getTicketMessages()
  }, [selectedId, lastMessagesCheck])

  const opendTickets = useQuery({
    queryKey: ['opendTickets'],
    queryFn: getOpendTickets,
  })

  async function assignTickets(id) {
    try {
      await api
        .post(`operation_panel/messages/assign-ticket?ticket_id=${id}`)
        .then(res => {
          setOpenToast(true)
          setToastAlert('success')
          setToastMessage('Ticket assigned successfully.')
          opendTickets.refetch()
        })
    } catch (error) {
      console.error(error)
      setOpenToast(true)
      setToastAlert('error')
      setToastMessage('An error occurred while assigning the ticket.')
    }
  }

  function handleSubmit() {
    if (message === '') {
      // HANDLE
    } else {
      // setSendMessageLoad({ ...sendMessageLoad, state: 'loading' })
      socket.open()
      socket.emit('send_message', {
        message: message,
        receiver: selectedChat?.ticket?.user_phone,
        ticket_id: selectedId,
        time_zone: moment().local().format('Z').toString(),
      })
      setMessage('')
      getTicketMessages()
      // setSelectedId(selectedId)
    }
  }

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleSubmit()
    }
  }

  useEffect(() => {
    getMessages()
  }, [getMessages])

  // useEffect(() => {
  //   getTicketMessages()
  // }, [getTicketMessages])

  useEffect(() => {
    setIsMobileDrawerOpen(false)
  }, [matches])

  useEffect(() => {
    const token = localStorage.getItem('access_token')

    if (token) {
      socket = io(
        `${baseURL}?token=${
          localStorage.getItem('access_token')
            ? JSON.parse(localStorage.getItem('access_token')?.toString() || '')
            : ''
        }`,
        settings
      )

      const onConnect = () => {
        // console.log('Connect')
      }

      const onDisconnect = () => {
        socket.off('connect', onConnect)
        socket.off('disconnect', onDisconnect)
        socket.off('connect_error', onConnectError)
        // console.log('Disconnect')
      }

      const onConnectError = (error: any) => {
        console.log('connect_error', error?.message)
      }

      socket.on('connect', onConnect)
      socket.on('disconnect', onDisconnect)
      socket.on('connect_error', onConnectError)
    }

    return () => {
      if (socket) {
        socket.disconnect()
      }
    }
  }, [])

  useEffect(() => {
    const messageContainer = messageContainerRef.current
    if (messageContainer) {
      messageContainer.scrollTop = messageContainer.scrollHeight
    }
  }, [selectedChat, selectedChat?.ticket?.help_messages])

  useEffect(() => {
    socket.on('new_message', (msg: string) => {
      // getTicketMessages()
      setSendMessageLoad({ ...sendMessageLoad, state: 'sucess' })
      setLastMessagesCheck(msg)
    })
  }, [socket, sendMessageLoad])

  function onChangeMessage(e: any) {
    setMessage(e.target.value)
  }

  const SideBar = () => (
    <>
      <div className={styles.column}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            overflowX: 'hidden',
          }}
        >
          <h4 className={styles.heading}>Message</h4>
          {isMobileDrawerOpen && (
            <TfiClose
              onClick={() => setIsMobileDrawerOpen(false)}
              style={{
                cursor: 'pointer',
              }}
            />
          )}
        </Box>
        <Tabs
          value={selectedTab}
          onChange={(event, newValue) => setSelectedTab(newValue)}
          centered
          variant="fullWidth"
        >
          <Tab
            sx={{
              borderBottom: '1px solid #8F95B2',
            }}
            label="Open Tickets"
          />
          <Tab
            sx={{
              borderBottom: '1px solid #8F95B2',
            }}
            label="Assigned Tickets"
          />
        </Tabs>
      </div>
      <Box
        sx={{
          ...(isMobileDrawerOpen && { minWidth: '100vw' }),
        }}
        className={styles.scroll}
      >
        {selectedTab === 1 &&
          // Open Tickets tab
          (loadMessagesStatus.state === 'loading' ? (
            <Typography>LOADING.....</Typography>
          ) : (
            <>
              {chats?.map((chat, index) => {
                console.log(chat)
                return (
                  <ChatCard
                    key={chat.id}
                    selected={selectedId === chat.id ? true : false}
                    name={chat.user_name}
                    message={chat.last_message?.message ?? 'No messages'}
                    onClick={() => {
                      setSelectedName(chat?.user_name)
                      setSelectedId(chat.id)
                      setSelectedDate(convertTo12HourFormat(chat?.ticket_date))
                    }}
                  />
                )
              })}
            </>
          ))}
        {selectedTab === 0 &&
          // Assigned Tickets tab
          (opendTickets.isLoading ? (
            <Typography>LOADING.....</Typography>
          ) : (
            opendTickets.data.map(ticket => (
              <>
                <Tickets
                  key={ticket.id}
                  id={ticket.id}
                  date={setSelectedDate(
                    convertTo12HourFormat(ticket.ticket_date)
                  )}
                  onClick={() => assignTickets(ticket.id)}
                />
                <Divider />
              </>
            ))
          ))}
      </Box>
    </>
  )

  return (
    <div className={styles.container}>
      <Grid container className={styles.gridContainer}>
        <Grid item lg={4} md={12} xs={12} className={styles.leftGrid}>
          {matches ? (
            <Drawer
              variant="temporary"
              open={isMobileDrawerOpen}
              onClose={() => setIsMobileDrawerOpen(false)}
              anchor="left"
            >
              <SideBar />
            </Drawer>
          ) : (
            <SideBar />
          )}
        </Grid>
        <Grid
          item
          lg={8}
          md={12}
          xs={12}
          className={styles.rightGrid}
          ref={messageContainerRef}
        >
          {loadMessagesStatus.state === 'loading' ? (
            <Typography> LOADING..... </Typography>
          ) : (
            <>
              <div className={styles.messageTopHeader}>
                <MessageTopHeader
                  userName={selectedName}
                  date={selectedDate}
                  setCancel={setTicketsCancelled}
                  id={selectedId}
                  onClick={() => setIsMobileDrawerOpen(true)}
                />
              </div>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: 'auto',
                  justifyContent: 'flex-end',
                  minHeight: '86vh',
                }}
              >
                <div>
                  {selectedChat?.ticket?.help_messages
                    ?.slice()
                    ?.map((item, index) => {
                      if (
                        item.sender_phone === selectedChat?.ticket?.admin_phone
                      ) {
                        return index === 0 ? (
                          <Box mt="10px">
                            <ReceivedMessage
                              key={`recieve-${item.id}`}
                              message={item.message}
                              time={moment(item.message_date).format('hh:mm A')}
                            />
                          </Box>
                        ) : (
                          <ReceivedMessage
                            key={`recieve-${item.id}`}
                            message={item.message}
                            time={moment(item.message_date).format('hh:mm A')}
                          />
                        )
                      } else
                        return index === 0 ? (
                          <Box mt="10px">
                            <SendMessage
                              key={`send-${item.id}`}
                              message={item.message}
                              time={moment(item.message_date).format('hh:mm A')}
                            />
                          </Box>
                        ) : (
                          <SendMessage
                            key={`send-${item.id}`}
                            message={item.message}
                            time={moment(item.message_date).format('hh:mm A')}
                          />
                        )
                    })}
                </div>
              </Box>
              <InputContainer>
                <Container>
                  <Row style={{ width: '80%' }}>
                    <Input
                      value={message}
                      onChange={onChangeMessage}
                      placeholder="Send your message…"
                      onKeyPress={handleKeyPress}
                    />
                  </Row>
                  <Row>
                    <SendBtn onClick={handleSubmit}>
                      <Send
                        sx={{
                          height: '16px !important',
                          width: '16px !important',
                        }}
                      />
                    </SendBtn>
                  </Row>
                </Container>
              </InputContainer>
            </>
          )}
        </Grid>
      </Grid>

      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        onClose={() => setOpenToast(false)}
      >
        <Alert severity={toastAlert} onClose={() => setOpenToast(false)}>
          {toastMessage}
        </Alert>
      </Snackbar>
    </div>
  )
}

const Tickets = props => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        padding: '20px 30px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: '600',
            color: '#081735',
          }}
        >
          {`Ticket #${props.id}`}
        </Typography>
        <Typography
          sx={{
            color: '#92929D',
            fontSize: '12px',
          }}
        >
          {props.date}
        </Typography>
      </Box>

      <Button
        variant="contained"
        sx={{
          backgroundColor: '#6C5DD3',
          fontSize: '14px',
          color: '#fff',
          fontWeight: '500',
          boxShadow: 'none',
        }}
        onClick={props.onClick}
      >
        Assign Ticket
      </Button>
    </Box>
  )
}

const InputContainer = styled('div')({
  paddingBottom: '20px',
  minHeight: '55px',
  maxHeight: '200px',
  backgroundColor: 'white',
  position: 'sticky',
  bottom: '0',
})

const Container = styled('div')({
  border: '1px solid var(--border)',
  borderRadius: '59px',
  padding: '0px 15px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '0px 15px',
})

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
})

const Input = styled('textarea')({
  resize: 'none',
  border: 'none',
  width: '100%',
  outline: 'none',
  minHeight: '20px !important',
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '20px',
  paddingTop: '15px',
})

const SendBtn = styled('button')({
  width: '32px !important',
  height: '32px !important',
  borderRadius: '4px',
  backgroundColor: 'var(--primary)',
  border: 'none',
  color: 'var(--white)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
})

export default Messages
