import React from 'react'
import styles from './SearchInput.module.css'
import { InputBase } from '@mui/material'
import { Search } from '@mui/icons-material'

function SearchInput({ placeholder, value, onChange, width }) {
  return (
    <div className={styles.searchContainer} style={{ width }}>
      <Search className={styles.icon} />
      <InputBase
        className={styles.input}
        placeholder={placeholder || 'Search by staff names...'}
        value={value}
        onChange={onChange}
      />
    </div>
  )
}

export default SearchInput
