import React from 'react'
import { Paper, Typography, useMediaQuery, useTheme } from '@mui/material'

function DashboardCard({ counter, text, visible }) {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Paper
      sx={[
        matches ? sxStyles.card : sxStyles.mobCard,
        { visibility: visible ? 'hidden' : 'visible' },
      ]}
      elevation={0}
    >
      <Typography sx={sxStyles.counter}>{counter}</Typography>
      <Typography sx={sxStyles.text}>{text}</Typography>
    </Paper>
  )
}

const sxStyles = {
  card: {
    height: '160px',
    width: '24%',
    backgroundColor: 'white',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mobCard: {
    marginBottom: '10px',
    width: '48%',
    height: '140px',
    backgroundColor: 'white',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
  },
  counter: {
    fontWeight: 700,
    fontSize: '36px',
    lineHeight: '48px',
    color: 'custom.black',
  },
  text: {
    fontWeight: 500,
    fontSize: '17px',
    lineHeight: '20px',
    color: 'custom.black',
    textAlign: 'center',
  },
}

export default DashboardCard
