import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Button,
  Center,
  Box,
  VStack,
  Image,
  Text,
  RadioGroup,
  Stack,
  Radio,
} from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { PhoneInput } from 'main/components';
import { api } from 'main/configs/axios.config';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

type RegisterFormProps = {
  onSubmit: (data: any) => void;
  setPhone: (Value: string) => void;
};

const RegisterForm = (props: RegisterFormProps) => {
  const { onSubmit, setPhone } = props;
  const { handleSubmit, control, formState, setError } = useForm();
  const navigate = useNavigate();
  const [accountType, setAccountType] = useState<string>('Individual');

  useEffect(() => {
    sessionStorage.setItem('accountType', accountType || 'Individual');
  }, [accountType]);

  const onSubmitForm = async (data: any) => {
    setPhone(data.phone.toString().trim().split(' ').join(''));

    const response = await api.post('authentication/register', {
      phone: data.phone.toString().trim().split(' ').join(''),
    });
    if (response) {
      onSubmit(data);
    }
  };

  return (
    <Center>
      <Box
        borderRadius="md"
        boxShadow={{ base: 'none', md: 'md' }}
        h="fit-content"
        px={10}
        py={20}
      >
        <Image
          style={{ transition: '.2s', cursor: 'pointer' }}
          _hover={{
            transform: 'scale(1.08)',
            transition: '.2s',
          }}
          onClick={() => {
            navigate('/');
          }}
          src="/assets/images/Logo.png"
          mx="auto"
        />
        <Text textAlign="center" my={3}>
          <i>
            A code will be sent to verify this phone number. Please enter the
            code received.
          </i>
        </Text>
        <form onSubmit={e => e.preventDefault()} style={{ minWidth: 250 }}>
          <VStack spacing={5}>
            <RadioGroup onChange={setAccountType} value={accountType}>
              <Stack
                direction="row"
                flexWrap="wrap"
                gap={['10px', '10px', '0']}
              >
                <Box
                  width={['100%', '100%', 'auto']}
                  sx={{
                    minWidth: 'fit-content',
                    border: '1px solid',
                    padding: 1.5,
                    borderRadius: '8px',
                    borderColor:
                      accountType === 'Individual' ? 'red' : '#BABABA',
                    paddingRight: '15px',
                  }}
                >
                  <Radio
                    width={['100%', '100%', 'auto']}
                    value="Individual"
                    colorScheme="red"
                  >
                    Individual/Family
                  </Radio>
                </Box>
                <Box
                  width={['100%', '100%', 'auto']}
                  sx={{
                    minWidth: 'fit-content',
                    border: '1px solid',
                    padding: 1.5,
                    borderRadius: '8px',
                    borderColor: accountType === 'Facility' ? 'red' : '#BABABA',
                    paddingRight: '15px',
                  }}
                >
                  <Radio value="Facility" colorScheme="red">
                    Facility
                  </Radio>
                </Box>
                <Box
                  width={['100%', '100%', 'auto']}
                  sx={{
                    minWidth: 'fit-content',
                    border: '1px solid',
                    padding: 1.5,
                    borderRadius: '8px',
                    borderColor: accountType === 'Company' ? 'red' : '#BABABA',
                    paddingRight: '15px',
                    margin: '0',
                  }}
                >
                  <Radio sx={{ margin: '0' }} value="Company" colorScheme="red">
                    Company
                  </Radio>
                </Box>
              </Stack>
            </RadioGroup>

            <Controller
              control={control}
              name="phone"
              rules={{ required: 'Field is required.' }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl isInvalid={!!error} isRequired>
                  <FormLabel>Phone:</FormLabel>
                  <PhoneInput
                    value={value}
                    onChange={onChange}
                    setError={(value?: string) =>
                      setError('phone', { message: value })
                    }
                  />

                  {/* <Input type='text' value={value} onChange={onChange} /> */}
                  {error && (
                    <FormErrorMessage>{error.message}</FormErrorMessage>
                  )}
                </FormControl>
              )}
            />
            <Button
              backgroundColor="#E3022A"
              color={'#fff'}
              variant="solid"
              onClick={handleSubmit(onSubmitForm)}
              isLoading={formState.isSubmitting}
              marginTop={4}
              width={'100%'}
              sx={{ '&:hover': { backgroundColor: '#CC0024' } }}
            >
              Register
            </Button>
            <Text mt={3}>
              Already have an account?
              <Text ml={1} color="red" as="span">
                <Link to="/login">Login</Link>
              </Text>
            </Text>
          </VStack>
        </form>
      </Box>
    </Center>
  );
};
export default RegisterForm;
