import React from 'react'
import { Button, Box, Typography } from '@mui/material'
import styled from '@emotion/styled'

import { HiOutlineUserGroup } from 'react-icons/hi'
import { BsBagDash } from 'react-icons/bs'

export default function StaffReferencesCard({
  name,
  onClick,
  btnText,
  noButton,
  relationship,
  role,
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        padding: '18px',
        backgroundColor: '#fff',
        borderRadius: '6px',
        boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.04)',
        position: 'relative',
        width: {
          xs: '100%',
          sm: '100%',
          md: '48%',
          lg: '48%',
          xl: '32%',
        },
        flexWrap: 'wrap',
        marginTop: '25px',
        justifyContent: {
          xs: 'space-between',
          sm: 'space-between',
          md: 'space-between',
          lg: 'space-between',
        },
      }}
    >
      <Typography
        sx={{
          fontSize: {
            xs: '18px',
            sm: '18px',
            md: '18px',
            lg: '22px',
          },
          color: '#0C0C0D',
          fontWeight: '700',
        }}
      >
        {name}
      </Typography>
      <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
        <HiOutlineUserGroup color="#3F5263" />
        <Typography
          sx={{
            fontSize: '16px',
            color: '#3F5263',
            fontWeight: '400',
          }}
        >
          {relationship}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: {
            xs: 'flex-start',
            sm: 'flex-start',
            md: 'flex-start',
            lg: 'flex-start',
            xl: 'center',
          },
          justifyContent: 'space-between',
          flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'column',
            lg: 'column',
            xl: 'row',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '5px',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <BsBagDash color="#3F5263" />
          <Typography
            sx={{
              fontSize: '16px',
              color: '#3F5263',
              fontWeight: '400',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {role}
          </Typography>
        </Box>
        {noButton ?? (
          <Button
            sx={{
              marginLeft: {
                xs: '0',
                sm: '0',
                md: '0',
                lg: '10px',
              },
              color: '#D95624',
              outlineColor: '#D95624',
              borderColor: '#D95624',
              width: {
                xs: '100%',
                sm: '100%',
                md: '100%',
                lg: '100%',
                xl: 'auto',
              },
              marginTop: {
                xs: '10px',
                sm: '10px',
                md: '10px',
                lg: '10px',
                xl: '0',
              },
            }}
            variant="outlined"
            onClick={onClick}
          >
            {btnText}
          </Button>
        )}
      </Box>
    </Box>
  )
}
