import React, { useEffect, useState } from 'react';
import { TimeZoneHelper } from 'helper';
import { BiTime } from 'react-icons/bi';
import { HiOutlineMail } from 'react-icons/hi';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import {
  Box,
  Pagination,
  Checkbox,
  Typography,
  Button,
  Modal,
  Backdrop,
  Fade,
  Paper,
  Snackbar,
  Input,
} from '@mui/material';
import { BlankHeader } from '../../components';
import { ScheduleDemoService } from 'operational-sales-panel/services';
import { api } from 'main/configs/axios.config';
import { IoIosCalendar } from 'react-icons/io';
import CircularProgress from '@mui/material/CircularProgress';

function ScheduledDemo() {
  const [openModal, setOpenModal] = useState(false);
  const [demos, setDemos] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(9);
  const [meetingLink, setMeetingLink] = useState('');
  const [openAddMeetingLinkModal, setOpenAddMeetingLinkModal] =
    useState(false);
  const [newMeetingLink, setNewMeetingLink] = useState('');
  const [date, setDate] = useState(null);
  const [loading, setLoading] = useState(false);

  const [noMeetingChecked, setNoMeetingChecked] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sending, setSending] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [id, setID] = useState('');

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleNoMeetingChange = event => {
    setNoMeetingChecked(event.target.checked);
  };

  useEffect(() => {
    setDemos();
    setLoading(true);
    const formattedDate = date
      ? dayjs(date).format('YYYY-MM-DD')
      : null;

    ScheduleDemoService.getScheduleDemo({
      currentPage,
      page_size: rowsPerPage,
      no_meeting_link: noMeetingChecked,
      date: formattedDate,
    })
      .then(data => {
        setDemos(data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }, [
    noMeetingChecked,
    currentPage,
    rowsPerPage,
    newMeetingLink,
    date,
  ]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <BlankHeader />

        <Box sx={{ minHeight: '82vh', overflowX: 'hidden' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '25px 0 0 25px',
              flexWrap: 'wrap',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: '20px',
                gap: '20px',
                flexWrap: 'wrap',
              }}
            >
              <DatePicker
                label="Filter by date"
                value={date}
                onChange={newValue => {
                  setDate(newValue);
                }}
                format="YYYY-MM-DD"
              />

              <div>
                <Checkbox
                  id="noMeeting"
                  checked={noMeetingChecked}
                  onChange={handleNoMeetingChange}
                  color="primary"
                />
                <label htmlFor="noMeeting">No meeting Link</label>
              </div>
            </div>
          </div>

          {loading && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
              }}
            >
              <CircularProgress />
            </Box>
          )}

          {demos?.facilities?.length === 0 && loading === false ? (
            <Typography
              sx={{
                width: '100%',
                paddingLeft: '30px',
                paddingRight: '30px',
                marginTop: '25px',
                color: '#8694B0',
                fontSize: '20px',
              }}
            >
              No Data Avaialble!
            </Typography>
          ) : null}
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              gap: '2%',
              justifyContent: {
                xs: 'flex-start',
                sm: 'flex-start',
                md: 'space-between',
                lg: 'flex-start',
              },
              paddingLeft: {
                xs: '15px',
                sm: '15px',
                md: '30px',
                lg: '30px',
              },
              paddingRight: {
                xs: '15px',
                sm: '15px',
                md: '30px',
                lg: '30px',
              },
              marginTop: '15px',
            }}
          >
            {demos?.facilities?.map((item, index) => {
              const [username, domain] =
                item?.email !== null
                  ? item?.email?.split('@')
                  : [null, null];

              const [date, time] = item.meeting_date.split(' ');

              const formattedTime = new Date(
                '1970-01-01T' + time + 'Z'
              ).toLocaleTimeString('en-US', {
                timeZone: 'UTC',
                hour12: true,
                hour: 'numeric',
                minute: 'numeric',
              });

              return (
                <Box
                  key={item.id}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px',
                    padding: '18px',
                    backgroundColor: '#fff',
                    borderRadius: '6px',
                    boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.04)',
                    position: 'relative',
                    width: {
                      xs: '100%',
                      sm: '100%',
                      md: '48%',
                      lg: '48%',
                      xl: '32%',
                    },
                    flexWrap: 'wrap',
                    marginTop: '25px',
                    justifyContent: {
                      xs: 'space-between',
                      sm: 'space-between',
                      md: 'space-between',
                      lg: 'space-between',
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: '18px',
                        sm: '18px',
                        md: '18px',
                        lg: '22px',
                      },
                      color: '#0C0C0D',
                      fontWeight: '700',
                    }}
                  >
                    {item.facility_name}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '5px',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}
                  >
                    <HiOutlineMail color="#3F5263" />
                    <Typography
                      sx={{
                        fontSize: '16px',
                        color: '#3F5263',
                        fontWeight: '400',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {username}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        color: '#3F5263',
                        fontWeight: '400',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        marginLeft: '-5px',
                      }}
                    >
                      @{domain}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      gap: '5px',
                      alignItems: 'center',
                    }}
                  >
                    <IoIosCalendar color="#3F5263" />
                    <Typography
                      sx={{
                        fontSize: '16px',
                        color: '#3F5263',
                        fontWeight: '400',
                      }}
                    >
                      {'Meeting date: ' + date}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      gap: '5px',
                      alignItems: 'center',
                    }}
                  >
                    <BiTime color="#3F5263" />
                    <Typography
                      sx={{
                        fontSize: '16px',
                        color: '#3F5263',
                        fontWeight: '400',
                      }}
                    >
                      {'Meeting time: ' + formattedTime}
                    </Typography>
                  </Box>

                  {item.meeting_link ? (
                    // Display "View Meeting Link" button when meeting_link is not empty
                    <Button
                      sx={{
                        marginLeft: {
                          xs: '0',
                          sm: '0',
                          md: '0',
                          lg: '10px',
                        },
                        color: '#D95624',
                        outlineColor: '#D95624',
                        borderColor: '#D95624',
                        width: {
                          xs: '100%',
                          sm: '100%',
                          md: '100%',
                          lg: '100%',
                          xl: 'auto',
                        },
                        marginTop: {
                          xs: '10px',
                          sm: '10px',
                          md: '10px',
                          lg: '10px',
                          xl: '0',
                        },
                      }}
                      variant="outlined"
                      onClick={() => {
                        setOpenModal(true);
                        setMeetingLink(item.meeting_link);
                      }}
                    >
                      View Meeting Link
                    </Button>
                  ) : (
                    // Display "Add Meeting Link" button when meeting_link is empty
                    <Button
                      sx={{
                        marginLeft: {
                          xs: '0',
                          sm: '0',
                          md: '0',
                          lg: '10px',
                        },
                        color: '#219653',
                        outlineColor: '#219653',
                        borderColor: '#219653',
                        width: {
                          xs: '100%',
                          sm: '100%',
                          md: '100%',
                          lg: '100%',
                          xl: 'auto',
                        },
                        marginTop: {
                          xs: '10px',
                          sm: '10px',
                          md: '10px',
                          lg: '10px',
                          xl: '0',
                        },
                      }}
                      variant="outlined"
                      onClick={() => {
                        setID(item.id);
                        setOpenAddMeetingLinkModal(true);
                      }}
                    >
                      Add Meeting Link
                    </Button>
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>

        {demos?.facilities?.length ? (
          <Pagination
            sx={{ marginTop: '20px', marginBottom: '10px' }}
            count={demos?.total_pages}
            page={currentPage}
            onChange={handlePageChange}
          />
        ) : null}

        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal}>
            <Paper
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80%',
                maxWidth: '500px',
                padding: '20px',
                textAlign: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                  marginBottom: '10px',
                }}
              >
                Meeting Link
              </Typography>
              <Typography
                sx={{
                  fontSize: '16px',
                  color: '#30309e',
                }}
              >
                {meetingLink}
              </Typography>
            </Paper>
          </Fade>
        </Modal>

        <Modal
          open={openAddMeetingLinkModal}
          onClose={() => setOpenAddMeetingLinkModal(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openAddMeetingLinkModal}>
            <Paper
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80%',
                maxWidth: '500px',
                padding: '20px',
                textAlign: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '18px',
                  fontWeight: '500',
                  marginBottom: '10px',
                }}
              >
                Add Meeting Link
              </Typography>
              <Input
                width="100%"
                type="text"
                value={newMeetingLink}
                onChange={e => setNewMeetingLink(e.target.value)}
                placeholder="Enter Meeting Link"
                sx={{
                  width: '100%',
                  marginBottom: '20px',
                  padding: '8px',
                  fontSize: '16px',
                }}
              />
              <Button
                sx={{
                  color: '#219653',
                  outlineColor: '#219653',
                  borderColor: '#219653',
                  width: '100%',
                }}
                variant="outlined"
                onClick={() => {
                  setSending(true);
                  setMeetingLink(newMeetingLink);
                  api
                    .post(`/operation_panel/scheduled_demo/${id}`, {
                      time_zone: TimeZoneHelper.getUserTimeZone(),
                      meeting_link: newMeetingLink,
                    })
                    .then(response => {
                      console.log(
                        'Meeting link added successfully:',
                        response.data
                      );
                      setToastOpen(true);
                      setNewMeetingLink('');
                      setSending(false);
                    })
                    .catch(error => {
                      setSending(false);

                      console.error(
                        'Error adding meeting link:',
                        error
                      );
                    });
                }}
              >
                {sending ? 'sending...' : 'Add'}
              </Button>
            </Paper>
          </Fade>
        </Modal>

        <Snackbar
          open={toastOpen}
          autoHideDuration={3000}
          onClose={() => setToastOpen(false)}
          message="Meeting link added successfully"
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          color="success"
        />
      </Box>
    </LocalizationProvider>
  );
}

export default ScheduledDemo;
