// @ts-nocheck
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Modal,
  Typography,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  TextField,
  Snackbar,
  Input,
} from '@mui/material';
import { formatDate } from 'operational-sales-panel/services/date-formated';
import { MdLocationOn } from 'react-icons/md';
import { BiPhoneCall } from 'react-icons/bi';
import { HiOutlineMail } from 'react-icons/hi';
import { MdDateRange } from 'react-icons/md';
import { api } from 'main/configs/axios.config';
import AudioComponent from '../AudioComponent/AudioComponent';
import { Device } from 'twilio-client';
import { FaPhoneAlt } from 'react-icons/fa';
import { BsMicMute, BsMic } from 'react-icons/bs';
import { RiSendPlane2Line } from 'react-icons/ri';

export default function ContactFacilityModal({
  open,
  onClose,
  item,
  onMarkAsContacted,
  onMarkAsNoAnswer,
  onMarkAsNotInterested,
  contactName,
  onChangeContactName,
  contactStatus,
  onChangeContactStatus,
  isContact,
  isNoAnswer,
  emailURL,
  notesURL,
  smsURL,
  noCall,
  sendTemplate,
  noChat,
  noCallHistory,
  noChatTab,
  baseURL,
  fetchActiveData,
}) {
  const imageUrl = '/images/schoolPhoto.png';
  const [selectedTab, setSelectedTab] = useState(0);
  const [notes, setNotes] = useState([]);
  const [note, setNote] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [file, setFile] = useState('');
  const [isDragging, setIsDragging] = useState(false);
  const [recordings, setRecordings] = useState([]);
  const [twilioDevice, setTwilioDevice] = useState(null);
  const [callStatus, setCallStatus] = useState('idle');
  const [callDuration, setCallDuration] = useState(0);
  const [isMuted, setIsMuted] = useState(false);
  const [isHold, setIsOnHold] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [isRinging, setIsRinging] = useState(false);
  const device = new Device();
  const [sending, setSending] = useState(false);
  const [newNote, setNewNote] = useState(false);
  const [newSms, setNewSms] = useState(false);
  const [smsText, setSmsText] = useState('');
  const [sendTemplat, setSendTemplat] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [chatSMS, setchatSMS] = useState([]);
  const containerRef = React.useRef(null);
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [updatedEmail, setUpdateEmail] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [sendingSMS, setSendingSMS] = useState('');
  const [email, setEmail] = useState(item?.email);

  useEffect(() => {
    if (!notesURL && !emailURL) {
      setSelectedTab(2);
    }
  }, []);
  useEffect(() => {
    if (selectedTab === 2) {
      fetchRecordings();
    }
  }, [selectedTab]);

  useEffect(() => {
    fetchNotes();
  }, [newNote]);

  useEffect(() => {
    api
      .get('/call_center/call/token')
      .then(response => {
        const { accessToken } = response;
        device.setup(accessToken, { debug: true });
      })
      .catch(error => {
        console.error('Error retrieving access token:', error);
      });
  }, []);

  useEffect(() => {
    let interval;
    if (callStatus === 'connected') {
      interval = setInterval(() => {
        setCallDuration(prevDuration => prevDuration + 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [callStatus]);

  useEffect(() => {
    // Initialize Twilio Device
    // Set up event listeners for call status changes
    device.on('ready', () => {
      // console.log('Twilio Device ready')
    });

    device.on('connect', connection => {
      setCallStatus('connected');
      setIsStarted(true);
      setCallDuration(0);
      setIsRinging(true);
    });

    device.on('disconnect', () => {
      setCallStatus('idle');
      // setCallDuration(0)
      setIsStarted(false);
    });

    device.on('mute', () => {
      setIsMuted(true);
    });

    device.on('unmute', () => {
      // console.log('Call unmuted')
      setIsMuted(false);
    });

    device.on('hold', () => {
      // console.log('Call on hold')
      setIsOnHold(true);
    });

    device.on('unhold', () => {
      // console.log('Call taken off hold')
      setIsOnHold(false);
    });

    device.on('error', error => {
      // console.error('Twilio Device error:', error.message)
      setCallStatus('error');
    });

    setTwilioDevice(device);

    // Clean up when component unmounts
    return () => {
      if (twilioDevice) {
        twilioDevice.disconnectAll();
        twilioDevice.destroy();
      }
    };
  }, []);

  const formatCallDuration = duration => {
    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const handleCall = () => {
    const params = {
      to: item?.phone,
    };

    twilioDevice.connect(params);
    setCallStatus('connecting');
  };

  const handleHangUp = () => {
    if (twilioDevice) {
      // Hang up the call
      twilioDevice.disconnectAll();
      setCallStatus('idle');
    }
  };

  const handleMute = () => {
    if (twilioDevice) {
      if (isMuted) {
        setIsMuted(false);
        twilioDevice.activeConnection().mute(false);
      } else {
        setIsMuted(true);
        twilioDevice.activeConnection().mute(true);
      }
    }
  };

  const fetchRecordings = async () => {
    try {
      const response = await api.get(
        `/call_center/call/recordings?phone_number=${item?.phone}`
      );
      setRecordings(response);
    } catch (error) {
      // console.log(error)
    }
  };

  const handleNoteChange = event => {
    setNote(event.target.value);
  };

  const handleSmsChange = event => {
    setSmsText(event.target.value);
  };

  const handleSubjectChange = event => {
    setSubject(event.target.value);
  };

  const handleMessageChange = event => {
    setMessage(event.target.value);
  };

  const fetchSMS = async () => {
    if (!noChat) {
      try {
        const response = await api.get(`${smsURL}?phone_number=${item?.phone}`);
        setchatSMS(response);
      } catch (error) {}
    }
  };

  useEffect(() => {
    fetchSMS();
  }, [selectedTab, newSms]);

  const handleSendSMS = async () => {
    try {
      if (smsText) {
        const response = await api.post(`${smsURL}/${item?.id}`, {
          text: smsText,
        });
        setSmsText('');
        setNewSms(prev => !prev);
      } else {
        showAlert('error', 'SMS cannot be empty!');
      }
    } catch (error) {
      console.error('Error sending sms:', error);
    }
  };

  const handleSendSMSwithoutBody = async () => {
    setSendingSMS(true);
    try {
      await api.post(`${smsURL}/${item?.id}`);
      setNewSms(prev => !prev);
      setSendingSMS(false);
      showAlert('sucess', 'SMS was successfully sent!');
    } catch (error) {
      console.error('Error sending sms:', error);
      showAlert('error', error?.response?.data?.message);

      setSendingSMS(false);
    }
  };

  const handleEditEmailClick = () => {
    setIsEditingEmail(true);
  };

  const handleUpdateEmailClick = async () => {
    setUpdateEmail(true);
    try {
      await api.post(`${baseURL}${item?.id}/update_email`, {
        email: newEmail,
      });
      setEmail(newEmail);
      fetchActiveData();
      setUpdateEmail(false);
      setIsEditingEmail(false);
      setNewEmail('');
      showAlert('success', 'Email updated successfully');
    } catch (error) {
      setUpdateEmail(false);

      console.error('Error updating email:', error);
      showAlert('error', error?.response?.data?.message);
    }
  };

  // useEffect(() => {
  //   // TRIGEER EMAIL
  // }, [email])

  const handleSendSmsTemplate = async () => {
    setSendTemplat(true);
    try {
      const response = await api.post(`${baseURL}${item?.id}/send_template`);
      setSendTemplat(false);
      showAlert('success', 'The template was successfully sent!');
    } catch (error) {
      setSendTemplat(false);
      console.error('Error sending email:', error);
      showAlert('error', error?.response?.data?.message);
    }
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleSendSMS();
    }
  };

  const handleAddNote = async () => {
    setSending(true);
    try {
      const response = await api.post(`${notesURL}`, {
        note: note,
        id: item?.id,
      });
      setSending(false);
      setNote('');
      setNewNote(prev => !prev);
    } catch (error) {
      setSending(false);
      console.error('Error adding note:', error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleSelectFile = () => {
    setIsDragging(true);
    const fileInput = document.getElementById('file-input');
    fileInput.click();
  };

  const handleFileSelect = event => {
    setIsDragging(false);
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleDragEnter = event => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragOver = event => {
    event.preventDefault();
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = event => {
    event.preventDefault();
    setIsDragging(false);
    const selectedFile = event.dataTransfer.files[0];
    setFile(selectedFile);
  };

  const fetchNotes = async () => {
    if (notesURL) {
      try {
        const response = await api.get(`${notesURL}/${item?.id}`);
        setNotes(response);
      } catch (error) {
        // console.log(error)
      }
    }
  };

  // Scroll to the bottom after chatSMS updates
  useEffect(() => {
    const containerElement = containerRef.current;
    if (containerElement) {
      containerElement.classList.add('smooth-scroll');
      containerElement.scrollTop = containerElement.scrollHeight;

      const handleTransitionEnd = () => {
        containerElement.classList.remove('smooth-scroll');
      };
      containerElement.addEventListener('transitionend', handleTransitionEnd);

      return () => {
        containerElement.removeEventListener(
          'transitionend',
          handleTransitionEnd
        );
      };
    }
  }, [chatSMS]);

  const handleSendEmail = async () => {
    if (!subject) {
      showAlert('error', 'Subject is required.');
      return;
    } else if (!message) {
      showAlert('error', 'Message is required.');
      return;
    }

    setSending(true);

    const requestBody = {
      subject: subject,
      text: message,
      id: item?.id,
    };

    if (file) {
      requestBody.document = file;
    }
    try {
      const response = await api.post(emailURL, requestBody, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setSending(false);
      setSubject('');
      setMessage('');
      setFile('');
      showAlert('success', 'Email sent successfully.');
    } catch (error) {
      console.error('Error sending email:', error);
      setSending(false);
      setSubject('');
      setMessage('');
      setFile('');
      showAlert('error', 'Failed to send email.');
    }
  };

  const showAlert = (type, message) => {
    setAlertType(type);
    setAlertMessage(message);
    setAlertOpen(true);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  function sender(message, date, key) {
    const tokenRegex = /token=[^\s]+/;

    const hasToken = tokenRegex.test(message);

    const cleanMessage = hasToken ? message.replace(tokenRegex, '') : message;

    return (
      <Box
        key={key}
        sx={{
          backgroundColor: '#D95624',
          padding: '10px 20px',
          color: 'white',
          borderRadius: '0px 8px 8px 8px',
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word',
          maxWidth: ['320px', '320px', '500px'],
        }}
      >
        <Typography sx={{ overflowX: 'auto' }}>{cleanMessage}</Typography>
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: '300',
            color: '#ffffffab',
          }}
        >
          {formatDate(date)}
        </Typography>
      </Box>
    );
  }

  function resever(message, date, key) {
    return (
      <Box
        key={key}
        sx={{
          backgroundColor: '#858585',
          padding: '10px 20px',
          color: 'white',
          marginLeft: 'auto',
          borderRadius: '8px 0px 8px 8px',
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word',
          maxWidth: ['320px', '320px', '500px'],
        }}
      >
        <Typography>{message}</Typography>
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: '300',
            color: '#ffffffab',
          }}
        >
          {formatDate(date)}
        </Typography>
      </Box>
    );
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={[
          style.modal,
          {
            width: { xs: '90%', lg: '1127px' },
            borderRadius: '12px',
            padding: { xs: '12px', lg: '40px' },
            height: '80vh',
          },
        ]}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
            width: '100%',
            gap: '25px',
            alignItems: 'flex-start',
          }}
        >
          {/* Left SIDE */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: { xs: '100%', lg: 'fit-content' },
              boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.04)',
              borderRadius: '6px',
              marginTop: '18px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundImage: `url(${imageUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                width: 'auto',
                minWidth: { xs: 'auto', lg: '400px' },
                height: '159px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
                padding: '20px',
                borderRadius: '6px 6px 0 0',
              }}
            >
              <Typography
                sx={{
                  color: '#fff',
                  fontWeight: '600',
                  fontSize: '22px',
                  textAlign: 'center',
                  lineHeight: '28px',
                }}
              >
                {item?.facility_name ||
                  (item?.first_name && item?.last_name
                    ? item.first_name + ' ' + item.last_name
                    : item?.full_name) ||
                  item?.school_name}
              </Typography>
              {item?.city ? (
                <Box sx={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
                  <MdLocationOn color="#fff" />
                  <Typography
                    sx={{ color: '#fff', fontWeight: '400', fontSize: '16px' }}
                  >
                    {item?.city}, {item?.county ?? item?.country}
                  </Typography>
                </Box>
              ) : null}
            </Box>
            <Box
              sx={{
                width: { xs: '100%', lg: 'max-content' },
                minWidth: { xs: 'auto', lg: '100%' },
                padding: '0 16px',
              }}
            >
              <Typography
                sx={{
                  color: '#0C0C0D',
                  fontWeight: '600',
                  marginTop: '20px',
                  textAlign: 'center',
                }}
              >
                Application Status
              </Typography>

              {/* INFO */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                  position: 'relative',
                }}
              >
                {isStarted && (
                  <Box
                    display="flex"
                    position="absolute"
                    sx={{
                      backgroundColor: '#F9F8FF',
                      zIndex: 5,
                      width: '100%',
                      height: '100%',
                      borderRadius: '10px',
                      padding: '20px 12px',
                      justifyContent: 'space-around',
                    }}
                  >
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography
                        sx={{
                          color: 'var(--mian-color, #D95624)',
                          fontSize: '20px',
                          fontWeight: '700',
                        }}
                      >
                        {item?.phone}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: '#3F5263',
                        }}
                      >
                        Duration: {formatCallDuration(callDuration)} seconds
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', gap: '20px' }}>
                      <Box
                        sx={{
                          backgroundColor: '#E50808',
                          cursor: 'pointer',
                          borderRadius: '50%',
                          width: '45px',
                          height: '45px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        onClick={handleHangUp}
                      >
                        <FaPhoneAlt size="20px" color="white" />
                      </Box>

                      <Box
                        sx={{
                          backgroundColor: '#858585',
                          cursor: 'pointer',
                          borderRadius: '50%',
                          width: '45px',
                          height: '45px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        onClick={handleMute}
                      >
                        {isMuted ? (
                          <BsMicMute size="20px" color="white" />
                        ) : (
                          <BsMic size="20px" color="white" />
                        )}
                      </Box>
                    </Box>
                  </Box>
                )}
                {noCall === true ? (
                  <Box sx={{ marginTop: '10px' }}></Box>
                ) : (
                  <Button
                    variant="outlined"
                    sx={{
                      padding: '7px 30px',
                      borderColor: '#42AE3F',
                      color: '#42AE3F',
                      width: '100%',
                      marginTop: '14px',
                      marginBottom: '20px',
                      textAlign: 'center',
                    }}
                    onClick={handleCall}
                  >
                    Call
                  </Button>
                )}
                <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                  <BiPhoneCall size="20px" color="#3F5263" />
                  <Typography
                    sx={{
                      fontSize: '17px',
                      color: '#3F5263',
                      fontWeight: '400',
                    }}
                  >
                    {item?.phone}
                  </Typography>
                </Box>

                {email ? (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '5px',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}
                  >
                    <HiOutlineMail size="20px" color="#3F5263" />
                    <Typography
                      sx={{
                        fontSize: '17px',
                        color: '#3F5263',
                        fontWeight: '400',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {email}
                    </Typography>
                  </Box>
                ) : null}
                <Box
                  sx={{
                    display: 'flex',
                    gap: '5px',
                    alignItems: 'center',
                    paddingBottom: '20px',
                  }}
                >
                  <MdDateRange color="#3F5263" size="20px" />
                  <Typography
                    sx={{
                      fontSize: '17px',
                      color: '#3F5263',
                      fontWeight: '400',
                    }}
                  >
                    Date of birth
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          {/* RIGHT SIDE */}
          <Box sx={{ width: '100%' }}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="Tabs"
            >
              <Tab sx={{ display: notesURL ? 'flex' : 'none' }} label="Note" />
              <Tab sx={{ display: emailURL ? 'flex' : 'none' }} label="Email" />
              <Tab
                sx={{ display: !noCallHistory ? 'flex' : 'none' }}
                label="Call History"
              />
              <Tab
                sx={{ display: !noChatTab ? 'flex' : 'none' }}
                label="Chats"
              />
            </Tabs>

            <hr
              style={{
                opacity: '0.10000000149011612',
                background: '#081735',
              }}
            />

            {selectedTab === 0 && (
              <Box sx={{ marginTop: '45px' }}>
                <Typography
                  variant="body1"
                  sx={{ color: '#0C0C0D', fontSize: '18px', fontWeight: '600' }}
                >
                  Notes
                </Typography>
                <List component="ul">
                  {notes.map(note => {
                    return (
                      <ListItem key={note?.id}>
                        <ListItemText
                          sx={{ color: '#3F5263' }}
                          primary={note?.note}
                        />
                      </ListItem>
                    );
                  })}
                </List>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    marginBottom: { xs: '16px', lg: '16px' },
                  }}
                >
                  <TextField
                    id="note-input"
                    label="Write notes here..."
                    multiline
                    rows={4}
                    value={note}
                    onChange={handleNoteChange}
                    fullWidth
                    variant="outlined"
                  />
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: '#D95624',
                      width: 'fit-content',
                      '&:hover': {
                        backgroundColor: '#c14615',
                      },
                    }}
                    onClick={handleAddNote}
                  >
                    {sending ? 'Adding' : 'Add Note'}
                  </Button>
                </Box>
              </Box>
            )}
            {selectedTab === 1 && (
              <Box
                sx={{
                  marginTop: '45px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                  marginBottom: { xs: '16px', lg: '16px' },
                }}
              >
                <TextField
                  variant="outlined"
                  label="Subject"
                  fullWidth
                  value={subject}
                  onChange={handleSubjectChange}
                />

                <TextField
                  id="note-input"
                  label="Write your Message........."
                  multiline
                  rows={4}
                  value={message}
                  onChange={handleMessageChange}
                  fullWidth
                  variant="outlined"
                />

                <Typography sx={{ color: '#0C0C0D', fontWeight: '500' }}>
                  Attach File
                </Typography>
                <Box
                  sx={{
                    borderRadius: '10px',
                    border: '1px dashed rgba(0, 0, 0, 0.25)',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '24px',
                    padding: '16px',
                    cursor: 'default',
                    backgroundColor: isDragging
                      ? 'rgba(28, 28, 28, 0.24)'
                      : 'white',
                  }}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  onDragLeave={handleDragLeave}
                  onDragEnter={handleDragEnter}
                >
                  <img
                    alt="Upload Icon"
                    src="/images/feather_upload-cloud.png"
                  />

                  <Typography sx={{ fontWeight: '500', fontSize: '14px' }}>
                    {file ? file.name : 'Select a file or drag and drop here'}
                  </Typography>

                  <Typography
                    sx={{
                      fontWeight: '500',
                      color: 'rgba(0, 0, 0, 0.40)',
                      fontSize: '14px',
                      marginTop: '-20px',
                    }}
                  >
                    JPG, PNG or PDF, file size no more than 10MB
                  </Typography>

                  {!file ? (
                    <Button
                      variant="outlined"
                      sx={{
                        color: '#D95624',
                        width: 'fit-content',
                        fontSize: '12px',
                        borderColor: '#D95624',
                        marginTop: '-14px',
                      }}
                      onClick={handleSelectFile}
                    >
                      SELECT FILE
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      sx={{
                        color: 'rgba(229, 8, 8, 1)',
                        width: 'fit-content',
                        fontSize: '12px',
                        borderColor: 'rgba(229, 8, 8, 1)',
                        marginTop: '-14px',
                      }}
                      onClick={() => setFile(null)}
                    >
                      REMOVE FILE
                    </Button>
                  )}
                </Box>

                <Box sx={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: '#D95624',
                      width: 'fit-content',
                      '&:hover': {
                        backgroundColor: '#c14615',
                      },
                    }}
                    onClick={handleSendEmail}
                  >
                    {sending ? 'Sending..' : 'Send Email'}
                  </Button>

                  {sendTemplate === true ? (
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: '#AC21ED',
                        width: 'fit-content',
                        '&:hover': {
                          backgroundColor: '#9932c9',
                        },
                      }}
                      onClick={handleSendSmsTemplate}
                    >
                      {sendTemplat ? 'Sending..' : 'Send Email Template'}
                    </Button>
                  ) : null}

                  {sendTemplate === true ? (
                    isEditingEmail ? (
                      <div>
                        <input
                          style={{
                            padding: '8px 4px',
                            marginRight: '-4px',
                          }}
                          type="text"
                          value={newEmail}
                          onChange={e => setNewEmail(e.target.value)}
                          placeholder="Enter new email"
                        />
                        <Button
                          variant="contained"
                          sx={{
                            backgroundColor: '#65c47e',
                            width: 'fit-content',
                            '&:hover': {
                              backgroundColor: '#4cb567',
                            },
                          }}
                          onClick={handleUpdateEmailClick}
                        >
                          {updatedEmail ? 'Sending..' : 'Update'}
                        </Button>
                      </div>
                    ) : (
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: '#adb9d3',
                          width: 'fit-content',
                          '&:hover': {
                            backgroundColor: '#cbd0dc',
                          },
                        }}
                        onClick={handleEditEmailClick}
                      >
                        Edit email
                      </Button>
                    )
                  ) : null}
                </Box>
                <input
                  type="file"
                  id="file-input"
                  style={{ display: 'none' }}
                  onChange={handleFileSelect}
                />
              </Box>
            )}
            {selectedTab === 2 && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  marginBottom: { xs: '16px', lg: '16px' },
                  marginTop: '45px',
                  height: { xs: 'auto', lg: '500px' },
                  overflow: 'auto',
                }}
              >
                {recordings.length === 0 && (
                  <Typography>No Avaiable Recording</Typography>
                )}
                {recordings.map(recording => (
                  <Box
                    key={recording.sid}
                    sx={{
                      padding: '16px',
                      backgroundColor: '#F9F8FF',
                      borderRadius: '10px',
                    }}
                  >
                    <AudioComponent recording={recording} />
                    {recordings.length === 0 && (
                      <Typography>No Avaiable Recording</Typography>
                    )}
                  </Box>
                ))}
              </Box>
            )}

            {selectedTab === 3 && !noChat && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: { xs: '16px', lg: '16px' },
                  marginTop: '45px',
                  height: '500px',
                  backgroundColor: '#f5f7fa69',
                  borderRadius: '6px',
                  boxShadow: 'inset -6px -13px 20px 20px #c2c2c20f',
                  maxWidth: '600px',
                  overflowX: 'hidden',
                }}
              >
                <Box
                  ref={containerRef}
                  sx={{
                    padding: '18px',
                    overflow: 'auto',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '10px',
                    '&::-webkit-scrollbar': {
                      width: '8px',
                    },
                    '&::-webkit-scrollbar-track': {
                      background: '#f1f1f1',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: '#C1C1C1',
                      borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                      background: '#555',
                    },
                  }}
                >
                  {chatSMS.map(chat => {
                    if (chat.direction === 'outbound-api') {
                      return sender(chat.body, chat.dateSent, chat.sid);
                    } else {
                      return resever(chat.body, chat.dateSent, chat.sid);
                    }
                  })}
                </Box>
                <Box
                  sx={{
                    marginTop: '10px',
                    backgroundColor: '#627D98',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <TextField
                    onKeyDown={handleKeyPress}
                    id="outlined-basic"
                    variant="filled"
                    label="Send SMS"
                    color="warning"
                    fullWidth
                    value={smsText}
                    onChange={handleSmsChange}
                    sx={{
                      '.css-10botns-MuiInputBase-input-MuiFilledInput-input': {
                        backgroundColor: '#fafafa',
                        border: '1px solid #e7e7e7',
                      },
                      '.css-1lrmfws-MuiInputBase-root-MuiFilledInput-root:before':
                        {
                          border: 'none',
                        },
                    }}
                  />

                  <Box
                    onClick={handleSendSMS}
                    sx={{
                      width: '80px',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                      transition: 'all 0.2s ease',
                      '&:hover': {
                        transform: 'rotate(-20deg)',
                      },
                      '&:not(:hover)': {
                        transform: 'rotate(0deg)',
                      },
                    }}
                  >
                    <RiSendPlane2Line size="24px" color="white" />
                  </Box>
                </Box>
              </Box>
            )}

            {selectedTab === 3 && noChat && (
              <Box padding="15px 10px">
                <Button
                  variant="outlined"
                  sx={{
                    padding: '10px 30px',
                    fontWeight: '400',
                    color: '#081735',
                    borderColor: '#DBDBDB',
                    width: '100%',
                  }}
                  onClick={handleSendSMSwithoutBody}
                >
                  {sendingSMS ? 'sending..' : 'Send SMS'}
                </Button>
              </Box>
            )}
          </Box>
        </Box>

        <Box>
          {!noChatTab && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                flexWrap: 'wrap',
                alignItems: 'center',
                marginTop: '38px',
                gap: '10px',
                marginRight: { xs: 'revert', lg: '-30px' },
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  padding: '10px 30px',
                  fontWeight: '400',
                  color: '#081735',
                  borderColor: '#DBDBDB',
                  width: { xs: '100%', lg: 'auto' },
                }}
                onClick={onMarkAsNotInterested}
              >
                Mark as Not interested
              </Button>

              {isNoAnswer ?? (
                <Button
                  variant="contained"
                  sx={{
                    padding: '10px 30px',
                    fontWeight: '400',
                    backgroundColor: '#606a79',
                    width: { xs: '100%', lg: 'auto' },
                    '&:hover': {
                      backgroundColor: '#3e4652',
                    },
                  }}
                  onClick={onMarkAsNoAnswer}
                >
                  Mark as No Answer
                </Button>
              )}

              {isContact ?? (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    padding: '10px 30px',
                    fontWeight: '400',
                    width: { xs: '100%', lg: 'auto' },
                  }}
                  onClick={onMarkAsContacted}
                >
                  Mark as Contacted
                </Button>
              )}
            </Box>
          )}
        </Box>
        <Snackbar
          open={alertOpen}
          autoHideDuration={3000}
          onClose={handleAlertClose}
          message={alertMessage}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          sx={{
            backgroundColor: alertType === 'success' ? '#42AE3F' : '#E50808',
          }}
        />
      </Box>
    </Modal>
  );
}

const style = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    padding: '20px',
    overflow: 'auto',
  },
  headerRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  heading: {
    fontWeight: 400,
    fontSize: '22px',
    lineHeight: '27px',
    textAlign: 'center',
  },

  btn: {
    textTransform: 'none',
    fontSize: '16px',
    lineHeight: '19px',
    height: '45px',
    width: '45%',
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
  },
  actionText: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    color: 'custom.black',
  },
  contact: {
    fontWeight: 400,
    fontSize: '16px',
    color: 'custom.black',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    maxWidth: '100%',
  },
};
