import { React, useState } from 'react'
import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Snackbar,
} from '@mui/material'

import { HiOutlineDocumentChartBar } from 'react-icons/hi2'
import { MdOutlineTune } from 'react-icons/md'
import { WiTime3, WiTime5 } from 'react-icons/wi'
import { FcEmptyFilter } from 'react-icons/fc'

import { api } from 'main/configs/axios.config'
import { useQuery } from '@tanstack/react-query'
import { DashboardService } from 'homecare/services'
import { Buffer } from 'buffer'

export default function DashboardRequestCard({ data, setActiveShift }) {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const [isHireLoading, setHireIsLoading] = useState(false)
  const [emptyMessage, setEmptyMessage] = useState('')
  const [open, setOpen] = useState(false)
  const [imageSrc, setImageSrc] = useState(
    Array(getShifts?.data?.length).fill(null)
  )

  const getShifts = useQuery({
    queryKey: ['getActiveStaff'],
    queryFn: DashboardService.getActiveStaff,
  })

  const getPayment = useQuery({
    queryKey: ['getPayment'],
    queryFn: DashboardService.getPayment,
  })

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  async function handleHire(id) {
    setHireIsLoading(true)
    try {
      const response = await api.post(
        `individuals/shift/hire?staff_id=${id}&pm_id=${getPayment?.data?.data[0]?.id}`
      )
      setEmptyMessage('Success')
      setActiveShift('next')
    } catch (error) {
      setEmptyMessage('There was an error, please try again')
    }

    setHireIsLoading(false)
  }

  const PitfalImages = [
    'https://occ-0-2041-3662.1.nflxso.net/art/0d282/eb648e0fd0b2676dbb7317310a48f9bdc2b0d282.png',
    'https://ih1.redbubble.net/image.618427277.3222/flat,800x800,075,f.u2.jpg',
    'https://upload.wikimedia.org/wikipedia/commons/0/0b/Netflix-avatar.png',
    'https://i.pinimg.com/736x/eb/05/eb/eb05eb6fb0880b5c811669b60b0278bb.jpg',
    'https://i.pinimg.com/550x/e3/94/30/e39430434d2b8207188f880ac66c6411.jpg',
    'https://i.pinimg.com/550x/b6/77/cd/b677cd1cde292f261166533d6fe75872.jpg',
    'https://i.pinimg.com/736x/bd/ee/4c/bdee4c328550aaf21aa9f43fd19e2136.jpg',
  ]

  // const handleUserImage = async profile_pic => {
  //   let srcValue = ''
  //   const response = await api
  //     .get(`profile-pics/${profile_pic}`, {
  //       responseType: 'arraybuffer',
  //     })
  //     .then((res: any) => {
  //       let base64ImageString = Buffer.from(res, 'binary').toString('base64')
  //       srcValue = 'data:image/png;base64,' + base64ImageString
  //     })
  //   setImageSrc(srcValue)
  // }

  const handleUserImage = async (profile_pic, index) => {
    const response = await api
      .get(`profile-pics/${profile_pic}`, {
        responseType: 'arraybuffer',
      })
      .then(res => {
        let base64ImageString = Buffer.from(res, 'binary').toString('base64')
        let srcValue = 'data:image/png;base64,' + base64ImageString
        setImageSrc(prevState => {
          const newState = [...prevState]
          newState[index] = srcValue
          return newState
        })
      })
  }

  function displayCard(staff, index) {
    handleUserImage(staff.staff_details.profile_pic, index)
    return (
      <Box key={staff.id} mb="17px" sx={sxStyles.detailsCard}>
        <Stack direction="row" alignItems="center">
          {imageSrc ? (
            <img src={imageSrc[index]} alt="user" style={sxStyles.userImg} />
          ) : (
            <img
              src={
                PitfalImages[Math.floor(Math.random() * PitfalImages.length)]
              }
              alt="user"
              style={sxStyles.userImg}
            />
          )}
          <Box>
            <Typography
              sx={{ color: '#000000', fontSize: '20px', fontWeight: '600' }}
            >
              {staff.staff_details.first_name +
                ' ' +
                staff.staff_details.last_name}
            </Typography>
            <div style={{ marginTop: '8px' }}>
              <span
                style={{
                  marginRight: '16px',
                  color: '#848484',
                  fontSize: '16px',
                }}
              >
                {staff.staff_distance_miles} miles
              </span>
              <span style={{ color: '#848484', fontSize: '16px' }}>
                mileage: {staff.mileage}
              </span>
            </div>
          </Box>
        </Stack>
        <Button
          variant="contained"
          sx={[sxStyles.btn, { marginRight: '15px' }]}
          onClick={() => {
            handleHire(staff.staff_details.id)
          }}
          disabled={isHireLoading}
        >
          {isHireLoading ? <CircularProgress size={24} /> : 'Hire'}
        </Button>
        <Button
          variant="outlined"
          sx={[sxStyles.btn, { color: '#000000', borderColor: '#000000' }]}
        >
          Dismiss
        </Button>
      </Box>
    )
  }

  return (
    <Box sx={[sxStyles.container, { width: matches ? '517px' : '60%' }]}>
      <Typography fontSize="40px" sx={sxStyles.cardHeading}>
        Ongoing Requests
      </Typography>

      <Box sx={sxStyles.detailsCard}>
        <Stack mb="8px" direction="row" justifyContent="space-between">
          <Typography sx={sxStyles.subHeading}>Shift Details</Typography>
        </Stack>
        <Stack mb="4px" direction="row" justifyContent="space-between">
          <Typography sx={sxStyles.details}>
            <HiOutlineDocumentChartBar size="22px" />
            <span style={{ marginLeft: '6px' }}>
              Tasks: {data?.shift?.tasks?.length}
            </span>
          </Typography>
          <Typography sx={sxStyles.details}>
            Total:
            <span
              style={{
                color: '#F52A4C',
                fontWeight: 'bold',
                marginLeft: '3px',
              }}
            >
              ${data?.shift?.invoice_rate}
            </span>
          </Typography>
        </Stack>
        <Stack mb="4px" direction="row" justifyContent="space-between">
          <Typography sx={sxStyles.details}>
            <MdOutlineTune size="22px" />
            <span style={{ marginLeft: '6px' }}>Role</span>
          </Typography>
          <Typography sx={sxStyles.details}>{data?.shift?.role}</Typography>
        </Stack>
        <Stack mb="4px" direction="row" justifyContent="space-between">
          <Typography sx={sxStyles.details}>
            <WiTime3 size="22px" />
            <span style={{ marginLeft: '6px' }}>Start time</span>
          </Typography>
          <Typography sx={sxStyles.details}>
            {data?.shift?.start_time}
          </Typography>
        </Stack>
        <Stack mb="4px" direction="row" justifyContent="space-between">
          <Typography sx={sxStyles.details}>
            <WiTime5 size="22px" />
            <span style={{ marginLeft: '6px' }}>End time</span>
          </Typography>
          <Typography sx={sxStyles.details}>{data?.shift?.end_time}</Typography>
        </Stack>

        <Typography sx={[sxStyles.details, { marginTop: 2 }]}>
          Note: You will pay ${data?.shift?.invoice_rate} plus the mileage.
        </Typography>
      </Box>

      <Typography
        sx={{
          color: '#000000',
          fontSize: '18px',
          fontWeight: '500',
          m: '20px 0',
        }}
      >
        Staffs Applied
      </Typography>
      {getShifts.isLoading ? (
        <Box
          display="flex"
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size={24} />
        </Box>
      ) : getShifts?.data.length !== 0 ? (
        getShifts?.data?.map(staff => displayCard(staff))
      ) : (
        <Box ml="5px" display="flex" gap="5px">
          <Typography
            style={{
              color: '#B5AFAF',
              fontWeight: 'normal',
              letterSpacing: '1.5',
            }}
          >
            NO ACTIVE STAFF
          </Typography>
          <FcEmptyFilter />
        </Box>
      )}
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={emptyMessage}
      />
    </Box>
  )
}

const sxStyles = {
  container: {
    background: 'background',
    boxShadow: '0px 20px 50px rgba(171, 171, 171, 0.25)',
    zIndex: 1,
    position: 'absolute',
    top: 150,
    left: 50,
    padding: 4,
    maxHeight: '70vh',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '10px',
      backgroundColor: '#F6F6F6',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgb(245,42,76)',
      borderRadius: '20px',
      border: '3px solid #F5F5F5',
    },
  },
  cardHeading: {
    fontSize: 30,
    fontWeight: 700,
    marginBottom: '25px',
    color: 'custom.black',
  },
  detailsCard: {
    background: '#F6F6F6',
    padding: '16px',
    borderRadius: '6px',
  },
  subHeading: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#000000',
    marginBottom: 1,
  },
  details: {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#818181',
    marginBottom: 1,
    display: 'flex',
    alignItems: 'center',
  },
  heading: {
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '21px',
    marginTop: 1,
    marginBottom: 1,
  },
  userImg: {
    height: '50px',
    width: '50px',
    borderRadius: '50%',
    marginRight: '15px',
    objectFit: 'cover',
  },
  btn: {
    width: '100px',
    borderRadius: '47px',
    marginTop: '20px',
  },
}
