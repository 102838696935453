import React, { useState, useEffect } from 'react'
import {
  Button,
  Card,
  Stack,
  TextField,
  Typography,
  CircularProgress,
  useMediaQuery,
  useTheme,
  IconButton,
  Divider,
  Box,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
} from '@mui/material'
import { DashboardService } from 'homecare/services'
import io from 'socket.io-client'

import { HiOutlineDocumentChartBar } from 'react-icons/hi2'
import { MdOutlineTune } from 'react-icons/md'
import { WiTime3, WiTime5 } from 'react-icons/wi'
import { FcEmptyFilter } from 'react-icons/fc'

import DateRangeIcon from '@mui/icons-material/DateRange'
import { api } from 'main/configs/axios.config'

import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { useQuery } from '@tanstack/react-query'
import { Buffer } from 'buffer'

export default function StaffCard({ staff, shift, setShow, setTrack }) {
  const [isLoading, setIsLoading] = useState(false)
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  const [isHireLoading, setHireIsLoading] = useState(false)
  const [isDismissoading, setIsDismissLoading] = useState(false)
  const [emptyMessage, setEmptyMessage] = useState('')
  const [imageSrc, setImageSrc] = useState('')
  const [currentPayment, setCurrentPayment] = useState('')

  const [openModal, setOpenModal] = useState(false)

  const [open, setOpen] = useState(false)
  const handleClick = () => {
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  function displayModal(id) {
    setOpenModal(true)
  }

  const getPayment = useQuery({
    queryKey: ['getPayment'],
    queryFn: DashboardService.getPayment,
  })

  async function handleHire(card, index) {
    setHireIsLoading(true)
    setIsLoading(true)
    try {
      const response = await api.post(
        `individuals/shift/hire?staff_id=${staff?.staff_details?.id}&shift_id=${shift.id}&pm_id=${card.id}`
      )
      setEmptyMessage('Success')
      handleClick()
    } catch (error) {
      setEmptyMessage('There was an error, please try again')
      handleClick()
    }
    setHireIsLoading(false)
    setIsLoading(false)
    setTrack(true)
    setShow(false)
  }

  const PitfalImages = [
    'https://occ-0-2041-3662.1.nflxso.net/art/0d282/eb648e0fd0b2676dbb7317310a48f9bdc2b0d282.png',
    'https://ih1.redbubble.net/image.618427277.3222/flat,800x800,075,f.u2.jpg',
    'https://upload.wikimedia.org/wikipedia/commons/0/0b/Netflix-avatar.png',
    'https://i.pinimg.com/736x/eb/05/eb/eb05eb6fb0880b5c811669b60b0278bb.jpg',
    'https://i.pinimg.com/550x/e3/94/30/e39430434d2b8207188f880ac66c6411.jpg',
    'https://i.pinimg.com/550x/b6/77/cd/b677cd1cde292f261166533d6fe75872.jpg',
    'https://i.pinimg.com/736x/bd/ee/4c/bdee4c328550aaf21aa9f43fd19e2136.jpg',
  ]

  const handleUserImage = async () => {
    if (staff?.staff_details?.profile_pic) {
      let srcValue = ''
      const response = await api
        .get(`profile-pics/${staff?.staff_details?.profile_pic}`, {
          responseType: 'arraybuffer',
        })
        .then((res: any) => {
          let base64ImageString = Buffer.from(res, 'binary').toString('base64')
          srcValue = 'data:image/png;base64,' + base64ImageString
          setImageSrc(srcValue)
        })
    }
  }

  useEffect(() => {
    handleUserImage()
  }, [staff?.staff_details?.profile_pic])

  async function handleDismiss() {
    setIsDismissLoading(true)
    try {
      const response = await api.post(
        `individuals/shift/dismiss?staff_id=${staff?.staff_details?.id}&shift_id=${shift.id}`
      )
      setEmptyMessage('Done ✔')
      handleClick()
    } catch (error) {
      setEmptyMessage('There was an error, please try again')
      handleClick()
    }
    setIsDismissLoading(false)
  }

  function cardBox(card, index) {
    return (
      <Box
        onClick={() => handleHire(card, index)}
        key={card.id}
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          background: '#FFFFFF',
          boxShadow: '2px 2px 8px 2px rgba(180, 180, 180, 0.25)',
          borderRadius: '10px',
          padding: '20px',
          marginBottom: '15px',
          cursor: 'pointer',
          transition: 'all 0.2s ease-in-out',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        <Typography
          sx={{
            padding: '10px 15px',
            border: '2px solid #828282',
            color: '#0034AE',
            fontWeight: '600',
            cursor: 'pointer',
            textTransform: 'uppercase',
            fontStyle: 'italic',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              // transform: 'translateY(-5px)',
              fontStyle: 'normal',
            },
          }}
        >
          {card.card.brand}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <Typography sx={{ fontWeight: '600', cursor: 'pointer' }}>
            **** **** **** {card.card.last4}
          </Typography>
          <Typography
            sx={{ fontWeight: '600', alignSelf: 'self-end', cursor: 'pointer' }}
          >
            {card.card.exp_month.toLocaleString('en-US', {
              minimumIntegerDigits: 2,
            })}
            /{card.card.exp_year.toString().slice(-2)}
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      key={staff?.id}
      mb="17px"
      sx={{
        background: '#F6F6F6',
        padding: '16px',
        borderRadius: '6px',
        width: '100%',
      }}
    >
      <Stack direction="row" alignItems="center">
        {imageSrc ? (
          <img src={imageSrc} alt="user" style={sxStyles.userImg} />
        ) : (
          <img
            src={PitfalImages[Math.floor(Math.random() * PitfalImages.length)]}
            alt="user"
            style={sxStyles.userImg}
          />
        )}
        <Box>
          <Typography
            sx={{ color: '#000000', fontSize: '20px', fontWeight: '600' }}
          >
            {staff?.staff_details?.first_name +
              ' ' +
              staff?.staff_details?.last_name}
          </Typography>
          <div style={{ marginTop: '8px' }}>
            <span
              style={{
                marginRight: '16px',
                color: '#848484',
                fontSize: '16px',
              }}
            >
              {staff.staff_distance_miles} miles
            </span>
            <span style={{ color: '#848484', fontSize: '16px' }}>
              mileage: {staff.mileage}
            </span>
          </div>
        </Box>
      </Stack>
      <Button
        variant="contained"
        sx={[sxStyles.btn, { marginRight: '15px' }]}
        onClick={() => {
          displayModal(staff?.staff_details?.id)
          // handleHire(staff?.staff_details?.id)
        }}
        disabled={isHireLoading}
      >
        {isHireLoading ? <CircularProgress size={24} /> : 'Hire'}
      </Button>
      <Button
        variant="outlined"
        sx={[sxStyles.btn, { color: '#000000', borderColor: '#000000' }]}
        onClick={handleDismiss}
        disabled={isDismissoading}
      >
        {isDismissoading ? <CircularProgress size={24} /> : 'Dismiss'}
      </Button>

      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle
          sx={{
            color: '#E3032A',
            fontWeight: '600',
            fontSize: '18px',
            textAlign: 'center',
          }}
        >
          Confirm Hiring
        </DialogTitle>
        <DialogContent>
          <Box display="flex" gap="10px">
            <Typography
              sx={{
                color: '#201C1C',
                fontWeight: '600',
                fontSize: { xs: '14px', sm: '14px', md: '16px' },
              }}
            >
              Shift price: ${shift.invoice_rate}
            </Typography>

            <Typography
              sx={{
                color: '#201C1C',
                fontWeight: '600',
                fontSize: { xs: '14px', sm: '14px', md: '16px' },
              }}
            >
              Mileage: ${staff.mileage}
            </Typography>

            <Typography
              sx={{
                color: '#201C1C',
                fontWeight: '600',
                fontSize: { xs: '14px', sm: '14px', md: '16px' },
              }}
            >
              Total price: ${staff.mileage + shift.invoice_rate}
            </Typography>
          </Box>

          {isLoading ? (
            <LinearProgress />
          ) : (
            <Box marginTop="15px">
              <Typography
                sx={{
                  color: '#E3032A',
                  fontSize: '14px',
                  marginBottom: '15px',
                }}
              >
                Choose payment method
              </Typography>

              {getPayment.isLoading ? (
                <Box
                  display="flex"
                  width="100%"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ minHeight: '250px', maxHeight: '76vh' }}
                >
                  <CircularProgress size={24} />
                </Box>
              ) : (
                getPayment.data?.data.map((card, index) => cardBox(card, index))
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={emptyMessage}
      />
    </Box>
  )
}

const sxStyles = {
  card: {
    zIndex: 1,
    position: 'absolute',
    top: 150,
    padding: '40px 25px',
    width: '660px',
  },
  heading: {
    fontWeight: '700',
    fontSize: '48px',
    lineHeight: '58px',
    color: 'custom.black',
  },
  summary: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#000000',
  },
  fullInput: {
    width: '100%',
    backgroundColor: 'custom.registerInput',
    marginBottom: 2,
    padding: '10px',
    borderRadius: '6px',
  },
  halfInput: {
    width: '48%',
    backgroundColor: 'custom.registerInput',
    marginBottom: 2,
    padding: '10px',
    borderRadius: '6px',
  },
  halfSelect: {
    width: '48%',
    marginBottom: 2,
    padding: '10px',
    borderRadius: '6px',
  },
  scheduleBtn: {
    borderRadius: '56px',
    backgroundColor: 'custom.registerInput',
    color: 'custom.black',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '19px',
  },
  detailsCard: {
    background: '#F6F6F6',
    padding: '16px',
    borderRadius: '6px',
    width: '100%',
  },
  subHeading: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#000000',
    marginBottom: 1,
  },
  details: {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#818181',
    marginBottom: 1,
    display: 'flex',
    alignItems: 'center',
  },
  userImg: {
    height: '50px',
    width: '50px',
    borderRadius: '50%',
    marginRight: '15px',
    objectFit: 'cover',
  },
  btn: {
    width: '100px',
    borderRadius: '47px',
    marginTop: '20px',
  },
}
