import React from 'react';
import { Button, Box, Typography } from '@mui/material';
import { CiLocationOn } from 'react-icons/ci';
import { BiPhoneCall } from 'react-icons/bi';
import { HiOutlineMail } from 'react-icons/hi';
import { BsPeople } from 'react-icons/bs';

export default function FacilityLeadCard({
  name,
  location,
  location2,
  availability,
  onClick,
  btnText,
  username,
  domain,
  phone,
  capacity,
  noButton,
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        padding: '18px',
        backgroundColor: '#fff',
        borderRadius: '6px',
        boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.04)',
        width: {
          xs: '100%',
          sm: '100%',
          md: '48%',
          lg: '32%',
        },
        flexWrap: 'wrap',
        marginTop: '25px',
        justifyContent: {
          xs: 'space-between',
          sm: 'space-between',
          md: 'space-between',
          lg: 'space-between',
        },
      }}
    >
      <Typography
        sx={{
          fontSize: {
            xs: '18px',
            sm: '18px',
            md: '18px',
            lg: '20px',
          },
          color: '#0C0C0D',
          fontWeight: '700',
        }}
      >
        {name}
      </Typography>
      <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
        <CiLocationOn color="#3F5263" />
        <Typography
          sx={{
            fontSize: '16px',
            color: '#3F5263',
            fontWeight: '400',
          }}
        >
          {location}, {location2}
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
        <BiPhoneCall color="#3F5263" />
        <Typography
          sx={{
            fontSize: '16px',
            color: '#3F5263',
            fontWeight: '400',
          }}
        >
          {phone}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          gap: '5px',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <HiOutlineMail color="#3F5263" />
        <Typography
          sx={{
            fontSize: '16px',
            color: '#3F5263',
            fontWeight: '400',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {username ? username : 'NO EMAIL!'}
        </Typography>
        <Typography
          display={username ? 'flex' : 'none'}
          sx={{
            fontSize: '16px',
            color: '#3F5263',
            fontWeight: '400',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            marginLeft: '-5px',
          }}
        >
          @{domain}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: {
            xs: 'flex-start',
            sm: 'flex-start',
            md: 'flex-start',
            lg: 'center',
          },
          justifyContent: 'space-between',
          flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'column',
            lg: 'row',
          },
        }}
      >
        {capacity && (
          <Box
            sx={{
              display: 'flex',
              gap: '5px',
              alignItems: 'center',
            }}
          >
            <BsPeople color="#3F5263" />
            <Typography
              sx={{
                fontSize: '16px',
                color: '#3F5263',
                fontWeight: '400',
              }}
            >
              Capacity {capacity}
            </Typography>
          </Box>
        )}
        {noButton ?? (
          <Button
            sx={{
              marginLeft: {
                xs: '0',
                sm: '0',
                md: '0',
                lg: '10px',
              },
              color: '#D95624',
              outlineColor: '#D95624',
              borderColor: '#D95624',
              width: { xs: '100%', sm: '100%', md: '100%', lg: 'auto' },
              marginTop: {
                xs: '10px',
                sm: '10px',
                md: '10px',
                lg: '0',
              },
            }}
            variant="outlined"
            onClick={onClick}
          >
            View details
          </Button>
        )}
      </Box>
    </Box>
  );
}
