// @ts-nocheck
import React from 'react'
import {
  IconButton,
  InputBase,
  Paper,
  Grid,
  Box,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import { Search } from '@mui/icons-material'
import { AutoComplete } from '..'

export default function FilterHeader({
  placeholderSearch,
  value,
  onChange,
  placeholderDropdown1,
  placeholderDropdown2,
  dropdown1Options,
  dropdown1Value,
  onChangeDropdown1,
  dropdown2Options,
  dropdown2Value,
  onChangeDropdown2,
  isNotActive,
  stateFilter,
}) {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Box
      container
      sx={[
        sxStyle.container,
        { marginTop: !matches && '60px', display: 'flex', flexWrap: 'wrap' },
      ]}
      columns={{ md: 12 }}
    >
      <Grid
        item
        xs={4}
        visibility={isNotActive ? 'hidden' : 'visible'}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Paper elevation={0} sx={sxStyle.search}>
          <IconButton aria-label="search">
            <Search />
          </IconButton>
          <InputBase
            sx={{ flex: 1 }}
            placeholder={placeholderSearch}
            inputProps={{ 'aria-label': placeholderSearch }}
            value={value}
            onChange={onChange}
          />
        </Paper>
      </Grid>
      <Grid
        sx={{ marginLeft: '10px' }}
        item
        visibility={isNotActive ? 'hidden' : 'visible'}
        xs={4}
      >
        <AutoComplete
          placeholder={placeholderDropdown1}
          options={dropdown1Options}
          value={dropdown1Value}
          onChange={onChangeDropdown1}
        />
        {placeholderDropdown2 && (
          <AutoComplete
            placeholder={placeholderDropdown2}
            options={dropdown2Options}
            value={dropdown2Value}
            onChange={onChangeDropdown2}
          />
        )}
      </Grid>
    </Box>
  )
}

const sxStyle = {
  container: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderBottom: 1,
    borderBottomColor: 'custom.border',
    padding: '20px 0px',
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  search: {
    p: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '12px',
    marginLeft: '20px',
  },
}
