// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { Box, Pagination, Typography } from '@mui/material'
import {
  ContactFacilityModal,
  StaffLeadCard,
  FilterHeader,
} from '../../components'
import { StaffLeadsService } from 'operational-sales-panel/services'
import { CitiesOptions } from 'constants'

function StaffLeadsContacted() {
  const [open, setOpen] = useState(false)
  const [details, setDetails] = useState()
  const [staffLeads, setStaffLeads] = useState()
  const [rowsPerPage, setRowsPerPage] = useState(9)
  const [contactName, setContactName] = useState()
  const [contactStatus, setContactStatus] = useState()
  const [staffLeadsModal, setStaffLeadsModal] = useState()
  const [currentPage, setCurrentPage] = useState(1)

  const [filter, setFilter] = useState({
    search: '',
    city: '',
  })

  const cold = '/assets/icons/cold.png'
  const hot = '/assets/icons/hot.png'
  const school = '/assets/icons/school.png'

  const handlePageChange = (event, page) => {
    setCurrentPage(page)
  }

  useEffect(() => {
    StaffLeadsService.getStaffLeadsContacted({
      currentPage,
      page_size: rowsPerPage,
      search: filter?.search,
      city: filter.city,
    })
      .then(data => setStaffLeads(data))
      .catch(err => console.log(err))
  }, [rowsPerPage, filter?.city, filter?.search, open, currentPage])

  const onMarkAsContacted = () => {
    StaffLeadsService.markContacted({
      id: staffLeadsModal?.id,
      contact_name: contactName,
      contact_status: contactStatus,
    })
      .then(() => setOpen(false))
      .catch(err => console.log(err))
  }

  const onMarkAsNoAnswer = () => {
    StaffLeadsService.markNoAnswer({
      id: staffLeadsModal?.id,
    })
      .then(() => setOpen(false))
      .catch(err => console.log(err))
  }

  const onMarkAsNotInterested = () => {
    StaffLeadsService.markNotInterested({
      id: staffLeadsModal?.id,
    })
      .then(() => setOpen(false))
      .catch(err => console.log(err))
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <FilterHeader
        placeholderSearch="Search staff"
        value={filter.search}
        onChange={e => setFilter({ ...filter, search: e?.target?.value })}
        placeholderDropdown1="City"
        dropdown1Options={CitiesOptions.cities}
        dropdown1Value={filter.city}
        onChangeDropdown1={(e, value) => setFilter({ ...filter, city: value })}
      />

      <Box sx={{ minHeight: '82vh', overflowX: 'hidden' }}>
        {staffLeads?.staff?.length === 0 ? (
          <Typography
            sx={{
              width: '100%',
              paddingLeft: '30px',
              paddingRight: '30px',
              marginTop: '15px',
              color: '#8694B0',
              fontSize: '20px',
            }}
          >
            No Data Avaialble!
          </Typography>
        ) : null}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            gap: '2%',
            justifyContent: {
              xs: 'flex-start',
              sm: 'flex-start',
              md: 'space-between',
              lg: 'flex-start',
            },
            paddingLeft: '30px',
            paddingRight: '30px',
            marginTop: '15px',
          }}
        >
          {staffLeads?.staff?.map((item, index) => {
            const [username, domain] =
              item?.email !== null ? item?.email?.split('@') : [null, null]
            return (
              <StaffLeadCard
                key={item?.id}
                name={`${item.first_name} ${item.last_name}`}
                location={item?.city}
                location2={item?.country}
                phone={item?.phone}
                username={username}
                domain={domain}
                btnText="View Details"
                isHotIcon={item.hot_staff ? hot : cold}
                isSchoolIcon={item.school_id === null ? null : school}
                onClick={() => {
                  setDetails(item)
                  setStaffLeadsModal(item)
                  setOpen(true)
                }}
              />
            )
          })}
        </Box>
      </Box>

      {staffLeads?.staff?.length ? (
        <Pagination
          sx={{ marginTop: '20px', marginBottom: '10px' }}
          count={staffLeads?.total_pages}
          page={currentPage}
          onChange={handlePageChange}
        />
      ) : null}

      {open && (
        <ContactFacilityModal
          item={details}
          open={open}
          onClose={() => {
            setOpen(false)
            setStaffLeadsModal()
          }}
          contactName={contactName}
          onChangeContactName={e => setContactName(e)}
          contactStatus={contactStatus}
          onChangeContactStatus={e => setContactStatus(e)}
          onMarkAsContacted={onMarkAsContacted}
          onMarkAsNotInterested={onMarkAsNotInterested}
          onMarkAsNoAnswer={onMarkAsNoAnswer}
          isContact={true}
          emailURL="/sales/staff_leads/email"
          notesURL="/sales/staff_leads/notes"
          smsURL="/sales/staff_leads/sms"
          noCall={true}
          noChat={true}
        />
      )}
    </Box>
  )
}

export default StaffLeadsContacted
